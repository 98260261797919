import { isEqual } from 'lodash';
import { useNavigateToNote } from '../../Utilities/NavigateTo';
import { useContext, useEffect, useMemo, useState } from 'react';
import { NotesContext } from '../../../Notes/Data/NotesContext';
import { Button, Modal, Tooltip } from 'antd';
import { Note } from '../../../Notes/Data/NoteType';
import { getJSONFormsObject, JSONFormsObject, saveJSONFormsObject } from '../../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject';
import { ChatMessage2 } from './ChatLog';
import { newNote, useSaveNote } from '../../../Notes/Data/NoteDBHooks';
import { useNoteDraftJS } from '../../../Notes/Data/FirestoreNoteClient';
import { SaveOutlined, MergeCellsOutlined, EditOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { RawDraftContentState, RawDraftContentBlock, EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { FIREBASE_FIRESTORE } from '../../../AppBase/App';
import { SelectedJSONFormsContext } from '../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext';
import { addNoteToParent } from '../../../Notes/Data/Actions/Tree/ManipulateTree';
import { convertMarkdownToDraftJSRaw } from '../../../Notes/UIs/NoteInformationComponents/DraftJSFromHTML';
import { SELECTED_TAB_IS_DATA, SELECTED_TAB_IS_NOTE } from '../../../Notes/UIs/NotePageAndHigher/NotePage';
import { extractJSONFromMarkdown, removeJSONFromMarkdown, convertDraftJSToMarkdown } from '../../../ServerConnection/LLMServer/MarkdownConverter';
//@ts-ignore
import ReactJsonViewCompare from 'react-json-view-compare';
import PluginEditor from '@draft-js-plugins/editor';
import { useBlockRendererFn, blockStyleFn } from '../../../Notes/UIs/DraftJSEditor/BlockEditing/BlockRendererAndStyleFns';
import { useDraftExtensions } from '../../../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/DraftJsToolbarAndPlugins';
import { useBasicHandleKeyCommand, useBasicKeyBindingFn } from '../../../Notes/UIs/DraftJSEditor/DraftKeyboardBindings';
import AutoPlayRecommendedMusicCheckbox from "./AutoPlayRecommendedMusicCheckbox";
import { commandCanGenerateMusic } from "../../../Extensions/TTRPG/TTRPGExtension";
import { InclusionByCommand, NoteInclusionType } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import InlineDraftJSViewer from '../../../Notes/UIs/InlineDraftJSViewer';
import { getBlockRenderMap } from '../../../Notes/UIs/DraftJSEditor/BlockEditing/BlockRenderMap';
import { getFreshUpdatedContentState } from '../../../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/GetFreshUpdatedContentState';
import { useExtensions } from '../../../Extensions/ExtensionsFramework/GetExtension';

const DEBUG = false;


function OverwriteNoteModal({loadedNote, loadedJSON, isModalOpen, setModalIsOpen, message, setMessage, isWaitingForChat}:{loadedNote:Note, loadedJSON:JSONFormsObject|null, isModalOpen:boolean, setModalIsOpen:(open:boolean)=>void, message:ChatMessage2, setMessage:(message:ChatMessage2)=>void, isWaitingForChat:boolean}) {
    const navigateToNote = useNavigateToNote();
    const saveNote = useSaveNote();

    const loadedNoteDraftJS = useNoteDraftJS(loadedNote);

    const {draftDiffers, jsonDiffers, newNote, newMessage} = useMemo(()=>{
        const {newNote, newMessage, hasDraftJSContent} = getSaveableNoteFrom(loadedNote.doc_name, message);

        const draftIsSame = isEqual(loadedNoteDraftJS, message.draftRawContent);
        const draftDiffers = hasDraftJSContent && !draftIsSame;
        const jsonDiffers = !isEqual(loadedJSON, message.jsonContent);
        // console.log("[OverwriteNoteModal]>useMemo> hasDraftJSContent: ",hasDraftJSContent, "  draftDiffers:",draftDiffers);
        return {draftDiffers, jsonDiffers, newNote:{...newNote, id:loadedNote.id} as Note, newMessage};
    },[loadedNote, loadedJSON, message]);

    async function submitChanges() {
        // TODO keep it open but replace it with a spinner and remove the buttons. (Or just close it and show a spinner on the main page).
        setModalIsOpen(false);
        if (jsonDiffers) {
            await saveJSONFormsObject(FIREBASE_FIRESTORE, loadedNote.id, message.jsonContent);
            // Bug: If (and only if) the note was the current editor open, this does not refresh the JSON editor. We need to do that manually somehow, perhaps via the current note context.
        }
        if (draftDiffers) {
            saveNote(newNote);
            if (newMessage)
                setMessage(newMessage);
        }
        // Sleep for a moment before going to that page... Giving it a moment to catch up seems to allow it to become fresh.
        await new Promise(r => setTimeout(r, 100));
        if (jsonDiffers && !draftDiffers)
            navigateToNote(newNote.id, SELECTED_TAB_IS_DATA);
        else 
            navigateToNote(newNote.id, SELECTED_TAB_IS_NOTE);
        if (jsonDiffers && (!loadedNote.type || loadedNote.type==="Note")) {
            alert("Please select a type for this note manually. (Until then, you won't be able to see the JSON).");
        }
    }


    // TODO Use a visual diff for the draftjs instead of a JSON viewer.

    return <Modal
        title="Overwrite Note?"
        closable={true}
        open={isModalOpen}
        onOk={submitChanges}
        onCancel={()=>{setModalIsOpen(false)}}
        okText="Overwrite"
        cancelText="Cancel"
        width="100vw" style={{ top: 20, minHeight: "100vh"}}
        footer={[
            <Button key="back" type="default" onClick={()=>{setModalIsOpen(false)}} disabled={isWaitingForChat}>Cancel</Button>,
            <Button key="submit" type="primary" onClick={submitChanges} disabled={isWaitingForChat}>Overwrite</Button>,
        ]}
        >
            {draftDiffers && <>
                <h3>Note Text</h3>
                <ReactJsonViewCompare oldData={loadedNoteDraftJS} newData={message.draftRawContent}/>
            </>}
            {jsonDiffers && <>
                <h3>Data</h3>
                <ReactJsonViewCompare oldData={loadedJSON} newData={message.jsonContent}/>
            </>}
       <p>Are you sure you want to overwrite the note?</p>
       
    </Modal>;
}

const HEADER_TYPES = ["header-one","header-two","header-three"];

function getFirstHeader(doc_data:RawDraftContentState) {
    // Remove any lines before the first H1 (sometimes the bot creates a description saying what it's creating)
    // And mark whether there are additional contents below the H1.
    for (let i=0;i<doc_data.blocks.length;i++) {
        // Sometimes it generats the first header as being another type besides H1.
        if (HEADER_TYPES.includes(doc_data.blocks[i].type)) {
            doc_data.blocks[i].type="header-one"; // make it header-one if it was a lower level header.
            return {found: true, doc_name: doc_data.blocks[i].text, index:i};
        }
    }
    return {found:false, index: null};
}

function getSaveableNoteFrom(doc_name:string, message:ChatMessage2) {
    // Create a new note
    const newNote = {doc_name:doc_name} as Note;
    let newMessage = null as ChatMessage2|null;
    let hasDraftJSContent = false;
    let needsAType = false;
    if (message.draftRawContent) {
        let doc_data = message.draftRawContent;
        // Remove any lines before the first H1 (sometimes the bot creates a description saying what it's creating)
        // And mark whether there are additional contents below the H1.
        const {index: indexOfFirstBlockWithHeader} = getFirstHeader(doc_data);
        // let indexOfFirstBlockWithHeader = null as number|null;
        // for (let i=0;i<doc_data.blocks.length;i++) {
        //     // Sometimes it generats the first header as being another type besides H1.
        //     if (HEADER_TYPES.includes(doc_data.blocks[i].type)) {
        //         indexOfFirstBlockWithHeader = i;
        //         doc_data.blocks[i].type="header-one"; // make it header-one if it was a lower level header.
        //         break;
        //     }
        // }
        if (indexOfFirstBlockWithHeader===null) {
            // Add the title block in as the first block.
            const titleBlock = {text:doc_name,type:"header-one",key:"HeaderBlock"+Math.random()} as unknown as RawDraftContentBlock;
            doc_data.blocks = [titleBlock, ...doc_data.blocks];
        } else if (indexOfFirstBlockWithHeader>0) {
            // Trim blocks before the header.
            doc_data.blocks = doc_data.blocks.slice(indexOfFirstBlockWithHeader);                
        }
        if (doc_data.blocks.length>1)
            hasDraftJSContent = true;
        newNote.doc_data=doc_data;
        newMessage = {...message, draftRawContent: doc_data} as ChatMessage2;
    }
    if (message.jsonContent) {
        // We also need to know the note type. Figure it out from the JSON.
        // TODO JSONFormsObject should have a type field, and we should use that in other places.
        if (message.jsonContent.type) {
            newNote.type=message.jsonContent.type;
        } else {
            // TODO pass this back
            needsAType = true;
            // alert("Please select the type for this note manually, it was not inclueded in the JSON.");
        }
    }
    return {newNote, newMessage, hasDraftJSContent, needsAType};
}

function InlineDraftJSEditor({draftRawContent, setDraftJSMessage, ref, isWaitingForChat}:{draftRawContent:RawDraftContentState, setDraftJSMessage:(message:RawDraftContentState)=>void, ref?:React.Ref<PluginEditor>, isWaitingForChat:boolean}) {
    const [editorState, setEditorStateLocal] = useState(() => EditorState.createWithContent(convertFromRaw(draftRawContent)));
    function setEditorState(newEditorState:EditorState) {
        setEditorStateLocal(newEditorState);
        setDraftJSMessage(convertToRaw(newEditorState.getCurrentContent()));
    }
    const basicKeyBindingFn = useBasicKeyBindingFn(editorState);
    const basicHandleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
    const blockRenderFn = useBlockRendererFn(setEditorState, editorState, false);
    const {headerComponents, plugins, extendedHandleKeyCommand} = useDraftExtensions(setEditorState,editorState, true, basicHandleKeyCommand);
  
    return <div className="editor inline-editor-main">
        {headerComponents}
        <Editor
            editorState={editorState}
            onChange={setEditorState}
            blockRendererFn={blockRenderFn}
            blockRenderMap={getBlockRenderMap()}
            blockStyleFn={blockStyleFn}
            plugins={plugins}
            handleKeyCommand={extendedHandleKeyCommand}
            keyBindingFn={basicKeyBindingFn}
            readOnly={isWaitingForChat}
            ref={ref}
        /></div>;
}

export function AssistantJSONContent({jsonContent, setNextMessage, isWaitingForChat}:{jsonContent:JSONFormsObject, setNextMessage:(nextMessage:string)=>void, isWaitingForChat:boolean}) {
    if (!jsonContent) {
        return <></>;
    }
    let nextLinks = null as string[]|null;
    if (jsonContent && jsonContent.next && Array.isArray(jsonContent.next)
        && jsonContent.next.every((nextLink:any)=>typeof nextLink==="string")) {
        nextLinks = jsonContent.next as string[];
    }

    const jsonComponents = [] as JSX.Element[];

    if (nextLinks) {
        // Added key to the wrapping div element.
        jsonComponents.push(
            <div key="next-links">
                {nextLinks.map((nextLink:string, index:number)=>
                    <Button key={`${nextLink}-${index}`} onClick={()=>{setNextMessage(nextLink)}} type='link' disabled={isWaitingForChat}>
                        {nextLink}
                    </Button>
                )}
            </div>
        );
    } else {
        // Added key to the fallback div element.
        jsonComponents.push(<div key="json-data">JSON: {JSON.stringify(jsonContent)}</div>);
    }
    return <>{jsonComponents}</>;
}


export function AssistantMessage({message:messageIncoming, prevMessage, deleteSelectedMessage, setNextMessage, setMessage, editing, setEditing, scrollToRef, linkToNote, extensions, isWaitingForChat, regenerateMessage}:{message:ChatMessage2, prevMessage?:ChatMessage2, deleteSelectedMessage?:()=>void, setNextMessage:(nextMessage:string)=>void, setMessage:(message:ChatMessage2)=>void, editing: boolean, setEditing:(editing:boolean)=>void, scrollToRef?:React.Ref<HTMLBRElement>, linkToNote: any, extensions: any[], isWaitingForChat:boolean, regenerateMessage:()=>void}) {
    const notesContext = useContext(NotesContext);
    const { extensions: extFromHook } = useExtensions();
    const navigateToNote = useNavigateToNote();
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const [isOverwriteModalOpen, setOverwriteModalOpen] = useState(false);
    const message = useMemo(()=>{
        // Convert the incoming message from markdown to draftjs & json.
        if (messageIncoming.role!== 'assistant' && messageIncoming.role !== 'system') {
            console.error("   AssistantMessage only accepts assistant and system messages. Got:",messageIncoming.role);
            debugger;
            return messageIncoming;
        }
        if (messageIncoming.userHasMadeEdits) {
            return messageIncoming; // already converted.
        }
        let markdownText = (messageIncoming.content as string);
        if (markdownText)
            markdownText = markdownText.trim();
        else
            markdownText = "";
        const jsonContent = extractJSONFromMarkdown(markdownText) as JSONFormsObject | null;
        if (jsonContent) {
            markdownText = removeJSONFromMarkdown(markdownText).trim();
        }
        const draftJSMessage = convertMarkdownToDraftJSRaw(markdownText);
        const freshContentState = getFreshUpdatedContentState(convertFromRaw(draftJSMessage), notesContext, extensions.length ? extensions : extFromHook);
        const freshDraftJSMessage = convertToRaw(freshContentState);
        return {...messageIncoming, draftRawContent: freshDraftJSMessage, jsonContent} as ChatMessage2;
    },[messageIncoming, messageIncoming.content, notesContext, extensions, extFromHook]);
    const doc_name = useMemo(() => {
        if (!message.draftRawContent)
            return null;
        const {doc_name, index: blockWithH1} = getFirstHeader(message.draftRawContent as RawDraftContentState);
        if (blockWithH1 !== null) {
            return doc_name;
        }
        // console.log("[AssistantMessage]>useMemo> doc_name:",doc_name," hasNoteTitle:",hasNoteTitle," draftRawContent:",message.draftRawContent);
        return null;
    },[message.draftRawContent]);
    const [loadedNote, setLoadedNote] = useState(null as Note|null);
    const [loadedJson, setLoadedJson] = useState(null as JSONFormsObject|null);
    const [noteIsDifferent, setNoteIsDifferent] = useState(false);
    const [errorNotesWithSameName, setErrorNotesWithSameName] = useState([] as Note[]);
    useEffect(() => { // Load note by this name
        if (!doc_name) {
            setLoadedNote(null);
            return;
        }
        async function checkIfNoteExists() {
            if (DEBUG) console.log("[AssistantMessage]>useEffect> Checking if note exists for doc_name:",doc_name);
            const notes = await notesContext.getNotesOfName(doc_name as string);
            if (notes.length===0) {
                setLoadedNote(null);
                setLoadedJson(null);
                setErrorNotesWithSameName([]);
                return;
            } else if (notes.length>1) {
                setLoadedNote(null);
                setLoadedJson(null);
                setErrorNotesWithSameName(notes);
                return;
            } else if (notes.length===1) {
                setLoadedNote(notes[0]);
                const jsonFormsObject = await getJSONFormsObject( notes[0].id);
                setLoadedJson(jsonFormsObject);
                setErrorNotesWithSameName([]);
            }
        }
        checkIfNoteExists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[doc_name]);
    useEffect(()=> { // Check for changes vs the note.
        if (!loadedNote || !message.draftRawContent) {
            setNoteIsDifferent(false);
            return;
        }
        const draftIsSame = isEqual(loadedNote.doc_data, message.draftRawContent);
        const jsonIsSame = isEqual(loadedJson, message.jsonContent);
        setNoteIsDifferent(!draftIsSame || !jsonIsSame);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadedNote,message.draftRawContent]);
    async function saveAsNewNote(doc_name:string) {
        if (!doc_name) {
            alert("Unfortunately, there was a bug. The system is having trouble processing the doc name.");
            if (DEBUG) console.error("[AssistantMessage]>saveAsNewNote> doc_name is blank. saveAsNewNote should not be callable in this case.");
            debugger;
            return;
        }
        // Create a new note
        const {newNote: newNoteDoc, newMessage, hasDraftJSContent} = getSaveableNoteFrom(doc_name, message);
        if (newMessage)
            setMessage(newMessage);
        const parentNote = selectedJsonFormsContext.note;
        if (parentNote)
            newNoteDoc.parent=parentNote.id;
        const createdNote = await newNote(FIREBASE_FIRESTORE, doc_name, newNoteDoc);
        notesContext.notesHaveBeenLoaded([createdNote]);
        if (message.jsonContent)
            await saveJSONFormsObject(FIREBASE_FIRESTORE, createdNote.id, message.jsonContent);
        if (parentNote) {
            await addNoteToParent(FIREBASE_FIRESTORE, createdNote, parentNote.id, null, notesContext);
        }
        if (message.jsonContent && !hasDraftJSContent)
            navigateToNote(createdNote.id, SELECTED_TAB_IS_DATA);
        else 
            navigateToNote(createdNote.id, SELECTED_TAB_IS_NOTE);
        setLoadedNote(createdNote);
        setNoteIsDifferent(false);
        return;
    }
    function saveAsAskForName() {
        const doc_name = prompt("Please enter a name for this note.");
        if (!doc_name) {            
            return;
        }
        saveAsNewNote(doc_name);
    }
    
    let noteAction = <></>;
    if (!doc_name) {
        noteAction = <Tooltip title="Choose a name and save as a new note">
            <Button type="text" onClick={saveAsAskForName} icon={<SaveOutlined />} disabled={isWaitingForChat}/>
        </Tooltip>;
    } else { // doc_name exists: there is a note title.
        if (errorNotesWithSameName.length>1) {
            noteAction = <div style={{color:"red"}}>There are multiple notes with the same name. Please delete one of them before trying to save this: {errorNotesWithSameName.map((note:Note,index:number) => <div key={note.id}>
                <Button type="link" onClick={()=>{navigateToNote(note.id)}} key={note.id}>"{note.doc_name}" ({index+1})</Button>
                </div>)}
            </div>;
        } else if (loadedNote!==null) {
            if (noteIsDifferent) {
                noteAction = <Tooltip title="Update the existing note. This will save over it">
                    <Button type="link" onClick={()=>{navigateToNote(loadedNote.id)}}>Go to "{doc_name}"</Button>
                    <Button type="text" onClick={()=>{setOverwriteModalOpen(true)}} icon={<MergeCellsOutlined />} disabled={isWaitingForChat}>Overwrite "{doc_name}"</Button>
                </Tooltip>;
            } else {
                noteAction = <Tooltip title="Note exists and is the same.">
                    <Button type="link" onClick={()=>{navigateToNote(loadedNote.id)}}>Go to "{doc_name}"</Button>
                </Tooltip>;
            }
        } else {
            // Note doesn't exist yet
            noteAction = <Tooltip title={"Will create a new note named \""+doc_name+"\""}>
                <Button type="text" onClick={()=>saveAsNewNote(doc_name)} icon={<SaveOutlined />} disabled={isWaitingForChat}/>
            </Tooltip>;
        }
    }
    
    // console.log("[AssistantMessage]>render> loadedNote:",loadedNote," loadedJson:",loadedJson," errorNotesWithSameName:",errorNotesWithSameName," noteIsDifferent:",noteIsDifferent)

    const setDraftJSMessage = async (draftRawContent: RawDraftContentState) => {
        const markdownContent = await convertDraftJSToMarkdown(draftRawContent, notesContext, linkToNote, extensions);
        setMessage({...message, draftRawContent: draftRawContent, content: markdownContent, userHasMadeEdits: true} as ChatMessage2);
    };

    // Extract command list from prevMessage.extra.notesIncluded and check if any command can generate music.
    const commandNoteList = (prevMessage?.extra?.notesIncluded || []).filter((noteInclusionType:NoteInclusionType)=>noteInclusionType.type===InclusionByCommand);
    const showMusicCheckbox = commandNoteList.some((command: any) => commandCanGenerateMusic(command.noteID));

    return <div>
        {/* {message.extra && <div>There is some extra on the assistant message. It's not being rendered.</div>} */}
        {noteIsDifferent && loadedNote && errorNotesWithSameName.length===0 &&
            <OverwriteNoteModal
                loadedNote={loadedNote}
                loadedJSON={loadedJson}
                isModalOpen={isOverwriteModalOpen} setModalIsOpen={setOverwriteModalOpen} message={message} setMessage={setMessage}
                isWaitingForChat={isWaitingForChat}/>
        }
        {!editing && message.draftRawContent && <>
            <InlineDraftJSViewer draftJSMessage={message.draftRawContent}/>
            {message.jsonContent && <AssistantJSONContent jsonContent={message.jsonContent} isWaitingForChat={isWaitingForChat} setNextMessage={setNextMessage} />}
            <Tooltip title="Edit this response">
                <Button type="text" onClick={()=>{setEditing(true)}} icon={<EditOutlined/>} disabled={isWaitingForChat}/>
            </Tooltip>&nbsp;&nbsp;
            {noteAction}
            <Tooltip title="Regenerate response">
                <Button type="text" onClick={regenerateMessage} icon={<ReloadOutlined />} disabled={isWaitingForChat || !prevMessage}/>
            </Tooltip>&nbsp;&nbsp;
            <Tooltip title="Delete this response">
                <Button type="text" onClick={deleteSelectedMessage} icon={<DeleteOutlined />} disabled={isWaitingForChat}/>
            </Tooltip>&nbsp;&nbsp;
            {showMusicCheckbox && <AutoPlayRecommendedMusicCheckbox />}
        </>}
        {editing && message.draftRawContent && <>
            <InlineDraftJSEditor
                draftRawContent={message.draftRawContent}
                setDraftJSMessage={setDraftJSMessage}
                isWaitingForChat={isWaitingForChat}
                />
            {message.jsonContent && <AssistantJSONContent jsonContent={message.jsonContent} isWaitingForChat={isWaitingForChat} setNextMessage={setNextMessage} />}
            <Tooltip title="Stop editing">
                <Button type="default" onClick={()=>{setEditing(false)}} disabled={isWaitingForChat}>Done</Button>
            </Tooltip>&nbsp;&nbsp;
            {noteAction}
            {showMusicCheckbox && <AutoPlayRecommendedMusicCheckbox />}
        </>}
        <br ref={scrollToRef}/>
    </div>;
}
