import React from "react";
import { CharacterMetadata, ContentBlock, ContentState, EditorBlock, EditorState, RawDraftContentBlock } from "draft-js";
import Immutable from "immutable";
import { Button, Switch, Tooltip } from "antd";
import { addBlockAfter, addLink, newBlock } from "./EditCheckableItemUtils";
import { COMMENT_BLOCK } from "./CommentBlock";
import { NotesContextType } from "../../../Data/NotesContext";
import { getNoteNameAndEmoji_async } from "../../../NotesTree/TreeUtilities/CreateTagTreeNodes";
import { getMusicTagsFromURL, getNoteIDFromURL, getURLForMusicTags, getURLForNoteId, useNavigateToMusic, useNavigateToNote } from "../../../../DecisionGraph/Utilities/NavigateTo";
import { useNavigateToVoice } from "../../NotePageAndHigher/ReplicaVoiceSideMenuAndSidebar";
import { getVoiceIDFromURL } from "../../NotePageAndHigher/ReplicaVoiceSideMenuAndSidebar";
import { getURLForVoiceId } from "../../NotePageAndHigher/ReplicaVoiceSideMenuAndSidebar";
import { ReplicaPitch, ReplicaVoice, useReplicaVoices } from "../../../../DecisionGraph/Utilities/Sound/ReplicaAPI";
import { Note } from "../../../Data/NoteType";
import { getNoteDraftJS } from "../../../Data/FirestoreNoteClient";
import { LINK_TO_NOTE_BLOCK_TYPE } from "./BlockConstants";

export const NOTE_LINK_ENTITY_TYPE = "NOTE_LINK";
export const VOICE_LINK_ENTITY_TYPE = "VOICE_LINK";
export const MUSIC_LINK_ENTITY_TYPE = "MUSIC_LINK";
export const SYNTHESIZED_LINE_ENTITY_TYPE = "SYNTHESIZED_LINE";

/*****
 * Inserts a special note block, with a lot of options.
 */
export function insertANoteIntoFocusedBlock(editorState: EditorState, doc_id: string, doc_name: string):EditorState {
    let block = newBlock(LINK_TO_NOTE_BLOCK_TYPE);
    let newData = block.getData().set("doc_id",doc_id).set("doc_name",doc_name).set("checked",true);
    //@ts-ignore
    block = block.set("data",newData);
    return addBlockAfter(editorState, editorState.getSelection().getFocusKey(), block);
}


/********
 * Inserts an entity link, nothing special, basically a regular entity, not a special block like the above.
 */
export async function insertANoteLinkEntityIntoFocusedBlock(editorState: EditorState, doc_id: string, doc_name: string, notesContext:NotesContextType):Promise<EditorState> {
  const note = await notesContext.loadNoteID(doc_id,true);
  const link_text = await getNoteNameAndEmoji_async(note, notesContext);
  const url = getURLForNoteId(doc_id);
  return addLink(editorState, link_text, url, "", NOTE_LINK_ENTITY_TYPE, "IMMUTABLE");
}

export function createNoteLinkEntity(contentState:ContentState, doc_id:string) {
  const url = getURLForNoteId(doc_id);
  const linkTargetOption:string ="_blank";
  const newContentState = contentState
  .createEntity(NOTE_LINK_ENTITY_TYPE, "IMMUTABLE", {
    url,
    targetOption: linkTargetOption,
  });
  const entityKey = newContentState.getLastCreatedEntityKey();
  return {newContentState, entityKey};
}

export function getVoiceTagText(voice_name:string, pitch:ReplicaPitch) {
  let text = "🗣 Voice: "+voice_name;
  if (pitch!==0) {
    if (pitch>0)
      text += " pitch+"+pitch;
    else
      text += " pitch"+pitch;
  }
  return text;
}

/********
 * Inserts an entity link, nothing special, basically a regular entity, not a special block like the above.
 */
export function insertAVoiceLinkEntityIntoFocusedBlock(editorState: EditorState, voice_id: string, voice_name: string, pitch:ReplicaPitch):EditorState {
  // console.log("[LinkToNoteBlockUtils.insertAVoiceLinkEntityIntoFocusedBlock] voice_id=",voice_id,"voice_name=",voice_name);
  return addLink(editorState, getVoiceTagText(voice_name, pitch), getURLForVoiceId(voice_id, pitch), "", VOICE_LINK_ENTITY_TYPE, "IMMUTABLE");
}
export function getMusicTagText(tags:string[]) {
  return "🎵 Music: "+tags.join(",");
}
export function insertAMusicLinkEntityIntoFocusedBlock(editorState: EditorState, tags: string[]):EditorState {
  // Interestingly, when we insert the full text, exactly, with getMusicTaxText() as the default text, we find editing errors after insert.
  // When we just insert less text, it works fine.
  // The same is happening with the insert of the voice name, where we insert something shorter. "Music: ..." is too long.
  return addLink(editorState, getMusicTagText(tags), getURLForMusicTags(tags), "", MUSIC_LINK_ENTITY_TYPE, "IMMUTABLE");
}

// We tried a dropdown, but perhaps because of the rendering approach of the editor, it often disappears instantly or after a few seconds.
// So we use a several types of toggles for inclusion.

export const INCLUSION_TYPE_FULL = "Full";
export const INCLUSION_TYPE_LINK = "Link";
export const INCLUSION_TYPE_MENTIONABLE = "Mention";
export const INCLUSION_TYPE_EDIT_CONTEXT = "EditContext";

export const FLAG_INCLUSION_V2 = true;

const INCLUSION_OPTIONS = [
  // This must stay as index 0, because it's the default
  {value: INCLUSION_TYPE_FULL,
    label: "📄",
    tooltip:"Full body included in the AI prompt"},
  {value: INCLUSION_TYPE_LINK,
    label: "🔗",
    tooltip:"A link to this note will be included in the AI prompt, so the chat can print links to it"},
  {value: INCLUSION_TYPE_MENTIONABLE,
    label: "🗣",
    tooltip:"Mentionable: If the user talks about this in chat, it will be included in the AI prompt. The title of the note is used to determine whether the user has mentioned it"},
  {value: INCLUSION_TYPE_EDIT_CONTEXT,
    label: "✏",
    tooltip:"Context for editing: The full body of this note will be included in the AI prompt if the user hints that they are trying to edit this"},
];
const INCLUSION_OPTIONS_V2 = [
  {value: INCLUSION_TYPE_FULL,
    label: "📄",
    tooltip:"Always included in the AI prompt"},
  {value: INCLUSION_TYPE_LINK,
    label: "🔗",
    tooltip:"Include a link in the AI prompt. Dynamically include the rest of the text in the AI prompt if directly mentioned."},
  // TODO implement this in the ChatEditorV2OnNotePage's preprocessing:
  {value: INCLUSION_TYPE_EDIT_CONTEXT,
      label: "✏",
      tooltip:"Context for editing: The full body of this note will be included in the AI prompt if the user hints that they are trying to edit this"},  
];

/*
  Bug: This doesn't get repropogated when editable is changed, so we don't re-render and the button stays enabled/disabled. You have to refresh the page to see it.
  We haven't fixed this because we're considering deprecating this type of link, because this kind of component doesn't seem to really be intended in draft-js's design.
  */
export class LinkToNoteBlock extends React.Component {
  render() {
    // const linkToNote = useLinkToNoteId();
    //@ts-ignore
    const { offsetKey, blockProps: { onClick, doc_name, emoji, doc_id, checked, onChangeChecked, inclusion, onChangeInclusion, editable } } = this.props;
    // console.log("LinkToNoteBlock.render: doc_id=",doc_id,"doc_name=",doc_name,"this.props=",this.props);

    const inclusionButtonText = inclusion ? INCLUSION_OPTIONS.find((option)=>option.value===inclusion)?.label : INCLUSION_OPTIONS[0].label;
    function onCycleInclusionType() {
      if (FLAG_INCLUSION_V2) {
        if (!inclusion) {
          // if there's no index, we assume it as 0, so the next is 1:
          onChangeInclusion(INCLUSION_OPTIONS_V2[1].value);
          return;
        }
        let index = INCLUSION_OPTIONS_V2.findIndex((option)=>option.value===inclusion);
        index = (index+1) % INCLUSION_OPTIONS_V2.length;
        onChangeInclusion(INCLUSION_OPTIONS_V2[index].value);
        return;
      }

      // cycle through the inclusion types
      if (!inclusion) {
        onChangeInclusion(INCLUSION_OPTIONS[1].value);
        return;
      }
      let index = INCLUSION_OPTIONS.findIndex((option)=>option.value===inclusion);
      index = (index+1) % INCLUSION_OPTIONS.length;
      onChangeInclusion(INCLUSION_OPTIONS[index].value);
    }
    let tooltipText;
    if (!checked) {
      tooltipText = "Not included in the AI prompt";
    } else 
      tooltipText = inclusion ? INCLUSION_OPTIONS.find((option)=>option.value===inclusion)?.tooltip : INCLUSION_OPTIONS[0].tooltip;
    
    return (
        <div
          // className={`checkable-list-item-block${checked ? ' is-checked' : ''}`}
          data-offset-key={offsetKey}
        >
          <div
            className='link-to-note-block__controls'
            contentEditable={false}
            suppressContentEditableWarning
          >
            <Button type="link" onClick={()=>onClick(doc_id,doc_name)}>{emoji?emoji+" ":""}{doc_name}</Button>
            <Tooltip title={tooltipText}>
              <Switch size="small" checked={checked} onChange={onChangeChecked} disabled={!editable}/>
              {checked && 
                <Button type="text" onClick={onCycleInclusionType} disabled={!editable}>{inclusionButtonText}</Button>}
            </Tooltip>
          </div>
          <div className='link-to-note-block__text link-to-note-block__controls'>
            <EditorBlock {...this.props}  />
          </div>
        </div>
      );
  }
}

export function getLinkToNoteBlockRenderMap() {
  return Immutable.Map({
    [/*LINK_TO_NOTE_BLOCK_TYPE*/"link-to-note"]: {
      element: 'li',
      wrapper: <ul />,
    },
  });
}

/****************************************
 * For regular links, we use a decorator.
 * 
 */
function findNoteLinkEntities(contentBlock:ContentBlock, callback: (start: number, end: number) => void, contentState:ContentState):void {
  contentBlock.findEntityRanges(
    (character:CharacterMetadata) => {
      const entityKey = character.getEntity();
      if (!entityKey) return false;
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() !== NOTE_LINK_ENTITY_TYPE) return false;
      // if (entity.getType() !== 'LINK') return false;
      const data = entity.getData();
      if (!data.url) return false;
      const noteID = getNoteIDFromURL(data.url);
      if (!noteID) {
        // console.warn("findNoteLinkEntities: found a link that doesn't look like a note link. data=",data);
        return false;
      }
      // console.log("findNoteLinkEntities: found note link to note ID ",noteID," data=",data," text=",text, " character=",character);
      return true;
    },
    callback
    // loggedCallback
  );
}


export function findSomeLinkEntities(contentBlock:ContentBlock, contentState:ContentState, callback: (start: number, end: number) => void):void {
  contentBlock.findEntityRanges(
    (character:CharacterMetadata) => {
      const entityKey = character.getEntity();
      if (!entityKey) return false;
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() === NOTE_LINK_ENTITY_TYPE) {
        const data = entity.getData();
        if (!data.url) return false;
        const noteID = getNoteIDFromURL(data.url);
        if (!noteID) {
          // console.warn("findNoteLinkEntities: found a link that doesn't look like a note link. data=",data);
          return false;
        }
        // console.log("findNoteLinkEntities: found note link to note ID ",noteID," data=",data," text=",text, " character=",character);
        return true;
      }
      if (entity.getType() === MUSIC_LINK_ENTITY_TYPE) {
        const data = entity.getData();
        if (!data.url) return false;
        const musicTags = getMusicTagsFromURL(data.url);
        if (!musicTags || musicTags.length===0) {
          return false;
        }
        return true;
      }
      if (entity.getType() === SYNTHESIZED_LINE_ENTITY_TYPE) {
        // const data = entity.getData();
        // if (!data.voice_uuid) return false;
        return true;
      }
      if (entity.getType() === "LINK") {
        const data = entity.getData();
        if (!data.url) return false;
        return true;
      }
      return false;
    }, callback);
}

/***********
 * Eventually we'll have this retrieve all link entities, and be able to use it in place of the other ones if we want. Then we'd rename it to get all link entities.
 * 
 */
export function getSomeLinkEntities(contentBlock:ContentBlock, contentState:ContentState) {
  const regularLinks = [] as URLLinkInNote[];
  const noteLinks = [] as NoteLinkInNote[];
  // const noteLinksAsBlocks = [] as NoteLinkInNote[];
  const musicLinks = [] as MusicLinkInNote[];
  const synthesizedTextEntities = [] as SynthesizedLineInNote[];
  findSomeLinkEntities(contentBlock, contentState, function callback(start:number, end:number) {
      const isInComment = contentBlock.getType()===COMMENT_BLOCK;
      const entityKey = contentBlock.getEntityAt(start);
      if (!entityKey) return;
      const entity = contentState.getEntity(entityKey);
      const data = entity.getData();
      const text = contentBlock.getText().slice(start,end);
      if (entity.getType() === NOTE_LINK_ENTITY_TYPE) {
        const noteID = getNoteIDFromURL(data.url);
        if (!noteID) {
          console.error("[getNoteLinkEntities] couldn't get note id from url=",data.url);
          return;
        }
        noteLinks.push({doc_id:noteID, offsetStart: start,offsetEnd:end, text: text, type: NOTE_LINK_ENTITY_TYPE, isInComment} as NoteLinkInNote);
      } else if (entity.getType() === MUSIC_LINK_ENTITY_TYPE) {
        const musicTags = getMusicTagsFromURL(data.url);
        if (!musicTags || musicTags.length===0) {
          console.error("[getNoteLinkEntities] couldn't get music tags from url=",data.url);
          return;
        }
        musicLinks.push({musicTags, offsetStart: start,offsetEnd:end, text: text, isInComment} as MusicLinkInNote);
      } else if (entity.getType() === SYNTHESIZED_LINE_ENTITY_TYPE) {
        const {text, voice_uuid, style_name, path} = entity.getData() as SynthesizedLineInNote;
        synthesizedTextEntities.push({text, voice_uuid, style_name, path, isInComment} as SynthesizedLineInNote);
        return true;
      } else if (entity.getType() === "LINK") {
        const url = data.url || "";
        regularLinks.push({url:url, offsetStart: start,offsetEnd:end, text:text, type: "LINK", isInComment} as URLLinkInNote);
      }
    }
  );
  if (synthesizedTextEntities.length>0) {
    debugger;
  }
  return {noteLinks, musicLinks, synthesizedTextEntities, regularLinks};
}


function findVoiceLinkEntities(contentBlock:ContentBlock, callback: (start: number, end: number) => void, contentState:ContentState):void {
  contentBlock.findEntityRanges(
    (character:CharacterMetadata) => {
      const entityKey = character.getEntity();
      if (!entityKey) return false;
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() !== VOICE_LINK_ENTITY_TYPE) return false;
      const data = entity.getData();
      if (!data.url) return false;
      const {isValid} = getVoiceIDFromURL(data.url);
      if (!isValid) return false;
      return true;
    },
    callback
  );
}
function findMusicLinkEntities(contentBlock:ContentBlock, callback: (start: number, end: number) => void, contentState:ContentState):void {
  contentBlock.findEntityRanges(
    (character:CharacterMetadata) => {
      const entityKey = character.getEntity();
      if (!entityKey) return false;
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() !== MUSIC_LINK_ENTITY_TYPE) return false;
      const data = entity.getData();
      if (!data.url) return false;
      const musicTags = getMusicTagsFromURL(data.url);
      if (!musicTags || musicTags.length===0) {
        // console.warn("findNoteLinkEntities: found a link that doesn't look like a note link. data=",data);
        return false;
      }
      // console.log("findNoteLinkEntities: found note link to note ID ",noteID," data=",data," text=",text, " character=",character);
      return true;
    },
    callback
  );
}
function findSynthesizedLineEntities(contentBlock:ContentBlock, callback: (start: number, end: number) => void, contentState:ContentState):void {
  contentBlock.findEntityRanges(
    (character:CharacterMetadata) => {
      const entityKey = character.getEntity();
      if (!entityKey) return false;
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() !== SYNTHESIZED_LINE_ENTITY_TYPE) return false;
      // TODO insert any checks needed on the data for this type
      // const data = entity.getData();
      // if (!data.url) return false;
      // const musicTags = getMusicTagsFromURL(data.url);
      // if (!musicTags || musicTags.length===0) {
      //   // console.warn("findNoteLinkEntities: found a link that doesn't look like a note link. data=",data);
      //   return false;
      // }
      // console.log("findNoteLinkEntities: found note link to note ID ",noteID," data=",data," text=",text, " character=",character);
      return true;
    },
    callback
  );
}

export type AnyLinkInNote = {
  text: string;
  type: string;
  offsetStart: number;
  offsetEnd: number;
  isInComment: boolean;
}

export type URLLinkInNote = AnyLinkInNote & {
  url: string;
  type: "LINK";
};

export type NoteLinkInNote = AnyLinkInNote & {
  doc_id: string;
  type: typeof LINK_TO_NOTE_BLOCK_TYPE | typeof NOTE_LINK_ENTITY_TYPE;
};

export type MusicLinkInNote = AnyLinkInNote & {
  musicTags: string[];
  text: string;
  type: typeof MUSIC_LINK_ENTITY_TYPE;
};
export type SynthesizedLineInNote = AnyLinkInNote & {
  text: string;
  voice_uuid: string;    
  style_name: string;
  path: string;
  type: typeof SYNTHESIZED_LINE_ENTITY_TYPE;
};


/**
 * Gets both types of links in notes, as long as they point to a doc.
 * 
 * Warning: This 
 * 
 * @param note 
 * @returns 
 */
export function getUncommentedNoteLinks(note:Note) {
  const linksInNote = [] as NoteLinkInNote[];
  const docData = getNoteDraftJS(note);
  docData.blocks.forEach((block:RawDraftContentBlock)=>{
    if (block.type===COMMENT_BLOCK) return;
    if (block.type===LINK_TO_NOTE_BLOCK_TYPE) {
      const data = block.data as any;
      if (!data || !data.checked) return;
      const doc_id = data.doc_id;
      if (!doc_id) {
        console.error("[getUncommentedLinksInNote] couldn't get doc_id from block.data=",data);
        return;
      }
      const doc_name = data.doc_name;
      linksInNote.push({doc_id, text:doc_name, type: LINK_TO_NOTE_BLOCK_TYPE, isInComment:false} as NoteLinkInNote);
      return;
    }
    block.entityRanges.forEach((range)=>{
      const entity = docData.entityMap[range.key];
      const linkText = block.text.substring(range.offset,range.offset+range.length+1);
      const data = entity.data;
      if (entity.type===NOTE_LINK_ENTITY_TYPE) {
        if (!data.url) return;
        const noteID = getNoteIDFromURL(data.url);
        if (!noteID) {
          console.error("[getUncommentedLinksInNote] couldn't get note id from url=",data.url);
          return;
        }
        linksInNote.push({doc_id:noteID, text: linkText, type: NOTE_LINK_ENTITY_TYPE, isInComment:false} as NoteLinkInNote);
      }
    });
  });
  return linksInNote;
}

export function getUncommentedVoiceLinksInNote(note:Note):ReplicaVoice[] {
  const voices = [] as ReplicaVoice[];
  const docData = getNoteDraftJS(note);
  docData.blocks.forEach((block)=>{
    if (block.type===COMMENT_BLOCK) return;
    block.entityRanges.forEach((range)=>{
      const entity = docData.entityMap[range.key];
      if (entity.type===VOICE_LINK_ENTITY_TYPE) {
        const linkText = block.text.substring(range.offset,range.offset+range.length);
        const data = entity.data;
        if (!data.url) return;
        const {isValid, voice_id, pitch} = getVoiceIDFromURL(data.url);
        if (!isValid) {
          console.error("[getUncommentedVoiceLinksInNote] couldn't get voice id from url=",data.url);
          return;
        }
        voices.push({uuid:voice_id,name:linkText, pitch} as ReplicaVoice);
      }
    });
  });
  return voices;
}

function doesCharacterHaveVoiceLinkEntity(character:CharacterMetadata, contentState:ContentState) {
  const entityKey = character.getEntity();
  if (!entityKey) return false;
  const entity = contentState.getEntity(entityKey);
  if (entity.getType() !== VOICE_LINK_ENTITY_TYPE) return false;
  const data = entity.getData();
  if (!data.url) return false;
  const {voice_id} = getVoiceIDFromURL(data.url);
  if (!voice_id) {
    // console.warn("findNoteLinkEntities: found a link that doesn't look like a note link. data=",data);
    return false;
  }
  return true;
}

export function getVoiceLinkEntities(contentBlock:ContentBlock, contentState:ContentState) {
  let voices = [] as ReplicaVoice[];
  contentBlock.findEntityRanges(
    (character:CharacterMetadata) => doesCharacterHaveVoiceLinkEntity(character, contentState),
    (start, end) => {
      const entityKey = contentBlock.getEntityAt(start);
      if (!entityKey) return;
      const entity = contentState.getEntity(entityKey);
      const data = entity.getData();
      const {isValid, voice_id, pitch} = getVoiceIDFromURL(data.url);
      if (!isValid) {
        console.error("[getVoiceLinkEntities] couldn't get voice id from url=",data.url);
        return;
      }
      voices.push({uuid:voice_id, pitch} as ReplicaVoice);
      // voices[voices.length-1].name=contentBlock.getText().slice(start,end);
    }
  );
  return voices;
}


/************************
 * Link renderers
 * 
 * Lessons learned: https://stackoverflow.com/questions/60904180/draft-js-losing-cursor-with-non-editable-entity-components/78427323#78427323
 * 
 * Draft-js assumes a lot about the renderers for entities.
 * - use a <span/> or a </a> (TBD what our options here are).
 * - do NOTE use "contentEditable={false}". That should only be used on blocks, not on entities.
 * - use data-offset-key={props.offsetkey} (TBD if this is essential, but it doesn't hurt)
 * - The span must contain the {props.children}. Don't update the text in the renderer. We can update the text in the note doc itself, e.g. at load time. But not in the renderer.
 * - Don't add special components inline into the text, for example, avoid adding AntD icons. Instead, use emojis in the text itself.
 * - TBD can we use Tooltips to show the new name of the note and details?
 */
function NoteLinkFunc(onClick: (doc_id: string, doc_name: string) => void){
  return function NoteLink(props: { contentState: ContentState, entityKey: string, children: React.ReactNode[], offsetkey: string  }) {
    const {url} = props.contentState.getEntity(props.entityKey).getData();
    const doc_id = getNoteIDFromURL(url);
    if (!doc_id) {
      console.error("NoteLinkFunc: couldn't get doc_id from url=",url);
      return <a href={url}>{props.children}</a>;
    }
    // const doc_name = props.contentState.getEntity(props.entityKey).getData().doc_name;
    const doc_name = "Unknown";
    return <a href={url} data-offset-key={props.offsetkey} className='link-to-note-link'
              onClick={(e)=>{ e.preventDefault(); onClick(doc_id, doc_name); }}>
              {props.children}
           </a>
  }
}
function VoiceLinkFunc(onClick: (voice_id: string, pitch:ReplicaPitch) => void) {
  const voices = useReplicaVoices();
  return function VoiceLink(props: { contentState: ContentState, entityKey: string, children: React.ReactNode[], offsetkey: string }) {
    const {url} = props.contentState.getEntity(props.entityKey).getData();
    const {isValid, voice_id, pitch} = getVoiceIDFromURL(url);
    if (!isValid) {
      console.error("VoiceLinkFunc: couldn't get voice id from url=",url);
      return <a href={url}>{props.children}</a>;
    }
    
    let onVoiceClickFunc;
    if (voices.length>0) {
      const voice = voices.find((voice)=>voice.uuid===voice_id);
      if (voice) {
        onVoiceClickFunc = () => {onClick(voice_id, pitch as ReplicaPitch)};
      } else {
        console.error("[VoiceLinkFunc] couldn't find voice with id=",voice_id);
      }
    }
    return <a href={url} data-offset-key={props.offsetkey} className='link-to-note-link' onClick={onVoiceClickFunc}>{props.children}</a>
  }
}
function MusicLinkFunc(onClick: (musicTags: string[]) => void) {
  return function MusicLink(props: { contentState: ContentState, entityKey: string, children: React.ReactNode[], offsetkey: string}) {
    const {url} = props.contentState.getEntity(props.entityKey).getData();
    const musicTags = getMusicTagsFromURL(url);
    let musicTagsFunc;
    if (musicTags && musicTags.length>0) {
      musicTagsFunc = () => {onClick(musicTags)};
    }
    return <a href={url} data-offset-key={props.offsetkey} className='link-to-note-link' onClick={musicTagsFunc}>{props.children}</a>
  }
}

function SynthesizedLineRenderFunc() {
  return function NarratedTextRender(props: { contentState: ContentState, entityKey: string, children: React.ReactNode[], offsetkey: string }) {
    const {text} = props.contentState.getEntity(props.entityKey).getData() as SynthesizedLineInNote;
    //@ts-ignore
    const defaultText = props.decoratedText as string;
    const className = defaultText===text ? "narrated-text-line" : ""; 
    return <span data-offset-key={props.offsetkey} className={className}>{props.children}</span>
  }
}

export function useNoteLinkPlugin() {
  // const notesContext = useContext(NotesContext);
  const navigateToNote = useNavigateToNote();
  function onClick (doc_id: string) {
    navigateToNote(doc_id);
  };
  return { 
    decorators: [
      {
        strategy: findNoteLinkEntities,
        component: NoteLinkFunc(onClick),
      }
    ]
  };
}
export function useVoiceLinkPlugin() {
  const navigateToVoice = useNavigateToVoice();
  return { 
    decorators: [
      {
        strategy: findVoiceLinkEntities,
        component: VoiceLinkFunc(navigateToVoice),
      }
    ]
  };
}
export function useMusicLinkPlugin() {
  const navigateToMusic = useNavigateToMusic();
  return { 
    decorators: [
      {
        strategy: findMusicLinkEntities,
        component: MusicLinkFunc(navigateToMusic),
      }
    ]
  };
}
export function useSynthesizedLinePlugin() {
  return { 
    decorators: [
      {
        strategy: findSynthesizedLineEntities,
        component: SynthesizedLineRenderFunc(),
      }
    ]
  };
}