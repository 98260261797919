import { cleanChatMessagesBeforeSendToServer } from "./GenericChatServer";
import { HttpsCallableOptions, httpsCallable } from "firebase/functions";
import { ChatCompletionMessageParam } from "openai/resources";
import { FIREBASE_FUNCTIONS } from "../../AppBase/App";
import { ChatAPIOptions, DEFAULT_CHAT_API_OPTIONS, LLM_QUALITY_LEVEL_BEST, LLM_QUALITY_LEVEL_FASTEST, LLMChatServerCall, LLMServer, StructuredOutputInputFormat } from "./GenericChatServerConsts";

const SERVER_TIMEOUT_MINS = 4; // 4 minutes. The source of truth is in our server functions in opeaifunctions.js

const DEBUG = false;

// The following type is replicated on the server. If you change it here, change it there too.
const MODEL_BEST = "best" as ModelType;
const MODEL_LIGHT = "light" as ModelType;
type ModelType = "best" | "light";

export const callOpenAIViaFirebaseFunctionsChatCommand:LLMChatServerCall = async function (messages: ChatCompletionMessageParam[], chatAPIOptions: ChatAPIOptions=DEFAULT_CHAT_API_OPTIONS) {
    const SERVER_OPTIONS: HttpsCallableOptions = {
        timeout: 1000*60*SERVER_TIMEOUT_MINS+1000, // milliseconds
    };    

    let {abortController, temperature=LLM_SERVERTYPE_OPENAI_INDIRECT.defaultTemperature, quality=LLM_SERVERTYPE_OPENAI_INDIRECT.supportedQuality[0], ...chatAPIOptionsRest} = chatAPIOptions;
    if (DEBUG) console.log("[callFirebaseOpenAI]>messages: ",messages);

    // Make sure the quality level is supported. If not supported
    if (LLM_SERVERTYPE_OPENAI_INDIRECT.supportedQuality.indexOf(quality)===-1) {
        // TODO warning message should be on console for this
        console.warn("[FirebaseOpenAPChatServer] Quality level "+quality+" is not supported by the server. Defaulting to "+LLM_SERVERTYPE_OPENAI_INDIRECT.supportedQuality[0]);
        quality = LLM_SERVERTYPE_OPENAI_INDIRECT.supportedQuality[0];
    }
    const model = quality===LLM_QUALITY_LEVEL_BEST ? MODEL_BEST : MODEL_LIGHT;

    const startTime = Date.now();

    async function makeTheCall(retries:number=1, attemptsSoFar:number=0):Promise<{success:boolean, message?:string, error?:string}>{
        try {
            if (DEBUG) console.log("[callFirebaseOpenAI] > sendChatMessage > chat to send: ",messages);
            const cleanMessages = cleanChatMessagesBeforeSendToServer(messages);
            const chatAPIParameters = {
                messages:cleanMessages,
                model,
                temperature,
                ...chatAPIOptionsRest,
            };
            if (temperature<=0 || temperature>=1) {
                throw new Error("[callFirebaseOpenAI]>Invalid temperature: "+temperature);
            }

            if (DEBUG) console.log("[callFirebaseOpenAI] > sendChatMessage > chatAPIParameters: ",chatAPIParameters);
            const commandResponse = await httpsCallable(FIREBASE_FUNCTIONS, "openaifunctions_commandChat", SERVER_OPTIONS)(chatAPIParameters) as {data: {succeeded:boolean, error?:string, choices: {message:ChatCompletionMessageParam}[]}};
            if (DEBUG) console.log("[callFirebaseOpenAI] > sendChatMessage > completed commandResponse: ",commandResponse);
            // console.log(commandResponse);
            //@ts-ignore
            if (!commandResponse.data.succeeded || commandResponse.data.choices.length===0) {
                if (chatAPIOptions.errorCallback)
                    chatAPIOptions.errorCallback("Error: "+commandResponse.data.error);
                return {success:false, error:commandResponse.data.error};
            } else {
                const endTime = Date.now();
                const timeTakenMinutes = (endTime-startTime)/1000/60;
                if (DEBUG) console.log("[callFirebaseOpenAI] > sendChatMessage > OpenAI API completed in "+timeTakenMinutes+" minutes.");
                if (chatAPIOptions.streamingCallback)
                    chatAPIOptions.streamingCallback(commandResponse.data.choices[0].message.content as string,commandResponse.data.choices[0].message.content as string, true);
                return {success:true, message:commandResponse.data.choices[0].message.content as string};
            }
        } catch (error) {
            // Check to see if this is a firebase internal error:
            //@ts-ignore
            if (error.name==="FirebaseError" && error.message==="deadline-exceeded") {
                // We can retry once. It's likely it'll fail again, though...
                const endTime = Date.now();
                const timeTakenMinutes = (endTime-startTime)/1000/60;
                console.error("Error: OpenAI API has taken "+timeTakenMinutes+" minutes (the server timeout is "+SERVER_TIMEOUT_MINS+" minutes). That's "+(attemptsSoFar+1)+" attempts at up to "+SERVER_TIMEOUT_MINS+" mins each...");
                if (retries>0) {
                    console.log("... Retrying "+(retries)+" more times...");
                    return await makeTheCall(retries-1, attemptsSoFar+1);
                } else {
                    console.error("... We've exceeded retries. Giving up.");
                    alert("Error: The ChatGPT server is running very slow right now. Please try again later.");
                    if (chatAPIOptions.errorCallback)
                        chatAPIOptions.errorCallback("Error: The ChatGPT server is running very slow right now. Please try again later.");
                    return {success:false, error:"Error: The ChatGPT server is running very slow right now. Please try again later."};
                }
            }
            // We get them when OpenAI takes extra long to reply, though we just set the timeout to now be 2 minutes -- but that's really long.
            if (abortController?.signal.aborted) {
                // Not a problem!
                return {success:false, error:"Aborted by user."};
            } else {
                // Is this still an unknown error? If so, let's take a look at it in the debugger.
                console.error(error);
                debugger;
                if (chatAPIOptions.errorCallback)
                    chatAPIOptions.errorCallback("Error: "+error);
                return {success:false, error:"Error: "+error};
            }
        }
    }
    return await makeTheCall();

};
export const LLM_SERVERTYPE_OPENAI_INDIRECT: LLMServer = {
    name: "Indirect GPT 4o",
    contextLength: 131072 /*128k*/,
    isLocal: false,
    // serverType: LLM_SERVERTYPE_FirebaseFunctionsOpenAI,
    defaultTemperature: 0.35, // Hypothesis: Because GPT-4 is a bit smarter than the others, a higher temperature has more variety without as much downside. But at 0.4 I do see some hallucinations.
    supportsStreaming: false,
    supportsFunctions: false, // OpenAI can but our functions don't
    supportedQuality: [LLM_QUALITY_LEVEL_BEST, LLM_QUALITY_LEVEL_FASTEST],
    chatCall: callOpenAIViaFirebaseFunctionsChatCommand,
    structuredOutputInputFormat: StructuredOutputInputFormat.NONE,
    /*modelTypeForTokenization: "gpt-4-0125-preview" as TiktokenModel*/
};

// export function useOpenAIViaFirebaseChatCommand(lmServerString:ServerEndpointString=LLM_SERVERTYPE_FirebaseFunctionsOpenAI, model: ModelType=MODEL_BEST) {
//     const options: HttpsCallableOptions = {
//         timeout: 1000*60*SERVER_TIMEOUT_MINS+1000, // milliseconds
//     };

//     const callFirebaseOpenAI:ChatAPICall = async function (messages:ChatCompletionMessageParam[], streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, chatAPIOptions:ChatAPIOptions=DEFAULT_CHAT_API_OPTIONS) {
//         const {abortController, ...chatAPIOptionsRest} = chatAPIOptions;
//         if (DEBUG) console.log("[callFirebaseOpenAI]>messages: ",messages);
//         let remoteCommandChat = function(){
//             if (lmServerString===LLM_SERVERTYPE_FirebaseFunctionsOpenAI)
//                 return httpsCallable(FIREBASE_FUNCTIONS, "openaifunctions_commandChat", options);
//             else if (lmServerString===LLM_SERVERTYPE_FirebaseGroq)
//                 return httpsCallable(FIREBASE_FUNCTIONS, "groq_commandChat", options);
//             else 
//                 // This should be effectively unreachable. While we allow passing in other lm server strings, it shouldn't be called.
//                 throw new Error("Bug: Don't call chat commands after passing in an invalid server type: "+lmServerString);
//         }() as HttpsCallable;

//         const startTime = Date.now();

//         async function makeTheCall(retries:number=1, attemptsSoFar:number=0):Promise<{success:boolean, message?:string, error?:string}>{
//             try {
//                 if (DEBUG) console.log("[callFirebaseOpenAI] > sendChatMessage > chat to send: ",messages);
//                 const cleanMessages = cleanChatMessagesBeforeSendToServer(messages);
//                 const chatAPIParameters = {
//                     messages:cleanMessages,
//                     model,
//                     ...chatAPIOptionsRest,
//                 };
//                 if (!chatAPIParameters.temperature || chatAPIParameters.temperature<=0 || chatAPIParameters.temperature>=1) {
//                     throw new Error("[callFirebaseOpenAI]>Invalid temperature: "+chatAPIParameters.temperature);
//                 }

//                 if (DEBUG) console.log("[callFirebaseOpenAI] > sendChatMessage > chatAPIParameters: ",chatAPIParameters);
//                 const commandResponse = await remoteCommandChat(chatAPIParameters) as {data: {succeeded:boolean, error?:string, choices: {message:ChatCompletionMessageParam}[]}};
//                 if (DEBUG) console.log("[callFirebaseOpenAI] > sendChatMessage > completed commandResponse: ",commandResponse);
//                 // console.log(commandResponse);
//                 //@ts-ignore
//                 if (!commandResponse.data.succeeded || commandResponse.data.choices.length===0) {
//                     if (errorCallback)
//                         errorCallback("Error: "+commandResponse.data.error);
//                     return {success:false, error:commandResponse.data.error};
//                 } else {
//                     const endTime = Date.now();
//                     const timeTakenMinutes = (endTime-startTime)/1000/60;
//                     if (DEBUG) console.log("[callFirebaseOpenAI] > sendChatMessage > OpenAI API completed in "+timeTakenMinutes+" minutes.");
//                     if (streamingCallback)
//                         streamingCallback(commandResponse.data.choices[0].message.content as string,commandResponse.data.choices[0].message.content as string, true);
//                     return {success:true, message:commandResponse.data.choices[0].message.content as string};
//                 }
//             } catch (error) {
//                 // Check to see if this is a firebase internal error:
//                 //@ts-ignore
//                 if (error.name==="FirebaseError" && error.message==="deadline-exceeded") {
//                     // We can retry once. It's likely it'll fail again, though...
//                     const endTime = Date.now();
//                     const timeTakenMinutes = (endTime-startTime)/1000/60;
//                     console.error("Error: OpenAI API has taken "+timeTakenMinutes+" minutes (the server timeout is "+SERVER_TIMEOUT_MINS+" minutes). That's "+(attemptsSoFar+1)+" attempts at up to "+SERVER_TIMEOUT_MINS+" mins each...");
//                     if (retries>0) {
//                         console.log("... Retrying "+(retries)+" more times...");
//                         return await makeTheCall(retries-1, attemptsSoFar+1);
//                     } else {
//                         console.error("... We've exceeded retries. Giving up.");
//                         alert("Error: The ChatGPT server is running very slow right now. Please try again later.");
//                         if (errorCallback)
//                             errorCallback("Error: The ChatGPT server is running very slow right now. Please try again later.");
//                         return {success:false, error:"Error: The ChatGPT server is running very slow right now. Please try again later."};
//                     }
//                 }
//                 // We get them when OpenAI takes extra long to reply, though we just set the timeout to now be 2 minutes -- but that's really long.
//                 if (abortController?.signal.aborted) {
//                     // Not a problem!
//                     return {success:false, error:"Aborted by user."};
//                 } else {
//                     // Is this still an unknown error? If so, let's take a look at it in the debugger.
//                     console.error(error);
//                     debugger;
//                     if (errorCallback)
//                         errorCallback("Error: "+error);
//                     return {success:false, error:"Error: "+error};
//                 }
//             }
//         }
//         return await makeTheCall();
//    };
//     return callFirebaseOpenAI;
// }