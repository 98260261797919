import { CloseOutlined, ClusterOutlined, PushpinFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { Avatar, Image, Menu, Tooltip } from "antd";
import Sider from "antd/es/layout/Sider";
import { getAuth } from "firebase/auth";
import { useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HELP_URL } from "../../../DecisionGraph/ActionComponents/OpenHelpAction";
import { SidebarContext } from "../../../DecisionGraph/Sidebars/SidebarController";
import { useSoundTrackInDrawer } from "../../../DecisionGraph/Utilities/Sound/MusicAndSpeechDrawer";
import { getQuery, useQuery } from "../../../DecisionGraph/Utilities/URLQueryParams";
import { PinnedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { useIsSmallScreen } from "./IsSmallScreen";
import { useNotesSiderMenu } from "./NotesSideMenuAndSidebars";
import { SiderMenuItem } from "./SideMenuAndSidebarType";
import { useReplicaSiderMenu } from "./ReplicaVoiceSideMenuAndSidebar";

const SIDER_FONT_COLOR = "gray";
function siderItemPaddingLeft(siderCollapsed:boolean) {
  return siderCollapsed?"10px":"24px";
}

const INCLUDE_RELATED_SIDEBAR=false; // TODO implement a sidebar then set this to true (the menu is there)

// New private hook to handle Sider logic
function useSider(
) {
  const isSmallScreen = useIsSmallScreen();
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const siderCollapsedFromQueryStr = query.get("siderCollapsed");
  const isSiderCollapsed = siderCollapsedFromQueryStr === null ? false : siderCollapsedFromQueryStr === "true";
  function setSiderCollapsed(value: boolean) {
    const freshQuery = getQuery();
    freshQuery.set("siderCollapsed", value.toString());
    navigate(location.pathname + "?" + freshQuery.toString(), { replace: true });
  }
  function toggleSiderCollapsed() {
    setSiderCollapsed(!isSiderCollapsed);
  }
  const { currentSidebar, setCurrentSidebar, hideSidebar, isSidebarVisible } = useContext(SidebarContext);
  const notesSider = useNotesSiderMenu(setCurrentSidebar, currentSidebar, isSidebarVisible, hideSidebar);
  const replicaSider = useReplicaSiderMenu(setCurrentSidebar, currentSidebar, isSidebarVisible, hideSidebar);
  const soundtrackSideMenu = useSoundTrackInDrawer();
  const siderMenuHookReturns = [notesSider, replicaSider, soundtrackSideMenu];



  // Build top and bottom menu items
  const siderTopMenuItems = [] as SiderMenuItem[];
  for (const hookReturn of siderMenuHookReturns) {
    siderTopMenuItems.push(...hookReturn.menuItems);
  }

  const pinnedJsonFormsContext = useContext(PinnedJSONFormsContext);

  // TODO make this a hook like the others
  if (INCLUDE_RELATED_SIDEBAR)
    siderTopMenuItems.push({
      key:"showRelated",
      label: pinnedJsonFormsContext.hasNote ? (pinnedJsonFormsContext.note ? pinnedJsonFormsContext.note.doc_name : "Pinned") : "Related",
      icon: pinnedJsonFormsContext.hasNote ? <PushpinFilled/> : <ClusterOutlined />,
      disabled:false
    });

  if (isSmallScreen)
    siderTopMenuItems.push({key:"hideSidebar", label:"Close", icon:<CloseOutlined />, disabled:false, onClick: hideSidebar});
  const siderBottomMenuItems = [];
  siderBottomMenuItems.push({key:"help", label:"Help", icon:<QuestionCircleOutlined />, disabled:false, onClick: () => window.open(HELP_URL, "_blank")});


  function onSiderMenuItemClick({ key }: { key: string }) {
    if (isSmallScreen)
      hideSidebar();
    for (const hookReturn of siderMenuHookReturns) {
      if (hookReturn.onSiderMenuItemClick(key))
        return true;
    }
  }

  const auth = getAuth();
  const photoURL = auth.currentUser?.photoURL;
  const siderComponent = (
    <Sider collapsible={true} className="noprint" key={"siderComponent"}
      collapsedWidth={isSmallScreen ? 0 : undefined}
      collapsed={isSiderCollapsed}
      zeroWidthTriggerStyle={isSmallScreen ? { display:"none" } : {}}
      onCollapse={(collapsed: boolean) => {
          if (collapsed !== isSiderCollapsed)
            setSiderCollapsed(collapsed);
      }}>
        {!isSmallScreen && !isSiderCollapsed &&
          <h1 style={{color: SIDER_FONT_COLOR, paddingLeft: siderItemPaddingLeft(isSiderCollapsed)}}>
            AI Got This
          </h1>
        }
        <Menu theme="dark" mode="inline" items={siderTopMenuItems} onClick={onSiderMenuItemClick} selectedKeys={[currentSidebar]}/>
        {/* ...existing code for flexible space... */}
        <div key="bottom-spacer" style={{ marginTop: 'auto', display: 'hidden' }}/>
        <Menu theme="dark" mode="inline" items={siderBottomMenuItems} onClick={onSiderMenuItemClick} selectedKeys={[currentSidebar]}/>
        <Tooltip title={auth.currentUser?.email+"  "+auth.currentUser?.uid} key="tooltip">
          <div style={{width:"100%", alignItems:"center", color:"gray", paddingLeft: isSiderCollapsed ? "20px" : "24px", paddingRight: isSiderCollapsed ? "0px" : "24px", display:"flex"}}>
              <Avatar src={photoURL && <Image src={photoURL}/> } key="avatarInHeader" />
              {!isSiderCollapsed &&
                <span style={{paddingLeft:"24px", color:"gray", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden"}}>
                  {auth.currentUser?.email}
                </span>
              }
          </div>
        </Tooltip>
    </Sider>
  );

  const sidebarComponent = useMemo(()=>{
    for (const hookReturn of siderMenuHookReturns) {
      if (hookReturn.sidebarComponent)
        return hookReturn.sidebarComponent;
    }
    return undefined;
  },[currentSidebar,siderMenuHookReturns]);

  function onShortcutKeyDown_sidepanels(keyName: string) {
    for (const hookReturn of siderMenuHookReturns)
      if (hookReturn.onShortcutKeyDown(keyName))
        return;
  }

  const siderComponents = [siderComponent];
  for (const hookReturn of siderMenuHookReturns) {
    siderComponents.push(...hookReturn.siderComponents);
  }

  return { siderComponents, toggleSiderCollapsed, sidebarComponent, onShortcutKeyDown_sidepanels };
}


// Updated hook that wires both Sider and Sidebar logic together
export function useSiderAndSidebar() {
  const { hideSidebar } = useContext(SidebarContext);
  const { siderComponents, toggleSiderCollapsed, sidebarComponent, onShortcutKeyDown_sidepanels } = useSider();

  return {
    siderComponents,
    sidebarComponent,
    hideSidebar,
    toggleSiderCollapsed,
    onShortcutKeyDown_sidepanels
  };
}

