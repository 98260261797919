import { z } from "zod";
import { zodResponseFormat } from "openai/helpers/zod";
import { ChatAPIOptions, LLM_QUALITY_LEVEL_FASTEST, StructuredOutputInputFormat } from "../GenericChatServerConsts";
import { ChatCompletionMessageParam } from "openai/resources";
import { GENRE_TBD, isTagGenre, SOUNDTRACK, TAG_TBD } from "../../../DecisionGraph/Utilities/Sound/Soundtrack1";
import { zodToJsonSchema } from "zod-to-json-schema";
import { LLMServer } from "../GenericChatServerConsts";

const DEBUG = true;

const EXCLUDE_TAGS = [TAG_TBD, GENRE_TBD];

const ALL_USED_TAGS = SOUNDTRACK.reduce((acc, track) => {
    track.tags.forEach(tag => {
        if (!acc.includes(tag) && !EXCLUDE_TAGS.includes(tag)) {
            acc.push(tag);
        }
    });
    return acc;
}, [] as string[]);

const PROMPT_START = `
# Purpose

You are a helpful assistant that selects the most appropriate tags to match a given situation. The tags should be listed in order of relevance, with the most relevant tags (e.g., genre) listed first and the least important tags (e.g., starts or ends differently) listed last. Then, return the tags that meet the mood of the assistant & user messages.

# Tags
Here are the available tags:
`;

const PROMPT_END = `
# Instructions
1. Select the most appropriate tags from the list above.
2. List the tags in order of relevance, the most important tag should be first (e.g. genre is always first)
3. Select a maximum of 4 tags (most situations will only require 2-3 tags, one of which will be genre)
`;

// Note that this works only with a single SOUNDTRACK and will need to be changed to runtime if we have multiple soundtracks.
const TagSelection = z.object({
    tags: z.array(z.enum(ALL_USED_TAGS as [string, ...string[]])),
});

export type TagSelection = z.infer<typeof TagSelection>;

export type TagSelectionResponse = TagSelection & {
    songs: string[];
};

// Modify function signature to accept llmServer
export async function recommendMusic(userInput: string, requiredGenre: string | undefined, llmServer: LLMServer): Promise<{ tags: string[], songs: string[] }> {
    if (llmServer.structuredOutputInputFormat===StructuredOutputInputFormat.NONE || !llmServer.structuredOutputCall)
        throw new Error("Structured output not supported by LLM server "+llmServer.name);

    // If there's a requiredGenre, it's a special case, we want to not ask about Genre.
    // Filter SOUNDTRACK to ensure requiredTags are included

    let filteredSoundtrack = SOUNDTRACK;
    if (requiredGenre) {
        const isGenre = isTagGenre(requiredGenre); // Ensure the requiredGenre is a valid genre
        if (!isGenre) {
            console.error("Invalid genre passed to recommendMusic: ", requiredGenre);
            debugger;
            throw new Error("Invalid genre passed to recommendMusic");
        }

        filteredSoundtrack = filteredSoundtrack.filter(track => track.tags.includes(requiredGenre));
        if (filteredSoundtrack.length === 1) {
            // We can return the only song that matches the required tags without using the AI:
            return { tags: [requiredGenre], songs: [filteredSoundtrack[0].path] };
        } else if (filteredSoundtrack.length === 0) {
            // If not enough songs match the required tags, reset the filter
            console.log("Too many tags were passed in to recommendMusic: Not enough songs match required tags, resetting filter");
            debugger;
            filteredSoundtrack = SOUNDTRACK;
        }
    }

    // Dynamically compute the available tags from filtered songs.
    let dynamicTagsSet = new Set<string>();
    for (const track of filteredSoundtrack) {
        track.tags.forEach(tag => dynamicTagsSet.add(tag));
    }
    // Remove the 'Genre: TBD' tag before sending off to the AI.
    dynamicTagsSet.delete(GENRE_TBD);

    // if requiredGenre is provided, remove all genres from the dynamic tags.
    if (requiredGenre) {
        const allGenreTags = new Set(ALL_USED_TAGS.filter(tag => isTagGenre(tag)));
        // Replace difference method with manual filtering
        dynamicTagsSet = new Set([...dynamicTagsSet].filter(tag => !allGenreTags.has(tag)));
    }

    let dynamicTags = Array.from(dynamicTagsSet).sort();

    // Dynamically create a grouped tags prompt.
    const dynamicGroupedTags = dynamicTags.reduce((acc, tag) => {
        // Use "Other" if there's no colon in the tag text
        const category = tag.includes(":") ? tag.split(": ")[0] : "Other";
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(tag);
        return acc;
    }, {} as { [key: string]: string[] });

    const dynamicTAGS_PROMPT = Object.entries(dynamicGroupedTags).map(([category, tags]) => `
${category}:
${tags.map(tag => `- ${tag}`).join("\n")}
`).join("\n");

    // Dynamically create the zod schema for tag selection.
    const DynamicTagSelection = z.object({
        tags: z.array(z.enum(dynamicTags as [string, ...string[]])),
    });
    const jsonSchema = zodToJsonSchema(DynamicTagSelection) as any;
    
    // Build the final prompt.
    const systemPrompt = `${PROMPT_START}${dynamicTAGS_PROMPT}${PROMPT_END}`;

    const messages = [
        { role: "system", content: systemPrompt },
        { role: "user", content: userInput },
    ] as ChatCompletionMessageParam[];

    const chatAPIOptions: ChatAPIOptions = {
        response_format: zodResponseFormat(DynamicTagSelection, "TagSelection"),
        quality: LLM_QUALITY_LEVEL_FASTEST,
    };

    // Call structuredOutputCall using llmServer instead of callOpenAIDirect_StructuredJSON.
    const output = await llmServer.structuredOutputCall<{ tags: string[] }>(
        messages,
        DynamicTagSelection,
        jsonSchema,
        "MusicRecommender",
        chatAPIOptions
    );

    if (output.success && output.structuredOutput) {
        const result = output.structuredOutput as { tags: string[] };
        let tags = result.tags;

        let matchingSongs = filteredSoundtrack
            .filter(track => tags.every(tag => track.tags.includes(tag)))
            .map(track => track.path);

        // If no songs match all tags, drop the last tag until at least one song matches.
        while (matchingSongs.length === 0 && tags.length > 0) {
            tags.pop();
            matchingSongs = filteredSoundtrack
                .filter(track => tags.every(tag => track.tags.includes(tag)))
                .map(track => track.path);
        }
        if (requiredGenre) {
            // prepend the requiredGenre to the tags
            tags = [requiredGenre, ...tags];
        }

        return { tags, songs: matchingSongs };
    } else {
        console.error("Failed to detect tags, got ", output);
        debugger;
        throw new Error(output.error || "Failed to detect tags");
    }
}
