import { Select, Tooltip } from "antd";
import { useStateLSString } from "../../Utilities/StateWithLocalCache";
import { LLM_SERVERS_OBJ } from "../../../ServerConnection/LLMServer/GenericChatServer";
import { LLM_SERVERTYPE_OPENAI_DIRECT } from "../../../ServerConnection/LLMServer/OpenAIDirectChatServer";
import { createContext, useContext, useEffect} from "react";
import { GLOBAL_DEBUG_OUTPUT } from "../../../GlobalDebug";
import { LLM_QUALITY_LEVEL_BEST, LLMQualityLevel, LLMServer } from "../../../ServerConnection/LLMServer/GenericChatServerConsts";

const LLM_SERVERS_USER_NAMES_STRINGS = LLM_SERVERS_OBJ.map((server: LLMServer) => server.name) as string[];
const LLM_SERVERS_USER_NAMES_STRINGS_SUPPORTINGREMOTE = LLM_SERVERS_OBJ.filter((server: LLMServer) => !server.isLocal).map((server: LLMServer) => server.name) as string[];

export type LLMServerContextType = {
    serverType: LLMServer;
    lmServers: string[];

    // User facing selection name (it's not the same as the server name)
    lmServerString: string;
    setLMServer: (server: string) => void;
    setModelQuality: (quality: LLMQualityLevel) => void;
}
export const LLMServerContext = createContext({} as LLMServerContextType);
export function LLMServerContextProvider({children}:{children:any}) {
    const isLocalishhost = (window.location.hostname === 'localhost' || window.location.hostname === "192.168.1.164") && window.location.port === "3000";
    const DEFAULT_LLM_SERVER_STRING = LLM_SERVERS_OBJ[0].name;
    const [lmServerSaved, setLMServer] = useStateLSString<string>("LLM_Select_Server_Type", DEFAULT_LLM_SERVER_STRING);
    const [modelQualitySaved, setModelQuality] = useStateLSString<LLMQualityLevel>("LLM_Select_Model_Quality", LLM_QUALITY_LEVEL_BEST);

    let llmServerTypeString = lmServerSaved;
    let lmSupportedServersStrings = LLM_SERVERS_USER_NAMES_STRINGS;
    if (!isLocalishhost) {
        lmSupportedServersStrings = LLM_SERVERS_USER_NAMES_STRINGS_SUPPORTINGREMOTE;
        // If we're not on localhost, we can't use the local server:
        // if (llmServerTypeString === LLM_MODEL_LOCAL) {
        //     setLMServer(LLM_MODEL_OPENAI_BEST);
        //     llmServerTypeString = LLM_MODEL_OPENAI_BEST;
        // }
    }
    // Default to first in list.
    if (!lmSupportedServersStrings.includes(llmServerTypeString))
        llmServerTypeString = DEFAULT_LLM_SERVER_STRING;
    // Get the server type:
    const serverType = LLM_SERVERS_OBJ.find((server: LLMServer) => server.name === llmServerTypeString) as LLMServer;

    let modelQuality:LLMQualityLevel = modelQualitySaved;
    if (serverType.supportedQuality.indexOf(modelQuality) === -1)
        modelQuality = serverType.supportedQuality[0];
    
    useEffect(() => {
        // Check for validity of the selection. If it's not valid, ask the user for info or change it.
        if (!lmSupportedServersStrings.includes(llmServerTypeString)) {
            setLMServer(DEFAULT_LLM_SERVER_STRING);
            return;
        }
        // If there's no OpenAI API key stored, prompt for it.
        if (serverType==LLM_SERVERTYPE_OPENAI_DIRECT) {
            const openai_api_key = localStorage.getItem("OPENAI_API_KEY");
            if (!openai_api_key) {
                const openai_api_key2 = prompt("Please enter your OpenAI API key to connect directly");
                if (openai_api_key2) {
                    localStorage.setItem("OPENAI_API_KEY", openai_api_key2);
                    return;
                } else {
                    // User canceled entry. Fall back to something else, the default.
                    setLMServer(DEFAULT_LLM_SERVER_STRING);
                    return;
                }
            }            
        }
    }, [serverType]);

    const providerValue = { serverType, lmServers: lmSupportedServersStrings, lmServerString: llmServerTypeString, setLMServer, setModelQuality };
    return <LLMServerContext.Provider value={providerValue}>
        {children}
    </LLMServerContext.Provider>;
}

const DEBUG=true || GLOBAL_DEBUG_OUTPUT;

export function SelectableLLMServerDropdown() {
    const { lmServers, lmServerString, setLMServer } = useContext(LLMServerContext);
    if (!lmServers || lmServers.length <= 1) {
        if (DEBUG)
            return <>{lmServers.length} servers found so no selection being shown.</>;
        return <></>; // nothing to select. We don't show the message.
    }

    return <>
        <Tooltip title="As of 2/2025, Gemini is the best balanced. You can switch to GPT-4 which performs differently.">
            <Select value={lmServerString} onChange={function (value: string) {
            setLMServer(value);
        } } style={{ minWidth: '100px' }}>
            {lmServers.map(option => <Select.Option key={option} value={option}>{option}</Select.Option>)}
            </Select>
        </Tooltip>
        &nbsp;
    </>;
}