import { CoreOperation, OPERATION_COMPLETE, OPERATION_SELECT, OperationRenderable, basicSaveOperation } from "../ExtensionsFramework/SaveOperationsBasics";
import { NavigateToNoteFunction } from "../../DecisionGraph/Utilities/NavigateTo";
import { JSONFormsObjectContextType } from "../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { Note } from "../../Notes/Data/NoteType";
import { Extension, ExtensionNoteTemplateType, RelatedListType } from "../ExtensionsFramework/ExtensionsList";
import { noteTypeIsCharacter } from "./CharacterEnemyNPCs/CharacterEnemyNPCs";
import { Enemy, Enemy_JsonFormsUISchema, EnemySchema } from "./CharacterEnemyNPCs/Enemy";
import { GameCardRelatedList } from "./GameCard/GameCardRelatedListType";
import { BattleType, BattleTypeSchema } from "./Battle/BattleNoteTypeEditor";
import { DefaultNoteTemplateTypes, SaveNoteSchema, SaveNoteType } from "../DefaultExtension/DefaultExtension";
import { PlayerCharacterJSONType, PlayerCharacterSchema, PlayerCharacterUISchema } from "./PlayerCharacter/PlayerCharacters";


export type SaveGameNoteType = SaveNoteType;

export const SaveGame_NoteType:ExtensionNoteTemplateType<SaveGameNoteType> = {
    name: "TTRPG Save Game",
    description: "Contains the save game context, including information about which TTRPG is being played, where the players are, and what they're doing.",
    emoji: "💾",
    schema: SaveNoteSchema,
    template_doc_ids: ["832332c1-47b3-45f1-905e-ce32d434b563"], // empty template "Save Game"
}

export const Player_NoteType:ExtensionNoteTemplateType<PlayerCharacterJSONType> = {
    name: "Player Character",
    emoji: "🧑",
    description: "A player (PC) in a tabletop role playing game",

    template_doc_ids: ["9c1db852-b9a8-4ba9-bff3-0e9eec0eb00e"],

    schema: PlayerCharacterSchema,
    jsonFormsUISchema: PlayerCharacterUISchema,
};

// export const NPC_NoteType:ExtensionNoteTemplateType<any> = {
//     name: "NPC",
//     emoji: "🧑",
//     description: "A non-player character in a tabletop role playing game",

//     template_doc_ids: [/*NPC Template*/"9883d97c-0269-4692-aaa9-0648dd06d7d7"],

//     schema: undefined,
// };

// export const Faction_NoteType:ExtensionNoteTemplateType<any> = {
//     name: "Faction",
//     emoji: "👪",
//     description: "A faction in a tabletop role playing game",

//     template_doc_ids: [/*Faction (type)*/"e513fc19-6c62-4778-a2ed-0bc49f3f580f"],

//     schema: undefined,
// };

export const Enemy_NoteType:ExtensionNoteTemplateType<Enemy> = {
    name: "Enemy",
    emoji: "👾",
    description: "A fightable enemy in a tabletop role playing game",

    template_doc_ids: [/*Enemy*/"6d8307ef-75cd-4923-9b8b-85cebe8b9360"],

    schema: EnemySchema,
    jsonFormsUISchema: Enemy_JsonFormsUISchema,
};

export const Event_NoteType:ExtensionNoteTemplateType<any> = {
    name: "Event",
    emoji: "🎬",
    description: "An event in a tabletop role playing game",

    template_doc_ids: [
        /*Event Template*/"40e444dc-1d7c-45bd-9339-b647babcda51",
    ],

    schema: undefined,
};

export const Location_NoteType:ExtensionNoteTemplateType<any> = {
    name: "Location",
    emoji: "🗺",
    description: "A location in a tabletop role playing game",

    template_doc_ids: [
        /*Dungeon Room Template*/"f19467ed-491c-4a3b-bae8-9d2f580bd454",
        /*City District Template*/"c72d2efa-3478-44aa-bbc4-9d98aae6aa51",
        /*Shop Template*/"6cf816c3-1ede-4ef0-abd8-da9436c0266d",
    ],

    schema: undefined,
};

export const Battle_NoteType:ExtensionNoteTemplateType<BattleType> = {
    name: "Battle",
    emoji: "⚔️",
    description: "A battle in a tabletop role playing game",

    template_doc_ids: [
        /*Battle*/"06030f33-3a9c-447c-8d74-be262f7c7a9a",
        // DEPRECATED./*Luminaria Battle Template*/"50e9a9b2-525f-489b-9d62-113661b53d4d",
    ],

    schema: BattleTypeSchema,
};

const Cards_RelatedListType:RelatedListType = {
    name: "Characters & Cards",
    description: "Player Cards",
    emoji: "🃏",
    listComponent: GameCardRelatedList
};

function getNoteTypeIsCompletable_TTRPG(noteType:string):string|null {
    // For basic notes we don't know, so we err on the side of safety.
    if (!noteType || noteType==="Note") return OPERATION_COMPLETE;

    // Characters could be killed off. We need to communicate that.
    if (noteTypeIsCharacter(noteType)) return "Kill";

    if (noteType==="Location" || noteType==="Battle" || noteType==="Passage")
        return OPERATION_COMPLETE;

    return null;
}
function getOpDisplayNameIfNoteTypeIsSelectable_TTRPG(noteType:string):string|null {
    // Almost everything is selectable, with a few exceptions.
    if (!noteType || noteType==="Note") return "Save";

    if (noteTypeIsCharacter(noteType)) return "Save";

    // Types that can never be selected, it's not useful:
    // Actually, saving these types is useful, because we can dynamically set up our prompt by selecting them here. There are some prompts we always want to include when writing, but may not always include during the game due to length.
    // if (noteType==="Save Game" || noteType==="Story" || noteType==="Prompt" || noteType==="Type")
        // return null;
    
    if (noteType==="Location" || noteType==="Battle" || noteType==="Passage")
        return "Save";

    return "Save";
}

function getOperationsForNote_TTRPG(noteContext:JSONFormsObjectContextType, note:Note):OperationRenderable[] {
    let operations = [] as OperationRenderable[];
    const completableOp = getNoteTypeIsCompletable_TTRPG(note.type);
    const selectableOp = getOpDisplayNameIfNoteTypeIsSelectable_TTRPG(note.type);
    let isSelected = noteContext.jsonFormsObject[OPERATION_SELECT] && noteContext.jsonFormsObject[OPERATION_SELECT].includes(note.id);
    let isCompleted = noteContext.jsonFormsObject[OPERATION_COMPLETE] && noteContext.jsonFormsObject[OPERATION_COMPLETE].includes(note.id);
    if (selectableOp) {
        if (isSelected) {
            operations.push({operation:OPERATION_SELECT, text:"Undo "+selectableOp, add:false});
        } else {
            // Not selected:
            if (!isCompleted)
                operations.push({operation:OPERATION_SELECT, text:selectableOp, add:true});
        }
    }
    if (completableOp) {
        if (noteContext.jsonFormsObject[OPERATION_COMPLETE] && noteContext.jsonFormsObject[OPERATION_COMPLETE].includes(note.id))
            operations.push({operation:OPERATION_COMPLETE, text:"Undo "+completableOp, add:false});
        else if (isSelected)
            operations.push({operation:OPERATION_COMPLETE, text:completableOp, add:true});
    }
    return operations;
}
function saveOperation_TTRPG(noteContext:JSONFormsObjectContextType, note:Note, operation:OperationRenderable, navigateToNote:NavigateToNoteFunction) {
    const otherOperations = [] as CoreOperation[];
    if (operation.operation===OPERATION_COMPLETE) {
        otherOperations.push(OPERATION_SELECT);
        if (operation.add)
            // We also want to navigate back to the Save note, because this is completed.
            navigateToNote(noteContext.note.id);
    }
    // The following branch is probably not reachable.
    else if (operation.operation===OPERATION_SELECT)
        otherOperations.push(OPERATION_COMPLETE);
    basicSaveOperation(noteContext, note, operation, otherOperations);
}


export const TTRPGExtension:Extension = {
    name: "TTRPG",
    description: "Tabletop Role Playing Game",
    included_notes_doc_ids: [/*All TTRPGs*/"c716c74b-850a-4133-a246-2e7c35d3f864"],
    noteTemplateTypes: [        
        SaveGame_NoteType,

        // HowTo_NoteType,

        // Player_NoteType,
        // NPC_NoteType,
        Enemy_NoteType,
        // Faction_NoteType,

        // Event_NoteType,

        // Location_NoteType,
        Battle_NoteType,

        ...DefaultNoteTemplateTypes,

    ] as ExtensionNoteTemplateType<any>[],
    definitiveNoteTemplateType: SaveGame_NoteType as ExtensionNoteTemplateType<any>,
    relatedListTypes: [
        Cards_RelatedListType
    ],
    getOperationsForNote: getOperationsForNote_TTRPG,
    saveOperation: saveOperation_TTRPG
};

const INTERACTIVE_STORY_NOTE_IDs = ["38f25579-87eb-41cb-91bd-b10e6f176697","9344dee6-32e4-44a7-aa6c-f8f0f4a83081"];

export function commandCanGenerateMusic(noteID:string) {
    return INTERACTIVE_STORY_NOTE_IDs.includes(noteID);
}