import OpenAI from "openai";
import {  cleanChatMessagesBeforeSendToServer } from "./GenericChatServer";
import { ChatCompletionCreateParamsNonStreaming } from "openai/resources";
import { ChatCompletionCreateParamsBase, ChatCompletionCreateParamsStreaming, ChatCompletionMessageParam, ChatCompletionMessageToolCall } from "openai/resources/chat/completions";
import { ChatAPICall, ChatAPICallReturnType, ChatAPIOptions, ChatStreamErrorCallback, LLM_QUALITY_LEVEL_BEST, LLM_QUALITY_LEVEL_FASTEST, LLMChatServerCall, LLMServer, StreamingCallback, StructuredOutput, StructuredOutputInputFormat } from "./GenericChatServerConsts";
import { JSONSchemaType } from "ajv";
import { z } from "zod";
import { zodResponseFormat } from "openai/helpers/zod";

const DEBUG = false;

const OPENAI_DEFAULT_CHAT_OPTIONS = {
    response_format: {type: "text"},
    max_tokens: undefined, // it won't let us entered -1 but it allows undefined to auto default to the max
    temperature: 1,
} as ChatAPIOptions;

function getOpenAI():OpenAI {
    const openai_api_key = localStorage.getItem("OPENAI_API_KEY");
    if (!openai_api_key) {
        console.error("[callOpenAIDirect]>No OpenAI API key found in local storage.");
        debugger;
        throw new Error("No OpenAI API key found in local storage.");
    }
    return new OpenAI({        
        apiKey: openai_api_key as string,
        dangerouslyAllowBrowser: true
    });
}

async function callChatAPIWithRetries_NonStreaming(sendPayload:ChatCompletionCreateParamsNonStreaming, streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void, startTime?:Date,numTriesLeft=1):Promise<ChatAPICallReturnType>{
    if (!startTime) {
        startTime = new Date();
    }
    const openai = getOpenAI();
    function getElapsedSeconds() {
        if (!startTime) {
            console.error("No start time in getElapsedSeconds");
            debugger;
            return 0;
        }
        return Math.round((new Date().getTime()-startTime.getTime())/1000);
    }
    async function failedMayRetry(failureReason:string):Promise<ChatAPICallReturnType> {
        if (numTriesLeft>0) {
            console.error("[callChatAPIWithRetries_NonStreaming] OpenAI failed after "+getElapsedSeconds()+"s, trying again. Failure reason: ",failureReason);
            return await callChatAPIWithRetries_NonStreaming(sendPayload, streamingCallback, errorCallback, callFunction, startTime, numTriesLeft-1);
        }
        // It's failed too many times.
        errorCallback && errorCallback(failureReason);
        if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]> failed after "+getElapsedSeconds()+"s");
        return {success:false, error:failureReason};
    }
    try {
        const chatAnswer = await openai.chat.completions.create(sendPayload);
        if (!chatAnswer || !chatAnswer.usage || !(chatAnswer.usage.completion_tokens>0) || chatAnswer.choices.length===0) {
            // failed.
            return await failedMayRetry("OpenAI returned "+JSON.stringify(chatAnswer));
        }
        const content = chatAnswer.choices[0].message.content || "";
        if (callFunction && chatAnswer.choices[0].message.tool_calls) {
            callFunction(chatAnswer.choices[0].message.tool_calls.map((toolCall)=>toolCall.function));
        }
        if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]>OpenAI succeeded after "+getElapsedSeconds()+"s, returning: ",content,chatAnswer);
        if (streamingCallback) {
            if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]>OpenAI succeeded after "+getElapsedSeconds()+"s. Sending to the stream: ",content);
            streamingCallback(content,content, true);
        } else {
            if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]>OpenAI succeeded after "+getElapsedSeconds()+"s. There's no stream, so we're simply returning the result: ",content);
        }
        return {success:true, message:content};
    } catch (e) {
        console.error("[callChatAPIWithRetriesNonStreaming] Error: ",e);
        return await failedMayRetry("Failed to get answer from openai due to exception: "+e);
    }
}

async function  callChatAPIWithRetries_Streaming(sendPayload:ChatCompletionCreateParamsStreaming, streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void, startTime?:Date,numTriesLeft=1):Promise<ChatAPICallReturnType>{
    if (!startTime) {
        startTime = new Date();
    }
    const openai = getOpenAI();
    function getElapsedSeconds() {
        if (!startTime) {
            console.error("No start time in getElapsedSeconds");
            debugger;
            return 0;
        }
        return Math.round((new Date().getTime()-startTime.getTime())/1000);
    }
    async function failedMayRetry(failureReason:string):Promise<ChatAPICallReturnType> {
        if (numTriesLeft>0) {
            console.error("[callChatAPIWithRetries_NonStreaming] OpenAI failed after "+getElapsedSeconds()+"s, trying again. Failure reason: ",failureReason);
            return await callChatAPIWithRetries_Streaming(sendPayload, streamingCallback, errorCallback, callFunction, startTime, numTriesLeft-1);
        }
        // It's failed too many times.
        errorCallback && errorCallback(failureReason);
        if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]> failed after "+getElapsedSeconds()+"s");
        return {success:false, error:failureReason};
    }
    try {
        const stream = await openai.chat.completions.create(sendPayload);
        let fullContent = "";
        let fullFunctionCalls = [] as ChatCompletionMessageToolCall.Function[];
        for await (const chunk of stream) {
            const delta = chunk.choices[0]?.delta;
            if (!delta) {
                continue;
            }
            if (delta.tool_calls && delta.tool_calls.length>0) {
                // Each of these chunks can be added to the rest of the content, concatenated by property.
                for (let i=0; i<delta.tool_calls.length; i++) {
                    let toolCallDelta = delta.tool_calls[i].function as {[key:string]:any};
                    let fullToolCall = fullFunctionCalls[i];
                    if (!fullToolCall) {
                        fullToolCall={} as ChatCompletionMessageToolCall.Function;
                    }
                    for (const key in toolCallDelta) {
                        //@ts-ignore
                        const currentValue = fullToolCall[key] || "";
                        // @ts-ignore
                        fullToolCall[key] = currentValue+toolCallDelta[key];
                    }
    
                    fullFunctionCalls[i] = fullToolCall;
                }
                // const tool_call0 = delta.tool_calls[0].function as {[key:string]:any};
                // for (const key in tool_call0) {
                //     const currentValue = fullToolCall[key] || "";
                //     fullToolCall[key] = currentValue+tool_call0[key];
                // }
                // console.log("[callChatAPIWithRetries_Streaming] Got tool call: ",fullToolCalls);
            }
            const contentChunk = delta.content || "";
            fullContent += contentChunk;
            if (streamingCallback) {
                // if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]>OpenAI got another reply at "+getElapsedSeconds()+"s. Adding to the stream: ",contentChunk);
                streamingCallback(fullContent,contentChunk, false);
            } else {
                // if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]>OpenAI succeeded after "+getElapsedSeconds()+"s. There's no stream, so we're simply returning the result: ",fullContent);
            }
        }
        callFunction?.(fullFunctionCalls);
        streamingCallback && streamingCallback(fullContent,"", true);
        return {success:true, message:fullContent};
    } catch (e) {
        console.error("[callChatAPIWithRetriesNonStreaming] Error: ",e);
        return await failedMayRetry("Failed to get answer from openai due to exception: "+e);
    }
}

function getPayloadBaseFrom(messages:ChatCompletionMessageParam[], chatAPIOptions:ChatAPIOptions):ChatCompletionCreateParamsBase {
    const messagesClean = cleanChatMessagesBeforeSendToServer(messages);

    const model = chatAPIOptions.quality===LLM_QUALITY_LEVEL_BEST ? "gpt-4o" : "gpt-4o-mini";
    const payloadBase = {
        // First, the defaults, which can be overriden by anythning:
        ...OPENAI_DEFAULT_CHAT_OPTIONS,

        // Then, our parameters. These can still be overridden by the caller.
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,        
        // And we always use these:
        messages: messagesClean,
        model,
    } as ChatCompletionCreateParamsBase;
    if (chatAPIOptions.temperature)
        payloadBase.temperature = chatAPIOptions.temperature;
    if (chatAPIOptions.max_tokens)
        payloadBase.max_tokens = chatAPIOptions.max_tokens;
    if (chatAPIOptions.tool_choice)
        payloadBase.tool_choice = chatAPIOptions.tool_choice;
    if (chatAPIOptions.tools)
        payloadBase.tools = chatAPIOptions.tools;
    if (chatAPIOptions.response_format)
        payloadBase.response_format = chatAPIOptions.response_format;

    if (!payloadBase.temperature || payloadBase.temperature<0 || payloadBase.temperature>2) {
        throw new Error("[callOpenAIDirect]>Invalid temperature: "+payloadBase.temperature);
    }
    return payloadBase;
}

export const callOpenAIDirect_NoStreaming:ChatAPICall = async function (messages:ChatCompletionMessageParam[], streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, chatAPIOptions:ChatAPIOptions=OPENAI_DEFAULT_CHAT_OPTIONS, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void):Promise<ChatAPICallReturnType> {
    // if (DEBUG) console.log("[callOpenAIDirect]>Starting the call. messages: ",messages, "temperature: ",temperature);
    const nonStreamingPayload = {
        ...getPayloadBaseFrom(messages, chatAPIOptions),
        stream: false,
    } as ChatCompletionCreateParamsNonStreaming;

    return await callChatAPIWithRetries_NonStreaming(nonStreamingPayload, streamingCallback, errorCallback, callFunction);
};

export const callOpenAIDirect_StructuredJSON = async function <T>(messages:ChatCompletionMessageParam[],chatAPIOptions:ChatAPIOptions=OPENAI_DEFAULT_CHAT_OPTIONS):Promise<StructuredOutput<T>> {
    const openai = getOpenAI();
    const nonStreamingPayload = {
        ...getPayloadBaseFrom(messages, chatAPIOptions),
        stream: false,
    } as ChatCompletionCreateParamsNonStreaming;

    async function attempt() {
        if (DEBUG) console.log("[callOpenAIDirect_StructuredJSON]>Attempting to get structured response from OpenAI. messages: ",messages);
        const completion = await openai.beta.chat.completions.parse({
            model: nonStreamingPayload.model,
            messages: nonStreamingPayload.messages,
            response_format: chatAPIOptions.response_format,
        });
        if (DEBUG) console.log("[callOpenAIDirect_StructuredJSON]>Got completion: ",completion);

        const parsedContent = completion.choices[0].message.parsed;
        return { success: true, structuredOutput: parsedContent } as StructuredOutput<T>;
    }

    try {
        return await attempt();
    } catch (e) {
        console.error("[callOpenAIDirect_StructuredJSON] We got an error from either Zod or OpenAI. We'll try one more time. ", e);
        // Allow one retry. We've seen failures where it typed one emoji instead of another.
        return await attempt();
        // return { success: false, error: "Failed to get structured response from OpenAI: " + e };
    }
};

export const callOpenAIDirectVChatCommand:LLMChatServerCall = async function (messages: ChatCompletionMessageParam[], chatAPIOptionsV2: ChatAPIOptions=OPENAI_DEFAULT_CHAT_OPTIONS) {
    const streamingPayload = {
        ...getPayloadBaseFrom(messages, chatAPIOptionsV2),
        stream: true,
    } as ChatCompletionCreateParamsStreaming;
    return await callChatAPIWithRetries_Streaming(streamingPayload, chatAPIOptionsV2.streamingCallback, chatAPIOptionsV2.errorCallback, chatAPIOptionsV2.callFunction);
};

function structuredOutputCall<T>(messages: ChatCompletionMessageParam[], zodSchema: z.ZodSchema<T>, jsonSchema: JSONSchemaType<T>, schemaName:string, chatAPIOptions?: ChatAPIOptions): Promise<StructuredOutput<T>> {
    if (!zodSchema) {
        throw new Error("zodSchema is required for structured output call");
    }
    const chatAPIOptionsV2 = {
        ...OPENAI_DEFAULT_CHAT_OPTIONS,
        ...chatAPIOptions,
        response_format: zodResponseFormat(zodSchema, schemaName),
    } as ChatAPIOptions;
    return callOpenAIDirect_StructuredJSON(messages, chatAPIOptionsV2);
}

export const LLM_SERVERTYPE_OPENAI_DIRECT: LLMServer = {
    name: "GPT 4o direct",
    contextLength: 131072 /*12 8k*/,
    isLocal: false,
    // serverType: LLM_SERVERTYPE_OPENAI_DIRECT,
    defaultTemperature: 0.7,
    supportsStreaming: true,
    supportsFunctions: true,
    supportedQuality: [LLM_QUALITY_LEVEL_BEST, LLM_QUALITY_LEVEL_FASTEST],
    /*modelTypeForTokenization: "gpt-4-0125-preview" as TiktokenModel*/
    chatCall: callOpenAIDirectVChatCommand,
    structuredOutputInputFormat: StructuredOutputInputFormat.ZOD,
    structuredOutputCall,
};