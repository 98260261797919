/********
 * Everything in this file is to be called when it the user does some command.
 * For every function, take the return value and set it to the new editorState.
 */
import { ContentBlock, ContentState, DraftEntityMutability, EditorState, genKey, Modifier, RichUtils, SelectionState } from "draft-js";
import Immutable from "immutable";
//@ts-ignore
import {  getEntityRange,  getSelectionEntity} from 'draftjs-utils';
//@ts-ignore
import isSoftNewlineEvent from "draft-js/lib/isSoftNewlineEvent";
//@ts-ignore
import {getSelectedBlock} from  "draftjs-utils";
import { CHECKABLE_LIST_ITEM, isEmptyListItem } from "./CheckableListItem";
import { KEY_COMMAND_HANDLED, KEY_COMMAND_NOT_HANDLED } from "../DraftKeyboardBindings";


/*****
 * Generic utility function useful for changing a single block.
 * Intended for use in this file only.
 * 
 * TODO there is a bug here that it loses the current selection, going backwards one step to a previous selection.
 * This bug is not exclusive to this funciton, but also appears in our handleIndent function.
 */
export function changeBlockTo(editorState:EditorState,block:ContentBlock,newBlock:Immutable.Map<any,any>, changeType:string) {
  // preserve the selection
  
  const blockKey = block.getKey();
  const contentState = editorState.getCurrentContent();

  // const selection = editorState.getSelection();
  // const selectionBefore = contentState.getSelectionBefore();
  // const selectionAfter = contentState.getSelectionAfter();
    const blockMap = contentState.getBlockMap();
    //@ts-ignore
    const newBlockMap = blockMap.set(blockKey, newBlock);
    return EditorState.push(
      editorState,
      //@ts-ignore
      contentState.merge({ blockMap: newBlockMap }),
        // .set('selectionBefore', selectionBefore)
        // .set('selectionAfter', selectionAfter),
      changeType
     );
}

/********
 * Call when users click a checkbox.
 * Take the return value and set it to the new editorState.
 */
export function toggleChecked(editorState:EditorState, block: ContentBlock) {
    const isChecked = !(block.getData().get("checked"));
    // let newEditorState = CheckableListItemUtils.toggleChecked(editorState, block);
    const now=(new Date()).toDateString();
    // const lastTimeChecked = block.getData().get("lastChecked");
    // console.log("Set todo ",block.getKey(),"to",isChecked,"on",timeChecked,"previously",lastTimeChecked);
    let newData = block.getData().set("checked",isChecked);
    if (isChecked) {
        // Set data lastChecked to new Date()
        newData = newData.set('lastChecked',now);
    }
    const newBlock = block.set("data",newData);
    const newEditorState = changeBlockTo(editorState,block,newBlock, "toggle-checked");
    return newEditorState;
}

export function changeBlockPropertyValue(editorState:EditorState, block: ContentBlock, propertyName:string, propertyValue:any) {
  let newData = block.getData().set(propertyName,propertyValue);
  const newBlock = block.set("data",newData);
  const newEditorState = changeBlockTo(editorState,block,newBlock, "change-block-property-value");
  return newEditorState;
}


/*******
 * e.g. tab or shift-tab to indent or un-indent.
 */
export function changeBlockDepth(editorState: EditorState, block:ContentBlock, depthChangeDelta:number) {
  // preserve the selection:
  const depth = block.getDepth();
  const newBlock = block.set('depth', depth + depthChangeDelta);
  return changeBlockTo(editorState,block, newBlock, 'adjust-depth');
}

export function changeBlockType(editorState:EditorState){
  return EditorState.push(
    editorState,
    //@ts-ignore
    RichUtils.tryToRemoveBlockStyle(editorState),
    'change-block-type',
  );
}


/*******
 * e.g. after a drag-and-drop drop into any bin and with a new priority.
 */
 export function setBlockPriorityIfNeeded(editorState: EditorState, block:ContentBlock) {
    if (!block.getData().get("priority")) {
        console.log("Setting priority for '",block.getText()+"'");
        const newData = block.getData().set("priority",getNextPriority());
        const newBlock = block.set("data",newData);
        return changeBlockTo(editorState,block, newBlock, "set-priority");
    } else {
        console.log("NOT setting priority for '",block.getText()+"' because it has a priority. Block text= "+block.getText());
        return editorState;
    }
}

export function setBlockKeyPriorityIfNeeded(editorState:EditorState, blockKey:string) {
    const block = editorState.getCurrentContent().getBlockForKey(blockKey);
    return setBlockPriorityIfNeeded(editorState, block);
}



/*******
 * e.g. after a drag-and-drop drop into any bin and with a new priority.
 */
 export function setBinAndPriority(editorState: EditorState, block:ContentBlock, binName:string, priority:number) {
    const newData = block.getData().set("binName",binName).set("priority",priority);
    const newBlock = block.set("data",newData);
    return changeBlockTo(editorState,block, newBlock, "set-bin-and-priority");
}

// Priority gets randomly set every session.
// By default, as you create new checkable items, things will get lower priority (lower numbers) each new item in a session.
// There are no guarantees between sessions.
let LAST_PRIORITY = (Math.random()-0.5)*1000000000;

function getNextPriority() {
    LAST_PRIORITY = LAST_PRIORITY - 100000; // Since the random numbers are in the 10's of millions, this will tend to group priorities from a single session together.
    return LAST_PRIORITY;
}

export function toggleFocusedBlockIsCheckable(editorState: EditorState) {
    const now=(new Date()).toDateString();
    const block = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getFocusKey());
    if (block.getType()===CHECKABLE_LIST_ITEM) {
        // Just turn it off:
        return RichUtils.toggleBlockType(editorState, CHECKABLE_LIST_ITEM);
    }
    // Make it checkable:
    let newData = block.getData().set("createdCheckable",now).set("priority",getNextPriority());
    const newBlock = block.set("data",newData).set("type",CHECKABLE_LIST_ITEM);
    const newEditorState = changeBlockTo(editorState,block,newBlock,"toggle-is-checkable");
    return newEditorState;
}


export function newBlock(type:string = "unstyled") {
  //@ts-ignore
  let newBlock = new ContentBlock({
      key: genKey(),
      type: type,
      text: ''
    });
  return newBlock;
}


export function newCheckableBlock() {
    let block = newBlock('checkable-list-item');
    const now=(new Date()).toDateString();
    let newData = block.getData().set("createdCheckable",now).set("priority",getNextPriority());
    //@ts-ignore
    block = block.set("data",newData);
    return block;
}

// https://github.com/facebook/draft-js/issues/2325#issuecomment-652127067
export function addBlockAfter(editorState: EditorState, keyBefore: string, newBlock:ContentBlock) {  
    const contentState = editorState.getCurrentContent();
    const oldBlockMap = contentState.getBlockMap();
    const newBlockMap = Immutable.OrderedMap().withMutations(map => {
        //@ts-ignore   
      for (let [k, v] of oldBlockMap.entries()) {
        map.set(k, v);
  
        if (keyBefore === k) {
          map.set(newBlock.getKey(), newBlock);
        }
      }
    });
  
    return EditorState.forceSelection(
      EditorState.push(
        editorState,
        //@ts-ignore
        ContentState.createFromBlockArray(Array.from(newBlockMap.values()))
          .set('selectionBefore', contentState.getSelectionBefore())
          .set('selectionAfter', contentState.getSelectionAfter()),
        "insert-new-block"
      ),
      SelectionState.createEmpty(newBlock.getKey())
    );
  }
  

export function addLink(editorState:EditorState, linkTitle:string, linkTarget:string, linkTargetOption:string ="_blank", entityType:string="LINK", mutability:DraftEntityMutability="MUTABLE"):EditorState {
  let selection = editorState.getSelection();
  const currentEntity = getSelectionEntity(editorState);
  if (currentEntity) {
    const entityRange = getEntityRange(editorState, currentEntity);
    const isBackward = selection.getIsBackward();
    if (isBackward) {
      selection = selection.merge({
        anchorOffset: entityRange.end,
        focusOffset: entityRange.start,
      });
    } else {
      selection = selection.merge({
        anchorOffset: entityRange.start,
        focusOffset: entityRange.end,
      });
    }
  }
  const entityKey = editorState
    .getCurrentContent()
    .createEntity(entityType, mutability, {
      url: linkTarget,
      targetOption: linkTargetOption,
    })
    .getLastCreatedEntityKey();

  let contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    `${linkTitle}`,
    editorState.getCurrentInlineStyle(),
    entityKey
  );
  let newEditorState = EditorState.push(
    editorState,
    contentState,
    'insert-characters'
  );

  // insert a blank space after link
  selection = newEditorState.getSelection().merge({
    anchorOffset: selection.get('anchorOffset') + linkTitle.length,
    focusOffset: selection.get('anchorOffset') + linkTitle.length,
  });
  newEditorState = EditorState.acceptSelection(newEditorState, selection);
  contentState = Modifier.insertText(
    newEditorState.getCurrentContent(),
    selection,
    ' ',
    newEditorState.getCurrentInlineStyle(),
    undefined
  );
  newEditorState = EditorState.push(newEditorState, contentState, 'insert-characters');

  // Ensure the selection is set to the end of the inserted link
  const newSelection = newEditorState.getSelection().merge({
    anchorOffset: selection.get('anchorOffset') + 1,
    focusOffset: selection.get('anchorOffset') + 1,
  });
  return EditorState.forceSelection(newEditorState, newSelection);
}

export function scrollSelectionIntoViewInAMoment() {
  setTimeout(function() {
    // ⚠ This might not be implemented: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
    // If it's not it'll throw an error but the app won't crash. Alternatively we could check and fall back, but the fallback ain't great.
    //@ts-ignore
    window.getSelection().focusNode.parentElement.scrollIntoViewIfNeeded();
    //@ts-ignore
    window.getSelection().anchorNode.parentElement.scrollIntoViewIfNeeded();

    // Force to scroll into view -- thanks to https://stackoverflow.com/questions/48286946/programmatically-jump-to-a-specific-block-and-move-the-focus-caret-to-a-decorate
    // window.getSelection().focusNode.parentElement.scrollIntoView();
    //@ts-ignore
    // window.getSelection().anchorNode.parentElement.scrollIntoView();
  },1);
}


export function useHandleReturn(editorState:EditorState, onEditorStateChangeFromProps:(newEditorState:EditorState)=>void) {
  function handleReturnForEmptyListItem(block:ContentBlock) {
    const depth = block.getDepth();
    if (depth > 0) {
      onEditorStateChangeFromProps(changeBlockDepth(editorState, block, -1));
    } else if (depth === 0) {
      onEditorStateChangeFromProps(changeBlockType(editorState));
    }
  }

  /*************
   * We have two bad choices:
   * (a) Include handleReturn:
   *      (-) We get a warning message in the console.
   *      (-) The WYSIWYG editor handles a bit of it.
   *      (+) We can handle this exactly the way we want to.
   * (b) Don't include handleReturn
   *      (+) No console warning from draftjs
   *      (-) We don't get all return events from the system for some unknown reason -- my guess is that WYSYIG editor is handling some of them.
   */
  function handleReturn(e:React.KeyboardEvent<Element>) {
    // console.log("handleReturn: ",e);
    if (isSoftNewlineEvent(e)) {
      // console.log("\tWas soft newline");
      onEditorStateChangeFromProps(RichUtils.insertSoftNewline(editorState));
      return KEY_COMMAND_HANDLED;
    }
    const block = getSelectedBlock(editorState);  
    if (isEmptyListItem(block)) {
      // console.log("Was empty list item");
      handleReturnForEmptyListItem(block);
      return KEY_COMMAND_HANDLED;
    }
    return KEY_COMMAND_NOT_HANDLED;
  }

  return handleReturn;
}