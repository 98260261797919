import { zodResponseFormat } from "openai/helpers/zod";
import { useRef, useEffect, useContext } from "react";
import { CommandZod, detectCommandV2, getRuntimeCommandZod, getRuntimeJSONSchema } from "./CommandDetectionAI";
import { ChatMessage2 } from "../../../DecisionGraph/ChatEditor/UI/ChatLog";
import { GenericTestPage, GenericTestPageRef, Test } from "../../../TestPages/GenericTestPage";
import { LLMServerContextProvider, SelectableLLMServerDropdown, LLMServerContext } from "../../../DecisionGraph/ChatEditor/UI/SelectableLLM";
import { StructuredOutputInputFormat } from "../GenericChatServerConsts";

const standardTestCommands= ["start", "stop", "pause", "resume", "create", "delete", "update", "fetch", "list", "search"];
const standardTestContexts = ["game", "user", "session", "database", "file", "network", "system", "application", "service", "device"];

const TTRPG_TEST_COMMAND_INTERACTIVE_STORY = "Start an interactive story (command)";
const TTRPG_TEST_COMMAND_NEW_BATTLE_LUMINARIA = "Create a new battle in Luminaria (command)";
const TTRPG_TEST_COMMANDS = [
    "Describe a place or scene, narrate, or an NPC speaks (command)",
    "Rewrite with links (command)",
    "Call for a skill check when players attempt an action (command, when using custom skill dice)",
    TTRPG_TEST_COMMAND_INTERACTIVE_STORY,
    "Prepare a prewritten story for Powers TTRPG in Luminaria (command)",
    "Extract From Story (command)",
    "Create a new battle (command)",
    TTRPG_TEST_COMMAND_NEW_BATTLE_LUMINARIA,
];
const TTRPG_TEST_CONTEXT_LUMINARIA_CONTINENT = "Luminaria Continent (location)";
const TTRPG_TEST_CONTEXT_LUMINARIA_CITY = "Luminaria City (location)";
const TTRPG_TEST_CONTEXTS = [
    TTRPG_TEST_CONTEXT_LUMINARIA_CONTINENT,
    TTRPG_TEST_CONTEXT_LUMINARIA_CITY,
    "Bob Smith (Example NPC)",
    "Crimson Abominations in the Underground Lab (battle)",
    "NPC Quotes",
    "How to: TTRPGs",
    "Generic TTRPG Types",
    "The Arcane Art",
    "Fantasy Battle Maps",
    "Luminary Gems",
    "Rohan & Noah Game",
    "Old PTTRPG Prompts",
    "Alaric Eldridge Thorne, Master Alchemist of Sylvanholme",
    "Elysia",
    "Jarek Fennel Quill",
    "Nighttime Sounds Heard in Camp",
    "Tsui Game & Progress",
    "LeTourneau game & progress (WWPTD)",
    "Questions to ask players (T&L)",
    "Tsui Characters",
    "Letourneau Characters",
    "Bronzo (Noah)",
    "Scheduling Tsui & Letourneau Games",
    "T&L Session Log",
];

function CommandDetectionAITestPage() {
    const testPageRef = useRef<GenericTestPageRef | null>(null);
    const { serverType } = useContext(LLMServerContext);

    const tests: Test[] = [
        {
            name: "Basic Zod Shape",
            check: async function() {
                const zodCommand = getRuntimeCommandZod(standardTestCommands, standardTestContexts);
                if (!zodCommand) return { success: false, message: "Result is empty" };
                if (!zodCommand.shape) return { success: false, message: "Result does not have shape" };
                if (!zodCommand.shape.commands) return { success: false, message: "Result does not have shape.commands" };
                if (!zodCommand.shape.context) return { success: false, message: "Result does not have shape.context" };
                return { success: true };
            }
        },
        {
            name: "Zod to JSON Schema",
            check: async function() {
                const jsonSchemaCommand = getRuntimeJSONSchema(standardTestCommands, standardTestContexts);
                if (!jsonSchemaCommand) return { success: false, message: "Result is empty" };
                //@ts-ignore
                if (!jsonSchemaCommand.properties) return { success: false, message: "Result does not have properties" };
                //@ts-ignore
                if (!jsonSchemaCommand.properties.commands) return { success: false, message: "Result does not have properties.commands" };
                //@ts-ignore
                if (!jsonSchemaCommand.properties.context) return { success: false, message: "Result does not have properties.context" };
                //@ts-ignore
                if (!jsonSchemaCommand.properties.commands.items?.enum) return { success: false, message: "Result does not have properties.commands.items.enum" };
                //@ts-ignore
                if (!jsonSchemaCommand.properties.context.items?.enum) return { success: false, message: "Result does not have properties.context.items.enum" };
                //@ts-ignore
                if (jsonSchemaCommand.properties.commands.items.enum.length !== standardTestCommands.length) return { success: false, message: "Result properties.commands.enum length is not "+standardTestCommands.length };
                //@ts-ignore
                if (jsonSchemaCommand.properties.context.items.enum.length !== standardTestContexts.length) return { success: false, message: "Result properties.context.enum length is not "+standardTestContexts.length };
                //@ts-ignore
                if (jsonSchemaCommand.properties.commands.items.enum[0] !== "start") return { success: false, message: "Result properties.commands.enum[0] is not 'start'" };
                //@ts-ignore
                if (jsonSchemaCommand.properties.commands.items.enum[1] !== "stop") return { success: false, message: "Result properties.commands.enum[1] is not 'stop'" };
                //  @ts-ignore
                if (jsonSchemaCommand.properties.context.items.enum[0] !== "game") return { success: false, message: "Result properties.context.enum[0] is not 'game'" };
                //  @ts-ignore
                return { success: true };
            }
        },
        {
            name: "Test Zod Response Format",
            check: async function() {
                const responseFormat = zodResponseFormat(CommandZod, "Command");
                const responseFormat2 = zodResponseFormat(getRuntimeCommandZod(standardTestCommands, standardTestContexts), "Command");
                if (!responseFormat) return { success: false, message: "Result is empty" };
                if (!responseFormat2) return { success: false, message: "Result2 is empty" };
                return { success: true };
            }
        },
        {
            name: "Detect Command - Start Game",
            check: async function() {
                const result = await detectCommandV2(standardTestCommands, standardTestContexts, [{ role: "user", content: "start the game" } as ChatMessage2],"", serverType);
                if (!result) return { success: false, message: "Result is empty" };
                if (!result.commands) return { success: false, message: "Result did not return any commands, we got "+JSON.stringify(result) };
                if (!result.context) return { success: false, message: "Result did not return any context" };
                if (!result.commands.includes("start")) return { success: false, message: "Command 'start' not detected" };
                if (!result.context.includes("game")) return { success: false, message: "Context 'game' not detected" };
                return { success: true };
            }
        },
        {
            name: "Detect Command - Stop Game",
            check: async function() {
                const result = await detectCommandV2(standardTestCommands, standardTestContexts, [{ role: "user", content: "stop the game" } as ChatMessage2],"", serverType);
                if (!result.commands.includes("stop")) return { success: false, message: "Command 'stop' not detected" };
                if (!result.context.includes("game")) return { success: false, message: "Context 'game' not detected" };
                return { success: true };
            }
        },
        {
            name: "Detect Command - No Commands",
            check: async function() {
                const result = await detectCommandV2([], standardTestContexts, [{ role: "user", content: "I want to do something about the network" } as ChatMessage2],"", serverType);
                if (result.commands) {
                    return { success: false, message: "Commands were detected"};
                }
                if (!result.context) return { success: false, message: "Context not detected" };
                if (!result.context.includes("network")) return { success: false, message: "Context 'network' not detected" };
                return { success: true };
            }
        },
        {
            name: "Detect Command - No Context",
            check: async function() {
                const result = await detectCommandV2(standardTestCommands, [], [{ role: "user", content: "start the process" } as ChatMessage2], "", serverType);
                if (result.context) return { success: false, message: "Context was detected"};
                if (!result.commands) return { success: false, message: "Commands not detected" };
                if (!result.commands.includes("start")) return { success: false, message: "Command 'start' not detected" };
                return { success: true };
            }
        },
        {
            name: "Detect Command - No Commands nor Context",
            check: async function() {
                // This should error out. It's not possible to detect anything.
                try {
                    await detectCommandV2([], [], [{ role: "user", content: "I want to do something" } as ChatMessage2],"", serverType);
                    return { success: false, message: "No error thrown"};
                } catch (e) {
                    return { success: true, message: "Correctly errored" };
                }
            }
        },
        {
            name: "Detect Command - TTRPG Command test 1",
            check: async function() {
                const result = await detectCommandV2(TTRPG_TEST_COMMANDS, TTRPG_TEST_CONTEXTS, [{ role: "user", content: "Run a story in Luminaria City" } as ChatMessage2],"", serverType);
                if (!result.commands.includes(TTRPG_TEST_COMMAND_INTERACTIVE_STORY)) return { success: false, message: `Command '${TTRPG_TEST_COMMAND_INTERACTIVE_STORY}' not detected`};
                if (!result.context.includes(TTRPG_TEST_CONTEXT_LUMINARIA_CITY)) return { success: false, message: `Context '${TTRPG_TEST_CONTEXT_LUMINARIA_CITY}' not detected`};

                // For contexts it must always get luminaria city. Sometimes it gets luminaria continent.
                // gpt-4o is only giving luminaria city. Gemini flash is giving both (which is better)
                if (result.context.length===2 && !result.context.includes(TTRPG_TEST_CONTEXT_LUMINARIA_CONTINENT)) return { success: true};

                if (result.commands.length > 1 || result.context.length>1 ) {
                    let message = "Detected an extra ";
                    if (result.commands.length > 1) message += (result.commands.length-1)+" commands";
                    if (result.commands.length > 1 && result.context.length > 1) message += " and ";
                    if (result.context.length > 1) message += (result.context.length-1)+" contexts";
                    // Print the extra ones at the end:
                    if (result.commands.length > 1) message += "(extra commands: "+result.commands.slice(1).join(", ")+")";
                    if (result.context.length > 1) message += "(extra contexts: "+result.context.slice(1).join(", ")+")";
                    return { success: false, message: message};
                }
                return { success: true};
            }
        },
        {
            name: "Detect Command - TTRPG Command test 2, mentioned by indirect name one option",
            check: async function() {
                const result = await detectCommandV2(TTRPG_TEST_COMMANDS, TTRPG_TEST_CONTEXTS, [{ role: "user", content: "Run a story in the city" } as ChatMessage2],"", serverType);
                if (!result.commands.includes(TTRPG_TEST_COMMAND_INTERACTIVE_STORY)) return { success: false, message: `Command '${TTRPG_TEST_COMMAND_INTERACTIVE_STORY}' not detected`};
                if (!result.context.includes(TTRPG_TEST_CONTEXT_LUMINARIA_CITY)) return { success: false, message: `Context '${TTRPG_TEST_CONTEXT_LUMINARIA_CITY}' not detected`};
                if (result.commands.length > 1 || result.context.length>1 )
                    return { success: false, message: "Detected an extra "+(result.commands.length-1)+" commands and "+(result.context.length-1)+" contexts"};
                return { success: true};
            }
        },
        {
            name: "Detect Command - TTRPG Command test 3, implied context",
            check: async function() {
                const result = await detectCommandV2(TTRPG_TEST_COMMANDS, TTRPG_TEST_CONTEXTS, [{ role: "user", content: "Create a new battle in a new tavern in the city" } as ChatMessage2],"", serverType);
    
                if (!result.commands.includes(TTRPG_TEST_COMMAND_NEW_BATTLE_LUMINARIA)) return { success: false, message: `Command '${TTRPG_TEST_COMMAND_NEW_BATTLE_LUMINARIA}' not detected`};
                
                if (!result.context.includes(TTRPG_TEST_CONTEXT_LUMINARIA_CITY)) return { success: false, message: `Context '${TTRPG_TEST_CONTEXT_LUMINARIA_CITY}' not detected`};
    
                if (result.commands.length > 1 || result.context.length>1 ) {
                    let message = "Detected an extra ";
                    if (result.commands.length > 1) message += (result.commands.length-1)+" commands";
                    if (result.commands.length > 1 && result.context.length > 1) message += " and ";
                    if (result.context.length > 1) message += (result.context.length-1)+" contexts";
                    // Print the extra ones at the end:
                    if (result.commands.length > 1) message += "(extra commands: "+result.commands.slice(1).join(", ")+")";
                    if (result.context.length > 1) message += "(extra contexts: "+result.context.slice(1).join(", ")+")";
                    return { success: false, message: message};
                }
    
                return { success: true};
            }
        }    
    ];

    useEffect(() => {
        // Wait until the server type is loaded before running the tests:
        if (!serverType) return;

        if (testPageRef.current) {
            testPageRef.current.runSelectedTest();
        }
    }, [serverType]);

    return (
        <GenericTestPage
            ref={testPageRef}
            tests={tests}
            title="Command Detection AI Test Page"
            children={
                <>
                    <SelectableLLMServerDropdown/>
                    {!serverType && <div>Loading server...</div>}
                    {serverType?.structuredOutputInputFormat === StructuredOutputInputFormat.NONE && <div>Server does not support structured output</div>}
                </>
            }
        />
    );
}

// New wrapper component with provider at the root
export default function CommandDetectionAITestPageWrapper() {
    return (
        <LLMServerContextProvider>
            <CommandDetectionAITestPage />
        </LLMServerContextProvider>
    );
}