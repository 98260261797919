import { useState, useEffect, useCallback } from "react";
import { Select, Tooltip, Button, Table } from "antd";
import { extractJSONFromMarkdown } from "../ServerConnection/LLMServer/MarkdownConverter";

const tests = [
    {
        name: "Test1",
        markdown: `\`\`\`JSON
        {
        "name": "Evil Leprechaun",
        "HP": "Medium",
        "Special Attacks": [
        {
        "name": "Gold Coin Grenade",
        "effect": "Deals damage and temporarily blinds opponents with a shiny explosion."
        },
        {
        "name": "Illusory Double",
        "effect": "Creates an illusion of itself to confuse enemies, effectively giving it an extra turn."
        }
        ],
        "Weaknesses": [
        "Iron",
        "Direct sunlight"
        ],
        "Loot": [
        "Cursed gold coins",
        "Rare magical trinket"
        ],
        "Movement": "Can teleport short distances within its line of sight."
        }
        \`\`\``,
        check: (result: any) => result && result.name === "Evil Leprechaun"
    },
    {
        name: "Test2",
        markdown: `\`\`\`JSON
        ["hello","world"]
        \`\`\``,
        check: (result: any) => Array.isArray(result) && result.length === 2 && result[0] === "hello" && result[1] === "world"
    },
    {
        name: "Test3",
        markdown: `This is a test with no JSON.`,
        check: (result: any) => result === null
    },
    {
        name: "Test4",
        markdown: `\`\`\`JSON
        {"name":"test"`,
        check: (result: any) => result === null
    },
    {
        name: "Test5",
        markdown: `{"name":"test"}`,
        check: (result: any) => result === null
    },
    {
        name: "Test6",
        markdown: `Logical reasoning:
        * The user's request is vague and does not clearly relate to the available topic.
        * There is only one available topic, "Save Game", which does not seem relevant to the user's inquiry about an opportunity.

        \`\`\`JSON
        []
        \`\`\``,
        check: (result: any) => Array.isArray(result) && result.length === 0
    },
    {
        name: "Test7",
        markdown: `This is a test with JSON in the middle.\n\`\`\`JSON\n{"key":"value"}\n\`\`\`\nAnd some text after.`,
        check: (result: any) => result && result.key === "value"
    },
    {
        name: "Test8",
        markdown: `This is a test with JSON at the end.\n\`\`\`JSON\n{"key":"value"}\n\`\`\``,
        check: (result: any) => result && result.key === "value"
    },
    {
        name: "Test9",
        markdown: `\`\`\`JSON\n{"key":"value"}\n\`\`\`\nThis is a test with JSON at the beginning.`,
        check: (result: any) => result && result.key === "value"
    }
];

export function MarkdownConverterTestPage() {
    const [results, setResults] = useState<{ name: string; markdown: string; expected: string; actual: string; success: boolean }[]>([]);
    const [selectedTest, setSelectedTest] = useState<string>("all");
    const [output, setOutput] = useState<string>("");

    async function runTestDef(test: typeof tests[0]) {
        const result = extractJSONFromMarkdown(test.markdown);
        const success = test.check(result);
        setResults(prev => [...prev, {
            name: test.name,
            markdown: test.markdown,
            expected: JSON.stringify(result),
            actual: JSON.stringify(result),
            success
        }]);
        return success;
    }

    const runAllTests = useCallback(async () => {
        setResults([]);
        let successCount = 0;
        for (const test of tests) {
            const success = await runTestDef(test);
            if (success) successCount++;
        }
        setOutput(`All tests finished: ${successCount}/${tests.length} succeeded.`);
    }, []);

    function runSelectedTest() {
        if (selectedTest === "all") {
            runAllTests();
        } else {
            const test = tests.find(t => t.name.toLowerCase() === selectedTest);
            if (test) runTestDef(test);
        }
    }

    useEffect(() => {
        runAllTests();
    }, [runAllTests]);

    return <div>
        <h1>Markdown Converter Test Page</h1>
        <div>
            <Select style={{ width: "150px" }} value={selectedTest} onChange={setSelectedTest}>
                <Select.Option value="all">Run All Tests</Select.Option>
                {tests.map(test => (
                    <Select.Option
                        key={test.name}
                        value={test.name.toLowerCase()}
                    >
                        {test.name}
                    </Select.Option>
                ))}
            </Select>
            <Tooltip title="Run the selected test or all tests">
                <Button onClick={runSelectedTest}>Run</Button>
            </Tooltip>
        </div>
        <div>{output}</div>
        {results.length > 0 && <Table dataSource={results} columns={[
            { title: 'Status', dataIndex: 'success', key: 'success', render: (success: boolean) => success ? "✔" : "❌" },
            { title: 'Test Name', dataIndex: 'name', key: 'name' },
            { title: 'Markdown', dataIndex: 'markdown', key: 'markdown', render: (markdown: string) => (
                <Tooltip title={markdown}>
                    <span role="img" aria-label="info">ℹ️</span>
                </Tooltip>
            )},
            { title: 'Expected', dataIndex: 'expected', key: 'expected' },
            { title: 'Actual', dataIndex: 'actual', key: 'actual' }
        ]} rowKey="name" />}
    </div>;
}
