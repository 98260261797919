import { useState, useEffect } from 'react';
import { EditorState, RawDraftContentState, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { useBasicHandleKeyCommand } from './DraftJSEditor/DraftKeyboardBindings';
import { blockStyleFn, useBlockRendererFn } from './DraftJSEditor/BlockEditing/BlockRendererAndStyleFns';
import { useDraftExtensions } from './DraftJSEditor/DraftJSPluginsEditor/DraftJsToolbarAndPlugins';
import { getBlockRenderMap } from './DraftJSEditor/BlockEditing/BlockRenderMap';


export default function InlineDraftJSViewer({draftJSMessage}:{draftJSMessage:RawDraftContentState}) {
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(convertFromRaw(draftJSMessage)));
    // How can we make this more efficient?
    useEffect(() => {
        setEditorState(EditorState.createWithContent(convertFromRaw(draftJSMessage)));
    },[draftJSMessage]);
    
    // const basicKeyBinding = useBasicKeyBindingFn(editorState);
    const basicHandleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
    const blockRenderFn = useBlockRendererFn(setEditorState, editorState, false);
    const {plugins} = useDraftExtensions(setEditorState,editorState, false, basicHandleKeyCommand);
    return <div className="editor inline-editor-main">
      <Editor
        editorState={editorState}
        onChange={setEditorState} // this has to be present for links to be rendered, not sure why.
        blockRendererFn={blockRenderFn}
        blockRenderMap={getBlockRenderMap()}
        blockStyleFn={blockStyleFn}
        plugins={plugins}
        readOnly={true}
    />
    </div>;
}