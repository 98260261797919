import { JSONSchemaType } from "ajv";
import { ChatCompletionMessageParam, ChatCompletionMessageToolCall, ChatCompletionTool, ChatCompletionToolChoiceOption } from "openai/resources";
import { z } from "zod";

export const LLM_QUALITY_LEVEL_BEST = "best";
export const LLM_QUALITY_LEVEL_FASTEST = "fastest";
export type LLMQualityLevel = typeof LLM_QUALITY_LEVEL_BEST | typeof LLM_QUALITY_LEVEL_FASTEST;

export type StreamingCallback = (fullOutput: string, newOutput: string, isDone: boolean) => void;
export type ChatStreamErrorCallback = (error: string) => void;
export type ChatAPIOptions = {
    // from the ChatCompletionCreateParamsBase:
    temperature?: number;
    max_tokens?: number;
    tool_choice?: ChatCompletionToolChoiceOption;
    tools?: Array<ChatCompletionTool>;
    response_format?: any;//ResponseFormatText | ResponseFormatJSONObject | ResponseFormatJSONSchema;

    // Just for us:
    abortController?: AbortController;
    
    streamingCallback?: StreamingCallback;
    errorCallback?: ChatStreamErrorCallback;
    callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void;
    quality:LLMQualityLevel;
};
export const DEFAULT_CHAT_API_OPTIONS: ChatAPIOptions = {
    response_format: {type: "text"},
    max_tokens: -1,
    quality: LLM_QUALITY_LEVEL_BEST,
};

export type ChatAPICallReturnType = {success:boolean, message?:string, error?:string};

export type StructuredOutput<T> = {success:boolean, structuredOutput?:T, error?:string};

// TO DEPRECATE old call type:
export type ChatAPICall = (messages: ChatCompletionMessageParam[], streamingCallback?: StreamingCallback, errorCallback?: ChatStreamErrorCallback, options?: ChatAPIOptions, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void) => Promise<ChatAPICallReturnType>;

export enum StructuredOutputInputFormat {
    JSON,
    ZOD,
    NONE,
}

export type LLMChatServerCall = (messages: ChatCompletionMessageParam[], chatAPIOptions?: ChatAPIOptions)=>Promise<ChatAPICallReturnType>;
export type LLMStructuredOutputCall = <T>(messages: ChatCompletionMessageParam[], zodSchema:z.ZodSchema<T>, jsonSchema:JSONSchemaType<T>, schemaName:string, chatAPIOptions?: ChatAPIOptions)=>Promise<StructuredOutput<T>>;

export type LLMServer = {
    name: string;
    contextLength: number; // In Tokens
    isLocal: boolean;
    // serverType: ServerEndpointString;
    defaultTemperature: number;
    supportsStreaming: boolean; // to support streaming, it can't use Firebase Functions, which have a single batch output.
    supportsFunctions: boolean;
    // modelTypeForTokenization?: TiktokenModel; // Best model type for tokenization. Currently only supporting OpenAI tokenization counts in LLMTokenCounter
    supportedQuality: LLMQualityLevel[];
    chatCall: LLMChatServerCall;
    structuredOutputInputFormat: StructuredOutputInputFormat;
    structuredOutputCall?: LLMStructuredOutputCall;
}