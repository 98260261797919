import './GameCard.css';
import "./SmallGameCard.css";
import { Segmented, Select, Table, InputNumber, Button } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { Discipline, ReusableCard, getCardsAreSame, getReusableCardCostStr as getReusableCardCostStr, getCardEnhancementsText, AreaEffectOnCard, CardActionType, getReusableCardDistinctName, getReusableCardCommandStringAsReact, getDefenseCardCommandStringReact, DiscardableCard, getDiscardableCardCommandStringAsReact, DefenseCard, XPAtLeast1, getAreaEffectDimensions } from './GameCardTypes';
import { AllReusableCards } from './ReusableCards';
import { PlayerCharacterInMemoryType } from '../PlayerCharacter/PlayerCharacters';
import { getGoldUsed, getPlayerLevel, getPlayerLife, getAcquirableThingsStrings, getXPUsed, AcquireableThingsType, getErrors as getErrorsInPlayer, getUpgradedPlayer, getPlayerReusableCards } from "./PlayerFunctions";
import { uniq } from 'lodash';
import { AllSkillCards } from './SkillCards';
import { useStateURLStringArray } from '../../../DecisionGraph/Utilities/URLQueryParams';
import { useStateLSString } from '../../../DecisionGraph/Utilities/StateWithLocalCache';
import { AppstoreOutlined, PrinterOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { TableProps } from 'antd/lib';
import { useReactToPrint } from 'react-to-print';
import { getReusableCardEquivalentPoints } from './CardPoints';

const DEBUG_CHARACTER_SHEET_LAYOUT = false;

type GameCardTagsType = {name:string,type:string,count:number};
type CardSide = "front"|"back"|"both";

const AllGameCards = [...AllReusableCards, ...AllSkillCards];

export function GameCardComponent({gameCard, cardSide}:{gameCard:ReusableCard, cardSide:CardSide}) {
    const renderFront = cardSide==="front" || cardSide==="both";
    const renderBack = cardSide==="back" || cardSide==="both";
    return <>
    {renderFront && 
        <div className={"gameCard "+gameCard.actionType+"Action"} style={{backgroundImage:"url("+gameCard.image+")"}} key={"front"}>
            <div className="headerRowLeftAligned">
                <div className="header"><span className="line1">{gameCard.name}</span><span className="line2">{gameCard.discipline}</span></div>
                <div className="headerEmptyColumn"></div>
            </div>
            <div className="gameCardContents">
                <div className="top">
                    {gameCard.commandAreaString && <AreaEffectOnCard card={gameCard}/>}
                    {/* BUG: The next line needs to be included even though it should have no effect.
                    The symptoms show up only when:
                    - printed
                    - NO card being shown has a "gameActionArea larger"
                    - the only cards that show the bug have a null gameCard.commandAreaString
                     */}
                    {!gameCard.commandAreaString && <div className="gameActionArea larger"/>}
                </div>
                <div className="middle"/>
                <div className="bottom">
                    <div className="costRow">
                        <div className="costEmptyColumn"></div>
                        <div className="cost">{getReusableCardCostStr(gameCard)}</div>
                    </div>
                    <div className="directions">
                        {getReusableCardCommandStringAsReact(gameCard)}
                        {gameCard.enhancements && gameCard.enhancements.length>0 && <>
                            <br/><br/>
                            <span className="commandColorText">{getCardEnhancementsText(gameCard)}</span></>}
                    </div>
                </div>
            </div>
        </div>}
    {renderBack && 
        <div className="gameCardBack" style={{backgroundImage:"url("+gameCard.image+")"}} key="back"/>}
    </>;
}
export function SmallReusableCardComponent({gameCard}:{gameCard:ReusableCard}) {
    return <div className={"smallGameCard "+gameCard.actionType+"Action"}  key={"front"}
    // style={{backgroundImage:"url("+gameCard.image+")"}}
    >
        <div className="headerRowLeftAligned">
            <div className="header">
                <span className="line1">{gameCard.name}</span>
                <span className="line2">{gameCard.discipline} Level {gameCard.xpCost}</span>
                {gameCard.enhancements && gameCard.enhancements.length>0 && <>
                <span className="line3"><i>{getCardEnhancementsText(gameCard,35)}</i></span></>}
            </div>
            <div className="headerEmptyColumn">
                {getReusableCardCommandStringAsReact(gameCard,true)}

                {/* TODO this will be hard, we'll have to make it very small.*/}
                {/* <span className="left">
                    {gameCard.commandAreaString && <AreaEffectOnCard card={gameCard}/>}
                </span> */}

                {/* {gameCard.enhancements && gameCard.enhancements.length>0 && <>
                <span className="commandColorText"><i>{getCardEnhancementsText(gameCard)}</i></span></>} */}
            </div>
        </div>
    </div>
}

export function SmallReusableAttackCardComponent({gameCard}:{gameCard:ReusableCard}) {
    const { columns } = getAreaEffectDimensions(gameCard.commandAreaString || "");
    const useInlineOneColumn = !gameCard.commandAreaString;
    const useInlineTwoColumns = gameCard.commandAreaString && columns <= 3 && gameCard.name.length<15;
    const useMultiRow = gameCard.commandAreaString && !useInlineTwoColumns;
    return (
        <div className={"smallGameCard "+gameCard.actionType+"Action"} key={"front"}>
            <div className="headerRowLeftAligned">
                <div className="header">
                    <span className="line1">{gameCard.name}</span>
                    <span className="line2">{gameCard.discipline} Level {gameCard.xpCost}</span>
                    {gameCard.enhancements && gameCard.enhancements.length > 0 &&
                        <span className="line3"><i>{getCardEnhancementsText(gameCard,35)}</i></span>
                    }
                </div>
                {useInlineOneColumn && (
                    <div className="headerEmptyColumn">
                        {getReusableCardCommandStringAsReact(gameCard,true)}
                    </div>
                )}
                {useInlineTwoColumns && (
                    <div className="headerEmptyColumn1">
                        {getReusableCardCommandStringAsReact(gameCard,true)}
                    </div>
                )}
                {useInlineTwoColumns && (
                    <div className={"headerEmptyColumn2_"+columns+"_wide"}>
                        {gameCard.commandAreaString && <AreaEffectOnCard card={gameCard}/>}
                    </div>
                )}
                {!useInlineTwoColumns && (
                    <div className="headerEmptyColumnForAreaEffect">
                        {gameCard.commandAreaString && <AreaEffectOnCard card={gameCard}/>}
                    </div>
                )}
            </div>
            {useMultiRow && (
                // Stacked layout: Title row then below it the command text and area effect block
                <div className="stackedContent">
                        {getReusableCardCommandStringAsReact(gameCard,true)}
                </div>
            )}
        </div>
    );
}

export function SmallDiscardableCardComponent({gameCard, isOnCharacterSheet=false}:{gameCard:DiscardableCard, isOnCharacterSheet?:boolean}) {
    return <div className={"smallGameCard discardable"}  key={"front"}>
            <div className="headerRowLeftAligned">
                <div className="header">
                    <span className="line1">{gameCard.name}</span>
                </div>
                <div className="headerEmptyColumn">
                    {getDiscardableCardCommandStringAsReact(gameCard,true, isOnCharacterSheet)}
                </div>
            </div>
        </div>
}
export function SmallDefenseCardComponent({gameCard}:{gameCard:DefenseCard}) {
    return <div className={"smallGameCard defense"}  key={"front"}>
            <div className="headerRowLeftAligned">
                <div className="header">
                    <span className="line1">{gameCard.name}</span>
                </div>
                <div className="headerEmptyColumn">
                    {getDefenseCardCommandStringReact(gameCard)}
                </div>
            </div>
        </div>
}


export function PlayerInventoryCardComponent({player}:{player:PlayerCharacterInMemoryType}) {
    const level = getPlayerLevel(player);
    return <div className="gameCard player">
        <div className="headerRowLeftAligned">
            <div className="header">
                <span className="line1">{player.characterName}</span>
                <span className="line2">{player.role?<>{player.role} {level} &nbsp;&nbsp; </>:""}<i>({player.playerName})</i></span>
            </div>
            <div className="headerEmptyColumn"></div>
        </div>
        <div className="bodyStats">
            <div className="miscContent">
                {player.supporters?<div><b>Supporters<br/></b> {player.supporters}</div>:null}
                {player.supporters && player.miscItems?<hr/>:null}
                {player.miscItems?<div><b>Miscellaneous</b><br/>{player.miscItems}</div>:null}
            </div>
        </div>
    </div>;
}
export function PlayerUpgradeCardComponent({player}:{player:PlayerCharacterInMemoryType}) {
    const usedXP = getXPUsed(player);
    const usedGold = getGoldUsed(player);
    const level = getPlayerLevel(player);
    const errors = getErrorsInPlayer(player);
    
    const acquirables = getAcquirableThingsStrings(player);
    // Group them together so we can show them in a shorter list:
    const acquirablesGrouped = acquirables.reduce(function(acc:{[key:string]:string[]},acquirable:AcquireableThingsType) {
        if (!acc[acquirable.costStr])
            acc[acquirable.costStr] = [];
        acc[acquirable.costStr].push(acquirable.name);
        return acc;
    },{});

    return <div className="gameCard playerUpgrades">
        <div className="headerRowLeftAligned">
            <div className="header">
                <span className="line1">&nbsp;{player.characterName} ({player.playerName})</span>
                <span className="line2">&nbsp;&nbsp;Level {level} {player.role}</span>
            </div>
            <div className="headerEmptyColumn"></div>
        </div>
        <div className="bodyStats">
            {errors.length>0?<div className="errors">⚠ {errors.join(", ")}</div>:null}

            <div className="xpStats"><b>Can spend:</b></div>
            <div className="xpStats">&nbsp;&nbsp;{player.earnedXP-usedXP} XP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>(of {player.earnedXP} earned)</i></div>
            <div className="goldStats">&nbsp;&nbsp;{player.earnedGold-usedGold} Gold &nbsp;&nbsp;&nbsp;&nbsp;<i>(of {player.earnedGold} found)</i></div>
            <div className="miscContent">
                <br/>
                <b>Upgrades available (choose up to 2):</b><br/>
                <ul>
                    {Object.keys(acquirablesGrouped).map(function (costStr:string,i:number) {
                        // Join the strings together, prefix the last one with "OR":
                        // const joinedItems = acquirablesGrouped[costStr].map((name,j) => j===acquirablesGrouped[costStr].length-1?"or "+name:name).join(", ");
                        const joinedItems = acquirablesGrouped[costStr].join(", ");
                        return <li key={i}>
                            <b>{joinedItems}</b> for {costStr} {acquirablesGrouped[costStr].length>1?"each":""}
                        </li>})}
                </ul>
            </div>
        </div>
    </div>; 
}
export function PlayerDefenseCardComponent({player}:{player:PlayerCharacterInMemoryType}) {
    const life = getPlayerLife(player);
    const level = getPlayerLevel(player);
    const heartsPerRow = 5;
    return <div className="gameCard player">
        <div className="headerRowRightAligned">
            <div className="headerEmptyColumn"></div>
            <div className="header">
                <span className="line1">{player.characterName}</span>
                <span className="line2">{player.role?<>{player.role} {level} &nbsp;&nbsp; </>:""}<i>({player.playerName})</i></span>
            </div>
        </div>
        <div className="bodyStats">
            <div className="lifeRow">
                <div className="lifeHeader">{life } Life</div>
                <div className="lifeIcons">
                    {/* TODO eventually if we get enough life, we'd put 10 per line */}
                    {Array(Math.floor(life/heartsPerRow)+1).fill(0).map((_,i) => <div key={i}>{Array(Math.min(heartsPerRow,life-i*heartsPerRow)).fill(0).map((_,j) => <span key={j} className="lifeIcon">❤️</span>)}</div>)}
                </div>
            </div>
            <br/>
            <h2>Defense</h2>
            {player.defenseCards.length===0?<div><i>No defense cards</i></div>:<div>
                {player.defenseCards.map((defenseCard,index) => <div key={index}>
                    {getDefenseCardCommandStringReact(defenseCard)}<br/>
                </div>)}
            </div>}
            
        </div>
    </div>;
}


function getPCLabel(pc:PlayerCharacterInMemoryType) {
    return pc.playerName+"'s "+pc.characterName;
}

export enum GameCardListType {
    List = "List",
    Cards = "Cards",
    Sheet = "Sheet",
    SheetTabloid = "SheetTabloid"
};

function PlayersCardsComponent({pcs}:{pcs:PlayerCharacterInMemoryType[]}) {
    return <>
        {pcs.map(function(player,index) {
            return <span key={index}>
                <PlayerInventoryCardComponent player={player}/>
                <PlayerDefenseCardComponent player={player}/>
                <PlayerUpgradeCardComponent player={player}/>
            </span>;
        })}
    </>;
}
function GameCardsComponent({gameCards, cardSide}:{gameCards:ReusableCard[], cardSide:CardSide}) {
    return <>
        {gameCards.map(function(gameCard,index) {
            return <GameCardComponent gameCard={gameCard} cardSide={cardSide} key={"GameCard"+index}/>;
        })}
    </>;
}

function groupTogetherAcquirablesWithIdenticalCosts(acquirables:AcquireableThingsType[]) {
    return acquirables.reduce(function(acc:{[key:string]:string[]},acquirable:AcquireableThingsType) {
        if (!acc[acquirable.costStr])
            acc[acquirable.costStr] = [];
        acc[acquirable.costStr].push(acquirable.name);
        return acc;
    },{});
}

function CharacterSheet({pc: player, size, playAsLevel}:{pc:PlayerCharacterInMemoryType, size:"letter"|"tabloid", playAsLevel: XPAtLeast1}) {
    const actualLevel = getPlayerLevel(player);
    const level = playAsLevel;
    const life = getPlayerLife(player, level);
    const heartsPerRow = 5;

    const usedXP = getXPUsed(player);
    const usedGold = getGoldUsed(player);
    const errors = getErrorsInPlayer(player);

    const {upgradedPlayer, gainedGold, gainedXP, newLevel, playerUnusedXP } = getUpgradedPlayer(player);
    const acquirables = getAcquirableThingsStrings(upgradedPlayer);
    const upgradesNonSkillsGrouped = groupTogetherAcquirablesWithIdenticalCosts(acquirables.filter(acquirable => acquirable.reusableCard && acquirable.reusableCard.actionType!=="skill" || acquirable.defenseCard));
    const upgradesSkillsAndLifeGrouped = groupTogetherAcquirablesWithIdenticalCosts(acquirables.filter(acquirable => (acquirable.reusableCard && acquirable.reusableCard.actionType==="skill") || acquirable.isLife));
    const upgradesAllOthersGrouped = groupTogetherAcquirablesWithIdenticalCosts(acquirables.filter(acquirable => !acquirable.reusableCard && !acquirable.isLife && !acquirable.defenseCard));

    const reusableCardsOrdered = getPlayerReusableCards(player, level).sort(function(a:ReusableCard,b:ReusableCard){
        if (a.actionType===b.actionType) {
            if (a.xpCost===b.xpCost)
                return a.name.localeCompare(b.name);
            return b.xpCost-a.xpCost;
        }
        if (a.actionType==='attack')
            return -1;
        if (b.actionType==='attack')
            return 1;
        if (a.actionType==='enhance')
            return 1;
        if (b.actionType==='enhance')
            return -1;
        // Should never reach here:
        return a.name.localeCompare(b.name);
    });

    const attackCardComponents = reusableCardsOrdered.filter(gameCard => gameCard.actionType==="attack")
        .map((gameCard, index) => <SmallReusableAttackCardComponent gameCard={gameCard} key={`attack-${index}-${gameCard.name}`}/>);
    const skillCardComponents = reusableCardsOrdered.filter(gameCard => gameCard.actionType==="skill")
        .map((gameCard, index) => <SmallReusableCardComponent gameCard={gameCard} key={`skill-${index}-${gameCard.name}`}/>);
    const enhanceCardComponents = reusableCardsOrdered.filter(gameCard => gameCard.actionType==="enhance")
        .map((gameCard, index) => <SmallReusableCardComponent gameCard={gameCard} key={`enhance-${index}-${gameCard.name}`}/>);
    const discardableCardComponents = player.discardableCards && player.discardableCards
        .map((discardableCard, index) => <SmallDiscardableCardComponent gameCard={discardableCard} isOnCharacterSheet={true} key={`discard-${index}-${discardableCard.name}`}/>);
    const defenseCardComponents = player.defenseCards && player.defenseCards
        .map((defenseCard, index) => <SmallDefenseCardComponent gameCard={defenseCard} key={`defense-${index}-${defenseCard.name}`}/>);

    skillCardComponents.unshift(<div className={"smallGameCard skillAction description"}  key={"front"}>
            <div className="headerRowLeftAligned">
                <div className="header">
                    <span className="line1">Skills</span>
                </div>
                <div className="headerEmptyColumn">
                If you don't have a skill, you may always roll one die. If you fail, you may ask the GM for a second shot: the GM will name the cost (gold, energy, etc).
                </div>
            </div>
        </div>);

    const MAX_CARDS_ON_PAGE_1=8;
    const MAX_CARDS_ON_PAGE_2=7;
    let cardsOnPage1=<></>;
    let cardsOnPage2 = <></>;
    let hasCardsOnPage2 = false;
    // Adjust the count of attack card components (each attack card counts as two because they're double-height)
    // TODO This calculation is a guess. Eventually we should measure the height of each card and see how many fit on a page.
    const attackCardsHeight = attackCardComponents.length*1.5;
    const totalCards = attackCardsHeight+skillCardComponents.length+enhanceCardComponents.length;

    if (DEBUG_CHARACTER_SHEET_LAYOUT) {
        console.log("attackCardsCount",attackCardComponents.length,"x 2 =",attackCardsHeight);
        console.log("skillCardComponents.length",skillCardComponents.length);
        console.log("enhanceCardComponents.length",enhanceCardComponents.length);

        console.log("Total cards: ",totalCards);
    }
    
    if (totalCards<=MAX_CARDS_ON_PAGE_1) {
        // We believe everything will fit on page 1.
        cardsOnPage1 = <>
            {attackCardComponents}
            {skillCardComponents}
            {enhanceCardComponents}
        </>;
    } else if (attackCardsHeight+skillCardComponents.length<=MAX_CARDS_ON_PAGE_1) {
        // We believe all attack cards & all skill cards will fit on page 1.
        cardsOnPage1 = <>
            {attackCardComponents}
            {skillCardComponents}
        </>;
        cardsOnPage2 = <>
            {enhanceCardComponents}
        </>;
        hasCardsOnPage2 = true;
    } else if (skillCardComponents.length+enhanceCardComponents.length<=MAX_CARDS_ON_PAGE_2) {
        cardsOnPage1 = <>
            {attackCardComponents}
        </>;
        cardsOnPage2 = <>
            {skillCardComponents}
            {enhanceCardComponents}
        </>;
        hasCardsOnPage2 = true;
    } else {
        if (totalCards>MAX_CARDS_ON_PAGE_1+MAX_CARDS_ON_PAGE_2) {
            // TODO handle this by printing on multiple pages.
            console.error("Too many cards to fit on 2 pages!");
            debugger;
        }
        // We have to split skill card components between page 1 & page 2.
        const numSkillCardsOnPage1 = MAX_CARDS_ON_PAGE_1-attackCardsHeight;
        cardsOnPage1 = <>
            {attackCardComponents}
            {skillCardComponents.slice(0,numSkillCardsOnPage1)}
        </>;
        cardsOnPage2 = <>
            {skillCardComponents.slice(numSkillCardsOnPage1)}
            {enhanceCardComponents}
        </>;
        hasCardsOnPage2 = true;
    }

    const page1= <><div className="characterHeader attack">
            <div className="characterName">{player.characterName}</div>
            <div className="characterAbout">
                {player.role?<>Role: {player.role}<br/></>:""}
                Level {actualLevel!=level?(actualLevel+" playing at "):""} {level}
                &nbsp;<i>(Played by {player.playerName})</i>
            </div>
            <div className="pageType attack">
                <div className="pageTypeHeader"></div>
            </div>
        </div>
        <span className="reusableCards">
            {cardsOnPage1}
        </span>
    </>;
    const page2=<>
        <div className="characterHeader defend">
            <div className="characterName">{player.characterName}</div>
            <div className="pageType defend">
                <div className="pageTypeHeader"></div>
            </div>
        </div>
        {hasCardsOnPage2 && <span className="reusableCards">{cardsOnPage2}</span>}
        <div className="bodyStats">
            <div className="lifeRow">
                <div className="lifeHeader">{life } Life</div>
                <div className="lifeIcons">
                    {/* TODO eventually if we get enough life, we'd put 10 per line */}
                    {Array(Math.floor(life/heartsPerRow)+1).fill(0).map((_,i) => <div key={i}>{Array(Math.min(heartsPerRow,life-i*heartsPerRow)).fill(0).map((_,j) => <span key={j} className="lifeIcon">❤️</span>)}</div>)}
                </div>
            </div>
            <br/>
            {defenseCardComponents}
            {discardableCardComponents}
        </div>
        <div className="subHeader defend">
            Inventory
        </div>
        <div className="bodyStats inventoryRow">
            <div className="column30pct">
                {/* <div className="xpStats">&nbsp;&nbsp;{player.earnedXP-usedXP} XP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>(of {player.earnedXP} earned)</i></div> */}
                <div className="goldStats">&nbsp;&nbsp;{player.earnedGold-usedGold} Gold &nbsp;&nbsp;&nbsp;&nbsp;<i>(of {player.earnedGold} found)</i></div>
            </div>
            <div className="column40pct">
                {player.supporters?<div><b>Supporters<br/></b> {player.supporters}</div>:null}
            </div>
            <div className="column30pct">
                {player.miscItems?<div><b>Miscellaneous</b><br/>{player.miscItems}</div>:null}
            </div>
        </div>
    </>;
    const hasLeveledUp = newLevel>level;
    // How much XP can they spend on each group?
    let numXPToSpendPerGroup = "one";
    // If they have extra unspent XP or have gained more than 2 XP, they can choose more than 1
    // TODO we might carefully assign this to either the "skills" or "non-skills" group based on what they already own, to continue to match the balance
    if (playerUnusedXP>=3)
        // If it's 3, allow 2, if it's 4, allow 2, if it's 5, allow 3, etc.
        numXPToSpendPerGroup = "1-"+Math.round((playerUnusedXP+1)/2);
    const page3=<>
        <div className="characterHeader upgrades">
            <div className="characterName">{player.characterName}</div>
            <div className="pageType upgrades">
                <div className="pageTypeHeader"></div>
            </div>
        </div>
        <div className="subHeader upgrades">
            Upgrades Available
        </div>
        <div className="bodyStats">
            {errors.length>0?<div className="errors">⚠ {errors.join(", ")}</div>:null}
            <div className="xpStats">This session {player.characterName} gained {gainedXP} XP, {hasLeveledUp && <><b>leveled up to {newLevel}, </b></>} and ~{gainedGold} gold!</div>

            <div className="xpStats"><b>Can spend:</b></div>
            <div className="xpStats">&nbsp;&nbsp;{upgradedPlayer.earnedXP-usedXP} XP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>(of {upgradedPlayer.earnedXP} earned)</i></div>
            <div className="goldStats">&nbsp;&nbsp;{upgradedPlayer.earnedGold-usedGold} Gold &nbsp;&nbsp;&nbsp;&nbsp;<i>(of {upgradedPlayer.earnedGold} found)</i></div>
            <div className="miscContent">
                <br/>
                {Object.getOwnPropertyNames(upgradesNonSkillsGrouped).length>0 && <div>
                    <b>Choose {numXPToSpendPerGroup}:</b><br/>
                    <ul>
                        {Object.keys(upgradesNonSkillsGrouped).map(function (costStr:string,i:number) {
                            const joinedItems = upgradesNonSkillsGrouped[costStr].join(", ");
                            return <li key={i}>
                                <b>{joinedItems}</b> for {costStr} {upgradesNonSkillsGrouped[costStr].length>1?"each":""}
                            </li>})}
                    </ul>
                </div>}
                {Object.getOwnPropertyNames(upgradesSkillsAndLifeGrouped).length>0 && <div>
                    <b>{gainedXP==1?<><b>OR</b> </>:<></>}Choose {numXPToSpendPerGroup}:</b><br/>
                    <ul>
                        {Object.keys(upgradesSkillsAndLifeGrouped).map(function (costStr:string,i:number) {
                            const joinedItems = upgradesSkillsAndLifeGrouped[costStr].join(", ");
                            return <li key={i}>
                                <b>{joinedItems}</b> for {costStr} {upgradesSkillsAndLifeGrouped[costStr].length>1?"each":""}
                            </li>})}
                    </ul>
                </div>}
                {Object.getOwnPropertyNames(upgradesAllOthersGrouped).length>0 && <div>
                    <b>Choose one:</b><br/>
                    <ul>
                        {Object.keys(upgradesAllOthersGrouped).map(function (costStr:string,i:number) {
                            const joinedItems = upgradesAllOthersGrouped[costStr].join(", ");
                            return <li key={i}>
                                <b>{joinedItems}</b> for {costStr} {upgradesAllOthersGrouped[costStr].length>1?"each":""}
                            </li>})}
                    </ul>
                </div>}
            </div>
        </div>
    </>;

    if (size==="letter")
        return <div className="characterSheet letter">
            <div className="page">
                {page1}
            </div>
            <div className="page">
                {page2}
            </div>
            <div className="page">
                {page3}
            </div>
        </div>;
    //else if (size==="tabloid")
    return <div className="characterSheet tabloid">
            <div className="page">
                <div className="column1" style={{float:"left"}}>
                    {page1}
                </div>
                <div className="column2" style={{float:"right"}}>
                    {page2}
                </div>
            </div>
            <div className="page">
                <div className="column1" style={{float:"left"}}>
                    {page3}
                </div>
            </div>
        </div>;
}

function CharacterSheets({pcs, size, playAsLevel}:{pcs:PlayerCharacterInMemoryType[],size:"letter"|"tabloid", playAsLevel: XPAtLeast1}) {
    return <>
        {pcs.map(function(player,index) {
            return <CharacterSheet pc={player} key={index} size={size} playAsLevel={playAsLevel}/>;
        })}
    </>;
}


export default function GameCardList({pcs}:{pcs:PlayerCharacterInMemoryType[]}) {
    const [selectedTagNames,setSelectedTagNames] = useStateURLStringArray("gameCards_selectedTagNames",[]);
    const [cardSide, setCardSide] = useState<CardSide>("front");
    const [listType, setListType] = useStateLSString<GameCardListType>("gameCards_selectedListType", GameCardListType.List);
    // Default to auto, then let the user set it.
    
    const [playAsLevelState, setPlayAsLevel] = useState<XPAtLeast1 | undefined>(undefined);
    const playAsLevelIsAuto = playAsLevelState===undefined;

    const {gameCardsFiltered, hasGameCardFilter, pcsFiltered} = useMemo(function() {
        let gameCardsFiltered = AllGameCards;
        let pcsFiltered = pcs;
        let hasGameCardFilter = false;

        if (selectedTagNames.length > 0) {
            const selectedTags = selectedTagNames.map(tag => {
                const type = tag.slice(tag.lastIndexOf("[")+1,tag.lastIndexOf("]"));
                const name = tag.slice(0,tag.lastIndexOf("["));
                return {name,type} as GameCardTagsType;
            });
            const selectedCardType = selectedTags.filter((tag:GameCardTagsType) => tag.type==="Card Type");
            const selectedPlayerNames = selectedTags.filter((tag:GameCardTagsType) => tag.type==="Player");
            const selectedDisciplines = selectedTags.filter((tag:GameCardTagsType) => tag.type==="Discipline");
            const selectedXP = selectedTags.filter((tag:GameCardTagsType) => tag.type==="XP");
            const selectedCardNames = selectedTags.filter((tag:GameCardTagsType) => tag.type==="Action Card");

            const selectedActionType = selectedTags.filter((tag:GameCardTagsType) => tag.type==="Action Type");

            if (selectedPlayerNames.length > 0) {
                // This supports multiple player selection, even other parts of this function only support one player selected.
                pcsFiltered = pcs.filter(player => selectedPlayerNames.some(tag => tag.name===player.playerName));
                pcsFiltered = pcs.filter(player => selectedPlayerNames.some(tag => tag.name===getPCLabel(player)));
                // This version only includes cards if they're in the master list. Won't work well, because cards can be dynamically created from any combo of equipment.
                gameCardsFiltered = uniq(pcsFiltered.reduce(function(acc:ReusableCard[],player:PlayerCharacterInMemoryType) {
                    return acc.concat(getPlayerReusableCards(player, getPlayerLevel(player)));
                },[]));
            }
            if (selectedCardType.length>0) {
                if (selectedCardType[0].name==="Actions & Skills") {
                    // Keep our actions and skills. No need to filter them further.
                    // But don't show any player cards
                    hasGameCardFilter = true;
                } else if (selectedCardType[0].name==="Players") {
                    // Keep our players. No need to filter them further.
                    // But don't show any game cards.
                    // This must be after the "selectedPlayerNames" above to make sure it goes down to 0 when a player is selected.
                    gameCardsFiltered = [];
                }
            }

            if (selectedActionType.length>0) {
                gameCardsFiltered = gameCardsFiltered.filter(function(gameCard:ReusableCard) {
                    for (let i=0; i<selectedActionType.length; i++) {
                        let actionType = selectedActionType[i].name as CardActionType;
                        if (gameCard.actionType===actionType)
                            return true;
                    }
                });
                hasGameCardFilter=true;
            }
            if (selectedDisciplines.length>0) {
                gameCardsFiltered = gameCardsFiltered.filter(function(gameCard:ReusableCard) {
                    for (let i=0; i<selectedDisciplines.length; i++) {
                        let discipline = selectedDisciplines[i].name as Discipline;
                        if (gameCard.discipline===discipline)
                            return true;
                    }
                    return false;
                });
                hasGameCardFilter=true;
            }
            if (selectedXP.length>0) {
                gameCardsFiltered = gameCardsFiltered.filter(function(gameCard:ReusableCard) {
                    for (let i=0; i<selectedXP.length; i++) {
                        let xp = parseInt(selectedXP[i].name);
                        if (gameCard.xpCost===xp)
                            return true;
                    }
                    return false;
                });
                hasGameCardFilter=true;
            }
            if (selectedCardNames.length>0) {
                gameCardsFiltered = gameCardsFiltered.filter(function(gameCard:ReusableCard) {
                    for (let i=0; i<selectedCardNames.length; i++) {
                        let name = selectedCardNames[i].name;
                        if (gameCard.name===name)
                            return true;
                    }
                    return false;
                });
                hasGameCardFilter=true;
            }
        }
        return {gameCardsFiltered, hasGameCardFilter, pcsFiltered};
    },[selectedTagNames, cardSide, pcs]);
    const defaultPlayIsLevel = useMemo(function() {
        const totalLevels = pcsFiltered.reduce((acc,player) => acc+getPlayerLevel(player),0);
        return Math.round(totalLevels/pcsFiltered.length) as XPAtLeast1;
    },[pcsFiltered]);
    const playAsLevel = playAsLevelIsAuto?defaultPlayIsLevel:playAsLevelState;


    
    let gameCardTags:GameCardTagsType[] = gameCardsFiltered.reduce(function(acc:GameCardTagsType[],gameCard:ReusableCard) {
        // Add Action Type tags:
        let foundActionType = false;
        for (let i=0; i<acc.length; i++) {
            if (acc[i].name===gameCard.actionType && acc[i].type==="Action Type") {
                acc[i].count++;
                foundActionType = true;
                break;
            }
        }
        if (!foundActionType)
            acc.push({name:gameCard.actionType, type:"Action Type", count:1})

        // Add Discipline tags:
        let foundDiscipline = false;
        for (let i=0; i<acc.length; i++) {
            if (acc[i].name===gameCard.discipline && acc[i].type==="Discipline") {
                acc[i].count++;
                foundDiscipline = true;
                break;
            }
        }
        if (!foundDiscipline)
            acc.push({name:gameCard.discipline, type:"Discipline", count:1});

        // Add XP tags:
        let foundXP = false;
        for (let i=0; i<acc.length; i++) {
            if (acc[i].name===gameCard.xpCost+" XP" && acc[i].type==="XP") {
                acc[i].count++;
                foundXP = true;
                break;
            }
        }
        if (!foundXP)
            acc.push({name:gameCard.xpCost+" XP", type:"XP", count:1});

        // Add Name tags:
        let foundName = false;
        for (let i=0; i<acc.length; i++) {
            if (acc[i].name===gameCard.name && acc[i].type==="Action Card") {
                acc[i].count++;
                foundName = true;
                break;
            }
        }
        if (!foundName)
            acc.push({name:gameCard.name, type:"Action Card", count:1});

        
        return acc;
    },[]);
    // Add action & skill card tag.
    // These are the types that you can buy with XP.
    if (gameCardTags.length>0) {
        const count = gameCardTags.reduce((acc,tag) => acc+tag.count,0);
        gameCardTags.push({name:"Actions & Skills",type:"Card Type",count});
    }

    const pcTags = /*pcsFiltered to limit to only one player, or for all:*/pcs.map(function(pc:PlayerCharacterInMemoryType) {
        // Count the number of cards that the player owns that are in the filtered list:
        const playerCards = getPlayerReusableCards(pc, getPlayerLevel(pc)).filter(playerCard => gameCardsFiltered.some(filteredCard => getCardsAreSame(filteredCard,playerCard)));
        return {name:getPCLabel(pc), type:"Player", count:playerCards.length};
    });
    // Add a tag for all players:
    if (pcsFiltered.length>0) {
        const count = pcsFiltered.reduce((acc,tag) => acc+1,0);
        gameCardTags.push({name:"Players",type:"Card Type",count});
    }
    gameCardTags = gameCardTags.concat(pcTags);

    // function exportGridToJpeg() {
    //     const cardDeckElement = document.getElementById('cardDeck') as HTMLElement;
    //     // We want to print to an image without the margin, to be compatible with TableTop Similator's grid, so we remove the margin, then add it back after the image is created.
    //     cardDeckElement.className="cardDeckNoMargin";
    //     toJpeg(cardDeckElement, { quality: 1, pixelRatio: 5, style: {margin: "0px"} })
    //         .then(function (dataUrl) {
    //         var link = document.createElement('a');
    //         // Create a custom name based on the filters. Start with the player's name, if there's only one player:
    //         let fileName = "Powers TTRPG Cards";
    //         if (pcsFiltered.length===1)
    //             fileName = pcsFiltered[0].playerName+"'s Deck";
    //         else if (selectedTagNames.length===0)
    //             fileName = "Powers TTRPG - All Cards";
            
    //         link.download = fileName+'.jpeg';
    //         link.href = dataUrl;
    //         link.click();
    //         cardDeckElement.className="cardDeckWithMargin";
    //     });
    // }


    const tableColumns:TableProps<ReusableCard>['columns'] = useMemo(()=>{
        const tableColumnsInner = [{
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (value:any, card:ReusableCard) => {
                    return getReusableCardDistinctName(card);
                },
                width:100,
            },{
                title: "Text",
                key: "text",
                render: (value:any, card:ReusableCard) => {
                    return getReusableCardCommandStringAsReact(card, true)
                },
                width:300,
            },
            /*{
                title: "Type",
                dataIndex: "actionType",
                key: "actionType"
            }, */{
                title: "Points",
                dataIndex: "points",
                key: "points",
                render: (value:any, card:ReusableCard) => {
                    const points = getReusableCardEquivalentPoints(card);
                    return Math.round(points*10)/10;
                },
                width:40,
            }
        ] as TableProps<ReusableCard>['columns'];
        return tableColumnsInner;
    },[]);
    const [selectedCards,setTableRowSelection] = useState([] as ReusableCard[]);
    function onTableRowSelectionChange(selectedRowKeys: React.Key[], selectedRows: ReusableCard[]) {
        setTableRowSelection(selectedRows);
    }
    function getCheckboxPropsForTable(record: ReusableCard) {
        return {
            disabled: false
        };
    }
    const gameCardsFilteredForTable = useMemo(()=>{
        return gameCardsFiltered.map(
            (card:ReusableCard,index:number)=>{
                // const points = getReusableCardEquivalentPoints(card);
                return {...card,
                    key:(card.name+card.discipline+index),
                    // points,
                } as ReusableCard/* & {key:string};*/
            });
    },[gameCardsFiltered]);


    const componentToPrint = useRef();
    const handlePrint = useReactToPrint({
      content: function() {
        if (componentToPrint.current)
            return componentToPrint.current;
        return null;
      },
    });


    return <>
        <div className="noprint site-layout-background" style={{ padding: 24, paddingTop:0}}>
            Tags ({gameCardTags.length}) &nbsp;<Select mode="multiple" style={{width: "90%"}} placeholder="Select Tags" value={selectedTagNames} onChange={setSelectedTagNames}>
                {gameCardTags.map(tag => <Select.Option key={tag.name} value={tag.name+"["+tag.type+"]"}>{tag.name} [{tag.type}] ({tag.count})</Select.Option>)}
                </Select><br/>
            <Segmented 
                value={listType} 
                onChange={(s:string)=>setListType(s as GameCardListType)} 
                options={[
                    {value: GameCardListType.List, label:"List", icon: <UnorderedListOutlined />},
                    {value: GameCardListType.Cards, label: "Cards", icon: <AppstoreOutlined />},
                    {value: GameCardListType.Sheet, label: "Character Sheet (8.5x11)", icon: <AppstoreOutlined />},
                    {value: GameCardListType.SheetTabloid, label: "Character Sheet (11x17)", icon: <AppstoreOutlined />},
                ]}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Select style={{width: "200px"}} value={cardSide} onChange={setCardSide}>
                <Select.Option value="front">Front</Select.Option>
                <Select.Option value="back">Back</Select.Option>
                <Select.Option value="both">Both</Select.Option>
            </Select>
            {listType !== GameCardListType.List && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button onClick={handlePrint} icon={<PrinterOutlined/>}>Print</Button></>}
            {(listType === GameCardListType.Sheet || listType === GameCardListType.SheetTabloid) && (
                <>
                    {/* <Button onClick={exportGridToJpeg}>Export to Tabletop Simulator Card Deck JPG</Button> */}
                    Play as level{playAsLevelIsAuto?"(auto)":""}:
                    <InputNumber 
                        min={1} 
                        max={20} 
                        placeholder={playAsLevel+""} 
                        value={playAsLevelIsAuto ? null : playAsLevel} 
                        onChange={(value) => value !== null && setPlayAsLevel(value as XPAtLeast1)}
                    />
                </>
            )}
        </div>
        {listType === GameCardListType.Cards &&
            // @ts-ignore
            <div id="cardDeck" className="cardDeckWithMargin" ref={componentToPrint}>
                {!hasGameCardFilter && <PlayersCardsComponent pcs={pcsFiltered}/>}
                <GameCardsComponent gameCards={gameCardsFiltered} cardSide={cardSide}/>
            </div>}
        {listType === GameCardListType.Sheet && 
            // @ts-ignore
            <div id="characterSheets" className="cardDeckWithMargin" ref={componentToPrint}>
                <CharacterSheets pcs={pcsFiltered} size="letter" playAsLevel={playAsLevel}/>
            </div>}
        {listType === GameCardListType.SheetTabloid &&
            // @ts-ignore
            <div id="characterSheets" className="cardDeckWithMargin" ref={componentToPrint}>
                <CharacterSheets pcs={pcsFiltered} size="tabloid" playAsLevel={playAsLevel}/>
            </div>}
        {listType === GameCardListType.List && <>
            <div style={selectedCards.length>0?{width:"50%",display:"inline-block",verticalAlign:"top"}:undefined}>
                <Table
                    columns={tableColumns}
                    dataSource={gameCardsFilteredForTable}

                    rowSelection={{
                        type: 'checkbox',
                        onChange: onTableRowSelectionChange,
                        getCheckboxProps:getCheckboxPropsForTable
                    }}

                    scroll={{y:"calc( 100vh - 200px )"}}
                    size="small" pagination={false}/>
            </div>
            
            {selectedCards.length>0 && 
                <div style={{width:"50%",display:"inline-block",verticalAlign:"top"}}>
                    {/* @ts-ignore */}
                    <div ref={componentToPrint} id="cardDeck" className="cardDeckWithMargin">
                    {selectedCards.map(function(gameCard:ReusableCard,index:number) {
                        return <GameCardComponent gameCard={gameCard} cardSide={cardSide} key={"GameCard"+index}/>;
                    })}
                    </div>
                </div>
            }
        </>}
    </>;
}