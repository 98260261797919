// React:
import React, { useContext, useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom";
// AntD:
import { Layout, Tabs, Spin, Button, TabsProps } from 'antd';
import { MessageOutlined, MessageFilled, FileTextOutlined, FileTextFilled, DatabaseOutlined, DatabaseFilled, TableOutlined, MenuOutlined } from '@ant-design/icons';
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import ReactHotkeys from "react-hot-keys";
import { PinnedJSONFormsContext, SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { ChatEditorRefFunctions } from "../../../ServerConnection/LLMServer/SendChatToServerHook";
import { ChatLogContext } from "../../../DecisionGraph/ChatEditor/UI/ChatLog";
import { getQuery, useQuery } from "../../../DecisionGraph/Utilities/URLQueryParams";
import SaveableDraftPluginsNoteEditor from "../DraftJSEditor/DraftJSPluginsEditor/SaveableDraftPluginsNoteEditor";
import NoteDataEditor from "../NoteInformationComponents";
import { BattleNoteTypeEditor } from "../../../Extensions/TTRPG/Battle/BattleNoteTypeEditor";
import ChatEditorWithNotePreprocessing from "../../../DecisionGraph/ChatEditor/Preprocessing/ChatWithNotePreprocessing";
import { RelatedNotesLargePanel } from "../NoteInformationComponents/RelatedNotesLargePanel";
import { NoteHierarchyContext } from "../NoteInformationComponents/NoteHierarchyProvider";
import { useIsSmallScreen } from "./IsSmallScreen";
import { useSiderAndSidebar } from "./SiderMenu";
import { useNoteDraftJS } from "../../Data/FirestoreNoteClient";
import { useNavigateToDeselectNote } from "../../../DecisionGraph/Utilities/NavigateTo";

// Extracted Ant Properties (These must be below the imports)
const { Content } = Layout;

const HEADER_ABOVE_TABS=false;

export const SELECTED_TAB_PARAMETER="selectedTab";
export const SELECTED_TAB_IS_NOTE="Note";
export const SELECTED_TAB_IS_DATA="Object";
export const SELECTED_TAB_IS_CHAT="Chat";
export const SELECTED_TAB_IS_RELATED="Related";

function useChatTab(chatRefV2:React.MutableRefObject<ChatEditorRefFunctions>) {
  const pinnedJsonFormsContext = useContext(PinnedJSONFormsContext);
  const {selectedChatMessages} = useContext(ChatLogContext);

  let chatTabTitle = "Chat";
  if (pinnedJsonFormsContext.note) {
    chatTabTitle = "Chat";
  }

  return {
    label: <span>{chatRefV2.current.isWaitingForChat?<Spin size="small"/>:(selectedChatMessages?.length>0?<MessageFilled />:<MessageOutlined/>)}&nbsp;{chatTabTitle}</span>,
    key: SELECTED_TAB_IS_CHAT,
    children: <ChatEditorWithNotePreprocessing chatEditorRef={chatRefV2}/>,
    closable: false,
  };
}
function useRelatedTab() {
  const {isLoaded} = useContext(NoteHierarchyContext);
  return {
    label: <span>{isLoaded?<TableOutlined />:<Spin size="small"/>}<> Related</></span>,
    key: SELECTED_TAB_IS_RELATED,
    children: <RelatedNotesLargePanel/>,
    closable: false,
  };
}


export default function NotePage() {
  /***************************************
   * Hooks
   */
  const chatRef = useRef();
  const chatRefV2 = useRef<ChatEditorRefFunctions>({} as ChatEditorRefFunctions);

  const query = useQuery();
  const navigate = useNavigate();
  const navigateToDeselectNote = useNavigateToDeselectNote();
  const location = useLocation();
  const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
  const doc_id = selectedJsonFormsContext.note?.id;
  const noteType = selectedJsonFormsContext.note?.type;

  const selectedDocData = useNoteDraftJS(selectedJsonFormsContext.note);

  const isSmallScreen = useIsSmallScreen();
  
  const {siderComponents, hideSidebar,sidebarComponent,toggleSiderCollapsed, onShortcutKeyDown_sidepanels} = useSiderAndSidebar();

  const selectedTabFromQuery = query.get(SELECTED_TAB_PARAMETER);
  let selectedTab = selectedTabFromQuery || "";
  function setSelectedTab(value:string) {
    const freshQuery = getQuery();
    freshQuery.set(SELECTED_TAB_PARAMETER,value);
    navigate(location.pathname+"?"+freshQuery.toString(),{replace:true}); // changing tabs does not count as affecting the back button.
  }
  
  /**************************************
   * 
   * Dynamic Tabs based on selection
   */
  const tabItems:TabsProps['items'] = [];
  if (doc_id) {
    tabItems.push({
      label: <span>{((selectedDocData?.blocks && selectedDocData.blocks.length > 2) ||
        (selectedDocData?.blocks && selectedDocData.blocks.length === 2 && selectedDocData.blocks[1].text.length > 0))
          ?<FileTextFilled/>:<FileTextOutlined/>}Note</span>,
      key: SELECTED_TAB_IS_NOTE,
      children: <SaveableDraftPluginsNoteEditor focusSearch={focusSearch} doc_id={doc_id}/>,
      closable:true,
    });
    tabItems.push({
      label: <span>{selectedJsonFormsContext?.jsonFormsObject?.version>=0?<DatabaseFilled />:<DatabaseOutlined/>}Data</span>,
      key: SELECTED_TAB_IS_DATA,
      // TODO this has a bunch of tabs in it... We should really collapse the tabs into the upper level here, no need for two rows of tabs!
      // That will require quite a bit of refactoring of NoteDataEditor since those tabs are currently fairly deep down in GenericObjectEditor.
      children: <NoteDataEditor/>,
      closable: false,
    });
  }
  tabItems.push(useChatTab(chatRefV2));
  tabItems.push(useRelatedTab());
  if (noteType==="Battle") {
    tabItems.push({
      label: <span>Battle</span>,
      key: 'Battle',
      children: <BattleNoteTypeEditor />,
      closable:false,
    });
    if (!selectedTab)
      selectedTab="Battle";
  } else {
    // Not a Battle type
    if (!selectedTab)
      selectedTab=SELECTED_TAB_IS_NOTE;
    else if (selectedTab==="Battle")
      selectedTab=SELECTED_TAB_IS_CHAT;
  }

  function onShortcutKeyDown(keyName:string, e:any, handle:any) {
    e.preventDefault();
    e.stopPropagation();
    if (keyName==="control+s" || keyName==="command+s") {
      // Control-s does not need to be handled: users may have a tendency to press control-s, but it's already saved, and we don't want to switch to the HTML save dialog which is very intrusive.
    } else if (keyName==="control+/" || keyName==="command+/") {
      // Switch to the chat tab:
      if (selectedTab!==SELECTED_TAB_IS_CHAT)
        setSelectedTab(SELECTED_TAB_IS_CHAT);
      // Focus the chat input:
      setTimeout(()=>{
        //@ts-ignore
        chatRef?.current?.focusChatInput();
      },200);
    } else if (keyName.includes("alt+1"))
      setSelectedTab(SELECTED_TAB_IS_NOTE);
    else if (keyName.includes("alt+2"))
      setSelectedTab(SELECTED_TAB_IS_DATA);
    else if (keyName.includes("alt+3"))
      setSelectedTab(SELECTED_TAB_IS_CHAT);
    else if (keyName.includes("alt+4"))
      setSelectedTab(SELECTED_TAB_IS_RELATED);
    else if (keyName.includes("alt+5") && noteType==="Battle")
      setSelectedTab("Battle");
    else
      onShortcutKeyDown_sidepanels(keyName);
  }
  // Synthetic(?) event that focuses the search panel
  function focusSearch() {
    onShortcutKeyDown_sidepanels("control+e");
  }

  useEffect(() => {
    if (selectedJsonFormsContext.note?.doc_name) 
        document.title = selectedJsonFormsContext.note?.doc_name+' - AI Got This';
  }, [selectedJsonFormsContext.note?.doc_name]);

  function onEditTabs(targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove') {
      // We only support removing the Note tab for now, which will reset the note id parameter.
      if (action!== 'remove') {
        console.error("Unexpected action in onEditTabs: "+action);
        debugger;
        return;
      }
      if (targetKey !== SELECTED_TAB_IS_NOTE) {
        console.error("Unexpected targetKey in onEditTabs: "+targetKey);
        debugger;
        return;
      }
      // Handle removing the Note tab
      navigateToDeselectNote();
  }
  
  return (
      <ReactHotkeys keyName="control+e,command+e,alt+e,alt+f,control+shift+f,command+shift+f,control+s,command+s,control+/,command+/,control+o,command+o,alt+o,alt+1,control+alt+1,command+alt+1,alt+2,control+alt+2,alt+3,control+alt+3,alt+4,control+alt+4,alt+5,control+alt+5,control+shift+o,command+shift+o" onKeyDown={onShortcutKeyDown} filter={(_event) => {return true;}}>
        <div className={HEADER_ABOVE_TABS?"HEADER_ABOVE_TABS":"HEADER_IN_TABS"}>
          <Layout>
            {/*siderComponents are modals, mouseovers, drawers, etc, that can go anywhere*/}
            {siderComponents.map((component,i)=><React.Fragment key={i}>{component}</React.Fragment>)}
            <Content>
              <div id="JustAroundAllotment">
                <Allotment snap={true} vertical={false} defaultSizes={[200,600]} 
                  onVisibleChange={(index:number, value:boolean) => {
                    if (index === 0) {
                      hideSidebar();
                    } else {
                      console.error("Unexpected index in onVisibleChange: "+index);
                    }
                  }}>

                  <Allotment.Pane key="sidebarPane" minSize={150} preferredSize={200} visible={!!sidebarComponent}>
                    {sidebarComponent}
                  </Allotment.Pane>

                  <Allotment.Pane key="tabsPane" minSize={300} preferredSize={1000}>
                    <Tabs
                      destroyInactiveTabPane={true}
                      activeKey={selectedTab}
                      onChange={(key)=>setSelectedTab(key)}
                      key={noteType}
                      type="editable-card"
                      hideAdd
                      onEdit={onEditTabs}
                      items={tabItems}
                      tabBarExtraContent={
                        !HEADER_ABOVE_TABS && 
                          {
                            left: isSmallScreen?
                              <Button icon={<MenuOutlined/>} type="text" onClick={toggleSiderCollapsed} key="toggleSidebarCollapsedTabButton"/>
                              :<></>,
                          }}
                    />
                  </Allotment.Pane>
                </Allotment>
              </div>
            </Content>
          </Layout>
        </div>
      </ReactHotkeys>
  );
}
