import { useContext } from 'react';
import { ExtensionNoteTemplateType } from '../../../Extensions/ExtensionsFramework/ExtensionsList';
import { PinnedJSONFormsContext } from '../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext';
import { Note } from '../../../Notes/Data/NoteType';
import { NoteHierarchy, startLoadingNoteHierarchy, TopLevelNoteHierarchy } from '../../../Notes/UIs/NoteInformationComponents/NoteHierarchy';
import { getLastUserMessage, getLastUserNotesMentionedInChat, getPreviousNoteIDsOfType } from './PreprocessChatGenericHelpers';
import { useLoadNoteAsMarkdown, NoteAsMarkdown, getUniqMarkdownSystemPrompt } from './NotesToMarkdown';
import { NoteHierarchyContext } from '../../../Notes/UIs/NoteInformationComponents/NoteHierarchyProvider';
import { detectTemplate } from '../../../ServerConnection/LLMServer/Prompts/Older/DetectTemplateV8';
import { InclusionByMention, InclusionByPreviousChat, InclusionByTemplate, InclusionByTopic, InclusionByTopLevelHierarchy, NoteExclusionType, NoteInclusionType, PreProcessUserMessageFuncType, PreprocessUserMessageReturnType, useSendAnythingToServer, PreprocessingStepsType } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { ChatMessage2 } from '../UI/ChatLog';
import { uniq } from 'lodash';
import { NotesContext } from '../../../Notes/Data/NotesContext';
import { getNoteNameAndEmoji } from '../../../Notes/NotesTree/TreeUtilities/CreateTagTreeNodes';
import { calculateChatVector } from '../../../ServerConnection/LLMServer/ChatVectorCalculation';
import { estimateTokensFromString } from '../../../ServerConnection/LLMServer/LLMTokenCounter';
import { LLMServerContext } from '../UI/SelectableLLM';
import { isATemplateOrType } from '../../../Extensions/ExtensionsFramework/IsATemplate';
import useTopicDetectionV7Command, { getTopicInputV7FromMentionables, getTopicInputV7FromNotes } from '../../../ServerConnection/LLMServer/Prompts/Older/TopicDetectionAIV7';
import { useExtensions } from "../../../Extensions/ExtensionsFramework/GetExtension";

const USE_TEMPLATE_DETECTION_V8 = true;
export const CALCULATE_VECTOR_FROM_CHAT = false; // Currently implemented and tested, but not used anywhere.
export const ALWAYS_INCLUDE_JSON = true; // New constant
const DEBUG = true;

const PREPROCESSING_STEP_1 = "template";
const PREPROCESSING_STEP_2 = "topics";
const PREPROCESSING_STEP_3 = "loading notes";
const PREPROCESSING_STEPS_V9 = [PREPROCESSING_STEP_1, PREPROCESSING_STEP_2, PREPROCESSING_STEP_3];

function organizeNotesInTopLevelNoteHierarchyV9(topLevelNoteHierarchy: TopLevelNoteHierarchy) {
    // Parse the hierarchy to get the list of always included, mentionables, and templates.
    let topLevelIncludedIDs = [] as string[];
    let mentionableTemplatesIDs = [] as string[];
    let mentionableTopicsIDs = [] as string[];
    const processedNodes = new Set<string>();

    function parseNode(noteHierarchy: NoteHierarchy, isTopLevel: boolean) {
        if (processedNodes.has(noteHierarchy.note.id)) {
            return;
        }
        processedNodes.add(noteHierarchy.note.id);

        if (isTopLevel) {
            topLevelIncludedIDs.push(noteHierarchy.note.id);
            for (const noteID of noteHierarchy.fullyIncludedNoteIDs) {
                const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
                if (!nextNoteHierarchy) {
                    // When we hit this point before, a note had been deleted but it appears that the link to it was still present. No problem, ignore it.
                    continue;
                }
                parseNode(nextNoteHierarchy, true);
            }
            for (const noteID of noteHierarchy.linkedNoteIDs) {
                const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
                if (!nextNoteHierarchy) {
                    continue;
                }
                parseNode(nextNoteHierarchy, false);
            }
            for (const noteID of noteHierarchy.editContextNotes) {
                const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
                if (!nextNoteHierarchy) {
                    continue;
                }
                parseNode(nextNoteHierarchy, false);
            }
        } else if (isATemplateOrType(noteHierarchy.note)) {
            mentionableTemplatesIDs.push(noteHierarchy.note.id);
        } else {
            mentionableTopicsIDs.push(noteHierarchy.note.id);
        }
    }

    for (const noteHierarchy of topLevelNoteHierarchy.topNoteHierarchies) {
        parseNode(noteHierarchy, true);
    }

    // Remove duplicates.
    topLevelIncludedIDs = uniq(topLevelIncludedIDs);
    mentionableTemplatesIDs = uniq(mentionableTemplatesIDs).filter((id: string) => !topLevelIncludedIDs.includes(id));
    mentionableTopicsIDs = uniq(mentionableTopicsIDs).filter((id: string) => !topLevelIncludedIDs.includes(id) && !mentionableTemplatesIDs.includes(id));
    return { topLevelIncludedIDs, mentionableTemplatesIDs, mentionableTopicsIDs };
}

export function useGetTopicsFrom() {
    const loadNoteAsMarkdown = useLoadNoteAsMarkdown();
    const topicDetectionV7Command = useTopicDetectionV7Command();
    const jsonFormsContext = useContext(PinnedJSONFormsContext);


    async function getTopicsFrom(mentionableTopicsIDs: string[], lastUserMessage: string, templatesWithSchemas: ExtensionNoteTemplateType<any>[], numObjsWithTemplateSchema: number[], topLevelNoteHierarchy: TopLevelNoteHierarchy): Promise<{ promptPromises: Promise<NoteAsMarkdown>[]; outputOfTopicsThinking: string; }> {
        const promptPromises = [] as Promise<NoteAsMarkdown>[];
        let outputOfTopicsThinking = "";
        if (!topLevelNoteHierarchy)
            return { promptPromises, outputOfTopicsThinking };
        const mentionableTopicNotes = mentionableTopicsIDs.map(function (id: string) {
            if (topLevelNoteHierarchy.noteHierarchiesById[id])
                return topLevelNoteHierarchy.noteHierarchiesById[id].note;
            else {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: mentionable topic note not found in hierarchy. This probably means it was in the other hierarchy used for notes included in chats, we should merge them..");
                return undefined;
            }
        }).filter((note: Note | undefined) => note !== undefined) as Note[];
        let topicsInputV7 = getTopicInputV7FromNotes(jsonFormsContext.note, jsonFormsContext.jsonSchemaNote);
        topicsInputV7 = getTopicInputV7FromMentionables(mentionableTopicNotes, topicsInputV7);
        topicsInputV7.userInput = lastUserMessage;
        // console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> calling topics detection V7 API with ",topicsInputV7);
        const topicsOutputV7 = await topicDetectionV7Command(topicsInputV7);

        if (!topicsOutputV7.succeeded) {
            debugger;
        } else if (topicsInputV7.topics) {
            if (DEBUG) console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> topicsOutputV7 is ", topicsOutputV7);
            for (const topic of topicsOutputV7.topics as string[]) {
                let notes = mentionableTopicNotes.filter((note: Note) => note.doc_name === topic);
                if (notes.length === 0) {
                    // It seems common that it gives us topics with extra spaces in the name before and after.
                    notes = mentionableTopicNotes.filter((note: Note) => note.doc_name === topic.trim());
                    if (notes.length === 0) {
                        // TODO we might check the full hierarchy for this topic that the user mentioned.
                        console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> Warning: No notes found for topic: '" + topic + "'.");
                        continue;
                    }
                }
                // This is in the notesMentionable list, and it's a topic. So we load it and process it.
                const promptPromise = loadNoteAsMarkdown(notes[0], ALWAYS_INCLUDE_JSON || false, templatesWithSchemas, numObjsWithTemplateSchema);
                promptPromises.push(promptPromise);
            }
            outputOfTopicsThinking += " " + topicsOutputV7.logic;
        }
        return { promptPromises, outputOfTopicsThinking };
    }
    return getTopicsFrom;
}

export function useGetTemplateFrom() {
    const { serverType, sendToServerWithStreaming } = useSendAnythingToServer();
    const { topLevelNoteHierarchy } = useContext(NoteHierarchyContext);
    const loadNoteAsMarkdown = useLoadNoteAsMarkdown();


    async function getTemplateFrom(mentionableTemplatesIDs: string[], templatesWithSchemas: ExtensionNoteTemplateType<any>[], numObjsWithTemplateSchema: number[], messages: ChatMessage2[]): Promise<{ templateNote: Note | undefined; isEditingTemplate: boolean; templateMarkdownPromise: Promise<NoteAsMarkdown> | null; }> {
        if (!topLevelNoteHierarchy)
            return { templateNote: undefined, isEditingTemplate: false, templateMarkdownPromise: null };
        if (!serverType) {
            console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: Server type not found. This should not happen.");
            debugger;
            return { templateNote: undefined, isEditingTemplate: false, templateMarkdownPromise: null };
        }
        const lastUserMessage = getLastUserMessage(messages);
        const templateNoteIDsFromPreviousChats = getPreviousNoteIDsOfType(messages, "template");

        const mentionableTemplates = mentionableTemplatesIDs.map((id: string) => topLevelNoteHierarchy.noteHierarchiesById[id].note);
        if (!serverType.supportsFunctions || !USE_TEMPLATE_DETECTION_V8) {
            if (DEBUG) console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> Template detection V8 API not supported, so skipping template detection.");
            return { templateNote: undefined, isEditingTemplate: false, templateMarkdownPromise: null };
        }
        const templateDetectionPromise = detectTemplate(mentionableTemplates, lastUserMessage, sendToServerWithStreaming);
        if (DEBUG) console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> calling template detection V8 API with ", mentionableTemplates, lastUserMessage);

        ///////////////////////////////////////
        // Finish template detection
        // (finish first because it will usually execute faster)
        let templateNote = undefined as Note | undefined;
        let isEditingTemplate = false;
        const templateDetected = await templateDetectionPromise;
        // console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> template v8 detection templateDetected is ",templateDetected);
        let templateMarkdownPromise = null as Promise<NoteAsMarkdown> | null;
        if (templateDetected && templateDetected.type !== "none") {
            // Make sure it's a real note:
            templateNote = mentionableTemplates.find((note: Note) => note.doc_name === templateDetected?.template);
            if (!templateNote) {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: Template detected, but the note wasn't found in the mentionable notes. This should not happen.");
                // debugger;
            } else {
                // Put the template first.
                templateMarkdownPromise = loadNoteAsMarkdown(templateNote, ALWAYS_INCLUDE_JSON || true, templatesWithSchemas, numObjsWithTemplateSchema);
                if (templateDetected.type === "edit")
                    isEditingTemplate = true;
            }
        }
        if (templateNoteIDsFromPreviousChats.length > 0 && !templateNote) {
            // TODO This pulls one template from the previous chat. TODO we want to eventually support multiple templates so don't change the incoming variable, just the output of this function.
            templateNote = mentionableTemplates.find((note: Note) => note.id === templateNoteIDsFromPreviousChats[0]);
            if (!templateNote) {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: Template from previous chat not found in mentionable notes. This should not happen.");
                // debugger;
            }
            else
                templateMarkdownPromise = loadNoteAsMarkdown(templateNote, ALWAYS_INCLUDE_JSON || true, templatesWithSchemas, numObjsWithTemplateSchema);
        }
        return { templateNote, isEditingTemplate, templateMarkdownPromise } as { templateNote: Note | undefined; isEditingTemplate: boolean; templateMarkdownPromise: Promise<NoteAsMarkdown> | null; };
    }
    return getTemplateFrom;
}

export function usePreprocessUserMessageV9(): PreProcessUserMessageFuncType {
    const notesContext = useContext(NotesContext);
    const { serverType } = useContext(LLMServerContext);

    const getTemplateFrom = useGetTemplateFrom();
    const getTopicsFrom = useGetTopicsFrom();
    const loadNoteAsMarkdown = useLoadNoteAsMarkdown();

    const { topLevelNoteHierarchy } = useContext(NoteHierarchyContext);
    const { extensions } = useExtensions();

    async function preprocessUserMessageV9(messages: ChatMessage2[], setStepStarted: (step: string) => void, setStepComplete: (step: string) => void, setPreprocessingSteps: (steps: PreprocessingStepsType) => void): Promise<PreprocessUserMessageReturnType> {
        setPreprocessingSteps({ stepsInOrder: PREPROCESSING_STEPS_V9, progress: {} });
        if (!topLevelNoteHierarchy)
            return {} as PreprocessUserMessageReturnType;
        if (!serverType) {
            // Give it one second to load the context:
            await new Promise((resolve) => setTimeout(resolve, 1000));
            if (!serverType) {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: Server type not found. This should not happen.");
                debugger;
                throw new Error("Server type not found.");
            }
        }

        // Add vector calculation early in the function
        if (CALCULATE_VECTOR_FROM_CHAT) {
            /*await*/ calculateChatVector(messages);
        }

        const lastUserMessage = getLastUserMessage(messages);
        const noteIDsMentionedInChat = getLastUserNotesMentionedInChat(messages);

        // Finish loading before adding on the new stuff. Slower, but more reliable because it avoids race conditions. (TODO we might be able to optimize this into a single wait for the second one, potentially)
        if (!topLevelNoteHierarchy.isLoaded)
            await topLevelNoteHierarchy.finishLoadingPromise;
        const topLevelNoteHierarchy2 = startLoadingNoteHierarchy(noteIDsMentionedInChat, notesContext, undefined, topLevelNoteHierarchy);
        if (!topLevelNoteHierarchy2.isLoaded)
            await topLevelNoteHierarchy2.finishLoadingPromise;
        // console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> lastUserMessage is ",lastUserMessage);
        const templatesWithSchemas = [] as ExtensionNoteTemplateType<any>[];
        const numObjsWithTemplateSchema = [] as number[];

        const { topLevelIncludedIDs, mentionableTopicsIDs, mentionableTemplatesIDs } = organizeNotesInTopLevelNoteHierarchyV9(topLevelNoteHierarchy2);

        let getTemplateFromPromise = undefined as ReturnType<typeof getTemplateFrom> | undefined;
        if (!serverType.supportsFunctions) {
            // The current template implementation requires functions. Gemini doesn't support the same interfaces as OpenAI here.
            // So we add all templates as topics, allowing them to still be detected:
            mentionableTopicsIDs.push(...mentionableTemplatesIDs);
        } else {
            ///////////////////////////////////////
            // Template detection - Start parallel processing
            
            setStepStarted(PREPROCESSING_STEP_1);
            getTemplateFromPromise = getTemplateFrom(mentionableTemplatesIDs, templatesWithSchemas, numObjsWithTemplateSchema, messages);
            setStepComplete(PREPROCESSING_STEP_1);
        }


        ///////////////////////////////////////
        // Topic detection - Start parallel processing
        setStepStarted(PREPROCESSING_STEP_2);
        const getTopicsFromPromise = getTopicsFrom(mentionableTopicsIDs, lastUserMessage, templatesWithSchemas, numObjsWithTemplateSchema, topLevelNoteHierarchy2);

        ///////////////////////////////////////
        // Get ready to finish up the system prompts
        const notesIncluded = [] as NoteInclusionType[];
        const notesExcluded = [] as NoteExclusionType[];
        const notePromptPromises = [] as Promise<NoteAsMarkdown>[];

        // TODO reorder these so that the ones explicitly mentioned are first, and that way we can remove the last ones if we run out of space.
        // That means the order should be: noteIDsMentionedInChat, templateNote, topLevelIncludedIDs, noteIDsFromPreviousChats, promptPromisesFromTopics
        ///////////////////////////////////////
        // Add topLevelIncludedIDs to the promptPromises
        for (const noteID of topLevelIncludedIDs) {
            notesIncluded.push({ type: InclusionByTopLevelHierarchy, noteID });

            let note;
            if (topLevelNoteHierarchy2.noteHierarchiesById[noteID])
                note = topLevelNoteHierarchy2.noteHierarchiesById[noteID].note;
            else {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: noteID ", noteID, " not found in either hierarchy. This should not happen.");
                continue;
            }

            const promptPromise = loadNoteAsMarkdown(note, ALWAYS_INCLUDE_JSON || false, templatesWithSchemas, numObjsWithTemplateSchema);
            notePromptPromises.push(promptPromise);
        }
        // Add noteIDsMentionedInChat to the promptPromises
        for (const noteID of noteIDsMentionedInChat) {
            notesIncluded.push({ type: InclusionByMention, noteID });

            const loadNoteMentioned = async () => {
                const note = await notesContext.loadNoteID(noteID, true);
                const notePrompt = await loadNoteAsMarkdown(note, ALWAYS_INCLUDE_JSON || false, templatesWithSchemas, numObjsWithTemplateSchema);
                return notePrompt;
            };
            const notePromptPromise = loadNoteMentioned();
            notePromptPromises.push(notePromptPromise);
        }

        // Add noteIDsFromPreviousChats
        const noteIDsFromPreviousChats = getPreviousNoteIDsOfType(messages, undefined);
        for (const noteID of noteIDsFromPreviousChats) {
            notesIncluded.push({ type: InclusionByPreviousChat, noteID });
            const loadNoteFromPreviousChat = async () => {
                const note = await notesContext.loadNoteID(noteID, true);
                const notePrompt = await loadNoteAsMarkdown(note, ALWAYS_INCLUDE_JSON || false, templatesWithSchemas, numObjsWithTemplateSchema);
                return notePrompt;
            };
            const notePromptPromise = loadNoteFromPreviousChat();
            notePromptPromises.push(notePromptPromise);
        }

        const notePrompts = [] as NoteAsMarkdown[];

        debugger;


        ///////////////////////////////////////
        // Finish template detection
        // Finish first because it will usually execute faster, and we want to make the template the first in the list
        const templateNoteIDs = [];
        let isEditingTemplate = false;
        if (!getTemplateFromPromise) {
            isEditingTemplate = true; // We don't know and want to include the special text.
        } else /*if (getTemplateFromPromise)*/ {
            const { templateNote, isEditingTemplate: isEditingTemplateFromFunc, templateMarkdownPromise } = await getTemplateFromPromise;
            isEditingTemplate = isEditingTemplateFromFunc;
            if (templateMarkdownPromise) {
                notePromptPromises.unshift(templateMarkdownPromise!);
            }
            setStepComplete(PREPROCESSING_STEP_1);

            if (templateNote) {
                notesIncluded.push({ type: InclusionByTemplate, noteID: templateNote.id });
                for (let i = 0; i < templatesWithSchemas.length; i++) {
                    const numObs = numObjsWithTemplateSchema[i];
                    const template = templatesWithSchemas[i];

                    // This commented out version checks against tokensUsed. We don't want to wait for that though.
                    // if (numObs<3 && tokensUsed<serverType.contextLength-5500) {
                    //     console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> We have only ",numObjsWithTemplateSchema[0]," examples objects for '"+template.name+"'. We should load more.");
                    //     // First check if there are any more of these in memory already. We can check notesContext.notes.
                    //     const loadedNotes = notesContext.getLoadedNotesOfTemplate(template)
                    //     let addedNotes = 0;
                    //     for (const note of loadedNotes) {
                    //         if (notePrompts.filter((notePrompt:NotePrompt) => notePrompt.doc_id === note.id).length>0)
                    //             continue;
                    //         if (tokensUsed>serverType.contextLength-5000)
                    //             break;
                    //         notePrompts.push(await getNotePrompt(note, false, templatesWithSchemas, numObjsWithTemplateSchema));
                    //         tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(notePrompts));
                    //         addedNotes++;
                    //         if (addedNotes>=3)
                    //             break;    
                    //     }
                    // }
                    // This special logic seems poor. Would be more efficient to advise template writers to include several examples.
                    if (numObs < 3) {
                        console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> We have only ", numObjsWithTemplateSchema[0], " examples objects for '" + template.name + "'. We should load more.");
                        // First check if there are any more of these in memory already. We can check notesContext.notes.
                        const loadedNotes = notesContext.getLoadedNotesOfTemplate(template);
                        let addedNotes = 0;
                        for (const note of loadedNotes) {
                            if (notePrompts.filter((notePrompt: NoteAsMarkdown) => notePrompt.doc_id === note.id).length > 0)
                                continue;
                            notePrompts.push(await loadNoteAsMarkdown(note, ALWAYS_INCLUDE_JSON || false, templatesWithSchemas, numObjsWithTemplateSchema));
                            addedNotes++;
                            if (addedNotes >= 3)
                                break;
                        }
                    }
                }
                templateNoteIDs.push(templateNote.id);
            }
        }

        ///////////////////////////////////////
        // Finish topic detection
        const { promptPromises: promptPromisesFromTopics, outputOfTopicsThinking } = await getTopicsFromPromise;
        notePromptPromises.push(...promptPromisesFromTopics);
        setStepComplete(PREPROCESSING_STEP_2);

        ///////////////////////////////////////
        // Assemble prompts
        // Wait for and add all the prompts to the systemPrompts
        setStepStarted(PREPROCESSING_STEP_3);
        const promptResults = (await Promise.all(notePromptPromises)).filter((result: NoteAsMarkdown | null) => result !== null) as NoteAsMarkdown[];
        setStepComplete(PREPROCESSING_STEP_3); // this is where we're sure we're finished loading the topics.
        {
            const topicNoteIDs = uniq((await Promise.all(promptPromisesFromTopics)).map(({ doc_id }) => doc_id));
            mentionableTopicsIDs.forEach((id: string) => {
                if (topicNoteIDs.indexOf(id) < 0)
                    notesExcluded.push({ type: InclusionByTopic, noteID: id });

                else
                    notesIncluded.push({ type: InclusionByTopic, noteID: id });
            });
        }

        promptResults.forEach((prompt: NoteAsMarkdown, index: number) => {
            if (prompt && notePrompts.findIndex((notePrompt: NoteAsMarkdown) => notePrompt.doc_id === prompt.doc_id) < 0)
                notePrompts.push(prompt);
        });


        // If there's too much in the context, 
        let tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(notePrompts));
        while (tokensUsed > serverType.contextLength - 5000) {
            // Remove system prompts from last to first, until we have some space left.
            const removedPrompt = notePrompts.pop();
            if (!removedPrompt) {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: We ran out of prompts to remove, but we still have too many tokens.");
                break;
            }
            tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(notePrompts));
        }

        // let noteIDs = uniq(notePrompts.map(({doc_id}) => doc_id));
        // Add one more system prompt with links to all the documents that are included or could be included here.
        // List of links from the notes that are included in the context:
        // iterate over topLevelNoteHierarchy.noteHierarchiesById
        let linksStr = "# Links to all notes\nWhenever you refer to any of these topics, use the link instead of plain text.\n";
        for (const [noteID, noteHierarchy] of Object.entries(topLevelNoteHierarchy.noteHierarchiesById)) {
            // TODO this is a hard coded URL to the server.
            linksStr += "[" + getNoteNameAndEmoji(noteHierarchy.note, notesContext, extensions) + "](</note/" + noteID + ">) ";
        }
        const linkPrompt = {
            doc_name: "Links to all notes",
            doc_id: "links",
            type: "link",
            emoji: "🔗",
            markdownPrompt: linksStr,
            isTemplate: false
        } as NoteAsMarkdown;
        notePrompts.push(linkPrompt);
        let systemPrompt = getUniqMarkdownSystemPrompt(notePrompts);
        if (isEditingTemplate) {
            const EDIT_INCLUDE = "When editing an object's data: (1) Begin with the title using H1 (# Object Title). (2) Reprint the full object's JSON. Do not print changes only, but rather, include the full JSON. Start JSON with ```JSON and end with ```.";
            systemPrompt = EDIT_INCLUDE + "\n\n" + systemPrompt;
        }

        debugger;

        setStepComplete(PREPROCESSING_STEP_3);

        return {
            systemPrompt,
            outputOfTopicsThinking,

            notesIncluded,
            notesExcluded,
        } as PreprocessUserMessageReturnType;
    }

    return preprocessUserMessageV9;
}
