import { useContext } from "react";
import { SelectedJSONFormsContext } from "../../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { NoteHierarchyContext } from "../NoteHierarchyProvider";
import { Spin, Tabs } from "antd";
import { getNoteTypeWithSchemaFor } from "../../../../Extensions/ExtensionsFramework/ExtensionsSchemas";
import { JSONFormsEditorV2, ObjectHistoryTab } from "../../../JSONFormsInNote/GenericObjectEditor";
import { RawJSONEditor } from "../../../../JSONEditing/RawJSONEditing/RawJSONEditor";
import { JSONFormsObject } from "../../../Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject";
import { CommandPromptNoteType } from "../../../../Extensions/DefaultExtension/DefaultExtension";
import { useExtensions } from "../../../../Extensions/ExtensionsFramework/GetExtension";

function CommandPromptDataTab() {
    return <>This is a command. It will be used to detect what the user's intention is.</>;
}

export function ExtensionSupportedObjectEditor() {
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const note = selectedJsonFormsContext.note;
    const doc_id = note?.id;
    const jsonFormsObject = selectedJsonFormsContext.jsonFormsObject;
    const isLoaded1 = selectedJsonFormsContext.isJSONFormsLoaded;
    const saveJSONFormsObject = selectedJsonFormsContext.saveJSONFormsObject;

    const { isLoaded: isLoaded2 } = useContext(NoteHierarchyContext);
    const { extensions } = useExtensions();

    if (!isLoaded1 || !isLoaded2) {
        return <Spin />;
    }

    const noteType = getNoteTypeWithSchemaFor(extensions, note);
    const hasSchema = noteType?.schema;
    const tabItems = [];

    if (hasSchema) {
        tabItems.push({
            label: 'Edit',
            key: '1',
            children: <JSONFormsEditorV2 />
        });
        tabItems.push({
            label: 'Raw JSON',
            key: '3',
            children: <>{jsonFormsObject &&
                <RawJSONEditor jsonStr={JSON.stringify(jsonFormsObject)} schema={noteType.schema} isSchema={false}
                    showSaveButton={true}
                    saveFunc={(jsonObject: object) => saveJSONFormsObject(jsonObject as JSONFormsObject)}
                    key={doc_id} />}
            </>
        });
        tabItems.push({
            label: 'JSON History',
            key: 'history',
            children: <ObjectHistoryTab doc_id={doc_id} jsonSchema={noteType?.schema} />
        });
    }
    if (tabItems.length === 0 && noteType === CommandPromptNoteType) {
        // Special case. We want a tab for specifying parameters for the command prompt here.
        tabItems.push({
            label: 'Command',
            key: '1',
            children: <CommandPromptDataTab />
        });
    }
    if (tabItems.length === 0) {
        return <>COMING SOON: Note backups</>;
    }

    return <>
        <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} items={tabItems} />
    </>;
}