import { DeleteOutlined, FileTextOutlined, FolderOutlined, OrderedListOutlined, PlusSquareOutlined, SearchOutlined } from "@ant-design/icons";
import { useContext, useMemo, useRef } from "react";
import { useBulkUploadMenuItem } from "../../../DecisionGraph/ActionComponents/BulkUpload";
import { useDeleteSelectedNotesAction } from "../../../DecisionGraph/ActionComponents/DeleteNoteAction";
import { useNewNoteAction } from "../../../DecisionGraph/ActionComponents/NewNoteAction";
import SearchSidebar from "../../../DecisionGraph/Sidebars/SearchSidebar";
import { useQuery } from "../../../DecisionGraph/Utilities/URLQueryParams";
import { SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import TagTree from "../../NotesTree/TagTree";
import { DraftJSOutline } from "../DraftJSEditor/DraftJSPluginsEditor/DraftJSOutlineSidebar";
import { SELECTED_TAB_IS_NOTE, SELECTED_TAB_PARAMETER } from "./NotePage";
import { SidemenuHook, SideMenuHookReturn, SiderMenuItem } from "./SideMenuAndSidebarType";

// Button menu keys
const MENU_KEY_NEW_NOTE = "newNote";
const MENU_KEY_DELETE_NOTE = "deleteNote";

// Sidebar menu keys
const MENU_KEY_LIST_OF_NOTES = "listOfNotes";
const MENU_KEY_SEARCH = "search";
const MENU_KEY_OUTLINE = "outline";

export const useNotesSiderMenu:SidemenuHook = function(setCurrentSidebar:(name:string)=>void,
currentSidebar:string, isSidebarVisible: boolean, hideSidebar:()=>void):SideMenuHookReturn {

  const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
  const doc_id = selectedJsonFormsContext.note?.id;

  const {bulkUploadMenuItem, bulkUploadComponent} = useBulkUploadMenuItem();
  const query = useQuery();
  // TODO this searchRef probably isn't set up correctly for this new model.
  const searchRef = useRef<HTMLInputElement>();


  const noteSubMenuItems = [];
  const onNewNoteClicked = useNewNoteAction(doc_id);
  noteSubMenuItems.push({key:MENU_KEY_NEW_NOTE,label:"New Note",icon:<PlusSquareOutlined />, onClick:()=>onNewNoteClicked()});
  noteSubMenuItems.push({key:MENU_KEY_LIST_OF_NOTES,label:"List",icon:<FolderOutlined />});
  noteSubMenuItems.push({key:MENU_KEY_SEARCH,label:"Search",icon:<SearchOutlined />});
  const deleteSelectedNotesAction = useDeleteSelectedNotesAction();
  noteSubMenuItems.push(bulkUploadMenuItem);


  const selectedNoteIDs:string[] = query.getAll("notes");
  const oneOrManyNotesAreSelected = doc_id || selectedNoteIDs.length>0;
  if (oneOrManyNotesAreSelected) {
    // TODO this looks exactly the same as the example on https://ant.design/components/menu#examples -- why doesn't it work? There have been lots of changes since version 5.14.0 so we should try again after an upgrade.
    // I'm finding it doesn't work here nor even in the top menu so it could be due to an old version of ant design
    // noteMenuItems.push({type:"divider"});
    // TODO pick a standard and be consistent: decide whether to hide irrelevant menu items or to disable them

    if (doc_id) {
      const selectedTabFromQuery = query.get(SELECTED_TAB_PARAMETER);
      const isOutlinableContent = selectedTabFromQuery===SELECTED_TAB_IS_NOTE;

      noteSubMenuItems.push({key:MENU_KEY_OUTLINE,label:"Outline",icon:<OrderedListOutlined />, disabled:!isOutlinableContent})
    }
    // Delete can operate on one or manay notes:
    noteSubMenuItems.push({key:MENU_KEY_DELETE_NOTE,label:"Delete",icon:<DeleteOutlined />, onClick:()=>deleteSelectedNotesAction()});
  }

  const menuItems = [{key:"notesSubMenu",label:"Notes",icon:<FileTextOutlined />, children:noteSubMenuItems} as SiderMenuItem];

  function setSearchVisible() {
    setCurrentSidebar("Search");
    setTimeout(()=>{
      searchRef?.current?.focus();
    },200);
  }
  function setListOfNotesVisible() {
    setCurrentSidebar(MENU_KEY_LIST_OF_NOTES);
  }
  function setOutlineVisible() {
    setCurrentSidebar(MENU_KEY_OUTLINE);
  }

  function onSiderMenuItemClick(key:string):boolean {
    // If the user clicks a sidebar that's already visible, we'll hide it.
    if (key===MENU_KEY_LIST_OF_NOTES) {
      if (currentSidebar===MENU_KEY_LIST_OF_NOTES)
        hideSidebar();
      else
        setListOfNotesVisible();
      return true;
    } else if (key===MENU_KEY_SEARCH) {
      if (currentSidebar==="Search")
        hideSidebar();
      else
        setSearchVisible();
      return true;
    } else if (key===MENU_KEY_OUTLINE) {
      if (currentSidebar===MENU_KEY_OUTLINE)
        hideSidebar();
      else
        setOutlineVisible();
      return true;
    }
    return false;
  }
  function onShortcutKeyDown(keyName: string):boolean {
    // Note: control+e is also used as a synthetic event passed from the NotePage
    if (keyName==="control+e" || keyName==="command+e" || keyName==="control+shift+f" || keyName==="command+shift+f" || keyName==="alt+e" || keyName==="alt+f") {
      setSearchVisible();
      return true;
    } else if (keyName==="control+o" || keyName==="command+o" || keyName==="alt+o") {
      setListOfNotesVisible();
      return true;
    } else if (keyName==="control+shift+o" || keyName==="command+shift+o") {
      setOutlineVisible();
      return true;
    }
    return false;
  }

  const sidebarComponent = useMemo(()=>{
    if (currentSidebar===MENU_KEY_LIST_OF_NOTES)
      return <TagTree key="sidebarComponent"/>;
    else if (currentSidebar==="Search")
      return <SearchSidebar searchRef={searchRef} key="sidebarComponent"/>;
    else if (currentSidebar===MENU_KEY_OUTLINE) {
      if (selectedJsonFormsContext.noteEditorRef.current?.editorState && selectedJsonFormsContext.noteEditorRef.current?.setEditorState) {
        return <DraftJSOutline editorState={selectedJsonFormsContext.noteEditorRef.current?.editorState} setEditorState={selectedJsonFormsContext.noteEditorRef.current?.setEditorState} key="sidebarComponent"/>;
      } else {
        // Outline: when the tab is not the note, or no note is selected, we can't show the outline. But, auto-hiding it makes the screen jitter. So we won't auto-hide it after the user has clicked, it'll just be blank.
        return <>Empty</>;
      }
    }
    return undefined;
  },[currentSidebar,selectedJsonFormsContext.note?.id, selectedJsonFormsContext.noteEditorRef.current?.editorState, selectedJsonFormsContext.noteEditorRef.current?.setEditorState]);

  return {
    menuItems,
    siderComponents:[bulkUploadComponent || <></>],
    sidebarComponent,
    onSiderMenuItemClick,
    onShortcutKeyDown
  };
}