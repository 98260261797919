import { JSONSchemaType } from "ajv";
import { ExtensionJSONFormsObject, ExtensionNoteTemplateType } from "../ExtensionsFramework/ExtensionsList";


export type SaveNoteType = ExtensionJSONFormsObject & {
    Complete?: string[],
    Select?: string[],
};

export const SaveNoteSchema:JSONSchemaType<SaveNoteType> = {
    type: "object",
    properties: {
        name: {type: "string", nullable: false},
        id: {type: "string", nullable: false},
        version: {type: "number", nullable: false},
        Complete: {
            type: "array",
            items: {type: "string"},
            nullable: true,
        },
        Select: {
            type: "array",
            items: {type: "string"},
            nullable: true,
        },
    },
    required: [],
};

// export const Save_NoteType:ExtensionNoteTemplateType<SaveNoteType> = {
//     name: "Save",
//     emoji: "💾",
//     description: "A note that contains the current relevant global context. It also defines which extensions to use.",
//     template_doc_ids: ["832332c1-47b3-45f1-905e-ce32d434b563"], // empty template
//     schema: SaveNoteSchema,
// };

// export const HowTo_NoteType:ExtensionNoteTemplateType<any> = {
//     name: "How To",
//     emoji: "📖",
//     template_doc_ids: ["4fad9983-8bf6-4951-84ba-4591fb8c5859"], // empty template
//     description: "A prompt optimized for the LLM (but can also be read by people) that explains how to handle a task or situation.",
// };

// Note: There are downstream dependencies on this being a single ID rather than an array.
export const COMMAND_PROMPT_DOC_ID = "d9a48da6-8c6c-47b2-a5a3-356397ac0190";

export const CommandPromptNoteType:ExtensionNoteTemplateType<any> = {
    name: "Command Prompt",
    emoji: "🖥️",
    template_doc_ids: [COMMAND_PROMPT_DOC_ID], // real blank note as a placeholder
    description: "A specific command from the user to do an action. This prompt is optimized for the LLM, for example, 'Create a new X JSON'.",
};

export const TypeNoteType:ExtensionNoteTemplateType<any> = {
    name: "Type",
    emoji: "📝",
    template_doc_ids: ["be198809-80a1-4b21-9d62-a03bdcde2418"], // real blank note as a placeholder
    description: "A note that contains a type definition.",
};

export const DefaultNoteTemplateTypes = [
    CommandPromptNoteType,
    TypeNoteType,
] as ExtensionNoteTemplateType<any>[];
