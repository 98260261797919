import { uniq } from 'lodash';
import { useNavigateToNote } from '../../Utilities/NavigateTo';
import { NoteInclusionType, InclusionByCommand, InclusionByMention, InclusionByTemplate, InclusionByTopic, InclusionByPreviousChat, InclusionByTopLevelHierarchy, InclusionByIndirectInclude } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { useContext, useMemo, useState } from 'react';
import { NotesContext } from '../../../Notes/Data/NotesContext';
import { NoteHierarchyContext } from '../../../Notes/UIs/NoteInformationComponents/NoteHierarchyProvider';
import { getNoteNameAndEmoji } from '../../../Notes/NotesTree/TreeUtilities/CreateTagTreeNodes';
import { Collapse, Tag, Popover, Button, Modal } from 'antd';
import { ChatMessage2 } from './ChatLog';
import { WarningOutlined } from '@ant-design/icons';
import { useExtensions } from "../../../Extensions/ExtensionsFramework/GetExtension";

const DEBUG = false;

function NoteTag({noteID, color}: {noteID: string, color?: string}) {
    const notesContext = useContext(NotesContext);
    const { extensions } = useExtensions();
    const note = notesContext.getLoadedNote(noteID, true);
    const navigateToNote = useNavigateToNote();

    function onClick() {
        if (noteID) {
            navigateToNote(noteID);
        }
    }
    
    if (!note) {
        return <Tag color="#f50">Loading</Tag>;
    }
    
    return (
        <Tag 
            color={color || "blue"} 
            style={{cursor: noteID ? "pointer" : undefined}}
            onClick={onClick}
        >
            {getNoteNameAndEmoji(note, notesContext, extensions)}
        </Tag>
    );
}

function NoteTagGroup({ title, noteIDs, color }: { title: string; noteIDs: string[]; color?: string; }) {
    if (!noteIDs?.length) return null;

    return (
        <div className="chat-item-title-ref2">
            {title && <>{title}: &nbsp;</>}
            {uniq(noteIDs).map((noteID) => (
                <NoteTag
                    key={noteID}
                    noteID={noteID}
                    color={color}
                    />
            ))}
        </div>
    );
}

function IncludedNotes({ notesIncluded }: { notesIncluded: NoteInclusionType[]; }) {
    const NOTE_TYPE_TO_NAME_MAP = [
        { type: InclusionByCommand, name: "Command" },
        { type: InclusionByMention, name: "Mentioned in chat" },
        { type: InclusionByTemplate, name: "From template" },
        { type: InclusionByTopic, name: "Related topics" },
        { type: InclusionByPreviousChat, name: "From previous chats" },
        { type: InclusionByTopLevelHierarchy, name: "From hierarchy" },
        { type: InclusionByIndirectInclude, name: "Included in notes above" },
    ];

    const noteTypes = uniq(notesIncluded.map(note => note.type));
    const mappedNoteTypes = NOTE_TYPE_TO_NAME_MAP.map(({ type }) => type);

    return (
        <>
            {NOTE_TYPE_TO_NAME_MAP.map(({ type, name }) => (
                <NoteTagGroup
                    key={type}
                    title={name}
                    noteIDs={notesIncluded.filter(note => note.type === type).map(note => note.noteID)} />
            ))}
            {noteTypes.filter(type => !mappedNoteTypes.includes(type)).map(type => (
                <NoteTagGroup
                    key={type}
                    title={`${type}`}
                    noteIDs={notesIncluded.filter(note => note.type === type).map(note => note.noteID)} />
            ))}
        </>
    );
}


function ExcludedNotes({ notesExcluded, outputOfTopicsThinking }: { notesExcluded: NoteInclusionType[], outputOfTopicsThinking?: string }) {
    const noteTypes = uniq(notesExcluded.map(note => note.type));

    const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

    return (
        <div style={{ marginLeft: "40px" }}>
            {noteTypes.map(noteType => {
                const noteTypeCapitalized = capitalize(noteType);
                return (
                    <Collapse
                        key={noteType}
                        accordion={true}
                        defaultActiveKey={[]}
                        style={{ width: "calc(100% - 60px)" }}
                        size='small'
                        items={[{
                            key: noteType,
                            label: `${noteTypeCapitalized}s that weren't included`,
                            children: <>
                                <NoteTagGroup
                                    title={``}
                                    //@ts-ignore
                                    noteIDs={notesExcluded
                                        .filter(note => note.type === noteType)
                                        .map(note => note.noteID)}
                                    color="magenta"
                                />
                            </>
                        }]}
                    />
                );
            })}
            {outputOfTopicsThinking && (
                <Collapse
                    accordion={true}
                    defaultActiveKey={[]}
                    style={{ width: "calc(100% - 60px)" }}
                    size='small'
                    items={[{
                        key: 'reasoning',
                        label: "Reasoning",
                        children: <div>Because: <i>{outputOfTopicsThinking}</i></div>
                    }]}
                />
            )}
            <br />
        </div>
    );
}


// function IndirectlyIncludedNotes({ notesExcluded }: { notesExcluded: NoteInclusionType[] }) {
//     const notesContext = useContext(NotesContext);
//     const navigateToNote = useNavigateToNote();
//     const { extensions } = useContext(NoteHierarchyContext);

//     return (
//         <div className="chat-item-title-ref2">
//             <Tooltip title={(uniq(notesExcluded)).map((noteExcluded: NoteInclusionType, index: number) => {
//                 const topic = notesContext.getLoadedNote(noteExcluded.noteID, true);
//                 if (!topic) // it may still be loading.
//                     return <Tag color="#f50" key={noteExcluded.noteID}>Loading</Tag>;
//                 return <Tag color="blue" key={noteExcluded.noteID} style={{ cursor: "pointer" }} onClick={() => { navigateToNote(noteExcluded.noteID) }}>{getNoteNameAndEmoji(topic, notesContext, extensions)}</Tag>;
//             })}>
//                 <Tag color='lime'>
//                     Those indirectly include {notesExcluded.length} notes
//                 </Tag>
//             </Tooltip>
//         </div>
//     );
// }


export function IncludedAndExcludedNotes({message}:{message: ChatMessage2}) {

    const notesContext = useContext(NotesContext);
    const { extensions } = useExtensions();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const {commandText, isLoaded} = useMemo(() => {
        const command = message.extra?.notesIncluded?.find(note => note.type === InclusionByCommand);
        if (!command) return {commandText:undefined, isLoaded:true};
        const commandId = command?.noteID;
        const commandNote = notesContext.getLoadedNote(commandId, true);
        if (!commandNote) return {commandText:undefined, isLoaded:false};
        return {commandText:commandNote.doc_name, isLoaded:true};
    }, [message.extra?.notesIncluded, notesContext.loadedNotes]);

    let showText = "Info" as string | JSX.Element;
    if (commandText) {
        showText = `🖥️ ${commandText}`;
    } else if (!isLoaded || notesContext.isLoading()) {
        // TODO. This doesn't automatically refresh, not sure why not.
        showText = "...";
    } else {
        // Check to see if there are any notes included:
        const notesIncluded = message.extra?.notesIncluded;
        const notesExcluded = message.extra?.notesExcluded;
        if (!notesIncluded && !notesExcluded) {
            if (extensions.length===0) {
                showText = <><WarningOutlined/> Select or pin a Save Game note to get extensions.</>;
            } else {
                // This happens normally in at least some places, it's not always an error.
                // When we're starting to process a note, it will temporarily display this.
                // I'm not sure if this is always an error, but it's certainly one on most messages b/c there's almost always some context like a selected note, pinned note, or referenced note.
                // console.log("ERROR WITH UNKNOWN CAUSE: No notes included or excluded in message:", message);
                showText = "...   ...";
            }
        }
    }

    const modalContent = (
        <div style={{ maxHeight: "80vh", overflowY: "auto", maxWidth: "90vw" }}>
            <div>{commandText}</div>
            {!!message.extra?.notesIncluded && <IncludedNotes notesIncluded={message.extra.notesIncluded} />}
            {!!(message.extra?.notesExcluded?.length ?? 0 > 0) && (
                <ExcludedNotes
                    // @ts-ignore
                    notesExcluded={message.extra.notesExcluded}
                    outputOfTopicsThinking={message.extra?.outputOfTopicsThinking}
                />
            )}
        </div>
    );

	return (
        <>
            <Button
                type="text"
                style={{ fontSize: "0.8em", paddingLeft: 50 /* aligns text */ }}
                onClick={handleOpenModal}
            >
                {showText}
            </Button>
            <Modal
                title="Notes Included"
                open={isModalVisible}
                onCancel={handleCloseModal}
                footer={null} // No footer buttons
                width="100%" // Full-screen width
                styles={{
                    body: { padding: "20px" }, // Use styles.body instead of bodyStyle
                }}
            >
                {modalContent}
            </Modal>
        </>
    );
}