import Showdown from "showdown";
import { NotesContextType } from "../../Notes/Data/NotesContext";
import { Extension } from "../../Extensions/ExtensionsFramework/ExtensionsList";
import { LinkToNoteFunction } from "../../DecisionGraph/Utilities/NavigateTo";
import { getJustThisDraftContentHTML } from "../../Notes/UIs/NoteInformationComponents/DocReferences";
import { RawDraftContentState } from "draft-js";
//@ts-ignore
// import { TestProperty } from "shared_client_server/TestProperty";
// console.log("The value of TestProperty is:",TestProperty);

const markdownConverter = new Showdown.Converter();


export function htmlToMarkdown(text:string):string {
    let output = markdownConverter.makeMarkdown(text);
    // For some reason, it creates a lot of these useless things.
    output = output.replaceAll("\n<!-- -->\n","");
    output = output.replaceAll("<!-- -->","");
    output = output.replaceAll("<br>\n","\n");
    output = output.replaceAll("<br>","\n");
    output = output.replaceAll("<br/>","\n");    
    if (output.indexOf("<br")!==-1) {
        console.log("htmlToMarkdown output:",output)
        debugger;
    }
    return output;
}

export function markdownToHTML(text:string):string {
    return markdownConverter.makeHtml(text);
}

const JSON_REGEX = /\\?`\\?`\\?`[Jj][Ss][Oo][Nn]\s*([\s\S]*?)\s*\\?`\\?`\\?`/;
export function removeJSONFromMarkdown(markdown:string):string {
    return markdown.replace(JSON_REGEX,"");
}

// const DEBUG_EXTRACT_JSON_FROM_MARKDOWN = true;
export function extractJSONFromMarkdown(markdown: string): Object | null {
    // const match = JSON_REGEX.exec(markdown);
    const match = JSON_REGEX.exec(markdown);
    if (!match || match.length!==2) {
        // if (DEBUG_EXTRACT_JSON_FROM_MARKDOWN) {
        //     console.warn("No JSON found in markdown:",markdown, "The match we found was: ",match);
        //     if (markdown.indexOf("```JSON\n")!==-1) {
        //         // const JSON_REGEX_START = /\\?`\\?`\\?`[Jj][Ss][Oo][Nn]\s*([\s\S]*?)\s*\\?`\\?`\\?`/g;
        //         const matchStart = JSON_REGEX.exec(markdown);
        //         if (matchStart) {
        //             console.warn("JSON start found, but no end found. We captured: ",matchStart);
        //             debugger;
        //         } else {
        //             console.warn("JSON start is in the text, but even the beginning wasn't capturable by the REGEXP");
        //         }
        //     }
        //     if (markdown.indexOf("\n```")!==-1) {
        //         console.warn("JSON end found, but no start found.");
        //     }
        // }
        return null;
    }
    try {
        return JSON.parse(match[1]);
    } catch (e) {
        console.error("Failed to parse JSON from markdown,",match[1],e);
        return null;
    }
}

export async function convertDraftJSToMarkdown(doc_data:RawDraftContentState, notesContext:NotesContextType, linkToNote:LinkToNoteFunction, extensions:Extension[]):Promise<string> {
    const noteHtml = await getJustThisDraftContentHTML(doc_data, notesContext, linkToNote, extensions);
    let noteMarkdown = htmlToMarkdown(noteHtml);
    return noteMarkdown;
}
