import { Note } from "../../Notes/Data/NoteType";
import { COMMAND_PROMPT_DOC_ID } from "../DefaultExtension/DefaultExtension";

export function isACommandPrompt(note: Note): boolean {
    if (!note.template_doc_id)
        return false;
    //@ts-ignore it's never undefined because it's statically defined in the DefaultExtension
    // return CommandPromptNoteType.template_doc_ids.includes(note.template_doc_id);
    return COMMAND_PROMPT_DOC_ID === note.template_doc_id;
}
