import { JSONSchemaType } from "ajv";
import { z } from "zod";
import { zodToJsonSchema } from "zod-to-json-schema";
import { ChatAPIOptions, LLM_QUALITY_LEVEL_FASTEST, LLMServer, StructuredOutput } from "../GenericChatServerConsts";
import { ChatCompletionMessageParam } from "openai/resources";
import { ChatMessage2 } from "../../../DecisionGraph/ChatEditor/UI/ChatLog";

const DEBUG = false;

const INCLUDE_COMMAND_PARENTS = true;

export const CommandZod = z.object({
    commands: z.array(z.string()),
    context: z.array(z.string()),
});

export type Command = z.infer<typeof CommandZod>;

type NonEmptyArray<T> = [T, ...T[]];
type NonEmptyStringArray = NonEmptyArray<string>;

export function getRuntimeCommandZod(commands:string[], contexts:string[]) {
    const obj = {} as any;
    if (commands?.length>=1) {
        obj.commands = z.array(z.enum(commands as NonEmptyStringArray));
    }
    if (contexts?.length>=1) {
        obj.context = z.array(z.enum(contexts as NonEmptyStringArray));
    }

    const runtimeCommand = z.object(obj);
    return runtimeCommand;
}
export function getRuntimeJSONSchema(commands:string[], contexts:string[]) {
    return zodToJsonSchema(getRuntimeCommandZod(commands, contexts));
}

// This has been confirmed to work at runtime as the schema is identical. The "as" is because Zod doesn't implement the AJV types directly.
export const CommandJSONSchema:JSONSchemaType<Command> = zodToJsonSchema(CommandZod) as JSONSchemaType<Command>;

const PROMPT_START = `
# Purpose

You are a helpful assistant that detects user intent by comparing the user input to a list of `;

const PROMPT_COMMANDs = `
# Commands
* If the user wants to do one or more commands, return a JSON object with the "commands" key set to an array of the commands they want to do. Leave the "commands" key out if the user doesn't want to do any of the listed commands.
* The commands should be in the deduced order required, with the first command to be completed listed first. (For example, create all enemies before creating a battle. Answer rules questions before generating new content).
`;

const PROMPT_CONTEXT = `
# Contextual topics
* You may make assumptions about the context based on the available topics, for example, if one location one character are listed as available, and the user wants to talk to a person, you can assume they're talking to that one character in that one location. Similarly, if the user mentions "the city" when there's only one available city, assume it's the available city.
* When the user directly and clearly mentions a specific contextual topic, list it first. When the user doesn't mention something directly but it seems highly relevant (such as the only town available when the user mentions a town), list those next. If it's unclear whether to include but logic suggests it's potentially relevant, include it at the end only if there are less than 15 contextual topics.
`;

// export async function detectCommand(commands: string[], contexts: string[], userInput: string): Promise<Command> {
//     const hasCommands = commands?.length>=1;
//     const hasContexts = contexts?.length>=1;
//     if (!hasCommands && !hasContexts) {
//         throw new Error("No commands or contexts given");
//     }
//     const zodRuntimeCommandSchema = getRuntimeCommandZod(commands, contexts);
//     const jsonRuntimeCommandSchema = getRuntimeJSONSchema(commands, contexts)

//     const systemPrompt = `${PROMPT_START}${hasCommands ? "commands" : ""}${hasCommands && hasContexts ? " and " : ""}${hasContexts ? "contextual topics" : ""}. Return a JSON object with the following structure:
//     ${JSON.stringify(jsonRuntimeCommandSchema, null, 2)}
    
//     ${hasCommands ? PROMPT_COMMANDs : ""}
//     ${hasContexts ? PROMPT_CONTEXT : ""}`;

//     const messages = [
//         {
//             role: "system",
//             content: systemPrompt
//         },{
//             role: "user",
//             content: userInput
//         }
//     ] as ChatCompletionMessageParam[];

//     const chatAPIOptions: ChatAPIOptions = {
//         response_format: zodResponseFormat(zodRuntimeCommandSchema, "Command"),
//         quality: LLM_QUALITY_LEVEL_FASTEST,
//     };

//     const response = await callOpenAIDirect_StructuredJSON(messages, chatAPIOptions);
//     if (response.success && response.structuredOutput) {
//         return response.structuredOutput as Command;
//     } else {
//         console.error("Failed to detect command, got ",response);
//         debugger;
//         throw new Error(response.error || "Failed to detect command");
//     }
// }

// New helper: convert notesContainingCommands into markdown prompt text.
function getCommandNoteParentPrompt(commandNotesMarkdown:string): string {
    if (!commandNotesMarkdown) return "";
    let prompt = "The following was provided by the user to offer some context when choosing which command to use:\n";
    prompt += commandNotesMarkdown;
    return prompt;
}

function getMessagesFrom(userMessages: ChatMessage2[], systemPrompt:string): ChatCompletionMessageParam[] {
    const userMessagesNoSystem = userMessages.filter((message) => message.role !== "system");
    if (userMessagesNoSystem.length === 0) {
        throw new Error("No user messages sent to detectCommandV2");
    }
    const messages = [
        {
            role: "system",
            content: systemPrompt
        },
        ...userMessagesNoSystem.map((message) => ({
            role: message.role,
            content: message.content
        }))
    ] as ChatCompletionMessageParam[];
    return messages;
}

// async function callAnyStructuredOutputCommand(messages:ChatCompletionMessageParam[], zodRuntimeCommandSchema:any):Promise<any> {
//     const chatAPIOptions: ChatAPIOptions = {
//         response_format: zodResponseFormat(zodRuntimeCommandSchema, "Command"),
//         temperature: 0, // no creativity, just repeat things that have been printed before.
//         quality: LLM_QUALITY_LEVEL_FASTEST,
//     };

//     if (DEBUG) {
//         console.log("Requesting structured output from "++" with messages: ",messages);
//     }

//     const response = await callOpenAIDirect_StructuredJSON(messages, chatAPIOptions);
//     if (response.success && response.structuredOutput) {
//         const structuredOutput = response.structuredOutput;
//         if (DEBUG) {
//             console.log("Structured output: ",structuredOutput);
//         }
//         return structuredOutput as Command;
//     } else {
//         console.error("Failed to detect command, got ",response);
//         debugger;
//         throw new Error(response.error || "Failed to detect command");
//     }
// }

export async function detectCommandV2(commands: string[], contexts: string[], userMessages: ChatMessage2[], commandNotesMarkdown: string, llmServer: LLMServer): Promise<Command> {
    const hasCommands = commands?.length>=1;
    const hasContexts = contexts?.length>=1;
    if (!hasCommands && !hasContexts) {
        throw new Error("No commands or contexts given");
    }
    if (!llmServer)
        throw new Error("No server provided to detectCommandV2");
    if (llmServer.structuredOutputCall === undefined)
        throw new Error("No structured output call available in the server. This is a bug, the caller should have checked.");

    const zodRuntimeCommandSchema = getRuntimeCommandZod(commands, contexts);
    const jsonRuntimeCommandSchema = getRuntimeJSONSchema(commands, contexts)

    let systemPrompt = `${PROMPT_START}${hasCommands ? "commands" : ""}${hasCommands && hasContexts ? " and " : ""}${hasContexts ? "contextual topics" : ""}. Return a JSON object with the following structure:
    ${JSON.stringify(jsonRuntimeCommandSchema, null, 2)}
    
    ${hasCommands ? PROMPT_COMMANDs : ""}
    ${hasContexts ? PROMPT_CONTEXT : ""}`;

    if (INCLUDE_COMMAND_PARENTS && commandNotesMarkdown) {
        systemPrompt += "\n" + getCommandNoteParentPrompt(commandNotesMarkdown);
    }

    if (DEBUG) {
        console.log("*Starting command Detection*")
        console.log("Possible commands: ",commands);
        console.log("Possible contexts: ",contexts);
        console.log("Last user message: ",userMessages[userMessages.length-1].content);
        console.log("Guidance from notes: ",commandNotesMarkdown);
    }

    const chatAPIOptions: ChatAPIOptions = {
        quality: LLM_QUALITY_LEVEL_FASTEST,
        response_format: zodRuntimeCommandSchema,
    };
    const output = await llmServer.structuredOutputCall<Command>(
        getMessagesFrom(userMessages, systemPrompt),
        zodRuntimeCommandSchema as unknown as z.ZodSchema<Command>,
        jsonRuntimeCommandSchema as JSONSchemaType<Command>,
        "CommandsAndContext",
        chatAPIOptions
    ) as StructuredOutput<Command>;
    if (!output.success || !output.structuredOutput) {
        throw new Error(output.error || "Failed to detect command");
    }
    return output.structuredOutput;
}

export async function detectFromOngoingCommand(currentCommand: string, commands: string[], contexts: string[], userMessages: ChatMessage2[], commandNotesMarkdown:string, llmServer: LLMServer): Promise<Command> {
    const hasCommands = commands?.length>=1;
    const hasContexts = contexts?.length>=1;
    if (!hasCommands && !hasContexts) {
        throw new Error("No commands or contexts given");
    }
    const zodRuntimeCommandSchema = getRuntimeCommandZod(commands, contexts);
    const jsonRuntimeCommandSchema = getRuntimeJSONSchema(commands, contexts)

    let systemPrompt = `${PROMPT_START}${hasCommands ? "commands" : ""}${hasCommands && hasContexts ? " and " : ""}${hasContexts ? "contextual topics" : ""}. Return a JSON object with the following structure:
    ${JSON.stringify(jsonRuntimeCommandSchema, null, 2)}
    
    ${hasCommands ? PROMPT_COMMANDs : ""}
    * If the user is still working on the previous command and has shown no signs that they've moved on to a new command or something that's not a command, return the command they're working on. When in doubt, assume the user is still working on the previous command: "${currentCommand}".
    ${hasContexts ? PROMPT_CONTEXT : ""}`;

    if (INCLUDE_COMMAND_PARENTS && commandNotesMarkdown) {
        systemPrompt += "\n" + getCommandNoteParentPrompt(commandNotesMarkdown);
    }

    const chatAPIOptions: ChatAPIOptions = {
        quality: LLM_QUALITY_LEVEL_FASTEST,
        response_format: zodRuntimeCommandSchema,
    };
    if (!llmServer.structuredOutputCall)
        throw new Error("No structured output call available in the server. This is a bug, the caller should have checked.");
    const output = await llmServer.structuredOutputCall<Command>(
        getMessagesFrom(userMessages, systemPrompt),
        zodRuntimeCommandSchema as unknown as z.ZodSchema<Command>,
        jsonRuntimeCommandSchema as unknown as JSONSchemaType<Command>,
        "CommandsAndContext",
        chatAPIOptions
    );
    if (!output.success || !output.structuredOutput) {
        throw new Error(output.error || "Failed to detect command");
    }
    const command = output.structuredOutput as Command;

    if (DEBUG) console.log("Previous command was ",currentCommand," and the new command is ",command.commands);
    
    return command;
}