import { BASE_XP } from "../GameCard/PlayerFunctions";
import { PlayerCharacterInMemoryType } from "./PlayerCharacters";

const TsuisSupporters = "";

const EARNED_XP=
    BASE_XP
    +15 // carried over from previous game
    -2; // we aren't earning more XP this session, this is auto-added
const EARNED_GOLD=
    28 // carried over from previous game
    -8; // we aren't earning more gold this session (this subtracts what's auto-added)

export const Tsuis_Game2_PlayerCharacters:PlayerCharacterInMemoryType[] = [
    {
        playerName:"Carl Tsui",
        characterName:"[TBD] (Tsui Game 2)",
        role: "",
        earnedXP: EARNED_XP,
        earnedGold:EARNED_GOLD,
        spentGold: 0,
        addedLife: 0,
        reusableCardFunctions: [
        ],
        defenseCards: [],
        discardableCards: [],
        miscItems: "",
        supporters: TsuisSupporters
    },
    {
        playerName:"Brandon Tsui",
        characterName:"TBD (Tsui Game 2)",
        role: "",
        earnedXP: EARNED_XP,
        earnedGold:EARNED_GOLD,
        spentGold: 0,
        addedLife: 0,
        reusableCardFunctions: [
        ],
        defenseCards: [],
        discardableCards: [],
        miscItems: "",
        supporters: TsuisSupporters
    },
    {
        playerName:"Noah Powers",
        characterName:"TBD (Tsui Game 2)",
        role: "",
        earnedXP: EARNED_XP,
        earnedGold:EARNED_GOLD,
        spentGold: 0,
        addedLife: 0,
        reusableCardFunctions: [
        ],
        defenseCards: [],
        discardableCards: [],
        miscItems: "",
        supporters: TsuisSupporters
    },
    
];
