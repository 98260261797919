import { convertFromHTML } from "draft-convert";
import { convertToRaw, RawDraftContentState } from "draft-js";
import { getNoteIDFromURL, getURLForNoteId } from "../../../DecisionGraph/Utilities/NavigateTo";
import { markdownToHTML } from "../../../ServerConnection/LLMServer/MarkdownConverter";

export function convertHTMLToDraftJSRaw(noteHTML: string):RawDraftContentState {
    const contentState = convertFromHTML({
        htmlToStyle: (nodeName, node, currentStyle) => {
            if (nodeName === 'span' && node.style.color === 'blue') {
                return currentStyle.add('BLUE');
            } else {
                return currentStyle;
            }
        },
        htmlToEntity: (nodeName, node, createEntity) => {
            if (nodeName !== 'a') {
                return;
            }
            let href = node.href as string;
            // If the URL has a full domain, we update it to a local URL.
            const noteId = getNoteIDFromURL(href);
            if (noteId) {
                href = getURLForNoteId(noteId);
                return createEntity(
                    'LINK',
                    'MUTABLE',
                    {url: node.href}
                )
            }
        },
        textToEntity: (text, createEntity) => {
            const result = [] as any[];
            text.replace(/@(\w+)/g, (match, name, offset) => {
                const entityKey = createEntity(
                    'AT-MENTION',
                    'IMMUTABLE',
                    {name}
                );
                result.push({
                    entity: entityKey,
                    offset,
                    length: match.length,
                    result: match
                });
                return match;
            });
            return result;
        },
        htmlToBlock: (nodeName, node) => {
            if (nodeName === 'blockquote') {
                return {
                    type: 'blockquote',
                    data: {}
                };
            }
        }
    })(noteHTML);
    const doc_data = convertToRaw(contentState);

    return doc_data;
}

export function convertMarkdownToDraftJSRaw(markdown: string):RawDraftContentState {
    let html = markdownToHTML(markdown);
    if (!html)
        return {blocks: [], entityMap: {}} as RawDraftContentState;
    // html = html.replaceAll("<blockquote>\n  <p>","<blockquote>").replaceAll("</p>\n</blockquote>","</blockquote>");
    // This removes the additional line at the end of blockquotes. It might only be necessary when going from markdown -> html -> draft-js
    html = html.replaceAll("</p>\n</blockquote>","</p></blockquote>")
    // This removes the additional space before the blockquote starts. Again, it might only be necessary when going from markdown -> html -> draft-js
        .replaceAll("<blockquote>\n  ","<blockquote>");
    const draftJSRaw = convertHTMLToDraftJSRaw(html);
    return draftJSRaw;
}