import { ChatCompletionMessageParam } from "openai/resources";
import { LLM_SERVERTYPE_OPENAI_INDIRECT } from "./FirebaseOpenAIChatServer";
import { LLM_SERVERTYPE_OPENAI_DIRECT } from "./OpenAIDirectChatServer";
import { LLM_SERVERTYPE_GEMINI_VIA_FIREBASE } from "./Gemini/GeminiFirebaseChatServer";
import { LLMServer } from "./GenericChatServerConsts";

/*
First one in the list is used as the default is SelectableLLM.
The default must always work (e.g. not requiring the user to start a server, enter an API key, etc).

Gemini via Firebase is the default, because it has ALL of the following:
 + It can stream responses, enabling users to see the response quicker
 + No configuration required by users
Bonus, but not criteria:
 + It's the cheapest.

The Firebase Functions versions are not the default, because:
 - Firebase Functions cannot stream

OpenAI Direct is not the default, because:
 - User has to manually add a key
But it does have a few benefits:
 + Fastest responses (though unlikely users can tell its faster than Gemini via Firebase)

We believe there's no large noticable user difference in performance between Gemini & OpenAI as of 12/2024
 ~ Speed will appear about the same
 ~ Response quality will appear about the same. Sometimes Gemini is better, sometimes OpenAI is better.

*/
export const LLM_SERVERS_OBJ = [
    LLM_SERVERTYPE_GEMINI_VIA_FIREBASE,
    // TODO update server or deprecate indirect OpenAI. The low quality version needs to be updated to use GPT-4o-mini. It's also slower so there's no reason for it to be used (except if the user doesn't have an API string. They can always use GEMINI via Firebase instead which is cheaper and faster)
    LLM_SERVERTYPE_OPENAI_INDIRECT,
    LLM_SERVERTYPE_OPENAI_DIRECT,

    // We've commented out some rarely used ones to make the list shorter, but they should be fully functioning if the servers are available.
    // {name: LLM_MODEL_LOCAL,
    //     contextLength: 32768,
    //     isLocal: true,
    //     serverType: LLM_SERVERTYPE_LMStudio,
    //     defaultTemperature: 0.3,
    //     supportsStreaming: true,
    //     supportsFunctions: false,
    //     /*modelTypeForTokenization: "gpt-4-0125-preview" as TiktokenModel*/},
    
    // {name: LLM_MODEL_DEPRECATED_GEMINI_VIA_CLOUDFUNCTIONS,
    //     contextLength: 32768,
    //     isLocal: false,
    //     defaultTemperature: 0.3,
    //     supportsStreaming: true,
    //     supportsFunctions: false,
    //     serverType: LLM_SERVERTYPE_FirebaseFunctionsVertexAI,
    // },
    // {name: LLM_MODEL_GROQ,
    //     contextLength: 32768,
    //     isLocal: false,
    //     defaultTemperature: 0.3,
    //     supportsStreaming: false,
    //     supportsFunctions: true,
    //     serverType: "FirebaseGroqChatServer",
    // },
] as LLMServer[];

export type CleanMessage = {
    role: "function" | "developer" | "system" | "user" | "assistant" | "tool";
    content: string;
};
export function cleanChatMessagesBeforeSendToServer(messages:ChatCompletionMessageParam[]):CleanMessage[] {
    return messages.map((message:ChatCompletionMessageParam)=>{
        const newMessage = {role: message.role, content: Array.isArray(message.content) ? message.content.join(' ') : (message.content ?? '')};
        return newMessage;
    });
}