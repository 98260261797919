import { HttpsCallableOptions, httpsCallable } from "firebase/functions";
import { Note } from "../../../../Notes/Data/NoteType";
import { FIREBASE_FUNCTIONS } from "../../../../AppBase/App";
import { extractJSONFromMarkdown, removeJSONFromMarkdown } from "../../MarkdownConverter";


const SERVER_TIMEOUT_MINS = 4; // 4 minutes. The source of truth is in our server functions

const DEBUG = true;

export type TopicDetectionV7Response = {
    succeeded:boolean,
    topics?:string[],
    logic?:string,
    error?:string
};
export type TopicsInputV7 = {
    userInput:string,
    topics:string[],
    topicsContext:string[],
};
export const DefaultTopicsInputV7:TopicsInputV7 = {
    userInput:"",
    topics:[],
    topicsContext:[],
};

/* This also dedupes topics. We don't add the same topic twice. */
function addTopicTo(topic:string, topicContext:string, topicsInput:TopicsInputV7) {
    if (topicsInput.topics.indexOf(topic)!==-1)
        return topicsInput;

    topicsInput.topics.push(topic);
    let topicContext2 = topicContext || "";
    if (topicContext2==="Type")
        topicContext2 = "Structured Type";
    else if (topicContext2==="Prompt")
        topicContext2 = "How to";
    topicsInput.topicsContext.push(topicContext2);
    return topicsInput;
}

export function getTopicInputV7FromNotes(selectedNote:Note, jsonSchemaNote:Note | undefined | null, topicsInput:TopicsInputV7=DefaultTopicsInputV7):TopicsInputV7 {
    if (jsonSchemaNote) {
        topicsInput = addTopicTo(jsonSchemaNote.doc_name,jsonSchemaNote.type,topicsInput);
    }
    if (selectedNote?.type==="Type") {
        if (!jsonSchemaNote) {
            // Assume it's not loaded yet.  
            // console.error("[TopicDetectionV5>getTopicInputV7From] note is a Type, but no jsonSchemaNote was provided. This is a bug.");
        } else if (selectedNote.type!==jsonSchemaNote.doc_name)
            console.error("[TopicDetectionV5>getTopicInputV7From] note is a Type, but the jsonSchemaNote.doc_name is different. This is a bug.");
    }
    return topicsInput;
}
export function getTopicInputV7FromMentionables(mentionables:Note[], topicsInput:TopicsInputV7=DefaultTopicsInputV7):TopicsInputV7 {    
    for (let mentionableNote of mentionables) {
        topicsInput = addTopicTo(mentionableNote.doc_name,mentionableNote.type, topicsInput);
    }
    return topicsInput;
}

export default function useTopicDetectionV7Command() {
    const options: HttpsCallableOptions = {
        timeout: 1000*60*SERVER_TIMEOUT_MINS+1000, // milliseconds
    };
    const remoteCommand = httpsCallable(FIREBASE_FUNCTIONS, "topicDetection", options);

    async function topicDetectionCommand(input:TopicsInputV7):Promise<TopicDetectionV7Response> {
        const startTime = Date.now();
        try {
            const commandResponse = await remoteCommand(input);
            if (DEBUG) console.log("[useTopicDetectionV7Command] > topicDetectionCommand > completed commandResponse: ",commandResponse);
            //@ts-ignore
            if (!commandResponse.data.succeeded || commandResponse.data.choices.length===0 || !commandResponse.data.choices[0]?.message?.content) {
                debugger;
                //@ts-ignore
                console.error("[useTopicDetectionV7Command] > topicDetectionCommand > Error: "+commandResponse.data.error);
                //@ts-ignore
                return {succeeded:false, error:commandResponse.data.error};
            } else {
                const endTime = Date.now();
                const timeTakenMinutes = (endTime-startTime)/1000/60;
                if (DEBUG) console.log("[useTopicDetectionV7Command] > topicDetectionCommand > API completed in "+timeTakenMinutes+" minutes.");
                //@ts-ignore
                const textWithJSON = commandResponse.data.choices[0].message.content;
                const json = extractJSONFromMarkdown(textWithJSON);
                if (DEBUG) console.log("[useTopicDetectionV7Command] > topicDetectionCommand > JSON extracted: ",json);
                if (!json) {
                    debugger;
                    //@ts-ignore
                    return {succeeded:false, error:"Error: No JSON detected in response from OpenAI API."};
                }
                const logic = removeJSONFromMarkdown(textWithJSON);

                return {succeeded:true, topics:json as string[], logic};
            }
        } catch (error) {
            // Check to see if this is a firebase internal error:
            //@ts-ignore
            if (error.name==="FirebaseError" && error.message==="deadline-exceeded") {
                // We can retry once. It's likely it'll fail again, though...
                const endTime = Date.now();
                const timeTakenMinutes = (endTime-startTime)/1000/60;
                debugger;
                // TODO retry?
                // console.error("Error: OpenAI API has taken "+timeTakenMinutes+" minutes (the server timeout is "+SERVER_TIMEOUT_MINS+" minutes). That's "+(attemptsSoFar+1)+" attempts at up to "+SERVER_TIMEOUT_MINS+" mins each...");
                // if (retries>0) {
                //     console.log("... Retrying "+(retries)+" more times...");
                //     return await makeTheCall(retries-1, attemptsSoFar+1);
                // } else {
                //     console.error("... We've exceeded retries. Giving up.");
                //     alert("Error: The ChatGPT server is running very slow right now. Please try again later.");
                //     errorCallback("Error: The ChatGPT server is running very slow right now. Please try again later.");
                //     return;
                // }
                return {succeeded:false, error:"Error: OpenAI API has taken "+timeTakenMinutes+" minutes (the server timeout is "+SERVER_TIMEOUT_MINS+" minutes)."};
            } else {
                debugger;
                return {succeeded:false, error:"Error: "+error};
            }
            // We get them when OpenAI takes extra long to reply, though we just set the timeout to now be 2 minutes -- but that's really long.
            // if (chatAPIOptions.abortController?.signal.aborted) {
            //     // Not a problem!
            // } else {
            //     // Is this still an unknown error? If so, let's take a look at it in the debugger.
            //     console.error(error);
            //     debugger;
            //     errorCallback("Error: "+error);
            // }
        }
    }
    return topicDetectionCommand;
}