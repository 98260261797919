import { useEffect, useRef } from "react";
import { Note } from "../Notes/Data/NoteType";
import { useSendAnythingToServer } from "../ServerConnection/LLMServer/SendChatToServerHook";
import { detectTemplate } from "../ServerConnection/LLMServer/Prompts/Older/DetectTemplateV8";
import { GenericTestPage, Test, GenericTestPageRef } from "./GenericTestPage";
import { LLMServerContextProvider, SelectableLLMServerDropdown } from "../DecisionGraph/ChatEditor/UI/SelectableLLM";

const TestingNoteFiller = {
    promptMentionExamples: [],
    type: "Prompt",
    doc_data: {blocks:[],entityMap:{}},
    version: 1,
};

export const TEST_LIST_OF_TEMPLATES:Note[] = [
    {
        doc_name: "NPC Template",
        id: "9883d97c-0269-4692-aaa9-0648dd06d7d7",
        ...TestingNoteFiller,
    },{
        doc_name: "Luminaria Event Template",
        id: "97fb915b-bdbb-4948-bb03-99b0b1ad28e1",
        ...TestingNoteFiller,
    },{
        doc_name: "Faction Template",
        id: "2b40cb10-bdaf-436a-8957-f034ec75bb3e",
        ...TestingNoteFiller,
    },{
        doc_name: "Shop Template",
        id: "6cf816c3-1ede-4ef0-abd8-da9436c0266d",
        ...TestingNoteFiller,
    },{
        doc_name: "Dungeon Room Template",
        id: "f19467ed-491c-4a3b-bae8-9d2f580bd454",
        ...TestingNoteFiller,
    },{
        doc_name: "Luminaria Battle Template",
        id: "50e9a9b2-525f-489b-9d62-113661b53d4d",
        ...TestingNoteFiller,
    }
];

// Replace the old local tests with a definitions array:
const testDefinitions = [
    {
        name: "Test1",
        userInput: "create a new event",
        correctType: "new",
        correctTemplate: "Luminaria Event Template"
    },
    {
        name: "Test2",
        userInput: "edit a room",
        correctType: "edit",
        correctTemplate: "Dungeon Room Template"
    },
    {
        name: "Test3",
        userInput: "something else",
        correctType: "none",
        correctTemplate: ""
    },
    {
        name: "Test4",
        userInput: "create a nw NPC",
        correctType: "new",
        correctTemplate: "NPC Template"
    },
    {
        name: "Test5",
        userInput: "edit a faction",
        correctType: "edit",
        correctTemplate: "Faction Template"
    },
    {
        name: "Test6",
        userInput: "tell me what you can do",
        correctType: "none",
        correctTemplate: ""
    },
    {
        name: "Test7",
        userInput: "nxet evnt: the bomb drops just as the door shuts behind us",
        correctType: "new",
        correctTemplate: "Luminaria Event Template"
    }
];

function InnerTemplateDetectionV8TestPage() {
    const { sendToServerWithStreaming, serverType } = useSendAnythingToServer(
        () => {},
        () => {}
    );

    const testPageRef = useRef<GenericTestPageRef | null>(null);

    useEffect(() => {
        document.title = "Template Detection Test Page - AI Got This";
        if (testPageRef.current) {
            testPageRef.current.runSelectedTest();
        }
    }, []);

    if (!serverType) {
        return <div>Loading server type</div>;
    }
    if (!serverType.supportsFunctions) {
        return <div>Template Detection is not supported on {serverType.name}</div>;
    }

    const tests: Test[] = testDefinitions.map((td) => ({
        name: td.name,
        check: async () => {
            try {
                const result = await detectTemplate(TEST_LIST_OF_TEMPLATES, td.userInput, sendToServerWithStreaming);
                const matchesType = (result.type === td.correctType);
                const matchesTemplate = (result.template === td.correctTemplate);

                if (!matchesType || !matchesTemplate) {
                    const runtimeTrace = JSON.stringify(result);
                    const msg = `Expected: type="${td.correctType}", template="${td.correctTemplate}"` +
                                ` but got "${runtimeTrace}"`;
                    return { success: false, message: msg };
                }
                return { success: true };
            } catch (e: any) {
                return { success: false, message: e.toString() };
            }
        }
    }));

    return (
        <GenericTestPage
            ref={testPageRef}
            tests={tests}
            title="Template Detection V8 Test Page"
            children={<SelectableLLMServerDropdown/>}
        />
    );
}

export function TemplateDetectionV8TestPage() {
    return (
        <LLMServerContextProvider>
            <InnerTemplateDetectionV8TestPage />
        </LLMServerContextProvider>
    );
}