import { Layout } from "@jsonforms/core";
import { JSONSchemaType } from "ajv";
import { ExtensionJSONFormsObject } from "../../ExtensionsFramework/ExtensionsList";
import { ActionCardEnhancement, DefenseCard, DiscardableCard, ReusableCard, XPAtLeast1 } from "../GameCard/GameCardTypes";
import { Letourneou_Game1_PlayerCharacters } from "./Letourneous_Game1";
import { Template_Characters } from "./TemplatePCs";
import { Tsuis_Game2_PlayerCharacters } from "./Tsuis_Game2";
import { AllReusableCardFunctions } from "../GameCard/ReusableCards";
import { AllEnhancements } from "../GameCard/ReusableCardEnhancements";
import { DefenseCardFunctions } from "../GameCard/DefenseCards";

const DEBUG = false;

export type EvenNumberUpTo50 = 0|2|4|6|8|10|12|14|16|18|20|22|24|26|28|30|32|34|36|38|40|42|44|46|48|50;

// TODO We want to create some sort of way to track the player's own experience playing, so that we can adjust the game and the instructions to their skill level.
// export type PlayerGameExperience = {
//   playerName: string;
//   skillSystem: XPAtLeast0;
//   moveSystem: XPAtLeast0;
//   combatSystem: XPAtLeast0;
// }

// Root type includes everything besides cards. When stored in JSON, we store card names. This can be converted to the cards themselves.
export type PlayerCharacterRootType = {
    playerName:string,
    characterName:string,
    role?:string,

    earnedXP:number,
    earnedGold:number,
    spentGold:number, // Money spent on things excluding cards (unusual items, boats, bribes, etc)
    
    addedLife: EvenNumberUpTo50,
    miscItems?:string,
    supporters?:string,
}
export type PlayerCharacterJSONType = PlayerCharacterRootType & ExtensionJSONFormsObject & {
    reusableCardIDs: {
        cardID:string,
        level:XPAtLeast1,
        reusableCardEnhancementIDs: string[],
    }[],
    defenseCardIDs:string[],
};
export type PlayerCharacterInMemoryType = PlayerCharacterRootType & {
    reusableCardFunctions: {func:(level:XPAtLeast1)=>ReusableCard, level:XPAtLeast1, enhancements:ActionCardEnhancement[]}[],
    defenseCards:DefenseCard[],
    discardableCards:DiscardableCard[],
};

export const PlayerCharacterSchema:JSONSchemaType<PlayerCharacterJSONType> = {
    type: "object",
    properties: {
        name: {type: "string", nullable: false},
        id: {type: "string", nullable: false},
        version: {type: "number", nullable: false},

        playerName: {type: "string", nullable: false, description: "The name of the player controlling this character"},
        characterName: {type: "string", nullable: false, description: "The name of the fictional character (PC)"},
        role: {type: "string", nullable: true, description: "Like a class, but optional, and may change over time depending on the character's available actions."},
        earnedXP: {type: "number", nullable: false},
        earnedGold: {type: "number", nullable: false},
        spentGold: {type: "number", nullable: false},
        addedLife: {type: "number", nullable: false},
        reusableCardIDs: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    cardID: {type: "string", nullable: false},
                    level: {type: "number", nullable: false},
                    reusableCardEnhancementIDs: {
                        type: "array",
                        items: {type: "string"},
                        nullable: false,
                    }
                },
                required: [],
            },
            nullable: false,
        },
        miscItems: {type: "string", nullable: true, description: "Any items the character is carrying that are not cards"},
        supporters: {type: "string", nullable: true, description: "Any NPCs or other characters that have committed to supporting this character"},
        defenseCardIDs: {
            type: "array",
            items: {type: "string"},
            nullable: false,
        }
    },
    required: [],
};
// Uncomment the following to get the JSON schema in the console, in the propert format so that it can be pasted into the JSONForms editor at https://jsonforms-editor.netlify.app/
// console.log("PlayerCharacterSchema:")
// console.log(PlayerCharacterSchema);

/**********
 * UI Schema
 * 
 * The typescript for the "Layout" in json forms is less than optimal.
 * We want to preserve editability in the JSON Forms Editor at https://jsonforms-editor.netlify.app/ so:
 * - We don't use the Layout type in the declaration itself, because that would force extra inline casts due to the unusual typescript written by JSON Forms Editor.
 * - Instead, we cast as unknown as Layout at the end
 * - We keep the double quotes around the "type" values, because that's how the JSON Forms Editor writes them.
 */
export const PlayerCharacterUISchema = {
  "type": "VerticalLayout",
  "elements": [
    {
      "type": "Group",
      "elements": [
        {
          "type": "Control",
          "scope": "#/properties/playerName"
        },
        {
          "type": "Control",
          "scope": "#/properties/characterName"
        }
      ],
      "label": ""
    },
    {
      "type": "Group",
      "elements": [
        {
          "type": "Control",
          "scope": "#/properties/earnedXP"
        },
        {
          "type": "Control",
          "scope": "#/properties/earnedGold"
        }
      ],
      "label": "Earnings"
    },
    {
      "type": "Group",
      "elements": [
        {
          "type": "Control",
          "scope": "#/properties/addedLife"
        },
        {
          "type": "Control",
          "scope": "#/properties/reusableCardIDs",
          "label": "Reusable Cards"
        },
        {
          "type": "Control",
          "scope": "#/properties/defenseCardIDs",
          "label": "Defense Cards"
        },
        {
          "type": "Control",
          "scope": "#/properties/miscItems"
        },
        {
          "type": "Control",
          "scope": "#/properties/supporters"
        }
      ],
      "label": "Growth"
    }
  ]
} as unknown as Layout;

export const AllPlayerCharacters:PlayerCharacterInMemoryType[] = [
  ...Template_Characters,
  ...Letourneou_Game1_PlayerCharacters,
  ...Tsuis_Game2_PlayerCharacters,
] as PlayerCharacterInMemoryType[];

export function convertPlayerCharacters(jsonPlayers: PlayerCharacterJSONType[]): PlayerCharacterInMemoryType[] {
    return jsonPlayers.map(jsonPlayer => {
        // Convert reusable card entries
        const reusableCardFunctions = jsonPlayer.reusableCardIDs.map(entry => {
            // Find matching reusable card function based on cardID (assumed to match function name)
            const cardFunc = AllReusableCardFunctions.find(func => func.name === entry.cardID);
            if (!cardFunc) {
                console.error(`Reusable card function not found for ${entry.cardID}`);
                return null;
            }
            // Lookup enhancements by name from AllEnhancements
            const enhancements = entry.reusableCardEnhancementIDs.map(enhID => {
                const enh = AllEnhancements.find(e => e.name === enhID);
                if (!enh) {
                    console.error(`Enhancement not found for ${enhID}`);
                }
                return enh;
            }).filter(e => e !== undefined) as any; // cast to ActionCardEnhancement[]
            return { func: cardFunc, level: entry.level, enhancements };
        }).filter(x => x !== null) as { func: (level: XPAtLeast1)=>ReusableCard, level: XPAtLeast1, enhancements: any[] }[];

        // Convert defense cards using DefenseCardFunctions; assume default level 1 if lookup succeeds.
        const defenseCards = jsonPlayer.defenseCardIDs.map(defID => {
            const defFunc = DefenseCardFunctions.find(func => func.name === defID);
            if (!defFunc) {
                console.error(`Defense card function not found for ${defID}`);
                return null;
            }
            return defFunc(1);
        }).filter(x => x !== null) as any[]; // cast to DefenseCard[]

        return {
            ...jsonPlayer,
            // Map JSON card IDs to in-memory card functions
            reusableCardFunctions,
            defenseCards,
            // For this example, assume discardableCards remains empty.
            discardableCards: []
        } as PlayerCharacterInMemoryType;
    });
}

function checkForDuplicateNames() {
  const entries: { name: string, origin: string }[] = [];

  AllReusableCardFunctions.forEach(func =>
      entries.push({ name: func.name, origin: 'AllReusableCardFunctions' })
  );
  DefenseCardFunctions.forEach(func =>
      entries.push({ name: func.name, origin: 'DefenseCardFunctions' })
  );
  AllEnhancements.forEach(enh =>
      entries.push({ name: enh.name, origin: 'AllEnhancements' })
  );

  // Group entries by name.
  const grouped: { [name: string]: string[] } = {};
  entries.forEach(e => {
      grouped[e.name] = grouped[e.name] ? [...grouped[e.name], e.origin] : [e.origin];
  });

  // Find duplicate names (any name with more than one origin)
  const duplicates = Object.entries(grouped)
      .filter(([name, origins]) => origins.length > 1)
      .map(([name, origins]) => `${name} found in: ${origins.join(', ')}`);

  const message = duplicates.join('; ');
  if (duplicates.length > 0) {
    console.error(message);
    debugger;
  }
  console.log("No duplicate names found among AllReusableCardFunctions, DefenseCardFunctions, and AllEnhancements");
};
if (DEBUG)
  checkForDuplicateNames();