import { useLocation, useNavigate } from "react-router-dom";
import { getQuery, querySetAll } from "./URLQueryParams";
import { Link } from "react-router-dom";
import { Note } from "../../Notes/Data/NoteType";
import { useContext } from "react";
import { NotesContext } from "../../Notes/Data/NotesContext";
import { getNoteNameAndEmoji } from "../../Notes/NotesTree/TreeUtilities/CreateTagTreeNodes";
import { isAValidNoteID } from "../../Notes/Data/FirestoreNoteClient";
import { SELECTED_MUSIC_TAGS_PARAMETER, getMusicTagsForURL, getMusicTagsFromURLParameter } from "./Sound/FullMusicSoundtrack";
import { SOUNDTRACK_DRAWER_OPEN_PARAMETER } from "./Sound/MusicAndSpeechDrawer";
import { SELECTED_TAB_IS_NOTE, SELECTED_TAB_PARAMETER } from "../../Notes/UIs/NotePageAndHigher/NotePage";
import { Popover } from "antd/lib";
import { useExtensions } from "../../Extensions/ExtensionsFramework/GetExtension";

const MUSIC_URL_PREFIX = "/?"+SOUNDTRACK_DRAWER_OPEN_PARAMETER+"=true&"+SELECTED_MUSIC_TAGS_PARAMETER+"=";

export function getURLForNoteId(doc_id:string) {
    return "/note/"+doc_id;
}

export function getURLForMusicTags(tags:string[]) {
    const tagsJSON = getMusicTagsForURL(tags);
    return MUSIC_URL_PREFIX+tagsJSON;
}

export function getMusicTagsFromURL(url:string) {
    if (!url)
        return null;
    if (!url.startsWith(MUSIC_URL_PREFIX)) {
        return null;
    }
    const endOfUrl = url.substring(MUSIC_URL_PREFIX.length);
    return getMusicTagsFromURLParameter(endOfUrl);
}

// function testMusicTagsURL() {
//     console.log("[testMusicTagsURL] Testing getMusicTagsFromURL:");
//     console.assert(getMusicTagsFromURL(MUSIC_URL_PREFIX+"[]")?.length===0,"Failed on empty tags");
//     console.assert(getMusicTagsFromURL(MUSIC_URL_PREFIX+"[\"tension\"]")?.length===1,"Failed on one tag");
//     console.assert(getMusicTagsFromURL(MUSIC_URL_PREFIX+"[\"tension\",\"mystery\"]")?.length===2,"Failed on two tags");

//     const tags2 = getMusicTagsFromURL(MUSIC_URL_PREFIX+"[\"tension\",\"mystery\"]");
//     console.assert(isEqual(tags2,["tension","mystery"]),`Failed on two tags: ${tags2}`);
//     console.assert(isEqual(getMusicTagsFromURL(MUSIC_URL_PREFIX+"[\"tension\",\"mystery\",\"action\"]"),["tension","mystery","action"]),"Failed on three tags");

//     // Test the reverse function:
//     console.log("[testMusicTagsURL] Testing getMusicTagsForURL:");
//     console.assert(getMusicTagsForURL([])==="[]","Failed on empty tags");
//     console.assert(getMusicTagsForURL(["tension"])==="[\"tension\"]","Failed on one tag");
//     console.assert(getMusicTagsForURL(["tension","mystery"])==="[\"tension\",\"mystery\"]","Failed on two tags");
//     console.log("[testMusicTagsURL] Testing complete.");

// }
// testMusicTagsURL();


export function getURLForNoteId_preserveQuery(query:URLSearchParams, doc_id:string) {
    querySetAll(query,"notes",[doc_id]);
    query.set(SELECTED_TAB_PARAMETER, SELECTED_TAB_IS_NOTE);
    return "/note/"+doc_id+"?"+query.toString();
}

export type NavigateToNoteFunction = (doc_id:string, tab?:string)=>void;

// Preserving any other selection info
export function useNavigateToNote():NavigateToNoteFunction {
    const navigate = useNavigate();
    return function(doc_id:string, tab:string = SELECTED_TAB_IS_NOTE) {
        const query = getQuery();
        querySetAll(query,"notes",[doc_id]);
        query.set(SELECTED_TAB_PARAMETER, tab);
        navigate("/note/"+doc_id+"?"+query.toString());
    }
}
export function useNavigateToDeselectNote() {
    const navigate = useNavigate();
    return function() {
        const query = getQuery();
        query.delete("notes");
        // Default behavior on closing a note is to go to the default. In the future chat won't be a tab so it wouldn't make sense to go there.
        query.delete(SELECTED_TAB_PARAMETER);
        navigate("/?"+query.toString());
    }
}

export function useNavigateToMusic() {
    const navigate = useNavigate();
    const location = useLocation();
    return function(tags:string[], openDrawer:boolean=true) {
        const query = getQuery();
        query.set(SOUNDTRACK_DRAWER_OPEN_PARAMETER,openDrawer.toString());
        query.set(SELECTED_MUSIC_TAGS_PARAMETER,getMusicTagsForURL(tags));
        // Preserve the note and rest of parameters, if there is one:
        navigate(location.pathname+"?"+query.toString(),{replace:true});
    }
}

export type LinkToNoteIdFunction = (doc_id:string, title:string)=>JSX.Element;

export function useLinkToNoteId(): LinkToNoteIdFunction {
    return function(doc_id:string, title:string) {
        const query = getQuery();
        const url = getURLForNoteId_preserveQuery(query,doc_id);
        return <Link to={url}>{title}</Link>;
    }
}

export type LinkToNoteFunction = (note:Note, showEmoji?:boolean, maxLength?:number)=>JSX.Element;

/*********
 * This is the same as useLinkToNote, but it doesn't use the <Link/> component so that it can be rendered without the router.
 * It also simplifies the URL, because we'll have to reconstitute it later anyways.
 * We don't want to include the extra stuff at the end because the chat bot picks up that stuff and hallucinates links from the other IDs.
 */
export function useLinkToNote_ForEditor(): LinkToNoteFunction {
    const notesContext = useContext(NotesContext);
    const {extensions} = useExtensions();
    return function(note:Note, showEmoji:boolean=true):JSX.Element {
        const query = getQuery();
        query.set("selectedTab","Note");
        let name = "";
        if (showEmoji)
            name = getNoteNameAndEmoji(note,notesContext, extensions);
        else
            name = note.doc_name;
        return <a href={"/note/"+note.id}>{name}</a>
    }
}

export function useLinkToNote(): LinkToNoteFunction {
    const notesContext = useContext(NotesContext);
    const {extensions} = useExtensions();
    return function(note:Note, showEmoji:boolean=true, maxLength?:number):JSX.Element {
        const query = getQuery();
        query.set("selectedTab","Note");
        let name = "";
        if (showEmoji)
            name = getNoteNameAndEmoji(note,notesContext, extensions);
            // name = getNoteNameAndEmoji(note,notesContext);
        else
            name = note.doc_name;
        const url = getURLForNoteId_preserveQuery(query,note.id);
        if (maxLength && name.length>maxLength) {
            return <Popover content={name}><Link to={url}>{name.substring(0,maxLength)+"..."}</Link></Popover>;
        }
        return <Link to={url}>{name}</Link>;
    }
}


export function getSubURLFromValidURL(url:string) {
    const DOMAIN_NAMES = ["http://localhost:3000/","https://aigotthis.com/","https://www.aigotthis.com/","http://192.168.1.164:3000/","/"];

    const domainList = DOMAIN_NAMES.filter((domain:string) => url.startsWith(domain));
    if (domainList.length === 0) {
        return {isValid:false,domain:"",path:"",query:new URLSearchParams()};
    }
    const domain = domainList[0];
    const endOfUrl = url.substring(domain.length);
    let path = "";
    let queryStr = "";
    const queryIndex = endOfUrl.indexOf("?");
    if (queryIndex !== -1) {
        path = endOfUrl.substring(0, queryIndex);
        queryStr = endOfUrl.substring(queryIndex);
    } else {
        path = endOfUrl;
    }
    const query = new URLSearchParams(queryStr);
    return {isValid:true,domain,path,query};
}
// function testGetSubURLFromValidURL() {
//     console.log("[testGetSubURLFromValidURL] Testing getSubURLFromValidURL:");
//     console.assert(getSubURLFromValidURL("http://localhost:3000/note/123")?.path==="note/123","Failed on path, was: "+getSubURLFromValidURL("http://localhost:3000/note/123")?.path);
//     console.assert(getSubURLFromValidURL("http://localhost:3000/note/123?selectedTab=Note")?.query.get("selectedTab")==="Note","Failed on query");
//     console.assert(getSubURLFromValidURL("http://localhost:3000/note/123?selectedTab=Note")?.domain==="http://localhost:3000/","Failed on domain");
//     console.log("[testGetSubURLFromValidURL] Testing complete.");
// }
// testGetSubURLFromValidURL();



export function getNoteIDFromURL(url:string) {
    const {isValid, path} = getSubURLFromValidURL(url);
    if (!isValid)
        return null;
    if (!path.startsWith("note/")) {
        return null;
    }
    let noteId = path.substring(5);
    if (!isAValidNoteID(noteId)) {
        if (noteId==="UUID")
            return null; // We have examples that write this one in example prompts where we don't want real note IDs, so don't log it in the console.
        console.warn("[getNoteIDFromURL] Warning: We have a URL with an invalid note ID. Since our validation check is not thoroughly tested, this could indicate an issue in the validation or a corrupted URL in our system, or just a copy/paste error by the user. noteId:",noteId);
        return null;
    }
    return noteId;
}
// function testGetNoteIDFromURL() {
//     console.log("[testGetNoteIDFromURL] Testing getNoteIDFromURL:");
//     console.assert(getNoteIDFromURL("http://localhost:3000/note/123")===null,"Failed on incorrect note ID");
//     console.assert(getNoteIDFromURL("http://localhost:3000/note/bb0f5c2a-b3dd-4a81-946e-9d31cbfc3428?selectedTab=Note")==="bb0f5c2a-b3dd-4a81-946e-9d31cbfc3428","Failed on note ID with query");
//     console.log("[testGetNoteIDFromURL] Testing complete.");
// }
// testGetNoteIDFromURL();