import { NotifyProgressLoadingNoteHierarchy, TopLevelNoteHierarchy, startLoadingNoteHierarchy } from "./NoteHierarchy";
import { createContext, useContext, useMemo, useState } from "react";
import { PinnedJSONFormsContext, SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { NotesContext } from "../../Data/NotesContext";
import { OPERATION_COMPLETE, OPERATION_SELECT } from "../../../Extensions/ExtensionsFramework/SaveOperationsBasics";
import { useNoteDraftJS } from "../../Data/FirestoreNoteClient";

export type NoteHierarchyContextType = {
    topLevelNoteHierarchy:TopLevelNoteHierarchy | null;

    percentLoadingComplete:number;
    isLoaded:boolean;
}

export const NoteHierarchyContext = createContext({} as NoteHierarchyContextType);

export function NoteHierarchyContextProvider({children}:{children:any}) {
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const pinnedJSONFormsContext = useContext(PinnedJSONFormsContext);
    const notesContext = useContext(NotesContext);
    const [percentComplete,setPercentComplete] = useState(0);
    const [isLoaded,setIsLoaded] = useState(false);
    
    const notifyProgressLoadingNoteHierarchy:NotifyProgressLoadingNoteHierarchy = function(progress:number,isDone:boolean) {
        setPercentComplete(progress);
        setIsLoaded(isDone);
    }

    const pinnedNoteDocData = useNoteDraftJS(pinnedJSONFormsContext.note);
    const selectedNoteDocData = useNoteDraftJS(selectedJsonFormsContext.note);

    const checkIfPinnedNotesJSONSelectHasChanged = pinnedJSONFormsContext.jsonFormsObject && pinnedJSONFormsContext.jsonFormsObject[OPERATION_SELECT];
    const checkIfPinnedNotesJSONCompleteHasChanged = pinnedJSONFormsContext.jsonFormsObject && pinnedJSONFormsContext.jsonFormsObject[OPERATION_COMPLETE];
    const checkIfSelectedJSONFormsSelectHasChanged = selectedJsonFormsContext.jsonFormsObject && selectedJsonFormsContext.jsonFormsObject[OPERATION_SELECT];
    const checkIfSelectedJSONFormsCompleteHasChanged =  selectedJsonFormsContext.jsonFormsObject && selectedJsonFormsContext.jsonFormsObject[OPERATION_COMPLETE]

    const topLevelNoteHierarchy = useMemo(()=>{
        // console.log("[NoteHierarchyProvider] Top notes have changed, starting to process them.");
        // Create a note hierarchy based of both the pinned note & the selected note.
        const doc_ids = [] as string[];
        if (pinnedJSONFormsContext.note && pinnedJSONFormsContext.isJSONFormsLoaded) {
            doc_ids.push(pinnedJSONFormsContext.note.id);
        }
        if (selectedJsonFormsContext.note && selectedJsonFormsContext.isJSONFormsLoaded && selectedJsonFormsContext.note.id!==pinnedJSONFormsContext.note?.id) {
            doc_ids.push(selectedJsonFormsContext.note.id);
        }
        if (doc_ids.length>0) {
            const topLevelNoteHierarchy = startLoadingNoteHierarchy(doc_ids, notesContext, notifyProgressLoadingNoteHierarchy);
            return topLevelNoteHierarchy;
        }
        // Handle case where no notes are selected
        notifyProgressLoadingNoteHierarchy(0, true);
        return null;
    }, [pinnedNoteDocData, checkIfPinnedNotesJSONSelectHasChanged, checkIfPinnedNotesJSONCompleteHasChanged, selectedNoteDocData, checkIfSelectedJSONFormsSelectHasChanged, checkIfSelectedJSONFormsCompleteHasChanged]); // eslint-disable-line react-hooks/exhaustive-deps

    const context = {
        topLevelNoteHierarchy,
        isLoaded,
        percentLoadingComplete:percentComplete,
    } as NoteHierarchyContextType;

    return <NoteHierarchyContext.Provider value={context}>
        {children}
    </NoteHierarchyContext.Provider>;
}

