import { Spin, Select, Tooltip } from "antd";
import { useState } from "react";
import { LLMServerContextProvider, SelectableLLMServerDropdown } from "../../../../DecisionGraph/ChatEditor/UI/SelectableLLM";
import useTopicDetectionV7Command, { TopicsInputV7 } from "./TopicDetectionAIV7";

const tests = [
    {
        name: "Test1",
        topics: ["Full list of this bot's capabilities","How to World Build","How to do math","How to do science"],
        userInput: "what is this bot's capabilities?",
        correctTopics: ["Full list of this bot's capabilities"]
    },
    {
        name: "Test2",
        topics: ["Full list of this bot's capabilities","How to World Build","How to do math","How to do science"],
        userInput: "How should I run an experiment?",
        correctTopics: ["How to do science"]
    }
    // ...add more test objects if desired...
];

export function TopicDetectionV7TestPage() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [output, setOutput] = useState<React.ReactNode>(<></>);
    const [selectedTest, setSelectedTest] = useState<string>("all");
    const [results, setResults] = useState<{name:string;expected:string[];actual:string[]; success:boolean; logic: string}[]>([]);
    const topicDetectionCommand = useTopicDetectionV7Command();

    async function runTestDef(test: typeof tests[0]) {
        setIsLoading(true);
        setOutput(<></>);
        const topicDetectionInput = { topics: test.topics, userInput: test.userInput } as TopicsInputV7;
        const response = await topicDetectionCommand(topicDetectionInput);
        const logic = response.logic?.split("\n").join(" ");
        if (response.succeeded && JSON.stringify(response.topics)===JSON.stringify(test.correctTopics)) {
            setResults(prev => [...prev, {
                name: test.name,
                expected: test.correctTopics,
                actual: response.topics || [],
                success: true,
                logic: logic || ""
            }]);
            return true;
        } else {
            setResults(prev => [...prev, {
                name: test.name,
                expected: test.correctTopics,
                actual: response.topics || [],
                success: false,
                logic: logic || ""
            }]);
            return false;
        }
    }

    async function runAllTests() {
        setResults([]); // reset results before running
        let successCount = 0;
        for (const test of tests) {
            const success = await runTestDef(test);
            if (success) successCount++;
        }
        setIsLoading(false);
        setOutput(`All tests finished: ${successCount}/${tests.length} succeeded.`);
    }

    function runSelectedTest() {
        if (selectedTest==="all") {
            runAllTests();
        } else {
            const test = tests.find(t => t.name.toLowerCase()===selectedTest);
            if (test) runTestDef(test).then(() => setIsLoading(false));
        }
    }

    return <LLMServerContextProvider>
        <div>
            <h1>Topic Detection V7 Test Page</h1>
            Note: This always uses a firebase function, and does not change depending on which LLM is selected by the user.
            <SelectableLLMServerDropdown/>
            <div>
                <Select style={{ width: "150px" }} value={selectedTest} onChange={setSelectedTest}>
                    <Select.Option value="all">Run All Tests</Select.Option>
                    {tests.map(test => (
                        <Select.Option
                            key={test.name}
                            value={test.name.toLowerCase()}
                        >
                            {test.name}
                        </Select.Option>
                    ))}
                </Select>
                <button onClick={runSelectedTest}>Run</button>
            </div>
            {isLoading && <Spin/>}
            {output}
            {results.length > 0 && <table style={{borderCollapse: "collapse"}} border={1} cellPadding={4}>
                <thead>
                    <tr><th>Status</th><th>Test Name</th><th>Expected</th><th>Actual</th><th>Logic</th></tr>
                </thead>
                <tbody>
                    {results.sort((a, b) => a.success === b.success ? 0 : a.success ? 1 : -1).map((r, i)=> 
                        <tr key={i}>
                            <td>{r.success ? "✔" : "❌"}</td>
                            <td>{r.name}</td>
                            <td>{r.expected.join(", ")}</td>
                            <td>{r.actual.join(", ")}</td>
                            <td>
                                <Tooltip title={r.logic}>
                                    <span role="img" aria-label="info">ℹ️</span>
                                </Tooltip>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>}
        </div>
    </LLMServerContextProvider>;
}