import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useStateLSBoolean } from '../StateWithLocalCache';

const MusicRecommendationContext = createContext({
  isMusicRecommendationEnabled: false,
  setIsMusicRecommendationEnabled: (enabled: boolean) => {},
});

export const MusicRecommendationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isMusicRecommendationEnabled, setIsMusicRecommendationEnabled] = useStateLSBoolean('isMusicRecommendationEnabled', true);

  // useMemo(() => {
  //   console.log("Music recommendation enabled: ", isMusicRecommendationEnabled);
  // }, [isMusicRecommendationEnabled]);

  return (
    <MusicRecommendationContext.Provider value={{ isMusicRecommendationEnabled, setIsMusicRecommendationEnabled }}>
      {children}
    </MusicRecommendationContext.Provider>
  );
};

export const useMusicRecommendation = () => useContext(MusicRecommendationContext);
