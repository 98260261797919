import { useContext, useMemo, useState } from "react";
import { ExclusionByContextWindow, InclusionByCommand, InclusionByMention, InclusionByPreviousChat, InclusionByTopic, InclusionByTopLevelHierarchy, NoteExclusionType, NoteInclusionType, PreProcessUserMessageFuncType, PreprocessUserMessageReturnType, PreprocessingStepsType, PostProcessRecievedMessageFuncType, InclusionByIndirectInclude } from "../../../ServerConnection/LLMServer/SendChatToServerHook";
import { NotesContext } from "../../../Notes/Data/NotesContext";
import { LLMServerContext } from "../UI/SelectableLLM";
import { getUniqMarkdownSystemPrompt, NoteAsMarkdown, useLoadNoteAsMarkdown } from "./NotesToMarkdown";
import { NoteHierarchyContext } from "../../../Notes/UIs/NoteInformationComponents/NoteHierarchyProvider";
import { ChatMessage2 } from "../UI/ChatLog";
import { calculateChatVector } from "../../../ServerConnection/LLMServer/ChatVectorCalculation";
import { getLastUserNotesMentionedInChat, getPreviousNoteIDsOfType } from "./PreprocessChatGenericHelpers";
import { NoteHierarchy, startLoadingNoteHierarchy, TopLevelNoteHierarchy } from "../../../Notes/UIs/NoteInformationComponents/NoteHierarchy";
import { isACommandPrompt } from '../../../Extensions/ExtensionsFramework/IsACommandPrompt';
import { detectFromOngoingCommand, detectCommandV2, Command } from "../../../ServerConnection/LLMServer/Prompts/CommandDetectionAI";
import { estimateTokensFromString } from "../../../ServerConnection/LLMServer/LLMTokenCounter";
import { getNoteNameAndEmoji } from "../../../Notes/NotesTree/TreeUtilities/CreateTagTreeNodes";
import { Note } from "../../../Notes/Data/NoteType";
import { getNoteDraftJS } from "../../../Notes/Data/FirestoreNoteClient";
import { COMMENT_BLOCK } from "../../../Notes/UIs/DraftJSEditor/BlockEditing/CommentBlock";
import { NOTE_LINK_ENTITY_TYPE } from "../../../Notes/UIs/DraftJSEditor/BlockEditing/LinkToNoteBlockUtils";
import { LINK_TO_NOTE_BLOCK_TYPE } from "../../../Notes/UIs/DraftJSEditor/BlockEditing/BlockConstants";
import { recommendMusic } from "../../../ServerConnection/LLMServer/Prompts/MusicRecommenderAI";
import { useNavigateToMusic } from "../../Utilities/NavigateTo";
import { useMusicTagsParameters } from "../../Utilities/Sound/FullMusicSoundtrack";
import { isTagGenre } from "../../Utilities/Sound/Soundtrack1";
import { commandCanGenerateMusic } from "../../../Extensions/TTRPG/TTRPGExtension";
import { useMusicRecommendation } from '../../Utilities/Sound/MusicRecommendationContext';
import { useExtensions } from "../../../Extensions/ExtensionsFramework/GetExtension";

const DEBUG_PREPROCESS = false;
const DEBUG_SERVER = false;
const DEBUG_POST_PROCESS_MUSIC = false;

export const CALCULATE_VECTOR_FROM_CHAT = false; // Currently implemented and tested, but not used anywhere.

const PREPROCESSING_STEP_1 = "Select command & topics";
const PREPROCESSING_STEP_2 = "Load context";
const PREPROCESSING_STEPS_V10 = [PREPROCESSING_STEP_1, PREPROCESSING_STEP_2];

const INCLUSION_USE_ALL_COMMANDS = false; // if set to true, command detection won't be used at all. That means the only way to mention a command is for the user to manually link to it.
const INCLUSION_USE_ALL_TOPICS = true;

// const EXCLUSION_REMOVE_LINKS = true;
const EXCLUSION_EXPLAIN_APPROACH_IN_PROMPT = true;
const EXCLUSION_REMOVE_PARAGRAPHS = false;

const DEBUG_EXCLUSION_REMOVE_PARAGRAPHS = true;

// DraftJS currently only supports one level of bullets. This adds to the system prompt to warn the LLM not to use nested bullets.
const PROMPT_NO_INDENTED_BULLETS = true;


// NEW: Helper function that receives an array of excluded note names and returns the exclusion instruction.
export function getNoteExclusionPrompt(notesExcluded: string[]): string {
	if (!notesExcluded || notesExcluded.length === 0) return "";
	const list = notesExcluded.map(name => `- ${name}`).join("\n");
	return "\n\nThese topics & names are reserved for other purposes. Do not use them to generate this response, you don't have enough information to use these. Do not use these names and make sure that any newly introduced names sound different:\n" + list;
}

/********
 * Check whether there's any point in including this Note as a topic.
 * If it doesn't have any content, we don't include it in the topic list.
 */
function isNoteParentOnly(note: Note) {
    // Check through the doc_data.blocks.
    // If a block is the first line, it's not counted (it's the title)
    // If a line is blank, it's not counted.
    // If a line is a comment, it's not counted
    // If a line completely contains just a link to another note, it's not counted.

    const doc_data = getNoteDraftJS(note);
    // Skip the first block, which is the title
    for (let i = 1; i < doc_data.blocks.length; i++) {
        const block = doc_data.blocks[i];
        let text = block.text.trim();
        if (block.type === COMMENT_BLOCK) continue; // comment block
        if (block.type === LINK_TO_NOTE_BLOCK_TYPE) continue; // link to note block
        for (const entity of block.entityRanges) {
            const entityType = doc_data.entityMap[entity.key].type;            
            if (entityType === NOTE_LINK_ENTITY_TYPE) {
                text = text.replace(text.substring(entity.offset, entity.offset + entity.length), '').trim();
            }
        }
        if (text.trim().length > 0) {
            return false; // found content
        }
    }
    return true; // no content found
}

export function processNoteIDs(
    noteHierarchy: NoteHierarchy, 
    topLevelNoteHierarchy: TopLevelNoteHierarchy, 
    noteInclusions: NoteInclusionType[], 
    isFullyIncluded: boolean, 
    noteIDsFromPreviousChats: string[],
    notesContainingCommands: Note[], // to collect parent notes for commands
    parent?: NoteHierarchy // parent of noteHierarchy
) {

    if (noteInclusions.find(note => note.noteID === noteHierarchy.note.id)) {
        if (isFullyIncluded && !noteInclusions.find(note => note.noteID === noteHierarchy.note.id && note.type === InclusionByTopLevelHierarchy)) {
            // Exception, reprocess it as a top level note.
        } else {
            return;
        }
    }

    if (isFullyIncluded) {
        if (!isNoteParentOnly(noteHierarchy.note))
            // Don't print it as included if it's blank, b/c it has no useful content
            noteInclusions.push({ type: InclusionByTopLevelHierarchy, noteID: noteHierarchy.note.id });

        for (const noteID of noteHierarchy.fullyIncludedNoteIDs) {
            const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
            if (!nextNoteHierarchy) continue;
            processNoteIDs(nextNoteHierarchy, topLevelNoteHierarchy, noteInclusions, true, noteIDsFromPreviousChats, notesContainingCommands, noteHierarchy);
        }
        for (const noteID of noteHierarchy.linkedNoteIDs) {
            const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
            if (!nextNoteHierarchy) continue;
            processNoteIDs(nextNoteHierarchy, topLevelNoteHierarchy, noteInclusions, false, noteIDsFromPreviousChats, notesContainingCommands, noteHierarchy);
        }
        for (const noteID of noteHierarchy.editContextNotes) {
            const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
            if (!nextNoteHierarchy) continue;
            processNoteIDs(nextNoteHierarchy, topLevelNoteHierarchy, noteInclusions, false, noteIDsFromPreviousChats, notesContainingCommands, noteHierarchy);
        }
    } else {
        const isACommand = isACommandPrompt(noteHierarchy.note);
        const inclusionType = isACommand ? InclusionByCommand : InclusionByTopic;
        if (!noteIDsFromPreviousChats.includes(noteHierarchy.note.id)) {
            const commandOrNonEmptyTopic = isACommand || !isNoteParentOnly(noteHierarchy.note);
            if (commandOrNonEmptyTopic) {
                // if (inclusionType === InclusionByCommand && noteHierarchy.note.id === "1a4a5e5a-9acb-4131-802c-e78dd6a04699") {
                //     debugger; // This is the note named doc_name==="Summarize a story or encounter for GM before using it (command)"
                // }
                noteInclusions.push({ type: inclusionType, noteID: noteHierarchy.note.id });
            }
            // If the note is a command and has a parent, add the parent's note to notesContainingCommands.
            if (isACommand && parent && !notesContainingCommands.find(n => n.id === parent.note.id)) {
                notesContainingCommands.push(parent.note);
            }
        }
    }
}


// Modified organizeNotesInTopLevelNoteHierarchyV10 to return notesContainingCommands.
export function organizeNotesInTopLevelNoteHierarchyV10(
    topLevelNoteHierarchy: TopLevelNoteHierarchy | null, 
    noteIDsFromPreviousChats: string[]
): { uniqueNoteInclusions: NoteInclusionType[], notesContainingCommands: Note[] } {
    if (!topLevelNoteHierarchy) {
        return { uniqueNoteInclusions: [], notesContainingCommands: [] };
    }

    const noteInclusions = [] as NoteInclusionType[];
    const notesContainingCommands = [] as Note[];

    for (const noteHierarchy of topLevelNoteHierarchy.topNoteHierarchies) {
        processNoteIDs(noteHierarchy, topLevelNoteHierarchy, noteInclusions, true, noteIDsFromPreviousChats, notesContainingCommands /* parent is undefined */);
    }

    // Remove duplicates with priority
    const uniqueNoteInclusions = Object.values(noteInclusions.reduce((acc, note) => {
        if (!acc[note.noteID] || 
            (acc[note.noteID].type !== InclusionByTopLevelHierarchy && note.type === InclusionByTopLevelHierarchy) ||
            (acc[note.noteID].type !== InclusionByCommand && note.type === InclusionByCommand)) {
            acc[note.noteID] = note;
        }
        return acc;
    }, {} as Record<string, NoteInclusionType>));

    return { uniqueNoteInclusions, notesContainingCommands };
}

let SERVER_TYPE_WAS_INITIALIZED = false;

export function usePreprocessUserMessageV10(): {preProcess_UserMessage: PreProcessUserMessageFuncType, postProcess_RecievedMessage: PostProcessRecievedMessageFuncType} {
    const notesContext = useContext(NotesContext);
    const { serverType } = useContext(LLMServerContext);
    const { topLevelNoteHierarchy } = useContext(NoteHierarchyContext);
    const { getExtensionsWithAdditionalNotes } = useExtensions();

    useMemo(()=>{
        if (serverType) {
            if (!SERVER_TYPE_WAS_INITIALIZED) {
                SERVER_TYPE_WAS_INITIALIZED = true;
                if (DEBUG_SERVER) console.log("Server type has been initialized this session.", serverType);
            }    
            return;
        }
        if (SERVER_TYPE_WAS_INITIALIZED) {
            console.error("BUG: Server type was previously initialized, but is now empty!! Look for hints as to why this might be happening.");
            debugger;
        }
    },[serverType]);

    const loadNoteAsMarkdown = useLoadNoteAsMarkdown();

    const [lastCommands, setLastCommands] = useState<Note[]>([]);
    const { isMusicRecommendationEnabled } = useMusicRecommendation();

    async function preProcess_UserMessage(messages: ChatMessage2[], setStepStarted: (step: string) => void, setStepComplete: (step: string) => void, setPreprocessingSteps: (steps: PreprocessingStepsType) => void): Promise<PreprocessUserMessageReturnType> {
        setPreprocessingSteps({ stepsInOrder: PREPROCESSING_STEPS_V10, progress: {} });
        if (DEBUG_PREPROCESS) console.log("[preprocessUserMessageV10] Starting the preprocessing function");
        // if (!topLevelNoteHierarchy) {
        //     console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> No notes are pinned nor selected. TODO we need to handle this case.");
        //     debugger;
        //     throw new Error("topLevelNoteHierarchy not found.");
        // }
        if (!serverType) {
            // Give it one second to load the context:
            await new Promise((resolve) => setTimeout(resolve, 1000));
            if (!serverType) {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: Server type not found. This should not happen.");
                debugger;
                throw new Error("Server type not found.");
            }
        }

        // Add vector calculation early in the function
        if (CALCULATE_VECTOR_FROM_CHAT) {
            /*await*/ calculateChatVector(messages);
        }

        const noteIDsMentionedInChat = getLastUserNotesMentionedInChat(messages);
        const noteIDsFromPreviousChats = getPreviousNoteIDsOfType(messages, undefined, [InclusionByCommand]);

        // Finish loading before adding on the new stuff. Slower, but more reliable because it avoids race conditions. (TODO we might be able to optimize this into a single wait for the second one, potentially)
        if (topLevelNoteHierarchy && !topLevelNoteHierarchy.isLoaded)
            await topLevelNoteHierarchy.finishLoadingPromise;

        const topLevelNoteHierarchy2 = await (async ()=> {
            // Are there other groups we should check?
            const noteIDsToCheck = [...noteIDsMentionedInChat, ...noteIDsFromPreviousChats];
            // if (DEBUG_POST_PROCESS_MUSIC && noteIDsFromPreviousChats.length>0 && noteIDsMentionedInChat.length==0)
                // debugger;
            if (noteIDsToCheck.length === 0)
                // There are no additional notes to look up that aren't already in topLevelNoteHierarchy
                return topLevelNoteHierarchy;
            // Are they already in topLevelNoteHierarchy?
            const anyNotAlreadyIn = !topLevelNoteHierarchy || noteIDsToCheck.some(id => !topLevelNoteHierarchy.topNoteHierarchies.find(nh => nh.note.id === id));
            if (anyNotAlreadyIn) {
                // There are some notes not in the top level hierarchy.
                const topLevelNoteHierarchy2_inner = topLevelNoteHierarchy?startLoadingNoteHierarchy(noteIDsToCheck, notesContext, undefined, topLevelNoteHierarchy):startLoadingNoteHierarchy(noteIDsToCheck, notesContext);
                if (!topLevelNoteHierarchy2_inner.isLoaded)
                    await topLevelNoteHierarchy2_inner.finishLoadingPromise;
                return topLevelNoteHierarchy2_inner;
            }
            // There are notes, and they're already in the default topLevelNoteHierarchy.
            return topLevelNoteHierarchy;
        })();

        const {uniqueNoteInclusions:possibleInclusions, notesContainingCommands} = organizeNotesInTopLevelNoteHierarchyV10(topLevelNoteHierarchy2, noteIDsFromPreviousChats);

        let commandIDs = [] as string[];
        let contextIDs = [] as string[];
        {
            ///////////////////////////////////////
            // Detect commands

            // Convert note IDs to note names
            setStepStarted(PREPROCESSING_STEP_1);
            let checkForTheseCommandNoteNames = [] as string[];
            let commandNotesMarkdown = "";

            // If any of the noteIDsMentionedInChat is a command, we should use those
            noteIDsMentionedInChat.forEach(noteID => {
                const note = notesContext.getLoadedNote(noteID, true);
                if (note && isACommandPrompt(note))
                    commandIDs.push(noteID);
            });
            if (commandIDs.length > 0) {
                // No command detection needed... (but we'll still detect topics)
                // Remove the command from the noteIDsMentionedInChat:
                const index = noteIDsMentionedInChat.indexOf(commandIDs[0]);
                if (index > -1) {
                    noteIDsMentionedInChat.splice(index, 1);
                }
                // console.log("Detected command(s) from noteIDsMentionedInChat: ");
                // // Print the names of the commands with an indent:
                // commandIDs.forEach(commandID => {
                //     const note = topLevelNoteHierarchy2.noteHierarchiesById[commandID];
                //     if (note)
                //         console.log("  ", note.note.doc_name);
                // });
            } else {
                if (INCLUSION_USE_ALL_COMMANDS) {
                    commandIDs = possibleInclusions.filter(item => item.type === InclusionByCommand).map(item => item.noteID);
                } else {
                    const commandNotes = [];
                    // Check for commands from the hierarchy:
                    const possibleCommandInclusionTypes_FromHierarchy = possibleInclusions.filter(item => item.type === InclusionByCommand);
                    const commandNotes_FromHierarchy = possibleCommandInclusionTypes_FromHierarchy.map(item => notesContext.getLoadedNote(item.noteID, true));
                    commandNotes.push(...commandNotes_FromHierarchy);
                    if (possibleCommandInclusionTypes_FromHierarchy.length > 0 && commandNotes_FromHierarchy.length === 0) {
                        console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: There are some commands in the possibleInclusions, but they are not loaded. This should not happen.");
                        debugger;
                    }

                    // Check for commands from the notes that are already loaded:
                    const commandNotes_FromKnownIncluded = noteIDsFromPreviousChats.map(id => notesContext.getLoadedNote(id, true)).filter(note => note && isACommandPrompt(note));
                    commandNotes.push(...commandNotes_FromKnownIncluded);
                    if (DEBUG_POST_PROCESS_MUSIC && commandNotes.length===0) {
                        console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: There are no commands in the notes that are already loaded. This should not happen.");
                        debugger;
                    }

                    checkForTheseCommandNoteNames = commandNotes.map(note => note.doc_name);
                    for (const note of notesContainingCommands) {
                        const notePrompt = await loadNoteAsMarkdown(note, true, [], []);
                        commandNotesMarkdown += "\n\n"+notePrompt.markdownPrompt;
                    }
                    commandNotesMarkdown = commandNotesMarkdown.trim();
                }
            }
            let contextNoteNames = [] as string[];
            if (INCLUSION_USE_ALL_TOPICS) {
                contextIDs = possibleInclusions.filter(item => item.type === InclusionByTopic).map(item => item.noteID);
            } else {
                contextNoteNames = possibleInclusions.filter(item => item.type === InclusionByTopic).map(item => notesContext.getLoadedNote(item.noteID,true).doc_name);
            }

            if (checkForTheseCommandNoteNames.length > 0 || contextNoteNames.length > 0) {
                if (DEBUG_PREPROCESS || DEBUG_POST_PROCESS_MUSIC) console.log("Calling the detect command AI...");
                let detectedCommands = undefined as Command | undefined;
                // Check whether any of the commands are already in noteIDsFromPreviousChats
                const currentComandIDs = getPreviousNoteIDsOfType(messages, InclusionByCommand);
                if (currentComandIDs.length > 0) {
                    const currentCommandId = possibleInclusions.find(item => item.noteID === currentComandIDs[0])?.noteID;
                    const currentCommandName = currentCommandId ? notesContext.getLoadedNote(currentCommandId, true)?.doc_name:null;
                    if (currentCommandName)
                         detectedCommands = await detectFromOngoingCommand(currentCommandName, checkForTheseCommandNoteNames, contextNoteNames, messages, commandNotesMarkdown, serverType);
                    if (DEBUG_PREPROCESS)
                        console.log("Detected commands from ongoing command: ", detectedCommands, "(previous command was '", currentCommandName, "')");
                }
                if (!detectedCommands)
                    detectedCommands = await detectCommandV2(checkForTheseCommandNoteNames, contextNoteNames, messages, commandNotesMarkdown, serverType);

                if (detectedCommands.commands?.length > 0)
                    commandIDs = detectedCommands.commands.map(name => {
                        const note = notesContext.loadedNotes.find(n => n.doc_name === name);
                        return note ? note.id : name;
                    });
                if (detectedCommands.context?.length > 0)
                    contextIDs = detectedCommands.context.map(name => {
                        const note = notesContext.loadedNotes.find(n => n.doc_name === name);
                        return note ? note.id : name;
                    });
            } else {
                // No commands or topics to detect.
                if (DEBUG_PREPROCESS || DEBUG_POST_PROCESS_MUSIC) console.log("No commands or topics to detect.");
            }
            setStepComplete(PREPROCESSING_STEP_1);
            if (DEBUG_PREPROCESS) console.log("Finished step 1...");
        }


        ///////////////////////////////////////
        // Get ready to finish up the system prompts
        setStepStarted(PREPROCESSING_STEP_2);
        const notesIncluded = [] as NoteInclusionType[];
        const notesExcluded = [] as NoteExclusionType[];
        const promises = [] as Promise<void>[];
        const notePrompts = [] as NoteAsMarkdown[];

        // Inline function to add notes to promptPromises
        async function addNotesToPromptPromises(noteIDs: string[], inclusionType: NoteInclusionType['type']) {
            for (const noteID of noteIDs) {
                // Check if it's already included:
                if (notesIncluded.find(note => note.noteID === noteID)) {
                    // TODO if the inclusion type is higher priority, we want to update it.
                    continue;
                }
                notesIncluded.push({ type: inclusionType, noteID });

                // TODO could this be done using just topLevelNoteHierarchy2.noteHierarchiesById[id].note ?
                const loadNote = async () => {
                    const note = await notesContext.loadNoteID(noteID, true);
                    const notePrompt = await loadNoteAsMarkdown(note, true, [], []);
                    notePrompts.push(notePrompt);
                };
                promises.push(loadNote());

                // Check its children have any fully included notes.
                if (!topLevelNoteHierarchy2) {
                    console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: Somehow we got a note even though topLevelNoteHierarchy2 was not initialized, which suggests that the logic around creating topLevelNoteHierarchy2 above is incorrect.");
                    debugger;
                    continue;
                }
                const noteHierarchy = topLevelNoteHierarchy2.noteHierarchiesById[noteID];
                if (noteHierarchy && noteHierarchy.fullyIncludedNoteIDs.length > 0) {
                    promises.push(addNotesToPromptPromises(noteHierarchy.fullyIncludedNoteIDs, InclusionByIndirectInclude));
                }
            }
        }
        // In order by most specific first. Command should be first so the commands that are mentioned elsewhere don't overwrite the "command" in the notesIncluded since it can be in more than one place.
        await addNotesToPromptPromises(commandIDs, InclusionByCommand);
        await addNotesToPromptPromises(noteIDsMentionedInChat, InclusionByMention);
        await addNotesToPromptPromises(noteIDsFromPreviousChats, InclusionByPreviousChat);
        await addNotesToPromptPromises(possibleInclusions.filter(item => item.type === InclusionByTopLevelHierarchy).map(item => item.noteID), InclusionByTopLevelHierarchy);
        await addNotesToPromptPromises(contextIDs, InclusionByTopic);

        const loadedCommands = commandIDs
            .map(id => notesContext.getLoadedNote(id, true))
            .filter(note => note);
        setLastCommands(loadedCommands);
        if (DEBUG_POST_PROCESS_MUSIC) console.log("[PreprocessV10CommandContext] setLastCommands called with:", loadedCommands, "from ", commandIDs);

        await Promise.all(promises);
        setStepComplete(PREPROCESSING_STEP_2);
        if (DEBUG_PREPROCESS) console.log("Finished step 2...");

        // If there's too much in the context, 
        let tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(notePrompts));
        while (tokensUsed > serverType.contextLength - 5000) {
            // Remove system prompts from last to first, until we have some space left.
            const removedPrompt = notePrompts.pop();
            if (removedPrompt) {
                notesExcluded.push({ type: ExclusionByContextWindow, noteID: removedPrompt.doc_id });
            } else {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: We ran out of prompts to remove, but we still have too many tokens.");
                break;
            }
            tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(notePrompts));
        }

        // Populate notesExcluded with topics and commands not included in the system prompt
        const includedNoteIDs = notePrompts.map(prompt => prompt.doc_id);
        possibleInclusions.filter(item => item.type === InclusionByTopic).forEach(({ noteID }) => {
            if (!includedNoteIDs.includes(noteID) && !notesExcluded.find(note => note.noteID === noteID)) {
                notesExcluded.push({ type: InclusionByTopic, noteID });
            }
        });
        possibleInclusions.filter(item => item.type === InclusionByCommand).forEach(({ noteID }) => {
            if (!includedNoteIDs.includes(noteID) && !notesExcluded.find(note => note.noteID === noteID)) {
                notesExcluded.push({ type: InclusionByCommand, noteID });
            }
        });

        // Add one more system prompt with links to all the documents that are included or could be included here.
        let linksStr = "# Links to all notes\nWhenever you refer to any of these topics, use the link instead of plain text.\n";
        const extensions = getExtensionsWithAdditionalNotes(includedNoteIDs);
        // Print links to included notes
        notesIncluded.forEach(noteInclusion => {
            if (noteInclusion.type === InclusionByCommand) return; // don't print commands
            const note = notesContext.getLoadedNote(noteInclusion.noteID, true);
            if (!note) return; // skip if note not loaded
            if (isACommandPrompt(note)) return; // don't print commands
            linksStr += "[" + getNoteNameAndEmoji(note, notesContext, extensions) + "](</note/" + noteInclusion.noteID + ">) ";
        });
        // This would print 
        // for (const [noteID, noteHierarchy] of Object.entries(topLevelNoteHierarchy.noteHierarchiesById)) {
        //     if (EXCLUSION_REMOVE_LINKS && notesExcluded.find(note => note.noteID === noteID)) continue;
        //     linksStr += "[" + getNoteNameAndEmoji(noteHierarchy.note, notesContext, extensions) + "](</note/" + noteID + ">) ";
        // }
        const linkPrompt = {
            doc_name: "Links to all notes",
            doc_id: "links",
            type: "link",
            emoji: "🔗",
            markdownPrompt: linksStr,
            isTemplate: false
        } as NoteAsMarkdown;
        notePrompts.push(linkPrompt);
        let systemPrompt = getUniqMarkdownSystemPrompt(notePrompts);

        // Remove paragraphs refering to the excluded notes, whenever there's a markdown link
        if (EXCLUSION_REMOVE_PARAGRAPHS) {
            const excludedNoteIDs = notesExcluded.map(note => note.noteID);
            systemPrompt = systemPrompt.split("\n").filter(paragraph => {
                const links = paragraph.match(/\[([^\]]+)\]\(\/note\/([^\)]+)\)/g) || [];
                if (links.length === 0) return true;
                const linksAreOnlyExcludedNotes = links.every(link => {
                    const noteID = link.match(/\[([^\]]+)\]\(\/note\/([^\)]+)\)/)?.[2];
                    const linkIsExcludedNote = noteID && excludedNoteIDs.includes(noteID);
                    return linkIsExcludedNote;
                });
                if (DEBUG_EXCLUSION_REMOVE_PARAGRAPHS && linksAreOnlyExcludedNotes) {
                    const idsOfUsedExcludedNotes = links.map(link => {
                        const noteID = link.match(/\[([^\]]+)\]\(\/note\/([^\)]+)\)/)?.[2];
                        return noteID && excludedNoteIDs.includes(noteID) ? noteID : null;
                    }).filter((noteID:string|null) => !!noteID) as string[];
                    const namesOfUsedExcludedNotes = idsOfUsedExcludedNotes.map(noteID => {
                        const note = notesContext.getLoadedNote(noteID, false);
                        return note ? note.doc_name : null;
                    }).filter((noteName:string|null) => !!noteName) as string[];
                    console.log("Paragraph excluded because it refers to only excluded notes: ", paragraph, namesOfUsedExcludedNotes);
                }
                return !linksAreOnlyExcludedNotes;
                // const hasNoExcludedNotes = links.every(link => {
                //     const noteID = link.match(/\[([^\]]+)\]\(\/note\/([^\)]+)\)/)?.[2];
                //     const linkIsNotExcludedNote = !noteID || !excludedNoteIDs.includes(noteID);
                //     return linkIsNotExcludedNote;
                // });
                // return hasNoExcludedNotes;
            }).join("\n");
        }
        // Append exclusion instruction
        if (EXCLUSION_EXPLAIN_APPROACH_IN_PROMPT && notesExcluded.length > 0) {
            const exclusionIds = notesExcluded.map(n => n.noteID);
            const exclusionNames = exclusionIds.map(id => {
                const name = notesContext.getLoadedNote(id,false)?.doc_name;
                return name;
            }).filter(n => n);
            systemPrompt += getNoteExclusionPrompt(exclusionNames);
        }
        if (PROMPT_NO_INDENTED_BULLETS)
            systemPrompt = systemPrompt+"\n\n When responding to this prompt and using bullets, use only one level of bullets. Avoid multi-level bullets. Do not use nested bullets. If you need more sections, consider using **Bold** on a line by itself to separate sections.";

        if (DEBUG_PREPROCESS) console.log("Finished loading prompts, just streaming the chat");

        return {
            systemPrompt,
            outputOfTopicsThinking: "",
            notesIncluded,
            notesExcluded,
        } as PreprocessUserMessageReturnType;
    }
    const navigateToMusic = useNavigateToMusic();
    const [tags, setTags] = useMusicTagsParameters();

    const postProcess_RecievedMessage: PostProcessRecievedMessageFuncType = async function postProcess_RecievedMessage_V10(completedMessage: string): Promise<void> {
        if (!isMusicRecommendationEnabled) {
            if (DEBUG_POST_PROCESS_MUSIC) console.log("Music recommendations are disabled.");
            return;
        }
        // TODO check if the command includes one of our interactive stories
        if (lastCommands.length === 0) {
            if (DEBUG_POST_PROCESS_MUSIC) {
                // This is currently a bug.
                console.error("No commands found in the last messages to postprocess.");
                // debugger;
            }
            return;
        }
        const isAnInteractiveStory = lastCommands.some(note => commandCanGenerateMusic(note.id));
        if (!isAnInteractiveStory) {
            return;
        } else {
            if (DEBUG_POST_PROCESS_MUSIC) console.log("Interactive story detected, recommending music.");
        }

        // TODO we need to pass in more information. It can't tell the genre from the last message.

        const genres = tags.filter(isTagGenre);
        if (DEBUG_POST_PROCESS_MUSIC) {
            if (genres.length>0)
                console.log("There are some existing genres: ", genres, "from ",tags);
            else
                console.log("There are no existing genres in tags: ", tags);
        }

        const musicInfo = await recommendMusic(completedMessage, genres[0], serverType);
        if (musicInfo.tags.length === 0) {
            if (DEBUG_POST_PROCESS_MUSIC) console.log("The AI did not recommend any music: ", musicInfo);
            return;
        }
        if (DEBUG_POST_PROCESS_MUSIC) console.log("Recommended soundtrack: ", musicInfo);
        // TODO display a checkbox on whether to autoplay music or not in ChatWithDraft
        navigateToMusic(musicInfo.tags);
    }

    return {preProcess_UserMessage, postProcess_RecievedMessage};
}
