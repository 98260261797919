import { DiscardableCard, PositiveNumberTo20 } from "./GameCardTypes";

import PlaceholderImg from "./CardImages/SD/Barricade.png";
import { getD4D20D100ForAvgRoll } from "./DiceNotation";
import { getDamagePerSpace } from "./CardPoints";
import { produceLevelCards } from "./ReusableCards";

export const DISCARD_THIS = "🗑 Discard this.";
export const CROSS_THIS_OFF = "🗑 Cross this off."; // only used in GameCardTypes.tsx 

/***************
 * Note: "Discard this" is exact text that is exactly replaced in GameCardTypes -- when it's a character sheet it gets changed to "Cross this off".
 * This is a weak design pattern, especially if we internationalize. Consider an incoming parameter to a function that creates the text.
 */

export function HealingPotion(level: PositiveNumberTo20): DiscardableCard {
    const healPerAffectedSpace = getDamagePerSpace(level, 0, 1, 1, 3/*put it at the equivalent of a HealingTouch*/,0/*less powerful than healing touch*/);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(healPerAffectedSpace);
    return {
        name: "Healing Potion "+level,
        actionType: "enhance",
        image: PlaceholderImg,

        goldCost: 3*level,
        minLevel: level,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange: 0,
        maxRange: 1,
        dicePerAffectedSpace: dicePerAffectedSpace,

        commandAreaString: `You 🎯`,

        commandString: `💨 This does not use up your turn.\n\n🤝 Select an ally 0-1 space away (even you) heals 🧡 **%dice%**.\n\n${DISCARD_THIS}`,
    };
}
export function EnergyPotion(level: PositiveNumberTo20): DiscardableCard {
    const healPerAffectedSpace = getDamagePerSpace(level, 0, 1, 1, 3/*put it at the equivalent of a HealingTouch*/,0/*less powerful than healing touch*/);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(healPerAffectedSpace);
    return {
        name: "Energy Potion "+level,
        actionType: "enhance",
        image: PlaceholderImg,

        goldCost: 6*level,
        minLevel: (level*2+1) as PositiveNumberTo20, // Energy potion is rare, so it's a higher level than healing potion

        moveSpaces: 0,
        spacesAffected: 1,
        minRange: 0,
        maxRange: 1,
        dicePerAffectedSpace: dicePerAffectedSpace,

        commandAreaString: `You 🎯`,

        commandString: `💨 This does not use up your turn.\n\n🤝 Select an ally 0-1 space away (even you) gains `+(`⚡`.repeat(level))+` `+level+` Energy.\n\n${DISCARD_THIS}`,
    };
}
export function SkillBoost(level: PositiveNumberTo20): DiscardableCard {
    return {
        name: "Skill Boost "+level,
        actionType: "enhance",
        image: PlaceholderImg,

        goldCost: 4*level,
        minLevel: level+1 as PositiveNumberTo20,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange: 0,
        maxRange: 1,

        commandAreaString: `You 🎯`,

        commandString: `💨 This does not use up your turn.\n\n🤝 Select an ally 0-1 space away (even you) next skill roll gains "+level+" extra dice.\n\n${DISCARD_THIS}`,
    };
}

export function Encouragement(level: PositiveNumberTo20): DiscardableCard {
    return {
        name: "Encouragement "+level,
        actionType: "enhance",
        image: PlaceholderImg,

        goldCost: 6*level, // deliberately expensive relative to the power
        minLevel: level+1 as PositiveNumberTo20,

        moveSpaces: 0,
        spacesAffected: 10,
        minRange: 0,
        maxRange: 10,

        commandAreaString: ``,

        commandString: `💨🎲 Play this at the beginning of the player's round in battle. Add +2 to each player's next roll, this round only. (This does not use up your turn. You can't use it outside of battle).\n\n${DISCARD_THIS}`,
    };
}

export function IcyWaterFlask(level: PositiveNumberTo20): DiscardableCard {
    return {
        name: "Icy Water Flask "+level,
        actionType: "enhance",
        image: PlaceholderImg,

        goldCost: 2*level,
        minLevel: level as PositiveNumberTo20,

        moveSpaces: 0,
        spacesAffected: 3,
        minRange: 1,
        maxRange: 3,

        commandAreaString: `...`,

        commandString: `💨 This does not use up your turn.\n\n`+
            `🎯 Target enemies in a 3x1 line 0-2 spaces away from you. Anything in those squares (even an ally) takes 🧡 **1d4-1 cold water** damage. They are 💦 **Wet**.\n\n`+
            `When 💦 **Wet**: ❄️ **cold* damage will be doubled, and no longer wet.\n\n`+
            `${DISCARD_THIS}`,
    };
}

export function OilFlask(level: PositiveNumberTo20): DiscardableCard {
    return {
        name: "Oil Flask "+level,
        actionType: "enhance",
        image: PlaceholderImg,

        goldCost: 2*level,
        minLevel: level as PositiveNumberTo20,

        moveSpaces: 0,
        spacesAffected: 3,
        minRange: 1,
        maxRange: 3,

        commandAreaString: `...`,

        commandString: `💨 This does not use up your turn.\n\n`+
            `🎯 Target enemies in a 3x1 line 0-2 spaces away from you. Anything in those squares (even an ally) takes 🔥 **1d4-1 fire** damage. They are 🛢 **Oily**.\n\n`+
            `When 🛢 **Oily**: 🔥 **fire* damage will be doubled, and no longer oily.\n\n`+
            `${DISCARD_THIS}`,
    };
}

export const AllDiscardableCardFunctions=[
    HealingPotion,EnergyPotion, SkillBoost, Encouragement, IcyWaterFlask, OilFlask
] as ((level:PositiveNumberTo20)=>DiscardableCard)[];

export function getAllDiscardableCards(level: PositiveNumberTo20): DiscardableCard[] {
    const cards = [] as DiscardableCard[];
    for (const cardFunction of AllDiscardableCardFunctions) {
        cards.push(...produceLevelCards<DiscardableCard>(cardFunction,level))
    }
    return cards;
}