import { ReusableCard, ReusableCardFunc, XPAtLeast0, XPAtLeast1 } from "./GameCardTypes";

import ArrowImg from "./CardImages/SD/Arrow.png";
import BarricadeImg from "./CardImages/SD/Barricade.png";
import FightingPunchImg from "./CardImages/SD/Fighting_Punch.png";
import WizardryFireImg from "./CardImages/SD/Wizardry_Fire.png";
import WizardryIceImg from "./CardImages/SD/Wizardry_Ice.png";
import AthleticRunImg from "./CardImages/SD/Athletic_Run.png";
import TelepathMindControlImg from "./CardImages/SD/Telepath_MindControl.png";
import BarbaricAxeSwingImg from "./CardImages/SD/Barbaric_AxeSwing.jpg";
import DragonbornImg from "./CardImages/SD/Dragonborn.png";
import DisciplineStealthImg from "./CardImages/SD/Discipline_Stealth.png";
import DestructExplodeImg from "./CardImages/SD/Destruct_Explode.png";
import HealingImg from "./CardImages/SD/Healing.jpg";
import RestImg from "./CardImages/SD/Rest.jpg";

import DustDevilsVeilImg from "./CardImages/Copilot/DustDevilsVeil.png";

// import PlaceholderImg from "./CardImages/SD/Barricade.png";

import { getD4D20D100ForAvgRoll, getDiceStr } from "./DiceNotation";
import { getDamagePerSpace } from "./CardPoints";

function getMoveCommandString(level:number):string {
    if (level===1)
        return "🦶 Move **%move%** spaces. (You may move 0-%move% spaces, diagonal counts as 1).\n\n";
    if (level<=3)
        return "🦶 Move **%move%** spaces.\n\n";
    // TODO we need more work to figure out how to compress further. This is a very short line, but it's useless shortening it this much.
    // return "🦶 **%move%**. ——— ";
    // return "🦶 **%move%**.\n\n";
    return "🦶 Move **%move%**.\n\n";
}

function get1EnergyString(level:XPAtLeast1):string {
    if (level===1)
        return "⚡ (Spend 1 energy): ";
    return "⚡: ";
}
function getTargetString(level:XPAtLeast1, lowercase:boolean=false):string {
    if (level<=3) {
        if (lowercase)
            return "🎯 target ";
        return "🎯 Target ";
    }
    return "🎯 ";
}

function getWidthAndHeightForAreaLevel(areaLevel:XPAtLeast0):{width:number,height:number} {
    // Calculate a width & height that's matches at least the targetArea
    // targetArea 0-3 = 3x1
    // targetArea 4-5 = 2x2
    // targetArea 6-7 = 2x3
    // targetArea 8 = 2x4
    // targetArea 9 = 3x3
    const targetArea = 3 + areaLevel;
    const width = Math.ceil(Math.sqrt(targetArea));
    const height = Math.ceil(targetArea/width);
    return {width,height};
}

function getAdjacentLineAreaString(spacesAffected:number, targetEmoji:string="🎯"):string {
    // The first affected space goes below the You.
    // After that, every other affected space goes above.
    // Pattern: Yx, xYx, xYxx, xxYxx, etc. 
    let commandAreaString = "";
    for (let i = 0; i < spacesAffected; i++) {
        /* We detect the middle space using the following algorithm:
         * 1. If there is only one space, it is the middle space.
         * 2. If there are two spaces, the first one is the middle space.
         * 3. If there are three spaces, the second one is the middle space.
         * 4. If there are four spaces, the second one is the middle space.
         * 5. If there are five spaces, the third one is the middle space.
         * 6. If there are six spaces, the third one is the middle space.
         * 7. If there are seven spaces, the fourth one is the middle space.
         * etc.
         * Generalized, that means the middle space is the (spacesAffected+1)/2 space, then because /2 is integer division, we subtract 1.
         */
        const isMiddleSpace = i === Math.floor((spacesAffected-1)/2);
        if (isMiddleSpace) {
            commandAreaString += "You "+targetEmoji+"\n";
        } else
            commandAreaString += "⬜ "+targetEmoji+"\n";
    }
    commandAreaString = commandAreaString.trim();
    return commandAreaString;
}

function getAdjacentSpaceString(targetEmoji:string="🎯"):string {
    return "You "+targetEmoji;
}

function getRectangularAreaString(width:number,height:number,targetEmoji:string="🎯"):string {
    let commandAreaString = "";
    for (let i = 0; i < height; i++) {
        for (let j = 0; j < width; j++) {
            commandAreaString += targetEmoji+" ";
        }
        commandAreaString += "\n";
    }
    commandAreaString = commandAreaString.trim();
    return commandAreaString;
}
function getSingleCellDistanceAreaString(maxRange:number,targetEmoji:string="🎯"):string {
    let commandAreaString = "You ";
    for (let i = 1; i < maxRange; i++)
        commandAreaString += "⬜ ";
    commandAreaString += targetEmoji;
    return commandAreaString;
}


function distanceAttack(level:XPAtLeast1,name:string,discipline:string,damageType:string, image?:string):ReusableCard {
    const minRange = 2;
    const maxRange = level+1;
    const moveSpaces = 3;
    // Every 6 levels we get an additional enemy affected.
    const spacesAffected = 1 + Math.floor(level/6);

    const damagePerAffectedSpace = getDamagePerSpace(level, minRange, maxRange, spacesAffected, moveSpaces);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(damagePerAffectedSpace);
    let commandAreaString = getSingleCellDistanceAreaString(maxRange);
    // We aren't showing the +1 option in the example, because it's hard to render clearly.
    // if (level>1) {
    //     commandAreaString += "OR 🎯";
    // }
    let commandString = getMoveCommandString(level)
        +get1EnergyString(level)
        +getTargetString(level)
        +"**%spacesaffected%** enemy **%range%** spaces away takes **%dice%** "+damageType+".";
    if (level>1 && level<=4)
        commandString += "\n\n⚡⚡: Same, but **%range+1%** spaces away.";
    else if (level>=5)
        commandString += "\n\n⚡⚡: **%range+1%** spaces away."+
        "\n\n⚡⚡: **%spacesaffected+1%** enemies.";
    
    return {
        name, discipline,
        actionType: "attack",
        image,
        xpCost: level,
        goldCost: 0,
        moveSpaces, spacesAffected, minRange, maxRange,
        dicePerAffectedSpace, commandAreaString, commandString,
    } as ReusableCard;
}
export const ArcheryArrow:ReusableCardFunc = function ArcheryArrow(level:XPAtLeast1):ReusableCard {
    return distanceAttack(level,"Arrow","Archery","piercing (physical) dmg", ArrowImg);
}

export const ConstructBarricade:ReusableCardFunc = function ConstructBarricade(level:XPAtLeast1):ReusableCard {
    const spacesAffected = 1 + level;
    let damageToDestroy = 2 + level;
    // The first affected space goes below the You.
    // After that, every other affected space goes above.
    // Pattern: Yx, xYx, xYxx, xxYxx, etc. 
    const commandAreaString = getAdjacentLineAreaString(spacesAffected,"X");
    let commandString = getMoveCommandString(level)
        +get1EnergyString(level)
        +getTargetString(level)
        +"an adjacent straight line to create a barricade. Mark it with \"X "+damageToDestroy+"\".\n\n"+
    "\"X\" Spaces: Allows ranged attacks. Destroyed by "+damageToDestroy+" dmg.";
    if (level<=2)
        commandString += " Physical check ✳✳ for PCs to hurdle.";
    else if (level<=6)
        commandString += " Physical check ✳ for PCs to hurdle.";
    else if (level<=10)
        commandString += " PCs can hurdle without a check.";
    return {
        name: "Barricade",
        discipline: "Construct",
        actionType: "enhance",
        image: BarricadeImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces: 2,
        spacesAffected,
        minRange: 1,
        maxRange: 1,
        commandAreaString,
        commandString,
    } as ReusableCard;
}


function areaAttackAdjacent(level:XPAtLeast1, name:string, discipline:string, damageType:string, image:string):ReusableCard {
    const baseDamage = 2.5; /* start at 1d4. */
    const damageIncrease = 1; /* because the area increases each level, damage increases only a very small amonut per level. */
    const damage = baseDamage + damageIncrease*(level-1);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(damage);
    const commandAreaString = getAdjacentLineAreaString(level,"🔥");
    let commandString = getMoveCommandString(level)
        +get1EnergyString(level)
        +getTargetString(level)
        +"%spacesaffected% adjacent spaces: enemies take **%dice%** "+damageType+".";
    if (level>1)
        commandString += "\n\n⚡⚡: Same, but **%dice+1d4-1%** dmg.";
    return {
        name,
        discipline,
        actionType: "attack",
        image,
        xpCost: level,
        goldCost: 0,
        moveSpaces: 2,
        spacesAffected: level,
        minRange: 1,
        maxRange: 1,
        dicePerAffectedSpace,
        commandAreaString,
        commandString,
    } as ReusableCard;
}
export function DestructExplode(level:XPAtLeast1):ReusableCard {
    return areaAttackAdjacent(level,"Explode","Destruct","explosive 🔥 fire dmg and destroy obstacles at GM's discretion (barricades, wooden doors, etc)",DestructExplodeImg);
}

function wizardry(damageLevel:XPAtLeast1, areaLevel:XPAtLeast0, rangeLevel:XPAtLeast0, name:string, discipline:string, damageType:string, image:string):ReusableCard {
    // Target area starts at 3. Then increases by 1. But it has to be a rectangle, so needs to be divisible, no prime numbers.

    const {width,height} = getWidthAndHeightForAreaLevel(areaLevel);
    const spacesAffected = width*height;
    const moveSpaces = 2;
    const minRange = 2;
    const maxRange = rangeLevel+2;

    const damagePerAffectedSpace = getDamagePerSpace(damageLevel, minRange, maxRange, spacesAffected, moveSpaces);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(damagePerAffectedSpace);

    // TODO (maybe) This does not include spacing using minRange & maxRange yet
    const commandAreaString = getRectangularAreaString(width,height);

    let commandString = getMoveCommandString(damageLevel)
        +get1EnergyString(damageLevel)
        +getTargetString(damageLevel)
        +"a "+width+"x"+height+" area, **%range%** spaces away. Enemies take **%dice%** "+damageType+"."
    if (damageLevel>1)
        commandString += "\n\n⚡⚡: Same, but **%dice+1d4-1%** "+damageType+".";
    return {
        actionType: "attack",
        xpCost: damageLevel,
        goldCost: 0,
        name, discipline, image, moveSpaces, spacesAffected, minRange, maxRange,
        dicePerAffectedSpace, commandAreaString, commandString,
    } as ReusableCard;
}

export function FireWizardryFireDart(level:XPAtLeast1):ReusableCard {
    return wizardry(level,0,level,"Fire Dart","Fire Wizardry","🔥 fire dmg",WizardryFireImg);
}

export const IceWizardryIceDart:ReusableCardFunc = function IceWizardryIceDart(level:XPAtLeast1):ReusableCard {
    return wizardry(level,0,level,"Ice Dart","Ice Wizardry","❄️ cold dmg",WizardryIceImg);
}

export const FireWizardryFireball:ReusableCardFunc = function FireWizardryFireball(level:XPAtLeast1):ReusableCard {
    return wizardry(level,level,0,"Fireball","Fire Wizardry","🔥 fire dmg",WizardryFireImg);
}

export const IceWizardryIceball:ReusableCardFunc = function IceWizardryIceball(level:XPAtLeast1):ReusableCard {
    return wizardry(level,level,0,"Iceball","Ice Wizardry","❄️ cold dmg",WizardryIceImg);
}

const BONUS_DICE_STRONGEST_ADJACENT=0.6;

export const FightingOneTwoPunch:ReusableCardFunc = function FightingOneTwoPunch(level:XPAtLeast1):ReusableCard {
    const moveSpaces = 3;
    const spacesAffected = 1;
    const minRange = 1;
    const maxRange = 1;
    const damagePerAffectedSpace = getDamagePerSpace(level, minRange, maxRange, spacesAffected, moveSpaces);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(damagePerAffectedSpace);

    const commandAreaString = getAdjacentLineAreaString(spacesAffected);
    let commandString = getMoveCommandString(level)
        +get1EnergyString(level)
        +getTargetString(level)
        +"**%spacesaffected%** adjacent enemies take **%dice%** bludgeoning (physical) dmg.";
    if (level>1) {
        const bonusDmg = damagePerAffectedSpace * (BONUS_DICE_STRONGEST_ADJACENT-0.2); // A little less than strongestAdjacentAttack
        const bonusDice = getD4D20D100ForAvgRoll(bonusDmg);        
        const bonusDiceString = getDiceStr(bonusDice);
        commandString += "\n\n⚡⚡: Same, but **%dice+"+bonusDiceString+"%** bludgeoning (physical) dmg.";
        // console.log("Fighting One-Two Punch #"+level+": commandString = ",commandString);
    }
    return {
        name: "One-Two Punch",
        discipline: "Fighting",
        actionType: "attack",
        image: FightingPunchImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces, spacesAffected, minRange, maxRange,
        dicePerAffectedSpace, commandAreaString, commandString,
    } as ReusableCard;
}



export const AthleticRun:ReusableCardFunc = function AthleticRun(level:XPAtLeast1):ReusableCard {
    const moveSpaces = 3 + level;
    const spacesAffected = 0;
    const minRange = 0;
    const maxRange = 0;
    let commandString = getMoveCommandString(level);
    if (level>1)
        commandString+="\n\n"+"⚡: 🦶 Move **%move+3%** spaces.";
    if (level>2)
        commandString += "\n\n⚡⚡: 🦶 Move **%move+6%** spaces.";
    return {
        name: "Run",
        discipline: "Athletic",
        actionType: "enhance",
        image: AthleticRunImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces, spacesAffected, minRange, maxRange,
        commandString,
    } as ReusableCard;
}

export const TelepathMindControl:ReusableCardFunc = function TelepathMindControl(level:XPAtLeast1):ReusableCard {
    let moveSpaces = 2;
    if (level>1)
        moveSpaces = 3;
    const enemyMoveSpaces = level;
    const spacesAffected = 1;
    const minRange = 1;
    const maxRange = level+1;
    const damagePerAffectedSpace = getDamagePerSpace(level, minRange, maxRange, spacesAffected, moveSpaces,-1.5);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(damagePerAffectedSpace);
    const commandAreaString = getSingleCellDistanceAreaString(maxRange);
    let commandString = getMoveCommandString(level)
        +get1EnergyString(level)
        +"Force "+getTargetString(level,true)
        +"enemy **%range%** spaces away to move **0-"+enemyMoveSpaces+"** spaces and attack an adjacent enemy for ❤️ **%dice%** bludgeoning (physical) dmg.";
    if (level===2)
        commandString += "\n\n⚡⚡: Same, but enemy can be **%range+1%** spaces away.";
    else if (level===3)
        commandString += "\n\n⚡⚡: Same, but enemy can be **%range+2%** spaces away.";
    else
        commandString += "\n\n⚡⚡: Force two target enemies **%range%** spaces away to move a combined **"+enemyMoveSpaces+"** spaces.";
    return {
        name: "Mind Control",
        discipline: "Telepath",
        actionType: "attack",
        image: TelepathMindControlImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces, spacesAffected, minRange, maxRange,
        dicePerAffectedSpace, commandAreaString, commandString,
    } as ReusableCard;
}

export const DragonbornFirebreath:ReusableCardFunc = function DragonbornFirebreath(level:XPAtLeast1):ReusableCard {
    const minRange = 1;
    const maxRange = 2;
    const moveSpaces = 3;
    const spacesAffected = 1;
    const damagePerAffectedSpace = getDamagePerSpace(level, minRange, maxRange, spacesAffected, moveSpaces);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(damagePerAffectedSpace);

    const commandAreaString = getSingleCellDistanceAreaString(maxRange,"🔥");
    let commandString = getMoveCommandString(level);
    if (level===1)
        commandString += "⚡ (Spend 1 energy): ";
    else
        commandString += "⚡: ";
    commandString += "🎯 Target **%spacesaffected%** enemies **%range%** spaces away take **%dice%** 🔥 fire dmg.";
    if (level>1) {
        const bonusDmg = damagePerAffectedSpace * (BONUS_DICE_STRONGEST_ADJACENT-0.3); // a little less than one-two punch because of the 1 additional range
        const bonusDice = getD4D20D100ForAvgRoll(bonusDmg);
        const bonusDiceString = getDiceStr(bonusDice);
        commandString += "\n\n⚡⚡: Same, but **%dice+"+bonusDiceString+"%** 🔥 fire dmg.";
    }
    return {
        name: "Fire Breath",
        discipline: "Dragonborn",
        actionType: "attack",
        image: DragonbornImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces, spacesAffected, minRange, maxRange,
        dicePerAffectedSpace, commandAreaString, commandString,
    } as ReusableCard;
}

function strongestAdjacentAttack(level:XPAtLeast1, name:string, discipline:string, damageType:string, damageEmoji:string, image?:string):ReusableCard {
    const spacesAffected = 1; // cannot be increased.
    const minRange = 1;
    const maxRange = 1;
    const moveSpaces = 3;

    // const baseDamage = 3.5; /* start at 1d4+1. */
    // const damageIncrease = getDamageIncreasePerLevel(level) + (level-1.5); // strongest single-target attack, strongest growth.
    // const damagePerAffectedSpace = baseDamage + damageIncrease;
    const damagePerAffectedSpace = getDamagePerSpace(level, minRange, maxRange, spacesAffected, moveSpaces);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(damagePerAffectedSpace);
    
    const commandAreaString = getAdjacentSpaceString(damageEmoji);
    let commandString = getMoveCommandString(level)
        + get1EnergyString(level)
        + getTargetString(level);
    commandString+="**%spacesaffected%** adjacent enemy takes **%dice%** "+damageType+".";
    if (level>1) {
        const bonusDmg = damagePerAffectedSpace * BONUS_DICE_STRONGEST_ADJACENT; // this is the strongest bonus because they take the biggest risk
        const bonusDice = getD4D20D100ForAvgRoll(bonusDmg);
        const bonusDiceString = getDiceStr(bonusDice);
        commandString += "\n\n⚡⚡: Same, but **%dice+"+bonusDiceString+"%** "+damageType+".";
    }
    return {
        name, discipline, image, moveSpaces, spacesAffected, minRange, maxRange, dicePerAffectedSpace,
        commandAreaString, commandString,
        actionType: "attack",
        xpCost: level,
        goldCost: 0,
    } as ReusableCard;
}


export const BarbaricAxeSwing:ReusableCardFunc = function BarbaricAxeSwing(level:XPAtLeast1):ReusableCard {
    return strongestAdjacentAttack(level,"Axe Swing","Barbaric","slicing (physical) dmg","🎯",BarbaricAxeSwingImg);
}
export const BarbaricSwordSlice:ReusableCardFunc = function BarbaricSwordSlice(level:XPAtLeast1):ReusableCard {
    return strongestAdjacentAttack(level,"Sword Slice","Barbaric","slicing (physical) dmg","🎯");
}
export const TentacleAttack:ReusableCardFunc = function TentacleAttack(level:XPAtLeast1):ReusableCard {
    return strongestAdjacentAttack(level,"Tentacle Lash","Barbaric","slicing (physical) dmg","🎯");
}

/************
 * Sneaky Knife Throw
 * Level 1: Same as Archery. 1 more range than barbaric, but 1 less damage.
 * Level 2+: Less range than Archery, it does not gain range, so it gains damage instead.
 * 
 */
export const SneakyKnifeThrow:ReusableCardFunc = function SneakyKnifeThrow(level:XPAtLeast1):ReusableCard {
    const minRange = 2;
    const maxRange = 2;
    const moveSpaces = 3;
    const spacesAffected = 1;
    // const baseDamage = 2.5; /* start at 1d4, since range is 2 */
    // Range does not increase each level, so damage increases by about the same amount as a close attack, it'll just always be a litle less.
    // const damageIncrease = getDamageIncreasePerLevel(level) + (level-1.5);
    // const damage = baseDamage + damageIncrease;
    const damagePerAffectedSpace = getDamagePerSpace(level, minRange, maxRange, spacesAffected, moveSpaces);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(damagePerAffectedSpace);
    const commandAreaString = `You ⬜ 🎯`;
    let commandString = getMoveCommandString(level)
        + get1EnergyString(level)
        + getTargetString(level)
        +"%spacesaffected% enemies **%range%** spaces away takes ❤️ **%dice%** piercing (physical) dmg.";
    if (level>1)
        commandString += "\n\n⚡⚡: Same, but ❤️ **%dice+1d4%** piercing (physical) dmg.";
    return {
        name: "Sneaky Knife Throw",
        discipline: "Knives",
        actionType: "attack",
        image: DisciplineStealthImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces, spacesAffected, minRange, maxRange, dicePerAffectedSpace, commandAreaString, commandString,
    } as ReusableCard;
}

export const HealingTouch:ReusableCardFunc = function HealingTouch(level:XPAtLeast1):ReusableCard {
    const moveSpaces=3;
    const spacesAffected=1;
    const minRange=0;
    const maxRange=1;
    const healPerAffectedSpace = getDamagePerSpace(level, minRange, maxRange, spacesAffected, moveSpaces,level);
    // const baseHeal = 3.5; /* start at 1d4+2. */
    // const healIncrease = getDamageIncreasePerLevel(level) + (level-1.5); // grows as fast as the strongest attacks.
    // const heal = baseHeal + healIncrease;
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(healPerAffectedSpace);
    const commandAreaString = `You ⚡`;    
    let commandString = getMoveCommandString(level)
        +get1EnergyString(level)
        +"🎯 You or ally **%range%** space away heals ❤️ **%dice%**.";
    if (level>1)
        commandString += "\n\n⚡⚡: Same, but ❤️ **%dice+1d4%**.";
    return {
        name: "Healing Touch",
        discipline: "Healing",
        actionType: "enhance",
        image: HealingImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces,spacesAffected, minRange, maxRange, dicePerAffectedSpace, commandAreaString, commandString,
    } as ReusableCard;
}

export const Rest0:ReusableCard = {
    name: "Breather",
    discipline: "Rest",
    actionType: "enhance",
    image: RestImg,

    xpCost: 0,
    goldCost: 0,

    moveSpaces: 0,
    spacesAffected: 0,
    minRange:0, maxRange: 0,

    commandString: "Do nothing this round.\n\n"+
    "Recover 1 ⚡ energy or 1 ❤️ (up to your max).",
};

export const Rest:ReusableCardFunc = function Rest(level:XPAtLeast1):ReusableCard {
    let commandString = "Do nothing this round.\n\n" +
        "Recover 1 ⚡ energy or 1 ❤️ (up to your max).";
    if (level > 1) {
        const diceCount = level - 1;
        commandString += `\n\nAdditionally, roll ${diceCount}d4. For each roll of 4, recover an extra ⚡ energy or ❤️.`;
    }
    return {
        name: "Breather",
        discipline: "Rest",
        actionType: "enhance",
        image: RestImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces: 0,
        spacesAffected: 0,
        minRange: 0,
        maxRange: 0,
        commandString,
    } as ReusableCard;
}

export const DustDevilsVeil:ReusableCardFunc = function DustDevilsVeil(level:XPAtLeast1):ReusableCard {
    const moveSpaces = 3;
    const spacesAffected = 0;
    const minRange = 0;
    const maxRange = 4 + Math.floor((level - 1) / 2);
    const targetCharacters = Math.floor((level+1)/2);
    let commandString = `🦶 Move **%move%**.\n\n⚡: 🎯 Target **${targetCharacters}** character${targetCharacters > 1 ? 's' : ''} **%range%** spaces away do${targetCharacters > 1 ? '' : 'es'} not take dmg from poison, vapors, or dust, until the next rest.`;
    return {
        name: "Dust Devil's Veil",
        discipline: "Construct",
        actionType: "enhance",
        image: DustDevilsVeilImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces,
        spacesAffected,
        minRange,
        maxRange,
        commandString,
    } as ReusableCard;
}

export const AllReusableCards:ReusableCard[] = [
];

export const AllReusableCardFunctions=[ArcheryArrow, ConstructBarricade, DestructExplode, BarbaricAxeSwing, BarbaricSwordSlice, TentacleAttack, FightingOneTwoPunch, SneakyKnifeThrow, HealingTouch, FireWizardryFireDart, IceWizardryIceDart, FireWizardryFireball, IceWizardryIceball, DragonbornFirebreath, AthleticRun, TelepathMindControl, Rest, DustDevilsVeil] as ReusableCardFunc[];
// We can use AllReusableCardFunctions[0].name to get the name, to eventually index these cards into a GUI.
// console.log("AllReusableCardFunctions",AllReusableCardFunctions);

export function produceLevelCards<T>(cardProducingFunction:(level:XPAtLeast1)=>T, maxLevel:XPAtLeast1) {
    let cards:T[] = [];
    for (let level=1; level<=maxLevel; level++) {
        cards.push(cardProducingFunction(level as XPAtLeast1));
    }
    return cards;
}
export function getLevelAppropriateCards<T>(cardProducingFunctions:((level:XPAtLeast1)=>T)[], level: XPAtLeast1,): T[] {
    const cards = [] as T[];
    for (const cardFunction of cardProducingFunctions) {
        cards.push(...produceLevelCards<T>(cardFunction,level))
    }
    return cards;
}


export const PRODUCE_LEVEL_CARDS = 10;

for (const cardFunction of AllReusableCardFunctions) {
    AllReusableCards.push(...produceLevelCards<ReusableCard>(cardFunction,PRODUCE_LEVEL_CARDS));
}


