import { convertFromRaw, RawDraftContentState } from "draft-js";
import {convertToHTML} from 'draft-convert';
import { MUSIC_LINK_ENTITY_TYPE, NOTE_LINK_ENTITY_TYPE, VOICE_LINK_ENTITY_TYPE } from "../DraftJSEditor/BlockEditing/LinkToNoteBlockUtils";
import { LINK_TO_NOTE_BLOCK_TYPE } from "../DraftJSEditor/BlockEditing/BlockConstants";
import ReactDomServer from 'react-dom/server';
import { Note } from "../../Data/NoteType";
import { LinkToNoteFunction, getNoteIDFromURL } from "../../../DecisionGraph/Utilities/NavigateTo";
import { getVoiceIDFromURL } from "../NotePageAndHigher/ReplicaVoiceSideMenuAndSidebar";
import { COMMENT_BLOCK } from "../DraftJSEditor/BlockEditing/CommentBlock";
import { CHECKABLE_LIST_ITEM } from "../DraftJSEditor/BlockEditing/CheckableListItem";
import { getNoteNameAndEmoji } from "../../NotesTree/TreeUtilities/CreateTagTreeNodes";
import { NotesContextType } from "../../Data/NotesContext";
import { Extension } from "../../../Extensions/ExtensionsFramework/ExtensionsList";
import { getNoteDraftJS } from "../../Data/FirestoreNoteClient";


function getDraftContentText(doc_data: RawDraftContentState) {
    var text = "";
    // Skip the first block, it's the name of the doc.
    for (const block of doc_data.blocks.slice(1)) {
        // TODO skip any blocks that are just a link to another doc.
        if (block.text.length>0) {
            if (text.length>0)
                text=text+"\n";
            text += block.text;
        }
    }
    return text;
}

/********************
 * This is also in a function on the server in AlgoliaSearch.js
 * Keep it synched!
 * 
 */
export function getNoteText(note:Note) {
    return getDraftContentText(getNoteDraftJS(note));
}

export async function getJustThisDraftContentHTML(doc_data: RawDraftContentState, notesContext:NotesContextType, linkToNote:LinkToNoteFunction, extensions:Extension[]):Promise<string> {
    if (!doc_data || !doc_data.blocks)
        return ""; // Not loaded yet, or no data.

    // Remove blocks that should not be rendered in HTML.
    const newBlocks = [];
    // Add index signature to the type '{}'
    interface StringIndexSignature {
        [key: string]: any;
    }

    const replacements: StringIndexSignature = {};
    for (let index=0; index<doc_data.blocks.length; index++) {
        const block = doc_data.blocks[index];
        if (block.type===COMMENT_BLOCK) {
            // we don't print comments, they're just for the editor.
            continue;
        }
        if (block.type===LINK_TO_NOTE_BLOCK_TYPE) {
            if (!block.data || !block.data.checked) {
                // We will not include this one in the prompt.
                continue;
            }
            // Let's load the note right now, so we can use the latest name.
            const innerNote = await notesContext.getNote(block.data.doc_id);
            if (!innerNote)
                continue;
            replacements["<p>PLACEHOLDER FOR BLOCK "+block.key+"</p>"]="\n"+ReactDomServer.renderToString(linkToNote(innerNote, true))+block.text;
        } else if (index===0) {
            // The header could be special.
            // TODO We could include a link to itself, to give the system a hint that when mentioning it, use a link.
        }

        newBlocks.push(block);
    }
    doc_data = {...doc_data,blocks:newBlocks};

    const contentState = convertFromRaw(doc_data);
    let html="";
    try {
        html = convertToHTML({
            blockToHTML: (block) => {
            if (block.type === 'code') {
                return <blockquote/>;
            } else if (block.type===CHECKABLE_LIST_ITEM) {
                const checked = !!block.data?.checked;
                return <ul><li><input type='checkbox' checked={checked} readOnly/>{block.text}</li></ul>
                // For blank, we could return this:
                // return {start:'',end:''};
            } else if (block.type===LINK_TO_NOTE_BLOCK_TYPE) {
                return <p>PLACEHOLDER FOR BLOCK {block.key}</p>
            }
            },
            entityToHTML: (entity, originalText) => {
                if (entity.type === NOTE_LINK_ENTITY_TYPE || entity.type === "LINK") {
                    const noteId = getNoteIDFromURL(entity.data.url);
                    if (noteId) {
                        let title = originalText;
                        const note = notesContext.getLoadedNote(noteId, true);
                        if (note)
                            title = getNoteNameAndEmoji(note,notesContext, extensions);
                        return <a href={entity.data.url}>{title}</a>;
                    }
                } else if (entity.type === VOICE_LINK_ENTITY_TYPE) {
                    const {isValid} = getVoiceIDFromURL(entity.data.url);
                    if (isValid) {
                        let title = originalText;
                        return <a href={entity.data.url}>{title}</a>;
                    }
                } else if (entity.type === MUSIC_LINK_ENTITY_TYPE) {
                    let title = originalText;
                    // const musicTags = getMusicTagsFromURL(entity.data.url);
                    return <a href={entity.data.url}>{title}</a>;
                }
                // TODO The default in the example is to return the original text. It's unclear if there's another layer of checking, this might be not rendering anything special for any other types of entities.
                return originalText;
            },
        })(contentState);

        // Replace the placeholders with the actual text.
        for (const replacement in replacements) {
            html = html.replaceAll(replacement,replacements[replacement]);
        }
        return html;
    } catch (e) {
        // There are a lot of possible errors from the convertToHTML function.
        const doc_name = doc_data.blocks[0].text;
        console.log("Error converting note '"+doc_name+"' to HTML. This error is likely something that can be fixed in DocReferences.getNoteHTML(): ",e);
        debugger;
        return getDraftContentText(doc_data);
    }
}
