import { GenericTestPage, Test, GenericTestPageRef } from "./GenericTestPage";
import { organizeNotesInTopLevelNoteHierarchyV10 } from "../DecisionGraph/ChatEditor/Preprocessing/PreprocessV10CommandContext";
import { NoteHierarchy, TopLevelNoteHierarchy } from "../Notes/UIs/NoteInformationComponents/NoteHierarchy";
import { useRef, useEffect } from "react";
import { COMMAND_PROMPT_DOC_ID } from "../Extensions/DefaultExtension/DefaultExtension";
import { InclusionByCommand, InclusionByTopic, InclusionByTopLevelHierarchy, NoteInclusionType } from "../ServerConnection/LLMServer/SendChatToServerHook";

function mockNoteH({ id, doc_name, fullyIncludedNoteIDs = [], linkedNoteIDs = [], editContextNotes = [], template_doc_id }: { id: string, doc_name: string, type?: string, fullyIncludedNoteIDs?: string[], linkedNoteIDs?: string[], editContextNotes?: string[], template_doc_id?: string }) {
    return {
        note: { id, doc_name, type:""/*deprecated*/, doc_data: { blocks: [], entityMap: {} }, version: 1, template_doc_id },
        fullyIncludedNoteIDs,
        linkedNoteIDs,
        editContextNotes,
        exists: true,
        jsonFormsObject: {version: 1},
        selectedNoteIDs: [],
        completedNoteIDs: [],
    } as NoteHierarchy;
}

const TOP_NOTES_A = [
    mockNoteH({ id: "1", doc_name: "Command (#1)",
        fullyIncludedNoteIDs: ["2"],
        linkedNoteIDs: ["3", "4"],
        template_doc_id: COMMAND_PROMPT_DOC_ID }),
    mockNoteH({ id: "2", doc_name: "Context Topic (#2)" }),
];

const MOCK_HIERARCHIES_AND_SOLUTIONS = [
    {
        name: "Everything's included somewhere",
        hierarchy: {
            completedNoteIDs: [],
            topNoteHierarchies: TOP_NOTES_A,
            noteHierarchiesById: {
                ...TOP_NOTES_A.reduce((acc, note) => ({ ...acc, [note.note.id]: note }), {}),
                "3": mockNoteH({ id: "3", doc_name: "Context Topic (#3)"}),
                "4": mockNoteH({ id: "4", doc_name: "Command (#4)", template_doc_id: COMMAND_PROMPT_DOC_ID}),
            },
            isLoaded: true,
            finishLoadingPromise: Promise.resolve(),
        },
        solution: [
            { type: InclusionByTopLevelHierarchy, noteID: "1" },
            { type: InclusionByTopLevelHierarchy, noteID: "2" },
            { type: InclusionByCommand, noteID: "4" },
            { type: InclusionByTopic, noteID: "3" },
        ],
    },
    {
        name: "Indirect notes should not be options",
        hierarchy: {
            completedNoteIDs: [],
            topNoteHierarchies: TOP_NOTES_A,
            noteHierarchiesById: {
                ...TOP_NOTES_A.reduce((acc, note) => ({ ...acc, [note.note.id]: note }), {}),
                "3": mockNoteH({ id: "3", doc_name: "Context Topic (#3)", linkedNoteIDs: ["5"] }),
                "4": mockNoteH({ id: "4", doc_name: "Command (#4)", linkedNoteIDs: ["6"],template_doc_id: COMMAND_PROMPT_DOC_ID }),
                "5": mockNoteH({ id: "5", doc_name: "Context Topic (#5)" }),
                "6": mockNoteH({ id: "6", doc_name: "Context Topic (#6)" }),
            },
            isLoaded: true,
            finishLoadingPromise: Promise.resolve(),
        },
        solution: [
            { type: InclusionByTopLevelHierarchy, noteID: "1" },
            { type: InclusionByTopLevelHierarchy, noteID: "2" },
            { type: InclusionByCommand, noteID: "4" },
            { type: InclusionByTopic, noteID: "3" },
        ],
    }
] as { name: string, hierarchy: TopLevelNoteHierarchy, solution: NoteInclusionType[] }[];

const MOCK_TESTS: Test[] = MOCK_HIERARCHIES_AND_SOLUTIONS.map(({ name, hierarchy, solution }) => ({
    name,
    check: async () => {
        const {uniqueNoteInclusions} = organizeNotesInTopLevelNoteHierarchyV10(hierarchy, []);
        const messages = [];

        const resultSorted = uniqueNoteInclusions.sort((a, b) => a.noteID.localeCompare(b.noteID));
        const solutionSorted = solution.sort((a, b) => a.noteID.localeCompare(b.noteID));

        if (resultSorted.length !== solutionSorted.length) {
            messages.push(`Expected ${solutionSorted.length} notes, but got ${resultSorted.length}.`);
        } else {
            for (let i = 0; i < resultSorted.length; i++) {
                if (resultSorted[i].noteID !== solutionSorted[i].noteID || resultSorted[i].type !== solutionSorted[i].type) {
                    messages.push(`Mismatch at index ${i}: expected { type: ${solutionSorted[i].type}, noteID: ${solutionSorted[i].noteID} }, but got { type: ${resultSorted[i].type}, noteID: ${resultSorted[i].noteID} }.`);
                }
            }
        }

        if (messages.length > 0) {
            return { success: false, message: messages.join(" ") };
        }
        return { success: true };
    },
}));

export default function PreprocessV10TestPage() {
    const testPageRef = useRef<GenericTestPageRef | null>(null);

    useEffect(() => {
        if (testPageRef.current) {
            testPageRef.current.runSelectedTest();
        }
    }, []);

    return <>
        <GenericTestPage ref={testPageRef} tests={MOCK_TESTS} title="Preprocess V10 Test Page" />
    </>;
}