import { PageHeader } from '@ant-design/pro-components';
import { useNavigate } from 'react-router-dom';
import GameCardList from '../Extensions/TTRPG/GameCard/GameCardUI';
import { AllPlayerCharacters } from '../Extensions/TTRPG/PlayerCharacter/PlayerCharacters';
import { useEffect } from 'react';


export default function GameCardsTestPage() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Cards - AI Got This';
      }, []);
    
    return <>
        <div className="noprint">
            <PageHeader
            title="Testing Pages"
            className="site-page-header"
            onBack={function(){navigate("/tests")}}
            />
            <div className="site-layout-background" style={{ padding: 24 }}>
                <h1>Game Cards</h1>
            </div>
        </div>
        <GameCardList pcs={AllPlayerCharacters}/>
    </>;
}