import { ContentState, convertFromRaw } from "draft-js"
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import "../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/editorStyles.css";
import "../Notes/UIs/DraftJSEditor/WYSIWYGEditor.css";
import { InstantSearch } from "react-instantsearch";
import { AlgoliaSearchClient } from "../DecisionGraph/AlgoliaSearch/AlgoliaSearchClient";
import OneLineDraftEditor from "../DecisionGraph/ChatEditor/UI/OneLineDraftJS";


const DEFAULT_DRAFT_DOCUMENT = {
  "blocks": [
      {
          "key": "cjp98",
          "text": "placeholder text for a few lines of text lorem ipsom dolor est placeholder text for a few lines of text lorem ipsom dolor est placeholder text for a few lines of text lorem ipsom dolor est placeholder text for a few lines of text lorem ipsom dolor est placeholder text for a few lines of text lorem ipsom dolor est",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
  ],
  "entityMap": {}
};


export default function NoToolbarDraftJSPluginsTestPage() {
    
    return <InstantSearch searchClient={AlgoliaSearchClient} indexName="AIGotThisNotes" future={{preserveSharedStateOnUnmount: true}} routing={true} >
         <OneLineDraftEditor initialContent={convertFromRaw(DEFAULT_DRAFT_DOCUMENT)} onSubmit={(contentState:ContentState)=>{alert("it's submitted!")}} autoFocus={true}/>
    </InstantSearch>;
}