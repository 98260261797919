import { EditorBlock, EditorState } from "draft-js";
import Immutable from "immutable";
import React from "react";
import { Tooltip } from "antd";
import { toggleFocusedBlockIsType } from "./GenericBlockFunctions";

export const COMMENT_BLOCK = "comment-block";


function toggleFocusedBlockIsComment(editorState: EditorState) {
  return toggleFocusedBlockIsType(editorState, COMMENT_BLOCK);
}

export function CommentButtonDraftJSPlugins(props:any) {
    const { setEditorState, getEditorState} = props;
    /**
     * We've found an interesting bug that appears to be deep in the static toolbar, and hopefully inconsequential in production.
     * When we change something on the Node.js server, the server makes a call to refresh the client, and then the getEditorState and setEditorState start to come back as undefined.
     * Once we refresh the page or navigate away from an editor and back to this one it starts coming in as normal.
     * 
     * It appears that old code is still being held by DraftJS even after editing. To prove this, you could:
     * 1. Uncomment the warning below
     * 2. Refresh the app
     * 3. Change the warning below (or comment it out)
     * 4. Notice that the warning below is printed in its original state, not the new state
     */
    // if (!setEditorState && !getEditorState) {
    //     console.warn("Warning: You are probably in Node.js developer mode and changed something. Refresh this page before using the Editor. Otherwise, you will get an error when you click on the Todo toolbar button.");
    // }
    function onClick() {
        if (!setEditorState || !getEditorState) {
            alert("Please refresh the page before using the toolbar -- this is a Node.js & DraftJS compatibility issue.");
        }
        // console.log("useTodoButton_DraftJSPlugins: onClick: editorState=",getEditorState());
        setEditorState(toggleFocusedBlockIsComment(getEditorState()));
    }
    // console.log("TodoButton_DraftJS: props=",props);
    return <div className="draftJSButtonWrapper" onMouseDown={(e)=>e.preventDefault()}>
        <Tooltip title = "Comment: Contents won't be used in prompts">
            <button onClick={onClick} className="draftJSButton">
            {/* Use double forward slash as button text */}
            <b>{'//'}</b>
            </button>
        </Tooltip>
    </div>;
}


export class CommentBlock extends React.Component {
    render() {
      //@ts-ignore
      const { offsetKey } = this.props;
      return (
        <div
          className="comment-block"
          data-offset-key={offsetKey}
        >
            <Tooltip title = "Comment: Contents won't be used in prompts">
            {/* <div
                className='comment-block-title'
                contentEditable={false}
                suppressContentEditableWarning
                >
                Comment
            </div> */}
          <div className='comment-block-content'>
            <EditorBlock {...this.props} />
          </div>
            </Tooltip>
        </div>
      )
    }
  }

export const commentBlockRenderMap = Immutable.Map({
  [COMMENT_BLOCK]: {
    element: "div",
    wrapper: <div className="comment-block"/>
  },
})
