import { useContext } from "react";
import { TopLevelNoteHierarchy } from "../../Notes/UIs/NoteInformationComponents/NoteHierarchy";
import { Extension, getInstalledExtensionsList } from "./ExtensionsList";
import { NoteHierarchyContext } from "../../Notes/UIs/NoteInformationComponents/NoteHierarchyProvider";

const DEBUG = false;

export function getApplicableExtensionsFromNoteIDs(doc_ids: string[]): Extension[] {
    const extensions = getInstalledExtensionsList();
    const applicableExtensions = extensions.filter(extension => {
        const includesAnApplicableNote = extension.included_notes_doc_ids.some(doc_id => {
            return doc_ids.includes(doc_id);
        });
        if (includesAnApplicableNote)
            return true;
        return false;
    });
    if (applicableExtensions.length>0) {
        if (DEBUG) console.log("Found applicable extensions", applicableExtensions);
        return applicableExtensions;
    }
    if (DEBUG) {
        // This happens regularly while loading. It's only an error if we've finished loading notes and there should be an extension (user has selected/pinned/etc.) and there isn't one.
        console.log("No applicable extensions found yet -- we may still be loading. This is an error if it should have found an extension, but it didn't.");
        // TODO we could check note types to show a warning if there's an uninstalled extension here.
        // The check would look something like the following.
        // const hasApplicableNoteTypes = extension.noteTypes.filter(noteType => {
        //     Object.values(topLevelNoteHierarchy.noteHierarchiesById).some(noteHierarchy => {
        //         if (noteHierarchy.note.type === noteType.name) {
        //             return true;
        //         }
        //     }
        //     )
        //     for (const noteHierarchy of Object.values(topLevelNoteHierarchy.noteHierarchiesById) {
                
        //         if (noteHierarchy.note.type === noteType.doc_id) {
        //             return true;
        //         }
        //     }
        //     topLevelNoteHierarchy.noteHierarchiesById
        //     return topLevelNoteHierarchy.noteHierarchiesById[noteType.doc_id];
        // });
    }
    return [];
}

export function getApplicableExtensionsFromNoteHierarchy(topLevelNoteHierarchy: TopLevelNoteHierarchy): Extension[]{
    if (!topLevelNoteHierarchy.noteHierarchiesById)
        return [];
    return getApplicableExtensionsFromNoteIDs(Object.keys(topLevelNoteHierarchy.noteHierarchiesById));
}

export function useExtensions(): {extensions: Extension[], getExtensionsWithAdditionalNotes: (doc_ids: string[]) => Extension[]} {
    const topLevelNoteHierarchy = useContext(NoteHierarchyContext).topLevelNoteHierarchy;
    let extensions = [] as Extension[];
    if (topLevelNoteHierarchy)
        extensions.push(...getApplicableExtensionsFromNoteHierarchy(topLevelNoteHierarchy));
    
    function getExtensionsWithAdditionalNotes(doc_ids: string[]): Extension[] {
        const additionalExtensions = getApplicableExtensionsFromNoteIDs(doc_ids);
        return extensions.concat(additionalExtensions);
    }
    return {extensions, getExtensionsWithAdditionalNotes};
}