import { extractNoteIDsFromDocData } from '../../../Notes/UIs/NoteInformationComponents/NoteHierarchy';
import { NoteInclusionType } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { ChatMessage2 } from '../UI/ChatLog';

function getLastUserChatMessage(selectedChatMessages: ChatMessage2[]) {
    const userMessages = selectedChatMessages.filter((message) => message.role === "user" && message.content);
    if (userMessages.length === 0) {
        console.error("[ChatEditorV2OnNotePage]>useLastUserMessage> BUG: No user messages found. This should not happen.");
        debugger;
    }
    const lastUserChatMessage = userMessages[userMessages.length - 1];
    return lastUserChatMessage;
}

export function getLastUserMessage(selectedChatMessages: ChatMessage2[]) {
    const lastUserChatMessage = getLastUserChatMessage(selectedChatMessages);
    const lastUserMessage = lastUserChatMessage.content as string;
    if (!lastUserMessage) {
        console.error("[ChatEditorV2OnNotePage]>useLastUserMessage> BUG: No user input found. This should not happen.");
        debugger;
    }
    // console.log("[ChatEditorV2OnNotePage]>useLastUserMessage> lastUserMessage is \"", lastUserMessage, "\" from ", lastUserChatMessage, " in ", selectedChatMessages);
    const noteIDsMentionedInChat = [] as string[];
    if (lastUserChatMessage.draftRawContent) {
        extractNoteIDsFromDocData(lastUserChatMessage.draftRawContent, (type, noteId) => {
            noteIDsMentionedInChat.push(noteId);
        });
    }

    return lastUserMessage;
}

export function getLastUserNotesMentionedInChat(selectedChatMessages: ChatMessage2[]) {
    const lastUserChatMessage = getLastUserChatMessage(selectedChatMessages);
    const noteIDsMentionedInChat = [] as string[];
    if (lastUserChatMessage.draftRawContent) {
        extractNoteIDsFromDocData(lastUserChatMessage.draftRawContent, (type, noteId) => {
            noteIDsMentionedInChat.push(noteId);
        });
    }
    return noteIDsMentionedInChat;
}

export function getPreviousNoteIDsOfType(selectedChatMessages: ChatMessage2[], type?: NoteInclusionType["type"], excludeTypes?: NoteInclusionType["type"][]) {
    let noteIDs = [] as string[];
    for (const message of selectedChatMessages) {
        if (message.extra && message.extra.notesIncluded) {
            let eligibleNoteInclusions: NoteInclusionType[] = message.extra.notesIncluded;
            if (type)
                eligibleNoteInclusions = eligibleNoteInclusions.filter((note: NoteInclusionType) => note.type === type);
            if (excludeTypes)
                eligibleNoteInclusions = eligibleNoteInclusions.filter((note: NoteInclusionType) => !excludeTypes.includes(note.type));
            noteIDs = eligibleNoteInclusions.map((note: NoteInclusionType) => note.noteID);
        }
    }
    return noteIDs;
}