import { CleanMessage,  cleanChatMessagesBeforeSendToServer } from "../GenericChatServer";
import { ChatCompletionMessageParam } from "openai/resources";
import { getVertexAI, getGenerativeModel, HarmCategory, HarmBlockThreshold, GenerateContentResult, ModelParams, GenerationConfig } from "firebase/vertexai";
import { FIREBASE_APP } from "../../../AppBase/App";
import { ChatAPICallReturnType, ChatAPIOptions, LLM_QUALITY_LEVEL_BEST, LLM_QUALITY_LEVEL_FASTEST, LLMChatServerCall, LLMServer, LLMStructuredOutputCall, StructuredOutput, StructuredOutputInputFormat } from "../GenericChatServerConsts";
import { z } from "zod";
import { JSONSchemaType } from "ajv";

const SERVER_TIMEOUT_MINS = 4; // 4 minutes. The source of truth is in our server functions in opeaifunctions.js

const DEBUG = false;
const DEBUG_STRUCTURED_OUTPUT = false;

const STRUCTURED_OUTPUT_USE_CHAT = false;


const MAX_OUTPUT_TOKENS = 8192; // Max for the 1.5 and 2.0 models per https://firebase.google.com/docs/vertex-ai/gemini-models#detailed-info

export const DEFAULT_GEMINI_CHAT_API_OPTIONS: ChatAPIOptions = {
    response_format: {type: "text"},
    max_tokens: -1,
    quality: LLM_QUALITY_LEVEL_BEST,
    temperature: 1,
};


function convertToHistory(cleanMessages:CleanMessage[]): { role: "user" | "model", parts: { text: string }[] }[] {
    let history: { role: "user" | "model", parts: { text: string }[] }[] = [];
    let lastRole: "user" | "model" | null = null;
    let lastMessage: { role: "user" | "model", parts: { text: string }[] } | null = null;

    cleanMessages.filter(msg => msg.role !== "function" && msg.role!=="tool" && msg.role!=="system").forEach(function(msg) {
        let newRole = "user" as "user" | "model";
        if (msg.role === "assistant") {
            newRole = "model";
        }
        if (newRole === lastRole && newRole === "user") {
            if (lastMessage) {
                lastMessage.parts[0].text += " " + msg.content;
            }
        } else {
            lastMessage = {
                role: newRole,
                parts: [{ text: msg.content as string }]
            };
            history.push(lastMessage);
            lastRole = newRole;
        }
    });
    return history;
}


export const callGeminiViaFirebaseChatCommand:LLMChatServerCall = async function (messages: ChatCompletionMessageParam[], chatAPIOptions: ChatAPIOptions=DEFAULT_GEMINI_CHAT_API_OPTIONS) {
    const vertexAI = getVertexAI(FIREBASE_APP);
    const {abortController, streamingCallback, errorCallback, ...chatAPIOptionsRest} = chatAPIOptions;
    let modelInstance: ReturnType<typeof getGenerativeModel>;
    /* Per the API video games may need to set the harm category for dangerous content higher.
    We're finding that in this TTRPG context it's getting blocked too frequently even on HIGH.
    TODO adjust this differently based on Extension group so only games get this blocking level.
    */
    const safetySettings = [{category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT, threshold: HarmBlockThreshold.BLOCK_NONE }];
    // As of 2/15/2025 the only 2.0 model is the flash version
    modelInstance = getGenerativeModel(vertexAI, { model: "gemini-2.0-flash", safetySettings});
    // if (chatAPIOptionsRest.quality===LLM_QUALITY_LEVEL_BEST) {
    //     modelInstance = getGenerativeModel(vertexAI, { model: "gemini-1.5-pro", safetySettings});
    // } else {
    //     modelInstance = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash", safetySettings});
    // }
    if (DEBUG) console.log("[callFirebaseGemini]>messages: ",messages);
    const startTime = Date.now();

    async function makeTheCall(retries:number=1, attemptsSoFar:number=0):Promise<ChatAPICallReturnType>{
        try {
            if (DEBUG) console.log("[callFirebaseGemini] > sendChatMessage > chat to send: ",messages);
            const cleanMessages = cleanChatMessagesBeforeSendToServer(messages);
            let temperature = DEFAULT_GEMINI_CHAT_API_OPTIONS.temperature;
            if (chatAPIOptionsRest.temperature) {
                if (chatAPIOptionsRest.temperature<0 || chatAPIOptionsRest.temperature>2) {
                    console.error("[callFirebaseGemini]>Invalid temperature: "+chatAPIOptionsRest.temperature+" so using default: "+temperature);
                }
                temperature = chatAPIOptionsRest.temperature;
                if (DEBUG) console.log("Temperature: "+temperature);
            }

            const system_instruction = cleanMessages.filter(msg => msg.role === "system").map(msg => msg.content).join("\n");
            let maxOutputTokens = MAX_OUTPUT_TOKENS;
            if (chatAPIOptionsRest.max_tokens && chatAPIOptionsRest.max_tokens>0 && chatAPIOptionsRest.max_tokens<MAX_OUTPUT_TOKENS)
                maxOutputTokens = chatAPIOptionsRest.max_tokens;

            const history = convertToHistory(cleanMessages);

            const chat = modelInstance.startChat({
                history,
                generationConfig: {
                    maxOutputTokens,
                    temperature,
                },
                systemInstruction: {
                    role: "system",
                    parts: [{text: system_instruction}]
                },
                safetySettings
            });

            const lastMessageContent = cleanMessages[cleanMessages.length - 1].content;
            if (lastMessageContent === undefined) {
                throw new Error("Last message content is undefined");
            }
            const result = await chat.sendMessageStream(lastMessageContent as string);

            let text = '';
            for await (const chunk of result.stream) {
                const chunkText = chunk.text();
                if (DEBUG) console.log(chunkText);
                text += chunkText;
                if (streamingCallback) streamingCallback(text, chunkText, false);
            }

            const endTime = Date.now();
            const timeTakenMinutes = (endTime-startTime)/1000/60;
            if (DEBUG) console.log("[callFirebaseGemini] > sendChatMessage > Gemini API completed in "+timeTakenMinutes+" minutes.");
            if (streamingCallback) streamingCallback(text, "", true);
            return {success:true, message:text};

        } catch (error) {
            // Check to see if this is a firebase internal error:
            //@ts-ignore
            if (error.name==="FirebaseError" && error.message==="deadline-exceeded") {
                // We can retry once. It's likely it'll fail again, though...
                const endTime = Date.now();
                const timeTakenMinutes = (endTime-startTime)/1000/60;
                console.error("Error: Gemini API has taken "+timeTakenMinutes+" minutes (the server timeout is "+SERVER_TIMEOUT_MINS+" minutes). That's "+(attemptsSoFar+1)+" attempts at up to "+SERVER_TIMEOUT_MINS+" mins each...");
                if (retries>0) {
                    console.log("... Retrying "+(retries)+" more times...");
                    return await makeTheCall(retries-1, attemptsSoFar+1);
                } else {
                    console.error("... We've exceeded retries. Giving up.");
                    alert("Error: The Gemini server is running very slow right now. Please try again later.");
                    if (errorCallback)
                        errorCallback("Error: The Gemini server is running very slow right now. Please try again later.");
                    return {success:false, error:"Error: The Gemini server is running very slow right now. Please try again later."};
                }
            }
            // We get them when Gemini takes extra long to reply, though we just set the timeout to now be 2 minutes -- but that's really long.
            if (abortController?.signal.aborted) {
                // Not a problem!
                return {success:false, error:"Aborted by user."};
            } else {
                // Is this still an unknown error? If so, let's take a look at it in the debugger.
                console.error(error);
                debugger;
                if (errorCallback)
                    errorCallback("Error: "+error);
                return {success:false, error:"Error: "+error};
            }
        }
    }
    return await makeTheCall();
};

async function callGeminiViaFirebaseStructuredOutput<T>(
    messages: ChatCompletionMessageParam[],
    chatAPIOptions: ChatAPIOptions
): Promise<StructuredOutput<T>> {
    // Initialize Vertex AI service using the Firebase app.
    const vertexAI = getVertexAI(FIREBASE_APP);
    // Use the same safety settings as elsewhere.
    const safetySettings = [{ category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT, threshold: HarmBlockThreshold.BLOCK_NONE }];
    
    // Build generation configuration using the provided response_format as JSON schema.
    const generationConfig = {
        responseMimeType: "application/json",
        responseSchema: chatAPIOptions.response_format, // assumes response_format is a valid JSON schema
        maxOutputTokens: (chatAPIOptions.max_tokens && chatAPIOptions.max_tokens > 0) ? chatAPIOptions.max_tokens : MAX_OUTPUT_TOKENS,
        temperature: chatAPIOptions.temperature !== undefined ? chatAPIOptions.temperature : DEFAULT_GEMINI_CHAT_API_OPTIONS.temperature,
    } as GenerationConfig;

    // Prepare messages.
    const cleanMessages = cleanChatMessagesBeforeSendToServer(messages);
    const history = convertToHistory(cleanMessages);

    // Initialize the Gemini model.
    const modelParams = {
        model: "gemini-2.0-flash",
        safetySettings,
        generationConfig
    } as ModelParams;
    const model = getGenerativeModel(vertexAI, modelParams);

        // Will chat work? it's not in the doc
    let text = "";
    if (STRUCTURED_OUTPUT_USE_CHAT) {
        const system_instruction = cleanMessages.filter(msg => msg.role === "system").map(msg => msg.content).join("\n");
        const chat = model.startChat({
            history,
            generationConfig,
            systemInstruction: {
                role: "system",
                parts: [{ text: system_instruction }]
            },
            safetySettings
        });

        // Use the last message as prompt.
        const prompt = cleanMessages[cleanMessages.length - 1].content;
        if (prompt === undefined) {
            throw new Error("Last message content is undefined");
        }
        const result = await chat.sendMessageStream(prompt);
        for await (const chunk of result.stream) {
            text += chunk.text();
        }    
    } else {
        // Generate content without chat.
        // Use all messages as prompt. Use "Model: " and "User: " prefixes for everything except the system message, which will just go first.
        const prompt = cleanMessages.map(msg => {
            let prefix = msg.role === "system" ? "" : msg.role + ": ";
            return prefix + msg.content;
        }).join("\n\n");
        const result = await model.generateContent(prompt);
        text = result.response.text();
    }
    if (DEBUG_STRUCTURED_OUTPUT)
        console.log("Gemini generated structured output:", text);

    // Attempt to parse the response as JSON.
    try {
        const structuredOutput = JSON.parse(text);
        return { success: true, structuredOutput };
    } catch (e) {
        console.error("Failed to parse Gemini structured output", e);
        return { success: false, error: "Failed to parse structured output: " + e };
    }
}

const structuredOutputCall:LLMStructuredOutputCall = async function callStructuredOutput<T>(messages: ChatCompletionMessageParam[], zodSchema: z.ZodSchema<T>, jsonSchema: JSONSchemaType<T>, schemaName:string, chatAPIOptions?: ChatAPIOptions): Promise<StructuredOutput<T>> {
    /* Remove $schema from the JSON schema, as it's not supported by VertexAI, it shows the error:

    VertexAI: Error fetching from https://firebasevertexai.googleapis.com/v1beta/projects/lofty-door-320319/locations/us-central1/publishers/google/models/gemini-2.0-flash:generateContent: [400 ] Invalid JSON payload received. Unknown name "$schema" at 'generation_config.response_schema': Cannot find field. [{"@type":"type.googleapis.com/google.rpc.BadRequest","fieldViolations":[{"field":"generation_config.response_schema","description":"Invalid JSON payload received. Unknown name \"$schema\" at 'generation_config.response_schema': Cannot find field."}]}] (vertexAI/fetch-error)
    at makeRequest (request.ts:199:1)
    at async generateContent (generate-content.ts:53:1)
    at async callGeminiViaFirebaseStructuredOutput (GeminiFirebaseChatServer.ts:221:1)
    at async detectCommandV2 (CommandDetectionAI.ts:179:1)
    at async Object.check (CommandDetectionAITestPage.tsx:111:1)
    at async runTestDef (GenericTestPage.tsx:31:1)
    at async GenericTestPage.tsx:49:1
    at async Object.runSelectedTest (GenericTestPage.tsx:85:1)
    */
    const {"$schema":schema,...jsonSchemaRest} = jsonSchema;

    // Put the JSON schema in the chatAPIOptions:
    const chatAPIOptionsWithResponseFormat = {...chatAPIOptions, response_format: jsonSchemaRest} as ChatAPIOptions;
    const output = await callGeminiViaFirebaseStructuredOutput<T>(messages, chatAPIOptionsWithResponseFormat);

    return output;
}

export const LLM_SERVERTYPE_GEMINI_VIA_FIREBASE: LLMServer = {
    name: "Gemini",
    contextLength: 1000000, /*1 million for Gemini 2.0 flash. (2 million can be reached if needed with I think 1.5 pro*/
    isLocal: false,
    // serverType: LLM_SERVERTYPE_FirebaseDirectGemini,
    defaultTemperature: 0.7,
    supportsStreaming: true,
    supportsFunctions: false,
    supportedQuality: [LLM_QUALITY_LEVEL_BEST, LLM_QUALITY_LEVEL_FASTEST],
    chatCall: callGeminiViaFirebaseChatCommand,
    structuredOutputInputFormat: StructuredOutputInputFormat.JSON,
    structuredOutputCall,
};

// export function useGeminiViaFirebaseChatCommand(lmServerString:ServerEndpointString="FirebaseFunctionsVertexAIChatServer", model: ModelType=MODEL_BEST) {
//     // Initialize the Vertex AI service
//     const vertexAI = getVertexAI(FIREBASE_APP);

//     const callFirebaseGemini:ChatAPICall = async function (messages:ChatCompletionMessageParam[], streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, chatAPIOptions:ChatAPIOptions=DEFAULT_CHAT_API_OPTIONS) {
//         const {abortController, ...chatAPIOptionsRest} = chatAPIOptions;
//         let modelInstance: ReturnType<typeof getGenerativeModel>;
//         /* Per the API video games may need to set the harm category for dangerous content higher.
//         We're finding that in this TTRPG context it's getting blocked too frequently even on HIGH.
//         TODO adjust this differently based on Extension group so only games get this blocking level.
//         */
//         const safetySettings = [{category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT, threshold: HarmBlockThreshold.BLOCK_NONE }];
//         if (model===MODEL_BEST) {

//             modelInstance = getGenerativeModel(vertexAI, { model: "gemini-1.5-pro", safetySettings});
//         } else {
//             // implied to be model===MODEL_LIGHT
//             modelInstance = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash", safetySettings});
//         }

//         if (DEBUG) console.log("[callFirebaseGemini]>messages: ",messages);

//         const startTime = Date.now();

//         async function makeTheCall(retries:number=1, attemptsSoFar:number=0):Promise<{success:boolean, message?:string, error?:string}>{
//             try {
//                 if (DEBUG) console.log("[callFirebaseGemini] > sendChatMessage > chat to send: ",messages);
//                 const cleanMessages = cleanChatMessagesBeforeSendToServer(messages);
//                 if (!chatAPIOptionsRest.temperature || chatAPIOptionsRest.temperature<=0 || chatAPIOptionsRest.temperature>=2) {
//                     throw new Error("[callFirebaseGemini]>Invalid temperature: "+chatAPIOptionsRest.temperature);
//                 }
//                 console.log("Temperature: "+chatAPIOptionsRest.temperature);
//                 const system_instruction = cleanMessages.filter(msg => msg.role === "system").map(msg => msg.content).join("\n");
//                 let maxOutputTokens = MAX_OUTPUT_TOKENS;
//                 if (chatAPIOptionsRest.max_tokens && chatAPIOptionsRest.max_tokens>0 && chatAPIOptionsRest.max_tokens<MAX_OUTPUT_TOKENS)
//                     maxOutputTokens = chatAPIOptionsRest.max_tokens;

//                 let history: { role: "user" | "model", parts: { text: string }[] }[] = [];
//                 let lastRole: "user" | "model" | null = null;
//                 let lastMessage: { role: "user" | "model", parts: { text: string }[] } | null = null;

//                 cleanMessages.filter(msg => msg.role !== "function" && msg.role!=="tool" && msg.role!=="system").forEach(function(msg) {
//                     let newRole = "user" as "user" | "model";
//                     if (msg.role === "assistant") {
//                         newRole = "model";
//                     }
//                     if (newRole === lastRole && newRole === "user") {
//                         if (lastMessage) {
//                             lastMessage.parts[0].text += " " + msg.content;
//                         }
//                     } else {
//                         lastMessage = {
//                             role: newRole,
//                             parts: [{ text: msg.content as string }]
//                         };
//                         history.push(lastMessage);
//                         lastRole = newRole;
//                     }
//                 });

//                 const chat = modelInstance.startChat({
//                     history,
//                     generationConfig: {
//                         maxOutputTokens,
//                         temperature: chatAPIOptionsRest.temperature,
//                     },
//                     systemInstruction: {
//                         role: "system",
//                         parts: [{text: system_instruction}]
//                     },
//                     safetySettings
//                 });

//                 const lastMessageContent = cleanMessages[cleanMessages.length - 1].content;
//                 if (lastMessageContent === undefined) {
//                     throw new Error("Last message content is undefined");
//                 }
//                 const result = await chat.sendMessageStream(lastMessageContent as string);

//                 let text = '';
//                 for await (const chunk of result.stream) {
//                     const chunkText = chunk.text();
//                     if (DEBUG) console.log(chunkText);
//                     text += chunkText;
//                     if (streamingCallback) streamingCallback(text, chunkText, false);
//                 }

//                 const endTime = Date.now();
//                 const timeTakenMinutes = (endTime-startTime)/1000/60;
//                 if (DEBUG) console.log("[callFirebaseGemini] > sendChatMessage > Gemini API completed in "+timeTakenMinutes+" minutes.");
//                 if (streamingCallback) streamingCallback(text, "", true);
//                 return {success:true, message:text};

//             } catch (error) {
//                 // Check to see if this is a firebase internal error:
//                 //@ts-ignore
//                 if (error.name==="FirebaseError" && error.message==="deadline-exceeded") {
//                     // We can retry once. It's likely it'll fail again, though...
//                     const endTime = Date.now();
//                     const timeTakenMinutes = (endTime-startTime)/1000/60;
//                     console.error("Error: Gemini API has taken "+timeTakenMinutes+" minutes (the server timeout is "+SERVER_TIMEOUT_MINS+" minutes). That's "+(attemptsSoFar+1)+" attempts at up to "+SERVER_TIMEOUT_MINS+" mins each...");
//                     if (retries>0) {
//                         console.log("... Retrying "+(retries)+" more times...");
//                         return await makeTheCall(retries-1, attemptsSoFar+1);
//                     } else {
//                         console.error("... We've exceeded retries. Giving up.");
//                         alert("Error: The Gemini server is running very slow right now. Please try again later.");
//                         if (errorCallback)
//                             errorCallback("Error: The Gemini server is running very slow right now. Please try again later.");
//                         return {success:false, error:"Error: The Gemini server is running very slow right now. Please try again later."};
//                     }
//                 }
//                 // We get them when Gemini takes extra long to reply, though we just set the timeout to now be 2 minutes -- but that's really long.
//                 if (abortController?.signal.aborted) {
//                     // Not a problem!
//                     return {success:false, error:"Aborted by user."};
//                 } else {
//                     // Is this still an unknown error? If so, let's take a look at it in the debugger.
//                     console.error(error);
//                     debugger;
//                     if (errorCallback)
//                         errorCallback("Error: "+error);
//                     return {success:false, error:"Error: "+error};
//                 }
//             }
//         }
//         return await makeTheCall();
//    };
//     return callFirebaseGemini;
// }
