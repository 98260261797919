import { DefaultDraftBlockRenderMap } from "draft-js";
import { checkableListItemBlockRenderMap } from "./CheckableListItem";
import { getLinkToNoteBlockRenderMap } from "./LinkToNoteBlockUtils";
import { commentBlockRenderMap } from "./CommentBlock";

export function getBlockRenderMap() {
  return DefaultDraftBlockRenderMap
    .merge(checkableListItemBlockRenderMap)
    .merge(getLinkToNoteBlockRenderMap())
    .merge(commentBlockRenderMap);
}
