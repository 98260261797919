// React & state:
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ContentState, EditorState, RawDraftContentState, convertFromRaw, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import "../../../Notes/UIs/DraftJSEditor/WYSIWYGEditor.css";
import { getAuth } from 'firebase/auth';
import { Avatar, Button, Collapse, Image } from 'antd';
import { ChatEditorRefFunctions, useSendToServerWithPreprocessing } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { ChatListDropdown, ChatLogContext, ChatMessage2 } from './ChatLog';
import { SelectableLLMServerDropdown } from './SelectableLLM';
import { findLastIndex, isString } from 'lodash';
import { convertDraftJSToMarkdown } from '../../../ServerConnection/LLMServer/MarkdownConverter';
import { NotesContext } from '../../../Notes/Data/NotesContext';
import { useLinkToNote_ForEditor } from '../../Utilities/NavigateTo';
import { convertMarkdownToDraftJSRaw } from '../../../Notes/UIs/NoteInformationComponents/DraftJSFromHTML';
import { useBasicHandleKeyCommand, useBasicKeyBindingFn } from '../../../Notes/UIs/DraftJSEditor/DraftKeyboardBindings';
import { blockStyleFn, useBlockRendererFn } from '../../../Notes/UIs/DraftJSEditor/BlockEditing/BlockRendererAndStyleFns';
import { useDraftExtensions } from '../../../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/DraftJsToolbarAndPlugins';
import { estimateTokensFromString } from '../../../ServerConnection/LLMServer/LLMTokenCounter';
import { useIsSmallScreen } from '../../../Notes/UIs/NotePageAndHigher/IsSmallScreen';
import OneLineDraftEditor from './OneLineDraftJS';
import { ChatProgressSteps } from './ChatProgress';
import { IncludedAndExcludedNotes } from './DisplayChatReferences';
import { AssistantMessage } from './EditableAssistantMessage';
import debounce from "lodash/debounce";
import { useExtensions } from "../../../Extensions/ExtensionsFramework/GetExtension";
import { getBlockRenderMap } from '../../../Notes/UIs/DraftJSEditor/BlockEditing/BlockRenderMap';

const DEBUG = false;
const DEBUG_CORRECT_EDITING_MESSAGE = false;
const DEBUG_SHOW_SYSTEM_PROMPT = false;
const DEBUG_SEND_TO_SERVER_WITH_STREAMING = false;

const DELAY_BETWEEN_CLIENT_CHAT_UPDATES = 400;

const SHOW_TOKENS = true;


function SystemMessageViewer({message, isWaitingForChat}:{message:ChatMessage2, isWaitingForChat:boolean}) {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  // This will have only the assistant & system messages, nothing from the regular. 
  const [tokensUsed, setTokensUsed] = useState(0);
  useEffect(() => {
    if (message.role !== 'system') {
      console.error("SystemMessageViewer only accepts system messages. Got:",message.role);
      debugger;
      return;
    }
    const draftJSMessage = convertMarkdownToDraftJSRaw(message.content as string);
    const editorStateNew = EditorState.createWithContent(convertFromRaw(draftJSMessage));
    setEditorState(editorStateNew);
    const tokensSystemPrompt = estimateTokensFromString(message.content as string);
    setTokensUsed(tokensSystemPrompt);
  },[message]);
  const basicKeyBindingFn = useBasicKeyBindingFn(editorState);
  const basicHandleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
  const blockRenderFn = useBlockRendererFn(setEditorState, editorState, false);
  const {plugins, extendedHandleKeyCommand} = useDraftExtensions(setEditorState,editorState, false, basicHandleKeyCommand, (a:boolean)=>{});

  let label = "System Prompt";
  if (SHOW_TOKENS && tokensUsed>0)
    label += " ("+Math.round((tokensUsed)/1000)+"k tokens)";

  return <Collapse accordion={true} items={[
        {
            key: "System Prompt",
            label,
            children: <div className="editor">
                <Button type='default' onClick={()=>navigator.clipboard.writeText(message.content as string)} disabled={isWaitingForChat}>Copy system prompt</Button>
                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    blockRendererFn={blockRenderFn}
                    blockRenderMap={getBlockRenderMap()}
                    blockStyleFn={blockStyleFn}
                    plugins={plugins}
            
                    handleKeyCommand={extendedHandleKeyCommand}
                    keyBindingFn={basicKeyBindingFn}
                    readOnly={true}
                />
            </div>
      }]}/>
}

export function ChatWithDraft({chatEditorRef}:{chatEditorRef:React.MutableRefObject<ChatEditorRefFunctions>}) {
    const { allChats, selectedChatIndex, setSelectedChatMessages } = useContext(ChatLogContext);
    const selectedChatMessages = allChats[selectedChatIndex]?.messages || [] as ChatMessage2[];
    const [editingMessageIndex, setEditingMessageIndexState] = useState(-1);
    const [currentMessageHasChanged, setCurrentMessageHasChanged] = useState(false);
    const {sendToServerWithStreaming, preprocessingProgressInSteps} = useSendToServerWithPreprocessing(chatEditorRef);

    const isWaitingForChat = chatEditorRef.current?.isWaitingForChat || false;
    const notesContext = useContext(NotesContext);
    const linkToNote = useLinkToNote_ForEditor();
    const { extensions } = useExtensions();
    
    function deleteMessage(index:number) {
        // TODO someday we'd like to implement undo. (We won't ask the user for confirmation because this is usually a lightweight operation).
        const newMessages = selectedChatMessages;
        let numToDelete = 1;
        // If this was an assistant message, we also check to see whether there was an empty user message after it.
        // It should always be an assistant message because we have not implemented delete on user messages.
        if (true) {
            // delete everything after this point:
            newMessages.splice(index);
        } else {
            const isAssistantMessage = newMessages[index].role === 'assistant';
            if (isAssistantMessage && index+1<newMessages.length && newMessages[index+1].role === 'user') {
                // We always delete the user message right afterwards, even if it has content, because this no longer makes sense to reply to it.
                // TODO check if all following messages are user messages. If so, delete all user messages.
                //  && newMessages[index+1].content === "")
                numToDelete = 2;
            }
            newMessages.splice(index,numToDelete);
        }
        
        // Remove duplicate trailing empty user messages if present
        if (newMessages.length >= 5) {
            // 5 is the minimum to have a (1) system (2) user message (3) a assistant (4) user message (5) empty user message
            const lastTwoMessagesAreUser = newMessages[newMessages.length - 1].role === 'user' && newMessages[newMessages.length - 2].role === 'user';
            const lastMessageContent = newMessages[newMessages.length - 1].content;
            const lastMessageIsEmpty = !lastMessageContent || (isString(lastMessageContent) && lastMessageContent.trim().length===0);
            if (lastTwoMessagesAreUser && lastMessageIsEmpty) {
                newMessages.splice(newMessages.length - 1, 1);
            }
        }
        
        setSelectedChatMessages(newMessages);
        // Update the editing message to make it the last user message.
        const lastUserMessageIndex = findLastIndex(newMessages,(message)=>message.role === 'user');
        if (lastUserMessageIndex>-1)
            setEditingMessageIndex(lastUserMessageIndex);
    }
    function setMessage(index:number, message:ChatMessage2) {
        const newMessages = selectedChatMessages;
        newMessages[index] = message;
        setSelectedChatMessages(newMessages);
        if (index === editingMessageIndex)
            setCurrentMessageHasChanged(true);
    }
    // function setCurrentUserMessageTo(message:string) {
    //     const newMessages = selectedChatMessages;
    //     const newMessage = {role:'user',content:message, createAt: new Date().getTime(), userHasMadeEdits:false} as ChatMessage2;
    //     newMessages[editingMessageIndex] = newMessage;
    //     setSelectedChatMessages(newMessages);
    //     setCurrentMessageHasChanged(true);
    // }

    const debouncedSetChatMessages = useMemo(() =>
        debounce((messages: ChatMessage2[]) => setSelectedChatMessages(messages), 300)
    , [setSelectedChatMessages]);

    async function setCurrentUserMessageToContentState(contentState: ContentState, submit:boolean) {
        const rawContentState = convertToRaw(contentState);
        let markdownContent = await convertDraftJSToMarkdown(rawContentState, notesContext, linkToNote, extensions);
        const newMessage = {role:'user', content: markdownContent, createAt: new Date().getTime(), userHasMadeEdits:false, draftRawContent:rawContentState} as ChatMessage2;
        const newMessages = selectedChatMessages;
        newMessages[editingMessageIndex] = newMessage;
        if (submit) {
            debouncedSetChatMessages.flush(); // Flush any pending updates
            setSelectedChatMessages(newMessages);
            setCurrentMessageHasChanged(true);
            await submitUserMessage(editingMessageIndex);
        } else {
            debouncedSetChatMessages(newMessages);
            setCurrentMessageHasChanged(true);
        }
    }

    useEffect(() => { // Anytime someone selects a different chat, we cancel any edits.
        setEditingMessageIndex(-1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedChatIndex]);
    // For debugging only
    // useMemo(()=>{
    //     console.log("[ChatWithDraft]> selectedChatMessages changed:",selectedChatMessages);
    // },[selectedChatMessages]); 
    useEffect(() => { // Adjust messages & selection to match
        if (DEBUG_CORRECT_EDITING_MESSAGE) console.log("[ChatWithDraft]>useEffect> Starting checks:");
        if (chatEditorRef.current.isWaitingForChat)
            return;
        // Check if editingMessageIndex is now invalid, if so, we need to change it to a valid one. This happens when the chat is changed, or we create a new chat.
        if (editingMessageIndex>-1) {
            if (editingMessageIndex<selectedChatMessages.length) {
                // it already exists, so don't change anything.
                // However, if we just deleted a message, it won't be focused by default.
                // TODO enhance this so we can get autofocus.
                if (DEBUG_CORRECT_EDITING_MESSAGE) console.log("[ChatWithDraft]>useEffect> Editing message index is already valid, not changing.");
                return;
            }
            // The message doesn't exist yet:
            //if (editingMessageIndex>=selectedChatMessages.length) {
            if (DEBUG_CORRECT_EDITING_MESSAGE) console.log("[ChatWithDraft]>useEffect> Editing message index is invalid, changing to -1.");
            setEditingMessageIndex(-1);
            // This useEffect will be called again with the new editingMessageIndex, so, still return:
            // }
            return;
        }
        if (selectedChatMessages.length===0 || selectedChatMessages[selectedChatMessages.length-1].role !== 'user') {
            // We need to add a user message at the end.
            if (DEBUG_CORRECT_EDITING_MESSAGE) console.log("[ChatWithDraft]>useEffect> Automatically adding a user message to the end.");
            const newMessages = selectedChatMessages;
            newMessages.push({role:'user',content:"", createAt: new Date().getTime(), userHasMadeEdits:false} as ChatMessage2);
            setSelectedChatMessages(newMessages);
        }
        if (DEBUG_CORRECT_EDITING_MESSAGE) console.log("[ChatWithDraft]>useEffect> Automatically editing the last message.");
        setEditingMessageIndex(selectedChatMessages.length-1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedChatMessages, editingMessageIndex, isWaitingForChat]);

    function setEditingMessageIndex(index:number) {
        if (index>-1) {
            DEBUG && console.log("[ChatWithDraft]>setEditingMessageIndex> Going to start editing message index:",index);
        }
        setEditingMessageIndexState(index);
    }
    async function submitUserMessage(doneEditingIndex:number, messages:ChatMessage2[]=selectedChatMessages) {
        if (!currentMessageHasChanged && messages.length>doneEditingIndex && messages[doneEditingIndex+1]?.role === 'assistant') {
            // Nothing has changed, and there's already an answer, so just stop editing.
            //   cancelEditingUserMessage(doneEditingIndex);
            setEditingMessageIndex(-1);
            return;
        }
        // User has submitted a message to process. Clean up, by removing any messages below this index, and send the message to the server.
        const messagesToSendToServer = messages.slice(0,doneEditingIndex+1);
        setSelectedChatMessages(messagesToSendToServer);
        chatEditorRef.current.isWaitingForChat=true;
        setEditingMessageIndex(-1); // Do this before the await, so that it will display as submitted.
        if (DEBUG_SEND_TO_SERVER_WITH_STREAMING) console.log("[ChatWithDraft]>submitUserMessage> Sending to server with streaming.");
        const {response, newChatMessages} = await sendToServerWithStreaming(messagesToSendToServer);
        // Confirm that newChatMessages ends with a user message:
        if (newChatMessages.length>0 && newChatMessages[newChatMessages.length-1].role !== 'user') {
            console.error("[ChatWithDraft]>submitUserMessage> The processed messages did not end with a user message.");
            debugger;
        }
        // Read and incrementally add the messages to the chat, until it's done:
        if (!response.body) {
            console.error("[ChatWithDraft]>submitUserMessage> Response has no body.");
            return;
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        // Create the new assistant message:
        const newAssistantMessage = {role: "assistant", content:"", userHasMadeEdits: false} as ChatMessage2;
        let lastAddition = new Date().getTime();
        while (true) {
            const {done, value} = await reader.read();
            if (done) {
                break;
            }
            const newChunk = decoder.decode(value);
            // Add the message to the chat.
            newAssistantMessage.content += newChunk;
            if (DEBUG_SEND_TO_SERVER_WITH_STREAMING) console.log("[ChatWithDraft]>submitUserMessage> Message so far:",newAssistantMessage.content);
            let latestAddition = new Date().getTime();
            if (latestAddition - lastAddition > DELAY_BETWEEN_CLIENT_CHAT_UPDATES) {
                // console.log("[ChatWithDraft]>submitUserMessage> Maxing message update");
                // Add the message to the chat, but only once per second, to avoid overloading react. (There might be another way)
                const newChatMessages2 = [...newChatMessages, newAssistantMessage];
                setSelectedChatMessages(newChatMessages2);
                lastAddition = latestAddition;
            }
        }
        const newChatMessages2 = [...newChatMessages, newAssistantMessage];
        setSelectedChatMessages(newChatMessages2);
        setCurrentMessageHasChanged(false);
        chatEditorRef.current.isWaitingForChat=false;
    }
    //   function cancelEditingUserMessage(cancelEditingIndex:number) {
    //     // Just stop editing. Revert the message to its original state.
    //     // Special case -- if it's the last message, we don't stop editing (until the user has selected another message)
    //     // This special case is required in order to make sure we get the events to start editing the next message -- I think the order is not onBlur then click or something like that, because we don't get the start editing message.
    //     if (cancelEditingIndex === selectedChatMessages.length-1) {
    //       if (DEBUG) console.log("[ChatWithDraft]>cancelEditingUserMessage> Ignoring onBlur to stop editing the last message.");
    //       return;
    //     }
    //     if (DEBUG) console.log("[ChatWithDraft]>cancelEditingUserMessage> User cancelled editing message at index:",editingMessageIndex);
    //     const newMessages = [...selectedChatMessages];
    //     newMessages[editingMessageIndex].content = uneditedMessage;
    //     setSelectedChatMessages(newMessages);
    //     setEditingMessageIndex(-1);
    //   }

    const auth = getAuth();
    const photoURL = auth.currentUser?.photoURL;
    const avatar = <Avatar
            src={photoURL && <Image src={photoURL}/>}
        />;

    function setNextMessage(nextMessage: string) {
        let newMessages = [...selectedChatMessages];
        const lastIndex = newMessages.length - 1;
        if (lastIndex >= 0 && newMessages[lastIndex].role === 'user' &&
            (!newMessages[lastIndex].content || 
                //@ts-ignore
                (isString(newMessages[lastIndex].content) && newMessages[lastIndex].content.trim().length === 0))) {
            newMessages[lastIndex] = {
                ...newMessages[lastIndex],
                draftRawContent: convertToRaw(ContentState.createFromText(nextMessage)),
                content: nextMessage,
                // Puzzle: we get a TypeScript error on the next line, even though we don't get it any other time we create a ChatMessage2 with a createAt field. Why?
                // createAt: new Date().getTime()
            } as ChatMessage2;
            // setSelectedChatMessages(newMessages);
            // submitUserMessage(lastIndex, newMessages);
        } else {
            // Append to the last message instead of adding:
            // TODO this will lose any links or other formatting that the user has already typed in the last message.
            const lastMessage = newMessages[lastIndex];
            const lastContent = lastMessage.content;
            if (lastContent!==nextMessage)
                newMessages[lastIndex] = {
                    ...lastMessage,
                    draftRawContent: convertToRaw(ContentState.createFromText(lastContent +"     "+ nextMessage)),
                    content: lastContent + nextMessage,
                    // createAt: new Date().getTime()
                } as ChatMessage2;
            // This would add a message:
            // newMessages = [...newMessages, {
            //     role: 'user',
            //     content: nextMessage,
            //     draftRawContent: convertToRaw(ContentState.createFromText(nextMessage)),
            //     createAt: new Date().getTime(),
            //     userHasMadeEdits: false
            // } as ChatMessage2];
            // setSelectedChatMessages(newMessages);
            // setEditingMessageIndex(newMessages.length - 1);
            // submitUserMessage(newMessages.length - 1, newMessages);
        }
        submitUserMessage(newMessages.length - 1, newMessages);
    }

    async function regenerateMessage(assistantIndex: number) {
        const userIndex = assistantIndex - 1;
        if (userIndex < 0 || selectedChatMessages[userIndex].role !== 'user') {
            console.error("No valid user message found prior to the assistant message at index", assistantIndex);
            return;
        }
        // Remove the assistant message.
        deleteMessage(assistantIndex);
        // Resubmit the user message.
        await submitUserMessage(userIndex);
    }

    const isSmallScreen = useIsSmallScreen();
    // useMemo(()=>{
    //     console.log("[ChatWithDraft]> selectedChatMessages changed:",selectedChatMessages);
    // },[selectedChatMessages]);

    const bottomOfLastMessageRef = useRef<HTMLBRElement>(null);
    useEffect(()=>{
        // When the chat messages change, we want to scroll to the bottom of the chat.
        // TODO a better form of this would be to scroll just once as soon as the user submits -- this would mean showing some blank space where the message will appear.
        // Check to make sure it's loading, because otherwise we don't want to scroll:
        if (!isWaitingForChat) {
            // TODO just finished? If we can be sure it just finished, scroll to the focused user message instead, it's a bit further down so that would be even better.
            // return;
        }
        if (bottomOfLastMessageRef.current) {
            bottomOfLastMessageRef.current.scrollIntoView({behavior: "smooth"});
        }
    },[selectedChatMessages,bottomOfLastMessageRef.current,isWaitingForChat]);

    const autoFocus = !isSmallScreen || editingMessageIndex===0; // On mobile, the autofocus brings up the keyboard on screen, which takes a ton of space. We don't want autofocus when there's text to read, but we do want it on a new chat.

    return <>
        <SelectableLLMServerDropdown/>
        <ChatListDropdown/><br/><br/>
        {selectedChatMessages.map((message:ChatMessage2, index) => {
            const isEditingThis = index === editingMessageIndex;
            if (message.role === 'user') {
                if (isEditingThis) {
                    return <div key={index}>{avatar}&nbsp;&nbsp;&nbsp;
                        <div style={{display:'inline-block', width:'calc(100% - 80px)',paddingTop:"10px"}}>
                            {<OneLineDraftEditor
                                key={selectedChatIndex+"_"+index} // Necessary when switching chats to make sure the editor is reset.
                                onSubmit={(contentState:ContentState)=>{setCurrentUserMessageToContentState(contentState, true)}}
                                onChange={(contentState:ContentState)=>{setCurrentUserMessageToContentState(contentState, false)}}
                                autoFocus={autoFocus}
                                initialContent={message && message.draftRawContent && convertFromRaw(message.draftRawContent as RawDraftContentState)}
                                readOnly={isWaitingForChat}
                            />}
                        </div>
                    </div>;
                } else {
                    const canUseDraftJSViewer =  message && message.draftRawContent;
                    if (!canUseDraftJSViewer) {
                        // We're getting this error a whole lot when chats are loading, I think it's not actually an error yet.
                        // console.error("The message does not have the draft content -- this is a bug");
                    }
                    const isLastUserMessage = index===selectedChatMessages.length-1;
                    return <div key={index}>
                        {<Button type="text" key={index} className="chat-with-draft-user-message"
                            disabled={isWaitingForChat}
                            onClick={()=>{setEditingMessageIndex(index)}}>
                                {avatar}&nbsp;&nbsp;&nbsp;
                                <div style={{display:"inline-block", width: 'calc(100% - 100px)', verticalAlign: "top"}}>
                                    {canUseDraftJSViewer && <OneLineDraftEditor
                                        key={selectedChatIndex+"_"+index} // Necessary when switching chats to make sure the editor is reset.
                                        initialContent={message && message.draftRawContent && convertFromRaw(message.draftRawContent as RawDraftContentState)}
                                        onSubmit={(contentState:ContentState)=>{/*not submittable*/}}
                                        readOnly={true}
                                    />}
                                    {!canUseDraftJSViewer && <>{message.content as string}<br/><br/></>}
                                </div>
                        </Button>}
                        {isLastUserMessage && chatEditorRef.current.isWaitingForChat && 
                            <>
                                <ChatProgressSteps preprocessingSteps={preprocessingProgressInSteps} />
                                <br/>
                                <br ref={bottomOfLastMessageRef}/>
                            </>}
                        <IncludedAndExcludedNotes message = {message} />
                    </div>
                }
            }
            if (message.role === 'system') {
                if (DEBUG_SHOW_SYSTEM_PROMPT && message.content) {
                    // Do we want to show these?
                    // return <div key={index}>{message.content}<br/><br/></div>;
                    return <div key={index}><SystemMessageViewer message={message} isWaitingForChat={isWaitingForChat}/><br/><br/></div>;
                    // return <div key={index}></div>;
                }
                return <div key={index}></div>;
            }
            if (message.role === 'assistant') {
                const isLast = index===selectedChatMessages.length-1;
                const refIfLast = isLast?bottomOfLastMessageRef:undefined;
                const prevMessage = index>0?selectedChatMessages[index-1]:undefined;
                return <AssistantMessage
                        key={index}
                        message={message}
                        prevMessage={prevMessage}
                        deleteSelectedMessage={()=>deleteMessage(index)}
                        setNextMessage={setNextMessage}
                        setMessage={(message:ChatMessage2)=>setMessage(index,message)}
                        editing={isEditingThis}
                        setEditing={(editing:boolean)=>setEditingMessageIndex(editing?index:-1)}
                        scrollToRef={refIfLast}
                        linkToNote={linkToNote}
                        extensions={extensions}
                        isWaitingForChat={isWaitingForChat}
                        regenerateMessage={() => regenerateMessage(index)}
                    />;
            }
            // console.error("Corrupted message role: ", message.role);
            return <div key={index}>Loading? Unknown message role: {message.role}<br/><br/></div>;
        })}
    </>;
}