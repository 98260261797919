import { isCurrentlyLoadingAnyTypes } from "../../Notes/Data/NoteDBHooks";
import { NotesContextType } from "../../Notes/Data/NotesContext";
import { Note } from "../../Notes/Data/NoteType";
import { Relationship } from "./RelationshipType";

// Generic helper.
export function findDuplicatesInArray(arr:String[]) {
    return new Set(arr.filter((item, index) => arr.indexOf(item) !== index));
}

export function getDuplicatesErrorMessage(arr:String[],prefix:string,duplicatesMessage="") {
    const duplicates = findDuplicatesInArray(arr);
    if (duplicates.size>0) {
        // Don't duplicate warnings:
        if (!duplicatesMessage.includes(prefix)) {
            duplicatesMessage+=prefix;
            const counts = {};
            for (const item of arr) {
                //@ts-ignore
                counts[item] = counts[item] ? counts[item] + 1 : 1;
            }

            //@ts-ignore
            for (const dupName of duplicates) {
                //@ts-ignore
                duplicatesMessage+=", there are "+counts[dupName]+" notes named \""+dupName+"\"";
            }
            duplicatesMessage+=".  ";
        }
    }
    return duplicatesMessage;
}

export type CheckMatchFunc = (propertyName:string, path:string) => boolean;
export type FoundMatchFunc = (propertyName:string, jsonSchemaProperties:any, actualValue:any, path:string, parentArrayItemProperties:any | undefined) => any;


// When we're on the "properties" part of a json schema, continue checking.
function rebuild_PropertiesPart(jsonSchemaProperties:any, actualValue:any,
    checkMatchFunc:CheckMatchFunc, foundMatchFunc:FoundMatchFunc, path:string) {

    for (const propertyName in jsonSchemaProperties) {
        const jsonSchemaPropertiesInner = jsonSchemaProperties[propertyName];
        if (checkMatchFunc(propertyName,path)) {
            if (Array.isArray(actualValue)) {
                // Special case -- do the next step for every item in the array.
                for (let i=0; i<actualValue.length; i++) {
                    const arrElem = actualValue[i]
                    const actualValueInner = arrElem[propertyName];
                    jsonSchemaProperties[propertyName]=foundMatchFunc(propertyName,
                        jsonSchemaPropertiesInner, actualValueInner,
                        path+"["+(i+1)+"]/"+propertyName, arrElem);
                }
            } else {
                // Just one item.
                const actualValueInner = actualValue && actualValue[propertyName];
                jsonSchemaProperties[propertyName]=foundMatchFunc(propertyName, jsonSchemaPropertiesInner, actualValueInner,path+"/"+propertyName, null);
            }
            continue;
        } else if (["boolean","number","string","integer"].includes(jsonSchemaPropertiesInner["type"])) {
            // Nothing to do, it's a type with no further information or chance of having one of our items deeper down.
            continue;
        }
        const actualValueInner = actualValue && actualValue[propertyName];
        if (jsonSchemaPropertiesInner["type"]==="object") {
            jsonSchemaProperties[propertyName]=rebuildMatchingPropertiesNamedInSchema(
                    jsonSchemaPropertiesInner, actualValueInner, checkMatchFunc, foundMatchFunc, path+"/"+propertyName);
        } else if (jsonSchemaPropertiesInner["type"]==="array") {
            if (jsonSchemaPropertiesInner["items"]["type"]==="array") {
                // TODO -- what do we do here? Not sure...
            } if (jsonSchemaPropertiesInner["items"]["type"]==="object") {
                // We know how to handle this, it's an array of objects.
                // This is the tricky one, we have to do something different for formDataPart.
                // Perhaps pass the array and let it be handled in a special way? That's what we're doing...
                jsonSchemaPropertiesInner["items"] = rebuildMatchingPropertiesNamedInSchema(
                    jsonSchemaPropertiesInner["items"], actualValueInner, checkMatchFunc, foundMatchFunc, path+"/"+propertyName);
            }
            //    schemaProperties[property]=insertTypesRecursive(schemaProperties[property],formDataPart);
        }
        // console.log(`${property}: ${schemaObj[property]}`);
      }

    return jsonSchemaProperties;
}

/*************
 * Pass in a json Schema, an object that's an instantiation of it (optional),
 * a function to determine which properties to select, and a function to handle.
 * 
 * Use cases:
 * (a) rebuild the jsonSchema -- e.g. populate an enum
 * (b) other actions that operate on certain properties
 * 
 */
export function rebuildMatchingPropertiesNamedInSchema(jsonSchema:any,
    actualValue:any,
    checkMatchFunc:CheckMatchFunc, foundMatchFunc:FoundMatchFunc,
    path:string="") {

    // The root always contains a properties node.
    jsonSchema["properties"]=rebuild_PropertiesPart(
        jsonSchema["properties"],actualValue,
        checkMatchFunc,
        foundMatchFunc,path);
    return jsonSchema;
}

// TODO we developed this to use it, but aren't using it... Why not?
// function getRelationships_v2(notesContext:NotesContextType, jsonFormsContext:JSONFormsObjectContextType) {
//     let errorMessages="";
//     const relationships:Relationship[] = [];
//     const notesOfTypeMap:any = {}; // Used for tracking the IDs selected for new notes, used when saving.

//     function handleMatchFunc(propertyName:string, jsonSchemaProperties:any,
//         actualValue:any, path:string, parentArrayItemProperties:any | undefined): any {
//         // Check to make sure it's valid.
//         if (typeof(jsonSchemaProperties)==="object") {
//             if (jsonSchemaProperties["type"]==="string") {
//                 // Good!
//             } else {
//                 errorMessages+="Warning: The type of property '"+propertyName+"' should be a string. We'll auto change it for you.";
//                 // We can handle this... But worth a warning.
//             }
//         } else {
//             errorMessages+="Warning: Corrupted object structure. '"+propertyName+"' should be an object, not a "+typeof(jsonSchemaProperties);
//             return jsonSchemaProperties;
//         }
//         const notesOfType = notesContext.loadedNotes.filter(function(note:Note){return note.type===propertyName});
//         notesContext.loadNotesOfType(propertyName); // This will help in a future render.
//         if (notesOfType.length===0) {
//             return jsonSchemaProperties;
//         }

//         const selectableNotes = notesOfType && notesOfType.filter((noteToCheck:Note)=>{
//             // Just filter out the current note, we don't list ourselves.
//             // (there might be some case where we need this, if that ever happens, we'll change it)
//             return noteToCheck.id!==jsonFormsContext?.note?.id;
//         }).map(
//             function(note:Note){
//                 // TODO -- how can we also include the ID? Do we just assume there's only single IDs or show an error?
//                 return note.doc_name;
//                 // return note.doc_name+" ("+note.id+")";
//             });
//         notesOfTypeMap[propertyName]=notesOfType;

//         const relatedNotes = actualValue && notesOfType && notesOfType.filter(
//             function(note:Note) {
//                 return note.doc_name===actualValue;
//             });
//         if (relatedNotes) {
//             // Remove the known one, we don't need to display it to the user since it's already in a column.
//             const newProperties = {...parentArrayItemProperties};
//             delete newProperties[propertyName];
//             for (const relatedNote of relatedNotes) {
//                 relationships.push({type:propertyName,name:actualValue,doc_id:relatedNote.id,source:path,additionalColumns:newProperties});
//             }
//         }
        
//         // Check selectableNotes for duplicates:
//         // duplicatesMessage=getDuplicatesErrorMessage(selectableNotes,"For type "+propertyName,duplicatesMessage);

//         // Fill in the enums
//         if (selectableNotes.length===0) {
//             // It may be 0 when loading. But this causes an error in JSON Forms, which expects to have at least 1 item in each enum.
//             // So we put in a placeholder, saying "Loading..."
//             // TODO show loading only if we're actually loading.
//             if (isCurrentlyLoadingAnyTypes())
//                 jsonSchemaProperties["enum"] = ["... Loading..."];
//             else
//                 jsonSchemaProperties["enum"] = ["No types available"];
//         } else {
//             const uniqueSelectableRelationships = new Set(selectableNotes);
//             jsonSchemaProperties["enum"] = Array.from(uniqueSelectableRelationships).sort();
//             // console.log("Replaced the enum of "+propertyName+" with "+uniqueSelectableRelationships.size+" items, to ",jsonSchemaProperties);
//         }

//         return jsonSchemaProperties;
//     }
//     if (!jsonFormsContext?.jsonFormsObject || !jsonFormsContext?.note) {
//         // isLoaded=false;
//     } else {
//         // Load the note that contains the type specification, e.g. if "type=Character" load "Character"
//         if (jsonFormsContext?.jsonSchema) {
//             rebuildMatchingPropertiesNamedInSchema(jsonFormsContext?.jsonSchema,jsonFormsContext?.jsonFormsObject,
//                 jsonFormsContext.findMatchFunc_isNoteOfType,handleMatchFunc);
//             // console.log("jsonSchema=",jsonSchema);
//             // console.log("Enemy: ",jsonSchema.properties.enemies.items.properties.Enemy)
//         } else {
//             // console.log("No JSON Schema for type:",type);
//         }
//     }
//     return {relationships, errorMessages, notesOfTypeMap};
// }

export function getRelationships_OldParameters(notesContext:NotesContextType, jsonFormsObject:any, note:Note | undefined, jsonSchema:any, findMatchFunc:CheckMatchFunc) {
    let errorMessages="";
    const relationships:Relationship[] = [];
    const notesOfTypeMap:any = {}; // Used for tracking the IDs selected for new notes, used when saving.

    function handleMatchFunc(propertyName:string, jsonSchemaProperties:any,
        actualValue:any, path:string, parentArrayItemProperties:any | undefined): any {
        // Check to make sure it's valid.
        if (typeof(jsonSchemaProperties)==="object") {
            if (jsonSchemaProperties["type"]==="string") {
                // Good!
            } else {
                errorMessages+="Warning: The type of property '"+propertyName+"' should be a string. We'll auto change it for you.";
                // We can handle this... But worth a warning.
            }
        } else {
            errorMessages+="Warning: Corrupted object structure. '"+propertyName+"' should be an object, not a "+typeof(jsonSchemaProperties);
            return jsonSchemaProperties;
        }
        const notesOfType = notesContext.loadedNotes.filter(function(note:Note){return note.type===propertyName});
        notesContext.loadNotesOfType(propertyName); // This will help in a future render.
        if (notesOfType.length===0) {
            return jsonSchemaProperties;
        }

        const selectableNotes = notesOfType && notesOfType.filter((noteToCheck:Note)=>{
            // Just filter out the current note, we don't list ourselves.
            // (there might be some case where we need this, if that ever happens, we'll change it)
            return noteToCheck.id!==note?.id;
        }).map(
            function(note:Note){
                // TODO -- how can we also include the ID? Do we just assume there's only single IDs or show an error?
                return note.doc_name;
                // return note.doc_name+" ("+note.id+")";
            });
        notesOfTypeMap[propertyName]=notesOfType;

        const relatedNotes = actualValue && notesOfType && notesOfType.filter(
            function(note:Note) {
                return note.doc_name===actualValue;
            });
        if (relatedNotes) {
            // Remove the known one, we don't need to display it to the user since it's alread in a column.
            const newProperties = {...parentArrayItemProperties};
            delete newProperties[propertyName];
            for (const relatedNote of relatedNotes) {
                relationships.push({type:propertyName,name:actualValue,doc_id:relatedNote.id,source:path,additionalColumns:newProperties});
            }
        }
        
        // Check selectableNotes for duplicates:
        // duplicatesMessage=getDuplicatesErrorMessage(selectableNotes,"For type "+propertyName,duplicatesMessage);

        // Fill in the enums
        if (selectableNotes.length===0) {
            // It may be 0 when loading. But this causes an error in JSON Forms, which expects to have at least 1 item in each enum.
            // So we put in a placeholder, saying "Loading..."
            // TODO show loading only if we're actually loading.
            if (isCurrentlyLoadingAnyTypes())
                jsonSchemaProperties["enum"] = ["... Loading..."];
            else
                jsonSchemaProperties["enum"] = ["No types available"];
        } else {
            const uniqueSelectableRelationships = new Set(selectableNotes);
            jsonSchemaProperties["enum"] = Array.from(uniqueSelectableRelationships).sort();
            // console.log("Replaced the enum of "+propertyName+" with "+uniqueSelectableRelationships.size+" items, to ",jsonSchemaProperties);
        }

        return jsonSchemaProperties;
    }
    if (!jsonFormsObject || !note) {
        // isLoaded=false;
    } else {
        // Load the note that contains the type specification, e.g. if "type=Character" load "Character"
        if (jsonSchema) {
            rebuildMatchingPropertiesNamedInSchema(jsonSchema,jsonFormsObject,
                //@ts-ignore
                findMatchFunc,handleMatchFunc);
            // console.log("jsonSchema=",jsonSchema);
            // console.log("Enemy: ",jsonSchema.properties.enemies.items.properties.Enemy)
        } else {
            // console.log("No JSON Schema for type:",type);
        }
    }
    return {relationships, errorMessages, notesOfTypeMap};
}
