import { Steps } from 'antd';
import { PreprocessingStepsType } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { LoadingOutlined } from '@ant-design/icons';

const { Step } = Steps;

export function ChatProgressSteps({ preprocessingSteps }: { preprocessingSteps: PreprocessingStepsType }) {
    const { stepsInOrder, progress } = preprocessingSteps;
    const currentStep = stepsInOrder.findIndex(step => progress[step] === null || !progress[step]);
    const numStepsComplete = stepsInOrder.filter(step => progress[step]).length;

    // There's always one extra step at the end after preprocessing is done, generating the response itself.
    const fullStepsInOrder = [...stepsInOrder, 'Generating response'];
    const isGeneratingResponse = numStepsComplete === stepsInOrder.length; // all other steps are done, but generating response is not
    const fullProgress = { ...progress, 'Generating response': isGeneratingResponse ? false : undefined } as Record<string, boolean | undefined>;

    return (
        <center>
            <div style={{ padding: '20px 20px' }}>
                <Steps
                    current={currentStep === -1 ? fullStepsInOrder.length : currentStep}
                    direction="vertical"
                    size="small">
                        {fullStepsInOrder.map((step, index) => (
                            <Step
                                key={index}
                                title={step}
                                icon={fullProgress[step] === false ? <LoadingOutlined /> : undefined}
                                />
                        ))}
                </Steps>
            </div>
        </center>
    );
}
