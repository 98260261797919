import { InstantSearch } from "react-instantsearch";
import NotePage from "./NotePage";
import { PinnedJSONFormsContextProvider, SelectedJSONFormsContextProvider } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { ChatLogContextProvider } from "../../../DecisionGraph/ChatEditor/UI/ChatLog";
import { LLMServerContextProvider } from "../../../DecisionGraph/ChatEditor/UI/SelectableLLM";
import { AlgoliaSearchClient } from "../../../DecisionGraph/AlgoliaSearch/AlgoliaSearchClient";
import { NoteHierarchyContextProvider } from "../NoteInformationComponents/NoteHierarchyProvider";
import { MusicRecommendationProvider } from "../../../DecisionGraph/Utilities/Sound/MusicRecommendationContext";
import { SidebarProvider } from "../../../DecisionGraph/Sidebars/SidebarController";

export default function NotePageContext() {
    return <PinnedJSONFormsContextProvider>
        <SelectedJSONFormsContextProvider>
            <ChatLogContextProvider>
                <LLMServerContextProvider>
                    <InstantSearch searchClient={AlgoliaSearchClient} indexName="AIGotThisNotes" future={{preserveSharedStateOnUnmount: true}}
                    /*routing={true} commented out because InstantSearch routing overwrites our own URLs*/ >
                        <NoteHierarchyContextProvider>
                            <MusicRecommendationProvider>
                                <SidebarProvider>                            
                                    <NotePage/>
                                </SidebarProvider>
                            </MusicRecommendationProvider>
                        </NoteHierarchyContextProvider>
                    </InstantSearch>
                </LLMServerContextProvider>
            </ChatLogContextProvider>
        </SelectedJSONFormsContextProvider>
    </PinnedJSONFormsContextProvider>;
}