// React & state:
import { useContext, useEffect, useRef } from 'react';
// Chat AI:
import { ChatEditorRefFunctions, InclusionByTopic, NoteExclusionType, NoteInclusionType, PreprocessUserMessageReturnType, useSendAnythingToServer } from '../ServerConnection/LLMServer/SendChatToServerHook';
import { ChatLogContext, ChatLogContextProvider } from '../DecisionGraph/ChatEditor/UI/ChatLog';
import { LLMServerContextProvider } from '../DecisionGraph/ChatEditor/UI/SelectableLLM';
import "../Notes/UIs/DraftJSEditor/WYSIWYGEditor.css";
import { ChatWithDraft } from '../DecisionGraph/ChatEditor/UI/ChatWithDraft';
import { InstantSearch } from 'react-instantsearch';
import { AlgoliaSearchClient } from '../DecisionGraph/AlgoliaSearch/AlgoliaSearchClient';
import { TEST_LIST_OF_TEMPLATES } from './TemplateDetectionV8TestPage';
import { detectTemplate } from "../ServerConnection/LLMServer/Prompts/Older/DetectTemplateV8";

const TEST_JUST_ADD_A_TEMPLATE = false;

export function usePreprocessUserMessageForTemplate(chatEditorRef: React.MutableRefObject<ChatEditorRefFunctions>) {
  const {selectedChatMessages} = useContext(ChatLogContext);
  function streamingWasCancelled() {
    console.log("Streaming was cancelled.");
  }
  function streamingHasFinished() {
    console.log("Streaming has finished.");
  }
  const {sendToServerWithStreaming } = useSendAnythingToServer(streamingWasCancelled, streamingHasFinished);


  async function preProcess_UserMessage_Test_WithTemplateDetection():Promise<PreprocessUserMessageReturnType> {
    // Get the user input from the chat editor
    const userMessages = selectedChatMessages.filter((message) => message.role === "user" && message.content);
    if (userMessages.length===0) {
        console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: No user messages found. This should not happen.");
        debugger;
    }
    const preprocessedReturn = {
      systemPrompt:"Respond to the user in a blockquote. Include a H1 header above the blockquote to title the response.",
      notesIncluded: [{noteID:"9883d97c-0269-4692-aaa9-0648dd06d7d7", type:InclusionByTopic}] as NoteInclusionType[],
      notesExcluded: [] as NoteExclusionType[],
      outputOfTopicsThinking: "Topics thinking",
    } as PreprocessUserMessageReturnType;

    const lastUserMessage = userMessages[userMessages.length-1].content as string || "";
    if (!lastUserMessage) {
      // Nothing to process.
      return preprocessedReturn;
    }
    const callFunctionSpec = await detectTemplate(TEST_LIST_OF_TEMPLATES, lastUserMessage, sendToServerWithStreaming);
    if (callFunctionSpec) {
      preprocessedReturn.systemPrompt="Template detected: "+JSON.stringify(callFunctionSpec);
    }
    return preprocessedReturn;
  }
  return preProcess_UserMessage_Test_WithTemplateDetection;
}

export function ChatWithTemplateDraftInner() {
  const chatEditorRef = useRef<ChatEditorRefFunctions>({} as ChatEditorRefFunctions);
  const preprocessUserMessageForTemplate = usePreprocessUserMessageForTemplate(chatEditorRef);

  async function preProcess_UserMessage_Test_JustAddATemplate():Promise<PreprocessUserMessageReturnType> {
    return {
      systemPrompt:"Test System Prompt",
      notesIncluded: [{noteID:"9883d97c-0269-4692-aaa9-0648dd06d7d7", type:InclusionByTopic}] as NoteInclusionType[],
      notesExcluded: [] as NoteExclusionType[],
      outputOfTopicsThinking: "Topics thinking",
    };
  }
  if (TEST_JUST_ADD_A_TEMPLATE)
    chatEditorRef.current.preProcess_UserMessage = preProcess_UserMessage_Test_JustAddATemplate;
  else 
    chatEditorRef.current.preProcess_UserMessage = preprocessUserMessageForTemplate;

  return <div className="HEADER_ABOVE_TABS">
    <ChatWithDraft chatEditorRef={chatEditorRef}/>
  </div>;
}

export default function ChatWithTemplateDraftTestPage() {
  useEffect(() => {
    document.title = 'Chat with Draft Test Page - AI Got This';
  }, []);
  
  return <InstantSearch searchClient={AlgoliaSearchClient} indexName="AIGotThisNotes" future={{preserveSharedStateOnUnmount: true}} routing={true}>
        <ChatLogContextProvider>
          <LLMServerContextProvider>
            <ChatWithTemplateDraftInner/>
          </LLMServerContextProvider>
        </ChatLogContextProvider>
      </InstantSearch>;
}