import { useContext, useMemo, useRef, useState } from 'react';
import { ChatLogContext, ChatMessage2 } from '../../DecisionGraph/ChatEditor/UI/ChatLog';
import { LLMServerContext } from '../../DecisionGraph/ChatEditor/UI/SelectableLLM';
import { ChatCompletionMessageParam, ChatCompletionMessageToolCall } from 'openai/resources';
import findLastIndex from 'lodash/findLastIndex';
import { ChatAPIOptions, ChatStreamErrorCallback, StreamingCallback } from './GenericChatServerConsts';
import { JSONSchemaType } from 'ajv';
import { z } from "zod";
import { estimateTokensFromString } from './LLMTokenCounter';

const DEBUG = false;
const DEBUG_PRINT_TOKENS = true;

export type PreprocessingStepsType = {
    stepsInOrder: string[]; // The steps in the order that they will be processed.
    progress: { [step: string]: null | boolean };
};
const DEFAULT_EMPTY_STEPS = {progress: {}, stepsInOrder: ["checking steps"]} as PreprocessingStepsType;

export type PreProcessUserMessageFuncType = (messages: ChatMessage2[], setStepStarted: (step:string)=>void, setStepComplete: (step: string) => void, setPreprocessingSteps: (steps: PreprocessingStepsType) => void) => Promise<PreprocessUserMessageReturnType>;
export type PostProcessRecievedMessageFuncType = (completedMessage: string) => void;

export type ChatEditorRefFunctions = {
    isWaitingForChat: boolean;

    // Preprocess before sending to server:
    preProcess_UserMessage: PreProcessUserMessageFuncType;

    // Post-process what comes from the server:
    postProcess_RecievedMessage?: PostProcessRecievedMessageFuncType;
};

export const InclusionByTopLevelHierarchy = "top level";
export const InclusionByMention = "mention";
export const InclusionByPreviousChat = "previous chat";
export const InclusionByTemplate = "template";
export const InclusionByTopic = "topic";
export const InclusionByCommand = "command";
export const InclusionByIndirectInclude = "indirect include";

export const ExclusionByContextWindow = "Ran out of context window";

export type InclusionTypes = typeof InclusionByTemplate | typeof InclusionByTopic | typeof InclusionByPreviousChat | typeof InclusionByTopLevelHierarchy | typeof InclusionByMention | typeof ExclusionByContextWindow | typeof InclusionByCommand | typeof InclusionByIndirectInclude;

export type NoteInclusionType = {
    type: InclusionTypes;
    noteID: string;
}

export type NoteExclusionType = NoteInclusionType;

export type NoteThinkingInfo = {
    notesIncluded: NoteInclusionType[];
    notesExcluded: NoteExclusionType[];

    outputOfTopicsThinking: string;
}

export type PreprocessUserMessageReturnType = NoteThinkingInfo & {
    systemPrompt: string;
    // noteIDs: string[];
};

export function useSendAnythingToServer(streamingWasCancelled?:()=>void, streamingHasFinished?:(success:boolean, completedMessageOrError:string)=>void) {
    const controllerRef = useRef<ReadableStreamDefaultController<any>>();
    const abortServerFetchController = useMemo(() => new AbortController(), []);
    const {serverType} = useContext(LLMServerContext);

    function cancelStreaming() {
        streamingWasCancelled?.();
        if (controllerRef.current)
            controllerRef.current.close();
        // Break the connection to the server, too:
        abortServerFetchController.abort();
    }
    function sendToServerWithStreaming(messages: ChatMessage2[], chatAPIOptions?:ChatAPIOptions, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void):Response {
        // console.log('ProChat Messages requested:', messages);
        const readableStream = new ReadableStream({
            async start(controller: ReadableStreamDefaultController<any>) {
                if (!serverType) {
                    // Wait 1 second first, just in case it's still loading:
                    await new Promise(r => setTimeout(r, 1000));
                    if (!serverType) {
                        alert("Sorry, we were unable to set up the server for chat. Please refresh the page and try again, or report this bug.");
                        debugger;
                        return;
                    }
                }
                controllerRef.current = controller;
                // fullOutputPrefix is used to prepend messages that won't come back from the server.
                let fullOutputPrefix = "";
                function addNewOutput(newOutput: string) {
                    if (!newOutput)
                        return;
                    const encoder = new TextEncoder();
                    const newOutputArrayBuffer = encoder.encode(newOutput).buffer;
                    try {
                        controller.enqueue(newOutputArrayBuffer);
                    } catch (e) {
                        console.error("[useSendToServer]>sendToServer> Error adding new output: ", e);
                        debugger;
                    }
                }

                const streamingCallback: StreamingCallback = function (fullOutput: string, newOutput: string, isDone: boolean): void {
                    // Convert newOutput string to an ArrayBuffer:
                    addNewOutput(newOutput);
                    if (isDone) {
                        // For some reason, when we call this, the system doesn't seem to know it's done for a while. Can't figure out why but it's probably an internal bug in ProChat.
                        if (DEBUG) {
                            console.log("[useSendToServer]>sendToServer The server is done! But it might not show in the UI for a bit due to a bug. Full output: ", fullOutputPrefix + fullOutput);
                        }
                        controller.close();
                        streamingHasFinished?.(true, fullOutputPrefix + fullOutput);
                    }
                };
                const errorCallback: ChatStreamErrorCallback = function (error: string) {
                    // We tried "controller.error() on its own, but that doesn't stop nor show the error message. We have to take this into our own hands to render the error.
                    addNewOutput("```" + error + "```");
                    controller.close();
                    controller.error(error);
                    console.error("[useSendToServer]>sendToServer> Error: ", error);
                    streamingHasFinished?.(false, error);
                };
                // if (DEBUG) console.log("[useSendToServer]>sendToServer> Calling chat API with ", openAIMessages);
                // Temperature notes: It might depend on the Chat API. 0.3 seems to be good for Gemini & ChatGPT3.5 but seems too low with GPT-4.
                const newChatAPIOptions = {
                    abortController: abortServerFetchController,
                    streamingCallback,
                    errorCallback,
                    callFunction,
                    ...chatAPIOptions
                } as ChatAPIOptions;
                serverType.chatCall(messages, newChatAPIOptions);
            },
        });
        return new Response(readableStream);
    }
    return { sendToServerWithStreaming, cancelStreaming, serverType };
}

/**************
 * TODO refactor this to use the above function call, to better separate out the concerns.
 * 
 * This would only use the special ChatEditorRefFunctions and then pass the rest to the above function.
 * 
 */
export function useSendToServerWithPreprocessing(
    chatEditorRef: React.MutableRefObject<ChatEditorRefFunctions>,
    setLastSystemPrompt?: (systemPrompt: string) => void
) {
    const { setSelectedChatMessages } = useContext(ChatLogContext);
    const [preprocessingProgressInSteps, setPreprocessingProgressInSteps] = useState<PreprocessingStepsType>(DEFAULT_EMPTY_STEPS);

    function stopInProgress() {
        if (chatEditorRef.current)
            chatEditorRef.current.isWaitingForChat = false;
    }

    function startInProgress() {
        setPreprocessingProgressInSteps(DEFAULT_EMPTY_STEPS);
        if (chatEditorRef.current)
            chatEditorRef.current.isWaitingForChat = true;
    }

    function streamingWasCancelled() {
        stopInProgress();
    }

    function streamingHasFinished(succeeded: boolean, completedMessageOrError: string) {
        if (succeeded) {
            if (chatEditorRef.current.postProcess_RecievedMessage)
                chatEditorRef.current.postProcess_RecievedMessage(completedMessageOrError);
        } else {
            // Failed! TODO pass the error back somehow for display to the user.
        }
        stopInProgress();
    }

    const { cancelStreaming, sendToServerWithStreaming: sendAnythingToServer } = useSendAnythingToServer(streamingWasCancelled, streamingHasFinished);

    function addPreProcessingExtra(messages: ChatMessage2[], preprocessedUserMessage: PreprocessUserMessageReturnType) {
        const { systemPrompt, ...extra } = preprocessedUserMessage;
        const newChatMessages = [...messages];
        const systemMessageIndex = newChatMessages.findIndex((message) => message.role === 'system' && message.content);
        if (systemMessageIndex === -1) {
            newChatMessages.unshift({ role: 'system', content: systemPrompt, userHasMadeEdits: false });
        } else {
            newChatMessages[systemMessageIndex] = { role: 'system', content: systemPrompt, userHasMadeEdits: false };
        }
        const lastUserMessageIndex = findLastIndex(newChatMessages, (message) => message.role === 'user' && !!message.content);
        if (lastUserMessageIndex === -1) {
            console.error("[useSendToServer]>sendToServer> Error: Couldn't find the last user message in the chat log. This is a bug, seemingly corrupted chat messages. ", newChatMessages);
            debugger;
            return messages;
        }
        const lastUserMessage = newChatMessages[lastUserMessageIndex];
        const newLastUserMessage = { ...lastUserMessage, extra: { ...lastUserMessage.extra, ...extra } } as ChatMessage2;
        newChatMessages[lastUserMessageIndex] = newLastUserMessage;
        if (DEBUG) console.log("[useSendToServer]>sendToServer>addPreProcessingInfo Added prefix message: ", newChatMessages);
        setSelectedChatMessages(newChatMessages);
        return newChatMessages;
    }

    function setStepStarted(step: string) {
        setPreprocessingProgressInSteps((prevSteps) => ({ ...prevSteps, progress: { ...prevSteps.progress, [step]: null } }));
    }

    async function getOpenAIMessages(messages: ChatMessage2[]) {
        let newChatMessages = messages;

        if (!chatEditorRef.current.preProcess_UserMessage) {
            console.error("[useSendToServer]>sendToServer> Error: No preProcess_UserMessage function found in ChatEditorRefFunctions.");
            debugger;
            return { newChatMessages };
        }
        if (chatEditorRef.current.preProcess_UserMessage) {
            const preprocessedUserMessage = await chatEditorRef.current.preProcess_UserMessage(newChatMessages,
                setStepStarted,
                function setStepComplete(step: string) {
                    setPreprocessingProgressInSteps((prevSteps) => ({ ...prevSteps, progress: { ...prevSteps.progress, [step]: true } }));
                },
                setPreprocessingProgressInSteps, // so user can call it
            );
            if (setLastSystemPrompt)
                setLastSystemPrompt(preprocessedUserMessage.systemPrompt);
            if (DEBUG) console.log("[useSendToServer]>sendToServer> Will populate system prompt ", preprocessedUserMessage);
            const hasAnOldSystemMessage = newChatMessages.length > 0 && newChatMessages[0].role === 'system';
            if (!hasAnOldSystemMessage)
                newChatMessages.unshift({ role: 'system', content: preprocessedUserMessage.systemPrompt, userHasMadeEdits: false });
            else {
                newChatMessages[0].content = preprocessedUserMessage.systemPrompt;
            }
            const userTokens = newChatMessages.filter(msg=>msg.role==="user").reduce((acc, msg) => acc + estimateTokensFromString(msg.content as string), 0);
            const assistantTokens = newChatMessages.filter(msg=>msg.role==="assistant").reduce((acc, msg) => acc + estimateTokensFromString(msg.content as string), 0);
            const systemTokens = estimateTokensFromString(preprocessedUserMessage.systemPrompt);
            if (DEBUG_PRINT_TOKENS) console.log("Sending chat to server with ", Math.round((userTokens+assistantTokens+systemTokens)/1000), "k tokens (", Math.round(userTokens/1000), "k user + ", Math.round(assistantTokens/1000), "k assistant + ", Math.round(systemTokens/1000), "k system tokens).");

            newChatMessages = addPreProcessingExtra(newChatMessages, preprocessedUserMessage);
        }
        return { newChatMessages };
    }

    async function sendToServerWithStreaming(messages: ChatMessage2[], chatAPIOptions?: ChatAPIOptions, callFunction?: (functionSpec: ChatCompletionMessageToolCall.Function[]) => void): Promise<{ response: Response, newChatMessages: ChatMessage2[] }> {
        startInProgress();
        const { newChatMessages } = await getOpenAIMessages(messages);
        return { response: sendAnythingToServer(newChatMessages, chatAPIOptions, callFunction), newChatMessages };
    }
 
    return { sendToServerWithStreaming, cancelStreaming, preprocessingProgressInSteps };
}


export async function getStringFromResponse(response: Response):Promise<string> {
    if (!response.body) {
        console.error("[ChatWithDraft]>submitUserMessage> Response has no body.");
        return "";
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    // Create the new assistant message:
    // const newAssistantMessage = {role: "assistant", content:""} as ChatMessage2;
    let newMessageContent = "";
    while (true) {
        const {done, value} = await reader.read();
        if (done) {
            break;
        }
        const newChunk = decoder.decode(value);
        newMessageContent += newChunk;
    }
    return newMessageContent;
}

export function useSendStructuredOutputToServer() {
    const {serverType} = useContext(LLMServerContext);

    async function sendStructuredOutput<T>(messages: ChatCompletionMessageParam[], zodSchema: z.ZodSchema<T>, jsonSchema: JSONSchemaType<T>, schemaName:string, chatAPIOptions?: ChatAPIOptions) {
        if (!serverType.structuredOutputCall) {
            console.error("[useSendStructuredOutputToServer]>sendStructuredOutput> Error: This server doesn't support structured output.");
            return Promise.resolve({ success: false, error: "This server doesn't support structured output." });
        }
        return serverType.structuredOutputCall(messages, zodSchema, jsonSchema, schemaName, chatAPIOptions);
    }

    return { sendStructuredOutput };
}