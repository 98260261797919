import{ createContext } from 'react';
import { getQuery, useQuery } from '../Utilities/URLQueryParams';
import { useLocation, useNavigate } from 'react-router-dom';

const SIDEBAR_NOT_VISIBLE_VALUE = "None";

export type SidebarContextType = {
    currentSidebar: string;
    isSidebarVisible: boolean;
    setCurrentSidebar: (sidebar: string) => void;
    hideSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContextType>({} as SidebarContextType);

export function SidebarProvider({children}:{children:any}) {
    const query = useQuery();
    const navigate = useNavigate();
    const location = useLocation();
    const currentSidebar = query.get(SIDEBAR_DISPLAYED_PARAMETER) || SIDEBAR_NOT_VISIBLE_VALUE;
    const isSidebarVisible = currentSidebar !== SIDEBAR_NOT_VISIBLE_VALUE;
    function setCurrentSidebar(name:string) {
      const freshQuery = getQuery();
      freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,name);
      navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
    }
    function hideSidebar() {
      setCurrentSidebar(SIDEBAR_NOT_VISIBLE_VALUE);
    }
    
    return (
        <SidebarContext.Provider value={{ currentSidebar, isSidebarVisible, setCurrentSidebar, hideSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
};export const SIDEBAR_DISPLAYED_PARAMETER = "sidebarDisplayed";

