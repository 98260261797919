// Moods
export const MOOD_UPBEAT = "Mood: Upbeat";
export const MOOD_CALM = "Mood: Calm";
export const MOOD_EPIC = "Mood: Epic";
export const MOOD_SAD = "Mood: Sad";
export const MOOD_HAPPY = "Mood: Happy";
export const MOOD_GLORIOUS = "Mood: Glorious";
export const MOOD_HOPEFUL = "Mood: Hopeful";
export const MOOD_EMOTIONAL = "Mood: Emotional";
export const MOOD_SACRED = "Mood: Sacred (e.g. temple)";
export const MOOD_WONDER = "Mood: Wonder";
export const MOOD_STARTS_SAD = "Mood: Starts Sad";
export const MOOD_SUSPENSE = "Mood: Suspense";
export const MOOD_TENSION = "Mood: Tension";
export const MOOD_VICTORY = "Mood: Victory";
export const MOOD_DEFEAT = "Mood: Defeat";
export const MOOD_HORROR = "Mood: horror";
// Multiple moods:
export const MOOD_STARTS_SUSPENSEFUL = "Mood: Starts Suspenseful";
export const MOOD_ENDS_HOPEFUL = "Mood: Ends Hopeful";
export const MOOD_ENDS_SLOWER = "Mood: Ends Slower";

// Intensity
export const INTENSITY_AMBIENT = "Intensity: ambient";
export const INTENSITY_MILD = "Intensity: mild";
// Multiple intensities:
export const INTENSITY_STARTS_MILD = "Intensity: Starts Mild";


export const TAG_BEGINNING_OF_AN_ADVENTURE = "beginning of an adventure";
export const TAG_EXPLORING = "exploring";
export const TAG_BATTLE = "battle";
export const TAG_REGULAR_BATTLE = "regular battle";
export const TAG_RESCUE = "rescue";
export const TAG_STINGER = "stinger"; // sharp sound, very short, less than 30 seconds
export const TAG_DISCOVERY = "discovery"; // A new thing is found.
// export const TAG_DRAMATIC_CHANGE = "dramatic change";
export const TAG_MEDIEVAL = "medieval";
export const TAG_TAVERN = "tavern";
export const TAG_TOWN = "town";
export const TAG_STORYTELLING = "storytelling";
export const TAG_TRAVEL = "travel";
export const TAG_CELTIC = "celtic";
export const TAG_CHASE = "chase";

export const TAG_TBD = "TBD"; // Temporary tag to use when testing to figure out which tags should be used.

// Genre:
const GENRE_PREFIX = "Genre: ";
export const GENRE_FANTASY = GENRE_PREFIX+"Medieval/Fantasy";
export const GENRE_SCIFI = GENRE_PREFIX+"Sci-Fi";
export const GENRE_MODERN = GENRE_PREFIX+"Modern";
export const GENRE_TBD = GENRE_PREFIX+"TBD";

export function isTagGenre(tag: string) {
    return tag.startsWith(GENRE_PREFIX);
}

/*************************************
 * To upload more files:
 * - Get files that are properly licensed (e.g. Pond5 subscription)
 * - Convert to ogg
 * - Upload to https://console.cloud.google.com/storage/browser/lofty-door-320319.appspot.com/Music?authuser=0&hl=en&project=lofty-door-320319&pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&prefix=&forceOnObjectsSortingFiltering=true
 * - Add to the list below. Start with "TAG_TBD" so we can test them and add tags.
 * 
 */

export type MusicTone = {
    name?: string; // TODO This will become required once we've added it to all entries.
    source_url?: string;
    description?: string;
    path: string;
    tags: string[];
};

export const SOUNDTRACK = [
    {
        name: "Unlimited Glory (Orchestra Epic Dramatic Adventure Uplifting Massive Triumph)",
        source_url: "https://www.pond5.com/royalty-free-music/item/083463896-unlimited-glory-orchestra-epic",
        description: "An epic, dramatic and uplifting trailer track made with epic orchestra, massive drums and more. Structure in three parts (trailer based). Epic / Dramatic / Glorious.",
        path: "Music/083463896-unlimited-glory-orchestra-epic.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI,
            MOOD_EPIC, MOOD_UPBEAT, MOOD_GLORIOUS, TAG_BEGINNING_OF_AN_ADVENTURE]
    }, {
        name: "Adventure",
        source_url: "https://www.pond5.com/royalty-free-music/item/010894270-adventure",
        description: "An inspiring, emotional cinematic track. This music is perfect for an adventure / action scene",
        path: "Music/010894270-adventure.ogg",
        tags: [GENRE_FANTASY, MOOD_UPBEAT, MOOD_EPIC, TAG_BEGINNING_OF_AN_ADVENTURE]
    }, {
        name: "Epic Action Adventure Medium",
        source_url: "https://www.pond5.com/royalty-free-music/item/111821899-epic-action-adventure-medium",
        path: "Music/111821899-epic-action-adventure-medium.ogg",
        // Also "Energetic"
        tags: [GENRE_FANTASY, MOOD_EPIC, MOOD_UPBEAT, MOOD_HOPEFUL, TAG_BEGINNING_OF_AN_ADVENTURE]
    }, {
        name: "Epic Trailer Music",
        source_url: "https://www.pond5.com/royalty-free-music/item/115364957-epic-trailer-music",
        path: "Music/115364957-epic-trailer-music.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_STARTS_SAD, MOOD_ENDS_HOPEFUL, TAG_BEGINNING_OF_AN_ADVENTURE]
    }, {
        name: "Inspirational Cinematic(Adventure,Suspense,Meditation,Emotional,Journey)(Ver.1) - P5",
        source_url: "https://www.pond5.com/royalty-free-music/item/120021443-inspirational-cinematicadventu",
        // No description available on website.
        // also "Grace"
        path: "Music/120021443-inspirational-cinematicadventu.ogg",
        tags: [GENRE_MODERN, GENRE_FANTASY,
            TAG_EXPLORING, MOOD_CALM, MOOD_HOPEFUL]
    }, {
        name: "Savannah Sunrise (Less Drums) Electro Ethnic Slow Ambient Floating Jungle Exotic",
        source_url: "https://www.pond5.com/royalty-free-music/item/048395854-savannah-sunrise-less-drums-el",
        description: "Light ambient, floating and atmospheric world / ethnic track. Deep mystery, beautiful scenery. Perhaps the wonder of nature, or the vastness of space. Has a mystical / world / foreign feel, but without being tied to a particular country or region. Good for nature, discovery, exploration and more. Potentially Africa / African, South American / Amazon / Jungle / natural scenery. Perhaps even sci-fi, an exotic alien planet, or a place of fantasy.",
        path: "Music/048395854-savannah-sunrise-less-drums-el.ogg",
        // Also "Mystical"
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, MOOD_CALM, INTENSITY_AMBIENT]
    }, {
        name: "Inspirational Piano Ambient - Hopeful And Peaceful Background Music - P5",
        source_url: "https://www.pond5.com/royalty-free-music/item/078590456-inspirational-piano-ambient-ho",
        description: "Inspirational, calm and hopeful ambient piano background music, featuring deep chords, gentle pads and evocative melodies that create a reflective, dreamy and peaceful mood. This music will be good as a background for church presentation, nature slide show, philosophical interview, promotion, or time lapse video.",
        path: "Music/078590456-inspirational-piano-ambient-ho.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_CALM, INTENSITY_AMBIENT]
    }, {
        name: "Epic Cinematic Edm",
        source_url: "https://www.pond5.com/royalty-free-music/item/111860261-epic-cinematic-edm",
        description: "This track will perfectly fit for movie, advertising, corporate, event",
        path: "Music/111860261-epic-cinematic-edm.ogg",
        tags: [
            GENRE_FANTASY, GENRE_SCIFI, GENRE_MODERN,
            TAG_BATTLE, MOOD_EPIC]
    }, {
        name: "Beyond Hell (Orchestra Epic Horde War Intense Action Battle Destruction)",
        source_url: "https://www.pond5.com/royalty-free-music/item/48262363-beyond-hell-orchestra-epic-horde-war-intense-action-battle-d",
        description: "A fiery and dramatic, epic, massive impact track, Urgent, desperate, intense and violent, Huge pounding percussion, full orchestra and choir, Also available as a Rock Mix, with additional heavy rock guitars and rock drums",
        path: "Music/048262363-beyond-hell-orchestra-epic-hor.ogg",
        tags: [GENRE_FANTASY, TAG_BATTLE, MOOD_EPIC, MOOD_HORROR]
    }, {
        name: "D Lukyanov - Rescue In The Ocean (Dramatic Danger Pomous Heroic Adventure)",
        source_url: "https://www.pond5.com/royalty-free-music/item/067400935-d-lukyanov-rescue-ocean-dramat",
        description: "A dramatic hero type theme. A dangerous situation, people in distress, lives in danger. Perhaps a brave rescue team or military intervention.",
        path: "Music/067400935-d-lukyanov-rescue-ocean-dramat.ogg",
        tags: [GENRE_MODERN, GENRE_SCIFI, TAG_BATTLE, TAG_RESCUE]
    }, {
        name: "Powerful Sport Hip-Hop (Epic Motivational Triumphal Driving Battle Cinematic) - P5",
        source_url: "https://www.pond5.com/royalty-free-music/item/84064974-powerful-sport-hip-hop-epic-motivational-triumphal-driving-b",
        description: "Powerful Sport Hip-Hop (Epic Motivational Energetic Driving Battle Cinematic) triumphal, heroic track. Perfect for use as film & videogame trailers, recitation, rap, Youtube video projects, trailer intro, war, commercials, slideshows, web videos, info-graphics, documentaries, featurettes, social media, Linkedin, Facebook, Instagram, Twitter, YouTube videos, Vimeo videos, fight, battle, photography production, sport videos, racing and flying videos, blogs, TV and radio media, etc.",
        path: "Music/084064974-powerful-sport-hip-hop-epic-mo.ogg",
        // TODO if we ever get too many fantasy & enough of other genres, remove GENRE_FANTASY
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, TAG_BATTLE, MOOD_EPIC]
    }, {
        path: "Music/089113040-epic-hybrid.ogg",
        // TODO if we ever get too many fantasy & enough of other genres, remove GENRE_FANTASY, it's a bit techno(?)
        tags: [GENRE_SCIFI, GENRE_MODERN, GENRE_FANTASY, TAG_BATTLE, MOOD_EPIC]
    }, {
        path: "Music/093730670-epic-medieval-tale.ogg",
        tags: [GENRE_FANTASY, TAG_BATTLE, MOOD_EPIC, MOOD_TENSION]
    }, {
        path: "Music/106062209-epic-drums-trailer-blockbuster.ogg",
        // TODO if we ever get too many fantasy & enough of other genres, remove GENRE_FANTASY, it's a bit techno(?)
        tags: [GENRE_FANTASY, GENRE_SCIFI, GENRE_MODERN, TAG_BATTLE, MOOD_EPIC]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/114134610-far-winds-orchestra-epic-soari",
        description: "Soaring, uplifting and positive, epic orchestral theme. Adventure / Wonderment / Grand.",
        path: "Music/114134610-far-winds-orchestra-epic-soari.ogg",
        tags: [GENRE_FANTASY, MOOD_HOPEFUL, TAG_EXPLORING]
    }, {
        path: "Music/114455103-hero-full-lyric-version-altern.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, TAG_BATTLE, MOOD_EPIC]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/123973368-men-honor-drums-and-ostinato-o",
        description: "Rousing and victorious orchestral theme, soaring with epic choir. Heroic / Adventure / Glory.",
        path: "Music/123973368-men-honor-drums-and-ostinato-o.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, TAG_BATTLE, TAG_REGULAR_BATTLE]
    }, {
        path: "Music/069116860-flying-swords-hollywood-cinema.ogg",
        tags: [GENRE_FANTASY,
            TAG_BATTLE, MOOD_EPIC]
    }, {
        path: "Music/104546828-cinematic-epic-fantasy-theme-d.ogg",
        tags: [GENRE_FANTASY, TAG_BATTLE, TAG_REGULAR_BATTLE]
    }, {
        path: "Music/108939913-epic-cinematicinspirational-ad.ogg",
        tags: [GENRE_FANTASY, MOOD_TENSION, TAG_BATTLE]
    }, {
        path: "Music/072405183-cinematic-mystery-1-minute-edi.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI,
            MOOD_TENSION, TAG_BATTLE]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/077794534-doomsday-dark-epic-trailer-apo",
        description: "Dark cinematic track starting with a dark foreseeing drone going into an epic ending with choir and pipe organ. The apocalypse is coming",
        path: "Music/077794534-doomsday-dark-epic-trailer-apo.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, MOOD_TENSION, MOOD_HORROR]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/064042966-knights-and-saints-orchestra-e",
        description: "A dramatic, orchestral, epic, emotional and expressive track, made with epic orchestra and epic drums made in trailer (3-part format where first part is gradation, the second part is tutti or most expressive part, and then the outro / afterthought).",
        path: "Music/064042966-knights-and-saints-orchestra-e.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_DEFEAT, MOOD_SAD, MOOD_HOPEFUL]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/051139596-high-impact-dramatic-cinematic",
        description: "Corporate Pop Rock Inspiring Motivation To Win Theme This sound track is Perfect for background music, corporate, or personal presentations, advertising film, radio, television, web casts. Our royalty free stock production music is also ideal for youtube profit sharing program.",
        path: "Music/051139596-high-impact-dramatic-cinematic.ogg",
        tags: [GENRE_MODERN, MOOD_EMOTIONAL, MOOD_HOPEFUL]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/090537112-cinematic-intense-trailer",
        description: "Cinematic Intense Trailer is a hybrid, epic trailer intro contains huge drums, digital sound effects, big orchestra and percussion.",
        path: "Music/090537112-cinematic-intense-trailer.ogg",
        tags: [GENRE_FANTASY, MOOD_EMOTIONAL, MOOD_HOPEFUL, MOOD_SUSPENSE, INTENSITY_STARTS_MILD]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/096480470-new-dawn-cinematic-trailer-ver",
        description: "An orchestral piece with acoustic guitar, electric guitars, drums and synths. It starts quietly with ease like a beautiful morning and evolves into a giant dawn when the band and the choir sets in.  This music is ideally for cinematic trailers, showreels and movie clips that are in need of muic with an emotional development and high climax.",
        path: "Music/096480470-new-dawn-cinematic-trailer-ver.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_EMOTIONAL, MOOD_STARTS_SAD, MOOD_ENDS_HOPEFUL]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/114059101-dramatic-emotional-epic-traile.ogg",
        description: "Epic Dramatic orchestral track, starting with emotional piano, then strings, building up to the epic orchestral. Perfect for film/game trailers, commercials, motivational videos and so on. Included Instruments: Piano, Strings, Brass and Percussion.",
        path: "Music/114059101-dramatic-emotional-epic-traile.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_EMOTIONAL]
    }, {
        path: "Music/124352320-yellow-cinematic-dramatic-posi.ogg",
        tags: [GENRE_MODERN,
            MOOD_EMOTIONAL, MOOD_HOPEFUL]
    }, {
        path: "Music/073180874-cinematic-epic-blockbuster-emo.ogg",
        tags: [GENRE_MODERN, GENRE_FANTASY, MOOD_EMOTIONAL, MOOD_UPBEAT, MOOD_EPIC, MOOD_HOPEFUL]
    }, {
        path: "Music/101214048-china-inspiring-cellos-dramati.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_EMOTIONAL, MOOD_HOPEFUL]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/107726227-epical-and-emotional-cinematic",
        // No description on website
        path: "Music/107726227-epical-and-emotional-cinematic.ogg",
        tags: [GENRE_MODERN, GENRE_FANTASY, MOOD_EMOTIONAL, MOOD_EPIC, MOOD_STARTS_SAD, MOOD_ENDS_HOPEFUL]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/109465491-epic-inspirational-movie-trail",
        description: "High quality epic music for any kind of production. Perfect for creating epic mood in various projects",
        path: "Music/109465491-epic-inspirational-movie-trail.ogg",
        tags: [GENRE_MODERN, GENRE_FANTASY, MOOD_EMOTIONAL, MOOD_EPIC, MOOD_UPBEAT, MOOD_ENDS_SLOWER]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/115087218-epic-movies",
        description: "High quality epic music for any kind of production.",
        path: "Music/115087218-epic-movies.ogg",
        tags: [GENRE_FANTASY, MOOD_EPIC, MOOD_UPBEAT, MOOD_ENDS_SLOWER]
    }, {
        path: "Music/117349105-dramatic-cinematic-epic-traile.ogg",
        tags: [GENRE_FANTASY, MOOD_EPIC, TAG_BEGINNING_OF_AN_ADVENTURE]
    }, {
        path: "Music/052997125-dramatic-epic-inspirational-tr.ogg",
        // TODO if we ever get too many fantasy & enough of other genres, remove GENRE_FANTASY
        tags: [GENRE_MODERN, GENRE_FANTASY, MOOD_EMOTIONAL, MOOD_EPIC, MOOD_EMOTIONAL]
    }, {
        path: "Music/076755955-sacred-cinematic-strings-and-c.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_SACRED, MOOD_SAD]
    }, {
        path: "Music/119476786-epic-dramatic-adventure-film-c.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, TAG_STINGER, MOOD_EPIC, MOOD_ENDS_SLOWER, MOOD_UPBEAT, MOOD_VICTORY]
    }, {
        path: "Music/122945730-epic-cinematic-stinger-action-.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, TAG_STINGER]
    }, {
        path: "Music/118927569-cinematic-drum-triplets.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, TAG_STINGER, MOOD_SUSPENSE]
    }, {
        path: "Music/088124203-road-glory-no-melody-epic-insp.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, MOOD_EMOTIONAL, MOOD_ENDS_HOPEFUL, INTENSITY_MILD]
    }, {
        path: "Music/055636279-orange-sun-piano-classical-mel.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_CALM]
    }, {
        path: "Music/091084714-exciting-event-inspirational-e.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_WONDER, INTENSITY_MILD ]
    }, {
        path: "Music/105702413-big-future-orchestral-epic-pos.ogg",
        tags: [GENRE_FANTASY, MOOD_WONDER, INTENSITY_STARTS_MILD]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/114134232-great-marvels-world-adventure-amazement-epic-grand-uplifting",
        path: "Music/114134232-great-marvels-world-adventure-.ogg",
        tags: [GENRE_FANTASY, MOOD_WONDER, MOOD_EPIC, TAG_DISCOVERY],
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/115273786-inspirational-piano-suspense-a",
        // No description on site.
        path: "Music/115273786-inspirational-piano-suspense-a.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_WONDER, MOOD_ENDS_HOPEFUL]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/048258053-glimpse-fairyland-grand-orches",
        description: "A beautiful and magical orchestral fantasy track, available with or without choir. Pastoral, melodic, sweeping passages of orchestral strings, harp and flutes paint a picture of magical worlds, amazing discoveries, new beginnings. Beauty and fantasy.",
        path: "Music/048258053-glimpse-fairyland-grand-orches.ogg",
        tags: [GENRE_FANTASY, MOOD_WONDER, TAG_DISCOVERY, MOOD_CALM]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/60773216-epic",
        description: "Epic D is a beautiful epic track with an emotional cinematic ambient structure making it perfect for your action-packed projects, film intros and trailers, or any intense promos.",
        path: "Music/060773216-epic.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_WONDER, MOOD_EMOTIONAL, MOOD_EPIC]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/063073916-fairy-world-60sec-fantasy-cine",
        description: "An enchanted world, full of flutes, horns, harps and thunderous timpani. Perfect for magical atmospheres, mystical adventures and secret doors. The mood on the song was inspired by Tim Burton fantasy movies like Alice in Wonderland, Sweeney Todd, Charlie and the Chocolate Factory, Nightmare Before Christmas",
        path: "Music/063073916-fairy-world-60sec-fantasy-cine.ogg",
        tags: [GENRE_FANTASY, MOOD_WONDER, TAG_DISCOVERY]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/066683599-sailing-skies-orchestra-midtem",
        description: "A light and uplifting orchestral track for grand and fun adventures. This track is great for more positive and adventurous projects, be it setting sails on the seven seas, soaring to new destinations, a bustling adventure town - or taking on a new challenge.",
        path: "Music/066683599-sailing-skies-orchestra-midtem.ogg",
        tags: [GENRE_FANTASY, MOOD_WONDER]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/083501052-maid-braveheart-percussion-and",
        description: "A medieval fantasy track made with acoustic medieval instruments. Easy and relaxed, with a nostalgic, mellow vibe.",
        path: "Music/083501052-maid-braveheart-percussion-and.ogg",
        tags: [GENRE_FANTASY, TAG_MEDIEVAL, TAG_TOWN, TAG_TAVERN]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/125047293-b-lynne-crystal-lake-orchestra",
        description: "Cinematic, chamber orchestra, storytelling track featuring piano, woodwinds and strings. Inspirational, elegant and timeless. Stately / Honorable / Graceful.",
        path: "Music/125047293-b-lynne-crystal-lake-orchestra.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, MOOD_HOPEFUL, TAG_STORYTELLING]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/053206524-heroic-main-titles",
        description: "This heroic orchestral track would suit for either a montage, credits or opening scene. Contains strings and epic brass with some heavy percussion elements and ethnic instruments like a Japanese flute and taiko drums. Instrumental, Action, Adventure Orchestral",
        path: "Music/053206524-heroic-main-titles.ogg",
        tags: [GENRE_FANTASY, MOOD_EPIC, MOOD_UPBEAT]
    }, {
        // TODO this one in particular would benefit from a full name since it gets cut off without the "a"
        source_url: "https://www.pond5.com/royalty-free-music/item/066683717-medieval-tale-medieval-orchest",
        description: "An instrumental track in Medieval / Historical sound. Atmospheric and wistful. Good for history / historical projects, fantasy, adventure and folk tales.",
        path: "Music/066683717-medieval-tale-medieval-orchest.ogg",
        tags: [GENRE_FANTASY, TAG_MEDIEVAL, MOOD_CALM, TAG_STORYTELLING]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/070956315-river-life-orchestra-epic-adve",
        description: "An emotional, epic, expressive, dramatic and cinematic track made with chamber strings, epic orchestra and big drums. The track is in trailer structure, divided into three parts: Intro, Main triumph part and Outro.",
        path: "Music/070956315-river-life-orchestra-epic-adve.ogg",
        tags: [GENRE_FANTASY, MOOD_EPIC, MOOD_EMOTIONAL]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/143338625-beautiful-open-world-explorati",
        description: "RPG/Fantasy Exploration Music! Perfect for medieval, middle-age, or fantasy videos and projects. Background music for corporate use, video-games, presentations, advertising, TV ads, vlogs, Youtube videos, and more.",
        path: "Music/143338625-beautiful-open-world-explorati.ogg",
        tags: [GENRE_FANTASY, INTENSITY_AMBIENT, TAG_TRAVEL, TAG_EXPLORING, MOOD_CALM]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/120302348-giannis-misty-underscore-cinem",
        description: "The music begins with percussion and subtle strings and brasses. Then it moves into a percussion driven theme played by strings. Later also piano enters playing lead. The music builds dynamically throughout the track, and in the end the piano is supported by bouzouki.",
        path: "Music/120302348-giannis-misty-underscore-cinem.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, MOOD_SUSPENSE]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/094845349-journey-nomad-orchestra-slow-e",
        description: "A textured piece of music which is given emotional focus with a solo cello at its heart. There is a feeling of being on a journey with this track with its variety of sections that never make it feel repetitive but always moving forward. The world fusion feel makes it suitable for a variety of projects from documentaries to fantasy films.",
        path: "Music/094845349-journey-nomad-orchestra-slow-e.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, TAG_TRAVEL, MOOD_STARTS_SAD, MOOD_ENDS_HOPEFUL, MOOD_EMOTIONAL]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/056189994-celtic-music-travel-journey-tr",
        description: "Celtic music with traditional instruments. This magical track with ethnic flute, harp and strings is perfect for videos, documentaries or films. Great for travel, folklore, journey, world and Irish videos.",
        path: "Music/056189994-celtic-music-travel-journey-tr.ogg",
        tags: [GENRE_FANTASY, TAG_TRAVEL, TAG_CELTIC]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/212618036-medieval-caravans-crossing-cal",
        description: "This orchestral track is composed in the medieval fantasy style. It’s dynamic, calm and peaceful. Musical instruments used: Magical Harp, Emotional String orchestra, Ambient Zither and Dulcimer, Folk Percussion, Fairytale Woodwinds, Epic Choir, Powerful Brass ensemble. This soundtrack is the perfect choice for any audio / video / game project requires fairy tale and adventure music background.",
        path: "Music/212618036-medieval-caravans-crossing-cal.ogg",
        tags: [GENRE_FANTASY, TAG_TOWN, TAG_MEDIEVAL, TAG_EXPLORING, TAG_TRAVEL, MOOD_ENDS_HOPEFUL, MOOD_STARTS_SUSPENSEFUL]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/267371106-bukowski-chased-through-labyri",
        description: "An action-packed cue with a strong percussive beat throughout. Chromatic and intense. Perfect for an action scene or tense drama.",
        path: "Music/267371106-bukowski-chased-through-labyri.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, MOOD_HORROR, TAG_CHASE]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/048257985-place-legends-ancient-ethnic-t",
        description: "Underscore / background track for use in fantasy / history / cultural / ancient tribes, travel / journey to far-away places or fantasy worlds. Haunting, wistful, good for use with adventure games, drama / storytelling in ethnic / world settings, etc.",
        path: "Music/048257985-place-legends-ancient-ethnic-t.ogg",
        tags: [GENRE_FANTASY, TAG_STORYTELLING, TAG_TRAVEL, MOOD_TENSION]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/232625533-journey-self-discovery-dark-am",
        description: "This ambient mysterious track is designed to take you on a journey of self-discovery and meditation. The hypnotic and repetitive sounds create a floating sensation, allowing your mind to wander and explore your innermost thoughts and desires.",
        path: "Music/232625533-journey-self-discovery-dark-am.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, INTENSITY_AMBIENT, MOOD_TENSION]
    }, {
        source_url: "https://www.pond5.com/royalty-free-music/item/267371090-bukowski-terror-intense-moody-",
        description: "Brooding and ominous, this dark tension & horror track grows from a quiet drone and builds to an intense atonal conclusion. (Stem files available.)",
        path: "Music/267371090-bukowski-terror-intense-moody-.ogg",
        tags: [GENRE_FANTASY, GENRE_MODERN, GENRE_SCIFI, MOOD_HORROR]
    }
    /*********
     c:\Users\aaron\Downloads\267371091-bukowski-dark-secrets.ogg
     */
] as MusicTone[];

function checkForUniquePaths() {
    const paths = SOUNDTRACK.map(music => music.path);
    const uniquePaths = new Set(paths);
    if (uniquePaths.size !== paths.length) {
        console.error("There are duplicate paths in the soundtrack!");
    }
}
checkForUniquePaths();