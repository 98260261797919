import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import AudioPlayer from 'react-h5-audio-player'; // Doc at https://github.com/lhz516/react-h5-audio-player?tab=readme-ov-file
import 'react-h5-audio-player/lib/styles.css';
import { Button, Spin, Tooltip } from "antd";
import { StepForwardOutlined } from "@ant-design/icons";
import { FIREBASE_STORAGE } from "../../../AppBase/App";
import { MusicTone } from "./Soundtrack1"; // if not already imported

const DEBUG = false;
export const PLAYER_UI_FANCY = "Fancy";
export const PLAYER_UI_BUTTON = "Button";
export const DRAWER_UI = "Drawer";
export const PLAYER_UIS = [PLAYER_UI_FANCY, PLAYER_UI_BUTTON, DRAWER_UI];
export const DEFAULT_PLAYER_UI = PLAYER_UI_BUTTON;

export type CurrentTrackData = {
    humanFriendlyFileName:string,
    trackPath:string
};


export type CloudPlaylistRef = {
    setIsPlaying:(isPlaying:boolean)=>void;
    isPlaying:boolean;
};

/*********************
 * CloudPlaylistComponent
 * 
 * This component plays a playlist of sound files from Google Cloud Storage.
 * 
 */
export function CloudPlaylist({playlist, playerUI=PLAYER_UI_FANCY,randomizeOrder=false,loop=false,showLoopButton=false,onDarkBackground=true,autoplay=true,tooltipContents=<></>, onTrackChange, onPlayingChanged, defaultVolume=1, cloudPlaylistRef, playbackRate}:{playlist:Array<string | MusicTone>, playerUI?:string, randomizeOrder?:boolean, loop?:boolean, showLoopButton?:boolean, onDarkBackground?:boolean,autoplay?:boolean,tooltipContents?:ReactElement, onTrackChange?:(trackData:CurrentTrackData)=>void, onPlayingChanged?:(isPlaying:boolean)=>void, defaultVolume?:number, cloudPlaylistRef?:React.MutableRefObject<CloudPlaylistRef | undefined>, playbackRate?:number}) {
    const [playlistIndexState, setPlaylistIndex] = useState(0);
    const playlistIndex = playlistIndexState>=playlist.length?0:playlistIndexState; // After the playlist is changed, the old index could be out of bounds.
    const [isPlaying, setIsPlayingInner] = useState(false);
    const audioH5Ref = useRef<AudioPlayer>(null);
    const [musicUrl, setMusicUrl] = useState<string|undefined>(undefined);
    function setIsPlaying(isPlaying:boolean) {
        if (DEBUG) console.log("[CloudPlaylistComponent] setIsPlaying(",isPlaying,")");
        setIsPlayingInner(isPlaying);
        if (onPlayingChanged) {
            onPlayingChanged(isPlaying);
        }
    }
    if (cloudPlaylistRef) {
        cloudPlaylistRef.current = {setIsPlaying, isPlaying};
    }
    const playlistOrdered = useMemo(() => {
        if (randomizeOrder) {
            return [...playlist].sort(() => Math.random() - 0.5);
        } else {
            return playlist;
        }
    }, [playlist, randomizeOrder]);
    useEffect(() => {
        const currentItem = playlistOrdered[playlistIndex];
        if (currentItem === undefined) return;
        const filePath = typeof currentItem === "string" ? currentItem : currentItem.path;
        const musicTestFileRef = storageRef(FIREBASE_STORAGE, filePath);
        try {
            getDownloadURL(musicTestFileRef).then((url) => {
                setMusicUrl(url);
            }).catch((error) => {
                console.error("[CloudPlaylistComponent] Error for path:", filePath, error);
            });
        } catch (e) {
            console.error("[CloudPlaylistComponent] Error calling getDownloadURL for path:", filePath, e);
        } 
    }, [playlistOrdered[playlistIndex]]);

    function playNextSong() {
        if (DEBUG) console.log("[CloudPlaylistComponent] playNextSong() playlistIndex=",playlistIndex,"playlistOrdered.length=",playlistOrdered.length,"(playlistIndex+1)%playlistOrdered.length=",(playlistIndex+1)%playlistOrdered.length);
        setPlaylistIndex((playlistIndex+1)%playlistOrdered.length);
    }
    function onMusicEnded() {
        if (DEBUG) console.log("[CloudPlaylistComponent] onMusicEnded() playlistIndex=",playlistIndex,"playlistOrdered.length=",playlistOrdered.length,"(playlistIndex+1)%playlistOrdered.length=",(playlistIndex+1)%playlistOrdered.length);
        if (playlistIndex+1===playlistOrdered.length) {
            if (loop) {
                if (DEBUG) console.log("[CloudPlaylistComponent] Playlist has ended. Loop is enabled, so we're restarting with the first track: ", playlistOrdered[0]);
                playNextSong();
            } else
                if (DEBUG) console.log("[CloudPlaylistComponent] Playlist has ended. Loop is disabled, so music is stopping.");
        } else {
            if (DEBUG) console.log("[CloudPlaylistComponent] A song has ended. Playing next song in the playlist: ", playlistOrdered[playlistIndex]);
            playNextSong();
        }
    }
    function togglePlayBasic() {
        if (isPlaying) {
            if (DEBUG) console.log("[CloudPlaylistComponent] Pausing audio");
            audioH5Ref.current?.audio.current?.pause();
        } else {
            if (DEBUG) console.log("[CloudPlaylistComponent] Playing audio");
            audioH5Ref.current?.audio.current?.play();
        }
    }
    useEffect(() => {
        if (audioH5Ref.current?.audio.current && playbackRate && playbackRate !== audioH5Ref.current?.audio.current?.playbackRate) {
            audioH5Ref.current.audio.current.playbackRate=playbackRate;
        }
    }, [playbackRate, audioH5Ref.current?.audio.current]);
    // function handleOnPopoverOpenChange(open:boolean) {
    //     setIsPopoverOpen(open);
    // }

    const humanFriendlyFileName = useMemo(() => {
        const currentItem = playlistOrdered[playlistIndex];
        if (!currentItem)
            return "";
        // Use name if available
        if (typeof currentItem !== "string" && currentItem.name) {
            if (onTrackChange) {
                if (currentItem.name && currentItem.path) {
                    setTimeout(() => onTrackChange({humanFriendlyFileName: currentItem.name as string, trackPath: currentItem.path as string}), 0);
                }
            }
            return currentItem.name;
        }
        // Fallback to parsing the file path (either string or MusicTone without name)
        const filePath = typeof currentItem === "string" ? currentItem : currentItem.path;
        let name = filePath.split("/").pop() as string;
        name = name.replaceAll("-", " ");
        name = name.split(".").shift() as string;
        if (name.split(" ")[0].length > 5 && !isNaN(parseInt(name.split(" ")[0])))
            name = name.split(" ").slice(1).join(" ");
        name = name.charAt(0).toUpperCase() + name.slice(1);
        if (onTrackChange) {
            setTimeout(() => onTrackChange({humanFriendlyFileName: name, trackPath: filePath}), 0);
        }
        return name;
    }, [playlistOrdered, playlistIndex]);

    const audioPlayer = useMemo(function() {
        const playlistIndexComponent = <Tooltip title={"Title: "+humanFriendlyFileName}>{playlistIndex+1}/{playlistOrdered.length}</Tooltip>;
        const customAdditionalControls = [];
        // if (showLoopButton) {
        //     customAdditionalControls.push(RHAP_UI.LOOP);
        // }
        customAdditionalControls.push(playlistIndexComponent);
        const nextButton = <Button type="text" onClick={playNextSong}><StepForwardOutlined /></Button>;
        // const customMainControls = [RHAP_UI.MAIN_CONTROLS] as (string | ReactElement)[];
        // if (playlist.length>1) {
        //     customMainControls.push(nextButton);
        // }
        // const customVolumeControls = [RHAP_UI.VOLUME] as (string | ReactElement)[];
        // if (playlist.length>1) {
        //     customVolumeControls.push(nextButton);
        // }
        customAdditionalControls.push(nextButton);
        return <AudioPlayer
            autoPlay={autoplay}
            src={musicUrl}
            showJumpControls={false} // There's no use case for rewinding or fast forwarding, IMHO it clutters the UI.
            ref={audioH5Ref}
            onPlay={(e)=>{setIsPlaying(true);}}
            onAbort={(e)=>{setIsPlaying(false);}} // When we skip tracks, it aborts the previous track.
            onPause={(e)=>{setIsPlaying(false);}}
            onClickNext={playNextSong}
            onClickPrevious={playNextSong}
            onEnded={onMusicEnded}
            customAdditionalControls={customAdditionalControls}
            loop={false} // When we set loop to true, it loops over a single track, not over the soundtrack, this makes it not very useful for us. We can implement loop above if we want. Note if you debug this, changing this value requires refreshing the page. (Perhaps this is taking it as a default value rather than the current value?)
            volume={defaultVolume}
        />
    }, [musicUrl,autoplay,playlistOrdered,playlistIndex,humanFriendlyFileName]);

    if (!musicUrl)
        return <>
            {DEBUG && <p>Loading music file...</p>}
            <Spin/>
        </>;

    return <>
        {DEBUG && <>
            Playing: {isPlaying?"Yes":"No"}<br/>
            Track File: {playlistOrdered[playlistIndex]}<br/>
            Music URL: {musicUrl}<br/>
        </>}
        {(playerUI===PLAYER_UI_FANCY) && audioPlayer}
        {/* {(playerUI===DRAWER_UI) && <>
            <Button type={onDarkBackground?"default":"text"} onClick={()=>setDrawerIsOpen(!drawerIsOpen)} style={onDarkBackground?{}:{}}>{isPlaying && <PauseCircleOutlined/>}{!isPlaying && <PlayCircleOutlined />}</Button>
            <Drawer destroyOnClose={false} open={drawerIsOpen} onClose={()=>setDrawerIsOpen(false)}>
                {audioPlayer}
            </Drawer>
        </>} */}
    </>;
}