import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import { useStateLSJSON, useStateLSNumber } from "../../Utilities/StateWithLocalCache";
import { createContext, useContext } from "react";
import { RawDraftContentState } from "draft-js";
import { JSONFormsObject } from "../../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject";
import { ChatCompletionMessageParam } from "openai/resources";
import { NoteThinkingInfo } from "../../../ServerConnection/LLMServer/SendChatToServerHook";

const SAVE_SYSTEM_MESSAGE_TO_LS = false; // New constant controlling system message saving

export type ChatMessage2 = ChatCompletionMessageParam & {
    // Inherited:
    // content: string;
    // role: OpenAIRole;
    draftRawContent?:RawDraftContentState;
    jsonContent?:JSONFormsObject;
    userHasMadeEdits:boolean;
    previousVersion?:ChatMessage2;
    extra?: NoteThinkingInfo;
};

export type ChatLog = {
    title?: string;
    messages: ChatMessage2[];
    // Coming soon: list of notes used for this chat.
};

export type ChatLogContextType = {
    selectedChatMessages: ChatMessage2[];
    setSelectedChatMessages: (messages: ChatMessage2[]) => void;
    allChats: ChatLog[];

    newChat: () => void;
    deleteSelectedChat: () => void;
    renameChat: (title:string) => void;

    selectedChatIndex: number;
    setSelectedChatIndex: (index: number) => void;
};

const LSPREFIX = "ChatEditorV2";

function getChatSummary(messages: ChatMessage2[]): string {
    let startOfUserMessages = messages.filter((message) => message.role === 'user').map((message) => message.content).join("\n");
    // TODO Strip markdown
    // console.log("Getting a title for a chat", startOfUserMessages);

    
    if (startOfUserMessages.length > 60)
        startOfUserMessages = startOfUserMessages.slice(0, 57) + "...";
    return startOfUserMessages;
}
function getNameOfChat(chat: ChatLog): string {
    if (chat.title) return chat.title;
    return getChatSummary(chat.messages);
}

export const ChatLogContext = createContext({} as ChatLogContextType);
export function ChatLogContextProvider({children}:{children:any}) {
    const [selectedChatIndex, setSelectedChatIndex_Internal] = useStateLSNumber(LSPREFIX + ">SelectedChatIndex", 0);
    const [allChats, setAllChats] = useStateLSJSON(LSPREFIX + ">Chats", [{ messages: [] as ChatMessage2[] }] as ChatLog[]);
    const selectedChatMessages = allChats[selectedChatIndex]?.messages || [] as ChatMessage2[];

    function setSelectedChatMessages(messages: ChatMessage2[]) {
        // Filter out system messages if the flag is false
        const messagesToSave = SAVE_SYSTEM_MESSAGE_TO_LS ? messages : messages.filter(message => message.role !== 'system');
        const newOtherChats = [...allChats];
        newOtherChats[selectedChatIndex] = {...newOtherChats[selectedChatIndex], messages: messagesToSave };
        setAllChats(newOtherChats);
    }
    function newChat() {
        const newOtherChats = [...allChats];
        newOtherChats.push({  messages: [] });
        setAllChats(newOtherChats);
        setSelectedChatIndex_Internal(newOtherChats.length - 1);
    }
    function deleteSelectedChat() {
        const newOtherChats = [...allChats];
        newOtherChats.splice(selectedChatIndex, 1);
        setAllChats(newOtherChats);
        // Look for an empty chat in the list, rather than going to the first, or pick the last one.
        for (let i = 0; i < newOtherChats.length; i++) {
            if (newOtherChats[i].messages.length === 0) {
                setSelectedChatIndex_Internal(i);
                return;
            }
        }
        // This will jump to the last chat created, which is a decent policy.
        // const newChatIndex = newOtherChats.length - 1;
        // This will jump to the next chat, which is decent if the user is trying to delete a bunch of chats starting at the beginning, etc.
        const newChatIndex = selectedChatIndex >= newOtherChats.length ? newOtherChats.length - 1 : selectedChatIndex;
        setSelectedChatIndex_Internal(newChatIndex);
    }
    function renameChat(title: string) {
        const newOtherChats = [...allChats];
        newOtherChats[selectedChatIndex].title = title;
        setAllChats(newOtherChats);
    }
    function setSelectedChatIndex(index: number) {
        // if (index === -1) {
        //     if (selectedChatMessages.length > 0)
        //         newChat();
        //     return;
        // }
        setSelectedChatIndex_Internal(index);
    }

    const providerValue = {
        selectedChatMessages,
        setSelectedChatMessages,
        allChats,
        newChat,
        deleteSelectedChat,
        renameChat,

        selectedChatIndex,
        setSelectedChatIndex
    } as ChatLogContextType;

    return <ChatLogContext.Provider value={providerValue}>
        {children}
    </ChatLogContext.Provider>;
}

export function ChatListDropdown() { 
    const { allChats, newChat, deleteSelectedChat, selectedChatIndex, setSelectedChatIndex, renameChat } = useContext(ChatLogContext);
    function renameChatOuter() {
        const title = prompt("New Chat Title", getNameOfChat(allChats[selectedChatIndex]));
        if (!title) return;
        renameChat(title);
    }

    return <>
        <Select value={selectedChatIndex} onChange={setSelectedChatIndex} style={{ width: 'calc(95% - 400px)' }}>
            {allChats.map((chat: ChatLog, index: number) => <Select.Option key={index} value={index}>{getNameOfChat(chat)}</Select.Option>)}
            {/* <Select.Option key={-1} value={-1}>New</Select.Option> */}
        </Select>
        &nbsp;<Button icon={<PlusOutlined />} onClick={newChat}>New</Button>
        &nbsp;<Button icon={<DeleteOutlined />} onClick={deleteSelectedChat}>Delete</Button>
        &nbsp;<Button icon={<EditOutlined/>} onClick={renameChatOuter}>Rename</Button>
    </>;
}