import React, { useRef, useEffect } from "react";
import { GenericTestPage, GenericTestPageRef, Test } from "../../../TestPages/GenericTestPage";
import { ChatCompletionMessageParam } from "openai/resources";
import { LLMServerContextProvider } from "../../../DecisionGraph/ChatEditor/UI/SelectableLLM";
import { JSONSchemaType } from "ajv";
import { LLM_SERVERTYPE_GEMINI_VIA_FIREBASE } from "./GeminiFirebaseChatServer";
import { z } from "zod";

const tests: Test[] = [
    {
        name: "Basic Structured Output Test",
        check: async () => {
            const messages: ChatCompletionMessageParam[] = [
                {
                    role: "system",
                    content: "Return the following JSON exactly: { \"result\": \"test\" }"
                },
                {
                    role: "user",
                    content: "Test structured output"
                }
            ];
            type TestOutput = { result: string };
            const jsonSchema:JSONSchemaType<TestOutput> = {
                type: "object",
                properties: {
                    result: { type: "string" }
                },
                required: ["result"]
            };
            // Dummy schema: expects an object with a required string property "result"
            // const chatAPIOptions = {
            //     response_format: jsonSchema,
            //     temperature: 0
            // } as ChatAPIOptions;
            if (!LLM_SERVERTYPE_GEMINI_VIA_FIREBASE.structuredOutputCall) {
                throw new Error("No structured output call available in Gemini. This is a bug.");
            }
            const response = await LLM_SERVERTYPE_GEMINI_VIA_FIREBASE.structuredOutputCall<TestOutput>(messages, {} as unknown as z.ZodSchema<TestOutput>, jsonSchema, "TestOutput");
            // const response = await callGeminiViaFirebaseStructuredOutput<TestOutput>(messages, chatAPIOptions);
            if (response.success && response.structuredOutput && response.structuredOutput.result === "test") {
                return { success: true };
            }
            return { success: false, message: "Mismatch: " + JSON.stringify(response) };
        }
    }
];

export default function GeminiStructuredOutputTestPage() {
    const testPageRef = useRef<GenericTestPageRef | null>(null);

    useEffect(() => {
        if (testPageRef.current) {
            testPageRef.current.runSelectedTest();
        }
    }, []);

    return (
        <LLMServerContextProvider>
            <GenericTestPage ref={testPageRef} tests={tests} title="Gemini Structured Output Test Page" />
        </LLMServerContextProvider>
    );
}
