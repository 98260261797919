import { ChatEditorRefFunctions } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { ChatWithDraft } from "../UI/ChatWithDraft";
import { usePreprocessUserMessageV10 } from './PreprocessV10CommandContext';
import { usePreprocessUserMessageV9 } from './PreprocessV9TemplateTopic';

const USE_V10 = true;

export default function ChatEditorWithNotePreprocessing({ chatEditorRef }: { chatEditorRef: React.MutableRefObject<ChatEditorRefFunctions> }) {
    const preprocessUserMessageV9 = usePreprocessUserMessageV9(); // template & topic version
    const preprocessUserMessageV10 = usePreprocessUserMessageV10(); // V10 version

    chatEditorRef.current.preProcess_UserMessage = USE_V10 ? preprocessUserMessageV10.preProcess_UserMessage : preprocessUserMessageV9;
    chatEditorRef.current.postProcess_RecievedMessage = USE_V10 ? preprocessUserMessageV10.postProcess_RecievedMessage : undefined;

    return <>
        <div id="chatWithDraftHolder" style={{ overflowY: "scroll", height: "calc(100vh - 45px)" }}>
            <ChatWithDraft chatEditorRef={chatEditorRef} />
        </div>
    </>;
}