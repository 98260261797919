import { UserOutlined } from "@ant-design/icons";
import { ReplicaVoiceSidebar } from "../../../DecisionGraph/Utilities/Sound/ReplicaVoiceSidebarAndModal";
import { getSubURLFromValidURL } from "../../../DecisionGraph/Utilities/NavigateTo";
import { ReplicaPitch } from "../../../DecisionGraph/Utilities/Sound/ReplicaAPI";
import { SELECTED_VOICE_ID_PARAMETER, SELECTED_VOICE_PITCH_PARAMETER, SELECTED_VOICE_TAGS_PARAMETER } from "../../../DecisionGraph/Utilities/Sound/ReplicaVoiceUI";
import { useNavigate, useLocation } from "react-router-dom";
import { getQuery } from "../../../DecisionGraph/Utilities/URLQueryParams";
import { SIDEBAR_DISPLAYED_PARAMETER } from "../../../DecisionGraph/Sidebars/SidebarController";

const SIDEBAR_DISPLAYED_VOICES="Voices";
const MENU_KEY_VOICES = "listOfVoices";

const VOICE_URL_PREFIX = "/?" + SIDEBAR_DISPLAYED_PARAMETER + "=" + SIDEBAR_DISPLAYED_VOICES + "&selectedVoiceID=";

const DEBUG = false;

export function getURLForVoiceId(voice_id: string, pitch: ReplicaPitch) {
    let url = VOICE_URL_PREFIX + voice_id;
    if (pitch !== 0)
        url += "&" + SELECTED_VOICE_PITCH_PARAMETER + "=" + pitch;
    return url;
}
export function getVoiceIDFromURL(url: string) {
    const { isValid, query } = getSubURLFromValidURL(url);
    if (!isValid)
        return { isValid: false, voice_id: "", pitch: 0 };
    const voice_id = query.get(SELECTED_VOICE_ID_PARAMETER);
    if (!voice_id) {
        return { isValid: false, voice_id: "", pitch: 0 };
    }
    const pitch = parseInt(query.get(SELECTED_VOICE_PITCH_PARAMETER) || "0");
    return { isValid: true, voice_id, pitch };
}
export function useNavigateToVoice() {
    const navigate = useNavigate();
    const location = useLocation();
    return function (voice_id: string, pitch: ReplicaPitch = 0) {
        const query = getQuery();
        query.set(SIDEBAR_DISPLAYED_PARAMETER, SIDEBAR_DISPLAYED_VOICES);
        query.set(SELECTED_VOICE_TAGS_PARAMETER, "[]");
        query.set(SELECTED_VOICE_ID_PARAMETER, voice_id);
        query.set(SELECTED_VOICE_PITCH_PARAMETER, pitch.toString());
        // Preserve the note and rest of parameters, if there is one:
        navigate(location.pathname + "?" + query.toString(), { replace: true });
    };
}

function testGetVoiceIDFromURL() {
    console.log("[testGetVoiceIDFromURL] Testing getVoiceIDFromURL:");
    console.assert(getVoiceIDFromURL("http://localhost:3000/?sidebarDisplayed=Voices")?.isValid===false,"Failed on incorrect voice ID");
    console.assert(getVoiceIDFromURL("http://localhost:3000/?sidebarDisplayed=Voices&selectedVoiceID=123&selectedVoicePitch=1")?.isValid===true,"Failed on valid parameters");
    console.assert(getVoiceIDFromURL("/?sidebarDisplayed=Voices&selectedVoiceID=123&selectedVoicePitch=1")?.voice_id==="123","Failed on voice ID");
    console.assert(getVoiceIDFromURL("/?sidebarDisplayed=Voices&selectedVoiceID=123")?.pitch===0,"Failed on default pitch");
    console.assert(getVoiceIDFromURL("/?sidebarDisplayed=Voices&selectedVoiceID=123&selectedVoicePitch=1")?.pitch===1,"Failed on pitch");
    console.log("[testGetVoiceIDFromURL] Testing complete.");
}
if (DEBUG)
    testGetVoiceIDFromURL();

export function useReplicaSiderMenu(
	setCurrentSidebar: (name: string) => void,
	currentSidebar: string,
	_isSidebarVisible: boolean,
	hideSidebar: () => void
) /*: SideMenuHookReturn*/ {
	// Note: using MENU_KEY_VOICES and SIDEBAR_DISPLAYED_VOICES from this file.
	function onSiderMenuItemClick(key: string): boolean {
		if (key === MENU_KEY_VOICES) {
			if (currentSidebar === SIDEBAR_DISPLAYED_VOICES)
				hideSidebar();
			else
				setCurrentSidebar(SIDEBAR_DISPLAYED_VOICES);
			return true;
		}
		return false;
	}
	function onShortcutKeyDown(keyName: string): boolean {
		return false;
	}
	const sidebarComponent = currentSidebar === SIDEBAR_DISPLAYED_VOICES ? <ReplicaVoiceSidebar key="sidebarComponent"/> : undefined;
	return {
		menuItems: [{ key: MENU_KEY_VOICES, label: "Voices", icon: <UserOutlined />, disabled: false }],
		siderComponents: [],
		sidebarComponent,
		onSiderMenuItemClick,
		onShortcutKeyDown
	};
}
