import { JSONSchemaType } from "ajv";
import { GLOBAL_DEBUG_INLINE_TESTS } from "../../../GlobalDebug";
import { is } from "immutable";

export type Dice = {
    diceType:number,
    numDice:number,
    plus:number
};

export const DiceSchema:JSONSchemaType<Dice> = {
    type: "object",
    properties: {
        diceType: {type: "number"},
        numDice: {type: "number"},
        plus: {type: "number"},
    },
    required: ["diceType","numDice","plus"],
};

export function getAvgRollD(diceType:number,numDice:number,plus:number):number {
    // Average roll for a d4 is 2.5, d6 is 3.5, d8 is 4.5, d10 is 5.5, d12 is 6.5, d20 is 10.5, d100 is 50.5.
    const avgRoll = (diceType+1)/2;
    return (numDice*avgRoll)+plus;
}

export function getAvgRoll(dice:Dice):number {
    return getAvgRollD(dice.diceType,dice.numDice,dice.plus);
}

function isHalfNumber(plus:number):boolean {
    return (((plus*2)%2)===1);
}
export function getDForAvgRoll(diceSize:number,avgRoll:number):Dice {
    const avgRollPerDie = getAvgRollD(diceSize,1,0);
    let numDice = Math.floor(avgRoll/avgRollPerDie);
    let plus = avgRoll%avgRollPerDie;
    // Round to the nearest half number before any checks.
    plus = Math.round(plus*2)/2;
    if ((isHalfNumber(plus) && diceSize<=6) || numDice===0) {
        // This is a half number at a d4 (or 0 dice). That means we have the wrong numDice.
        // When it's a d4, we correct by increase the numDice by 1 and decrease the plus by 3.
        numDice++;
        plus-=avgRollPerDie;
    }
    if (isHalfNumber(plus)) {
        // Round it 
        plus = Math.round(plus);
    }
    return {diceType:diceSize,numDice,plus} as Dice;
}
export function getD4D20D100ForAvgRoll(avgRoll:number):Dice {
    // if (avgRoll>=51.5) // start d100 at 1d100+1 so that the min roll is 2.
    // start d100 quite high so we're only rolling if it's potentially a big number. Rolling a larger number of dice means more reliable averages, so we only go up when we're ready to roll a few of them.
    if (avgRoll>=101.5)
        return getDForAvgRoll(100,avgRoll);
    // if (avgRoll>=11.5) // start d20 at 1d20+1 so that the min roll is 2.
    if (avgRoll>=31.5) // start d20 quite high so we're only rolling if it's a big number. The lower dice have more reliable top numbers, so we want to use them more often.
        return getDForAvgRoll(20,avgRoll);
    return getDForAvgRoll(4,avgRoll);
}

// function testGetD4ForAvgRoll() {
//     console.log("getD4ForAvgRoll(2.5):",getD4D20D100ForAvgRoll(2.5));
//     console.log("getD4ForAvgRoll(3.0):",getD4D20D100ForAvgRoll(3.0));
//     console.log("getD4ForAvgRoll(3.5):",getD4D20D100ForAvgRoll(3.5));
// }
// if (GLOBAL_DEBUG_INLINE_TESTS)
//     testGetD4ForAvgRoll();

// function printTSVForAvgRolls() {
//     let tsvStr = "AvgRoll\tReconstituted\tDice\n";
//     for (let i=1;i<=100;i++) {
//         const d = getD4D20D100ForAvgRoll(i);
//         tsvStr += i+"\t"+getAvgRoll(d)+"\t"+getDiceStr(d)+"\n";
//     }
//     console.log(tsvStr);
// }
// if (GLOBAL_DEBUG_INLINE_TESTS)
//     printTSVForAvgRolls();

/****
 * Handle simple dice notation, such as 1d6+2, 1d4-1, 2d20+2.
 */
export function parseDice(dNotation:string):Dice {
    const [firstNumStr,rest] = dNotation.toLowerCase().split("d");
    const firstNum = parseInt(firstNumStr);
    let plus = null as number|null;
    let diceType = null as number|null;

    if (!rest) {
        // This means there was no "d".
        // But there was still a first part, numDice.
        return {diceType:0,numDice:0,plus:firstNum} as Dice;
    } else /*if (rest)*/ {
        // Both a d and a rest.
        if (rest.includes("+")) {
            const [diceTypeStr,plusStr] = rest.split("+");
            diceType = parseInt(diceTypeStr);
            plus = parseInt(plusStr);
        } else if (rest.includes("-")) {
            const [diceTypeStr,plusStr] = rest.split("-");
            diceType = parseInt(diceTypeStr);
            plus = -parseInt(plusStr);
        } else {
            diceType = parseInt(rest);
            plus = 0;
        }    
    }
    return {diceType, numDice: firstNum, plus} as Dice;
}
export function addDice(d1:Dice,d2:Dice):Dice {
    if ((!d1.diceType && !d1.numDice) || (!d2.diceType && !d2.numDice)) {
        // Just add the bonus since there's only one dice on the side.
        return {
            diceType:d1.diceType+d2.diceType, // one is 0
            numDice:d1.numDice+d2.numDice, // one is 0
            plus:d1.plus+d2.plus
        } as Dice;
    }
    if (d1.diceType!==d2.diceType) {
        // Convert the smaller dice type to the larger dice type using the average roll:
        if (d1.diceType>d2.diceType) {
            const avgRoll2 = getAvgRoll(d2);
            const d2ToD1 = getDForAvgRoll(d1.diceType,avgRoll2);
            return addDice(d1,d2ToD1);
        } else if (d1.diceType<d2.diceType) {
            const avgRoll1 = getAvgRoll(d1);
            const d1ToD2 = getDForAvgRoll(d2.diceType,avgRoll1);
            return addDice(d1ToD2,d2);
        }
    }
    return {
        diceType:d1.diceType,
        numDice:d1.numDice+d2.numDice,
        plus:d1.plus+d2.plus
    };
}
export function getDiceStr(dice:Dice):string {
    if (!dice.numDice || !dice.diceType) {
        // Error if there's only one missing:
        if (!dice.numDice && dice.diceType) {
            throw new Error("numDice is missing. The caller passed in "+JSON.stringify(dice));
        }
        if (dice.numDice && !dice.diceType)
            throw new Error("diceType is missing.");
        return dice.plus.toString();
    }
    return dice.numDice+"d"+dice.diceType+(dice.plus>0?"+"+dice.plus:dice.plus<0?dice.plus:"");
}