import { ContentBlock, ContentState } from "draft-js";
import { SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { useContext } from "react";
import { NotesContext } from "../../../Notes/Data/NotesContext";
import { NARRATOR_STYLE, NARRATOR_VOICE_UUID, NarrationLine, processBlockToNarrate } from "./ProcessNarration";
import { ReplicaVoice, ReplicaVoiceStyle, useReplicaSynthesizeCommand, useReplicaVoices } from "./ReplicaAPI";
import { useRecommandAVoiceStyle } from "./RecommendAVoiceStyle";
import { noteTypeIsCharacter } from "../../../Extensions/TTRPG/CharacterEnemyNPCs/CharacterEnemyNPCs";
import { getUncommentedNoteLinks, getUncommentedVoiceLinksInNote, getVoiceLinkEntities } from "../../../Notes/UIs/DraftJSEditor/BlockEditing/LinkToNoteBlockUtils";
import { useExtensions } from "../../../Extensions/ExtensionsFramework/GetExtension";

const DEBUG = true;

export function useGetBlockquoteSynthesis() {
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const notesContext = useContext(NotesContext);
    const { extensions } = useExtensions();

    const voices = useReplicaVoices();
    const narratorVoice = voices.find(voice => voice.uuid===NARRATOR_VOICE_UUID);
    const narratorStyle = narratorVoice?.styles.find(style => style.name===NARRATOR_STYLE);
    const replicaSynthesizeCommand = useReplicaSynthesizeCommand();
    const {recommendAVoiceStyleCommand} = useRecommandAVoiceStyle();


    async function getBlockquoteSynthesis(block:ContentBlock, contentState:ContentState,
        setSynthesisProgress:(progress:number)=>void, setSynthesisMaxProgress:(maxProgress:number)=>void): Promise<{speechTracks:NarrationLine[],success:boolean,error:string|null}> {
        if (DEBUG) console.log("[getBlockquoteSynthesis]");
        if (!narratorVoice || !narratorStyle) {
            return {speechTracks:[],error:"We couldn't load the narrator's voice. Please refresh the page and try again.",success:false};
        }

        let voiceLinks = getVoiceLinkEntities(block, contentState);
        if (voiceLinks.length===0) {
            // Fall back on finding voices in the note:
            voiceLinks = getUncommentedVoiceLinksInNote(selectedJsonFormsContext.note);
            if (voiceLinks.length===0) {
                // Look up the characters in the note:
                const linksToNotes = getUncommentedNoteLinks(selectedJsonFormsContext.note);
                const linkedNotesPromises = linksToNotes.map(async (link) => await notesContext.loadNoteID(link.doc_id, true));
                const rawLinkedNotes = await Promise.all(linkedNotesPromises);
                const characterEnemyNotes = rawLinkedNotes.filter(note => !!note && noteTypeIsCharacter(note.type));
                // Get the voices from all of the notes:
                voiceLinks = characterEnemyNotes.reduce((acc, note) => {
                    const noteVoiceLinks = getUncommentedVoiceLinksInNote(note);
                    acc.push(...noteVoiceLinks);
                    return acc;
                }, [] as ReplicaVoice[]);
            }
        }
            
        // Map voiceLinks into voices from the list of voices:
        const thisNoteVoices = voices.filter(voice => voiceLinks.some(voiceLink => voiceLink.uuid===voice.uuid));
        let defaultQuoteVoice = undefined as ReplicaVoice|undefined;
        if (thisNoteVoices.length===1 && thisNoteVoices[0]) {
            // Good! We have a single voice to use.
            let defaultQuoteVoiceForTS = thisNoteVoices[0] as ReplicaVoice;
            const pitch = voiceLinks.find(voiceLink => voiceLink.uuid===defaultQuoteVoiceForTS.uuid)?.pitch || 0;
            defaultQuoteVoice = {...defaultQuoteVoiceForTS, pitch};
        }
        
        const speech = await processBlockToNarrate(block, contentState, defaultQuoteVoice, notesContext, extensions);
        if (speech.length===0) {
            // TODO make a better error here
            return {speechTracks:[],error:"We couldn't find any narration lines. Please refresh the page and try again.",success:false};
        }
        async function runSynthesisCommand(line:NarrationLine) {
            const style = (line.voice as ReplicaVoice).styles.find(style => style.name===line.style_name);
            if (!style) {
                // TODO
                console.log("[SoundtrackInDrawer] We didn't find a style for line=",line,"voice=",line.voice,"styles=",line.voice?.styles);
                alert("Bug: No style found in line: "+JSON.stringify(line));
                return;
            }
            await replicaSynthesizeCommand(line.voice_name,(style as ReplicaVoiceStyle),line.text, line.pitch || 0).then(filename => {
                if (filename)
                    line.path=filename;
                else {
                    // Synthesis failed:
                    console.error("[SoundtrackInDrawer] Synthesis failed for line: ",line);
                }
            });
        }
        let numPromisesComplete = 0;
        const allPromises = speech.map(async (line:NarrationLine, index:number) => {
            let voice = undefined as ReplicaVoice|undefined;
            if (line.voice_uuid) {
                voice = voices.find(voice => voice.uuid===line.voice_uuid);
                if (voice) {
                    line.voice=voice;
                    line.voice_name = voice.name;
                } else {
                    // TODO
                    console.error("[SoundtrackInDrawer] No voice found in line: ",line);
                    // alert("Bug: No voice found in line: "+JSON.stringify(line));
                    return;
                }
            } else {
                // TODO
                console.error("[SoundtrackInDrawer] No voice_uuid found in line: ",line);
                // alert("Bug: No voice_uuid found in line: "+JSON.stringify(line));
                return;
            }
            if (!line.style_name) {
                let foundVoice = false;
                if (line.voice) {
                    let textToRecommend = line.text;
                    if (index>1) {
                        // We can recommend a style based on the previous lines too, because it might change the context, esp. if there's a hint in the narration.
                        textToRecommend = speech[index-1].text + " " + line.text;
                    }
                    await recommendAVoiceStyleCommand(textToRecommend,line.voice,(style_name:string, speaker_id:string) => {
                        line.style_name = style_name;
                        foundVoice=true;
                    });
                }
                if (!foundVoice) {
                    // linksToNotes

                }
            }
            await runSynthesisCommand(line);
            numPromisesComplete++;
        });

        /*
        We have an optimization to wait for just the first track to be retrieved, and then start playing it immediately, while the others are still loading.
        However, this optimization seems to have several bugs.
            (1) If the user presses pause while playing the first track, once synthesis is complete, it will start playing the next track despite not having finished the first and the user having manually paused.
            (2) Sometimes it's skipping a track (the first?), or parts of tracks, or even playing them out of order (we are assuming this is only related to the first track).

        If we do fix this all up, we might even include a slight delay after the first track request above, to give the servers an additinonal chance to finish first.
        */
        // const START_FIRST_TRACK_EARLY = false; 
        // if (START_FIRST_TRACK_EARLY) {
        //     for (let i=0;i<allPromises.length;i++) {
        //         await allPromises[i];
        //         const firstTrack = speech[i];
        //         if (firstTrack && firstTrack.path) {
        //             setSpeechIndex(0);
        //             setSpeechTracks([firstTrack]);
        //             cloudSpeechPlaylistRef.current?.setIsPlaying(true);
        //             if (firstTrack.music_tags && firstTrack.music_tags.length>0) {
        //                 playBlockquoteMusic(firstTrack.music_tags);
        //             }
        //             // TODO it's also possible that the next one is done next, but not the whole stack... That case could be worth handling in this loop.

        //             // If the follow-up ones are done already, the following await Promis.all would take no time, leaving no time to start on the playlist.
        //             // So by giving the background threads just a bit of time to start this one, we make sure that it's playing when we reach the lines below, whether or not the background threads took time.
        //             await new Promise(resolve => setTimeout(resolve, 500));
        //             break;
        //         }
        //     }
        // }
        async function checkAllPromisesAreDone() {
            if (numPromisesComplete===speech.length) {
                return;
            }
            setSynthesisProgress(numPromisesComplete);
            setSynthesisMaxProgress(allPromises.length);
            setTimeout(checkAllPromisesAreDone, 500);
        }
        checkAllPromisesAreDone();
        await Promise.all(allPromises);
        const speechTracks = speech.filter(track => track!==undefined) as NarrationLine[];
        // if (speechTracks_new.length>1) {
        //     // Test to see if this continues the same track since the first one won't have changed
        //     if (DEBUG) console.log("[SoundtrackInDrawer] speechTracks_new=",speechTracks_new);
        //     if (DEBUG) console.log("[SoundtrackInDrawer] isPlayingSpeech local state=",isPlayingSpeech," isPlayingSpeech ref=",cloudSpeechPlaylistRef.current?.isPlaying," speechTracks_new=",speechTracks_new);
        //     // if (START_FIRST_TRACK_EARLY) {
        //     //     if (cloudSpeechPlaylistRef.current?.isPlaying) {
        //     //         // It'll continue, just fine, because there's no interruption:
        //     //         setSpeechTracks(speechTracks_new);
        //     //     } else {
        //     //         // It won't be playing. But we can help this by restarting the playlist, by removing the first item and setting the rest.
        //     //         speechTracks_new.shift();
        //     //         setSpeechIndex(0);
        //     //         setSpeechTracks(speechTracks_new);
        //     //         cloudSpeechPlaylistRef.current?.setIsPlaying(true);
        //     //     }
        //     // } else {
        //     //     setSpeechTracks(speechTracks_new);
        //     //     setSpeechIndex(0);
        //     //     cloudSpeechPlaylistRef.current?.setIsPlaying(true);
        //     // }
        //     // The next line doesn't work to restart the playlist, because it's already stopped. What can we do?
        //     // cloudSpeechPlaylistRef.current?.setIsPlaying(true);
        // }
        return {speechTracks, error:null,success:true};
    }

    return {getBlockquoteSynthesis};
}