import { Encouragement, EnergyPotion, IcyWaterFlask } from "../GameCard/DiscardableCards";
import { BASE_XP } from "../GameCard/PlayerFunctions";
import { Clogs, TentacleSpikes } from "../GameCard/ReusableCardEnhancements";
import { ConstructBarricade, DestructExplode, DustDevilsVeil, FightingOneTwoPunch, FireWizardryFireDart, HealingTouch, IceWizardryIceDart, Rest, SneakyKnifeThrow, TelepathMindControl, TentacleAttack } from "../GameCard/ReusableCards";
import { DisguiseSkill, InfluenceSkill, OutdoorsSkill, PhysicalSkill, StealthSkill } from "../GameCard/SkillCards";
import { PlayerCharacterInMemoryType } from "./PlayerCharacters";


/*********************************************************************************************************************
 * LeTourneau party     */

const LetourneauSupporters = "7 Ogrukh (4 berserkers, 3 scouts), Alaric Eldridge Thorne (Master Alchemist of Sylvanholme)";


export const Letourneou_Game1_PlayerCharacters:PlayerCharacterInMemoryType[] = [

{
  playerName:"Noah (with Letourneaus)",
  characterName:"Bronzo",
  role: "Shapechanging Brain In A Jar",
  earnedXP:BASE_XP+8   /*6/30/2024*/+1 /*8/5/2024*/ +1 /*9/22/2024*/+1 /*10/20/2024*/+2 /*1/5/2024*/+2,
  earnedGold:25 /*6/30/2024*/+4 /*8/5/2024*/ +4 /*9/22/2024*/+5 /*10/20/2024*/+5 /*1/5/2024*/+8,
  spentGold: 0,
  addedLife: 6,
  reusableCardFunctions: [
    {func:Rest, level:1, enhancements:[]},

    {func:DisguiseSkill, level:5, enhancements:[]},

    {func:IceWizardryIceDart, level:4, enhancements:[]},
    {func:TentacleAttack, level:5, enhancements:[TentacleSpikes(1)]},
    {func:TelepathMindControl, level:1, enhancements:[]},
    {func:DustDevilsVeil, level:1, enhancements:[]},
  ],
  defenseCards: [],
  discardableCards: [
    Encouragement(1),
  ],
  miscItems: "Small luminescent gemstone",
  supporters: LetourneauSupporters
},{
    playerName: "Bradley Letourneau",
    characterName: "Viking Guy",
    role: "Viking",
    earnedXP: BASE_XP+7        /*8/5/2024*/ +1 /*10/20/2024*/+2 /*1/5/2024*/+2,
    earnedGold: 17+4    /*8/5/2024*/ +4 /*10/20/2024*/+5 /*1/5/2024*/+8,
    spentGold: 0,
    addedLife: /*8/5/2024*/2 /*1/5/2024*/+2,
    reusableCardFunctions: [
      {func:Rest, level:1, enhancements:[]},

      {func:PhysicalSkill, level:2, enhancements:[]},
      {func:StealthSkill, level:2, enhancements:[]},
      {func:DisguiseSkill, level:3, enhancements:[]},

      {func:DestructExplode, level:2, enhancements:[Clogs]},
      {func:SneakyKnifeThrow, level:4, enhancements:[Clogs]},
      {func:FightingOneTwoPunch, level:1, enhancements:[Clogs]},
    ],
    defenseCards: [/*Defense_Stealth3_SneakyKnifeThrow3*/],
    discardableCards: [
      EnergyPotion(1),
    ],
    miscItems: "Horse",
    supporters: LetourneauSupporters,
  },{
    playerName: "Jason Letourneau",
    characterName: "Wolfgang Von Expo",
    role: "Mystic Healer",
    earnedXP: BASE_XP+7        /*8/5/2024*/ +1 /*10/20/2024*/+2 /*1/5/2024*/+2,
    earnedGold: 17+4    /*8/5/2024*/ +4 /*10/20/2024*/+5 /*1/5/2024*/+8,
    spentGold: 5/*bought boat*/,
    addedLife: 0,
    reusableCardFunctions: [
      {func:Rest, level:1, enhancements:[]},

      {func:HealingTouch, level:3, enhancements:[Clogs]},
      {func:TelepathMindControl, level:4, enhancements:[Clogs]},
      {func:FireWizardryFireDart, level:2, enhancements:[Clogs]},
      {func:ConstructBarricade, level:1, enhancements:[Clogs]},

      {func:StealthSkill, level:2, enhancements:[]},
      {func:OutdoorsSkill, level:2, enhancements:[]},
      {func:InfluenceSkill, level:1, enhancements:[]},
      {func:DisguiseSkill, level:1, enhancements:[]},

    ],
    defenseCards: [],
    discardableCards: [
      IcyWaterFlask(1)
    ],
    miscItems: "Horse, Boat on the Kingsport Docks",
    supporters: LetourneauSupporters
  }
] as PlayerCharacterInMemoryType[];