import { HealingPotion } from "../GameCard/DiscardableCards";
import { BASE_XP } from "../GameCard/PlayerFunctions";
import { Sword } from "../GameCard/ReusableCardEnhancements";
import { ArcheryArrow, AthleticRun, BarbaricSwordSlice, FightingOneTwoPunch, FireWizardryFireDart, HealingTouch, IceWizardryIceDart, Rest, SneakyKnifeThrow } from "../GameCard/ReusableCards";
import { OutdoorsSkill, PhysicalSkill, StealthSkill } from "../GameCard/SkillCards";
import { PlayerCharacterInMemoryType } from "./PlayerCharacters";

export const Template_Characters:PlayerCharacterInMemoryType[] = [
    {
        // Completely empty PC
        playerName:"TBD",
        characterName:"TBD (Completely empty)",
        role: "",
        earnedXP: BASE_XP,
        earnedGold:0,
        spentGold: 0,
        addedLife: 0,
        reusableCardFunctions: [
        ],
        defenseCards: [],
        discardableCards: [],
        miscItems: "",
        supporters: ""
    } as PlayerCharacterInMemoryType,
    /*{
      // Template empty player 1
      playerName:"_____________",
      characterName:"____________",
      role: "",
      earnedXP: BASE_XP,
      earnedGold:0,
      spentGold: 0,
      addedLife: 0,
      reusableCardFunctions: [
        {func:Rest, level:1, enhancements:[]},
        {func:SneakyKnifeThrow, level:1, enhancements:[]},
        {func:FightingOneTwoPunch, level:1, enhancements:[]},
        {func:HealingTouch, level:1, enhancements:[]},
        {func:StealthSkill, level:1, enhancements:[]},
      ],
      defenseCards: [],
      discardableCards: [HealingPotion(1)],
      miscItems: "",
      supporters: ""
  },
  {
    // Template empty player 2
    playerName:"_____________",
    characterName:"____________",
    role: "",
    earnedXP: BASE_XP,
    earnedGold:0,
    spentGold: 0,
    addedLife: 0,
    reusableCardFunctions: [
      {func:Rest, level:1, enhancements:[]},
      {func:BarbaricSwordSlice, level:1, enhancements:[{enhancement:Sword, level:1}]},
      {func:IceWizardryIceDart, level:1, enhancements:[]},
      {func:AthleticRun, level:1, enhancements:[]},
      {func:PhysicalSkill, level:1, enhancements:[]},
    ],
    defenseCards: [],
    discardableCards: [HealingPotion(1)],
    miscItems: "",
    supporters: ""
  },{
    // Template empty player 3
    playerName:"_____________",
    characterName:"____________",
    role: "",
    earnedXP: BASE_XP,
    earnedGold:0,
    spentGold: 0,
    addedLife: 0,
    reusableCardFunctions: [
      {func:Rest, level:1, enhancements:[]},
      {func:ArcheryArrow, level:1, enhancements:[]},
      {func:FireWizardryFireDart, level:1, enhancements:[]},
      {func:HealingTouch, level:1, enhancements:[]},
      {func:OutdoorsSkill, level:1, enhancements:[]},
    ],
    defenseCards: [],
    discardableCards: [HealingPotion(1)],
    miscItems: "",
    supporters: ""
  }*/
] as PlayerCharacterInMemoryType[];