import { EditorBlock } from "draft-js";
import Immutable from "immutable";
import React from "react";
import { Button } from "antd";
import { PlaySquareOutlined } from "@ant-design/icons";

export const BLOCKQUOTE_BLOCK = "blockquote";

const SHOW_NARRATE_BUTTON = false;

export class BlockquoteBlock extends React.Component {
    render() {
      //@ts-ignore
      const { blockProps: { onClick  } } = this.props
      return (
        <div className="blockquote-block">
          {SHOW_NARRATE_BUTTON && <Button className="" type="dashed" icon={<PlaySquareOutlined/>} onClick={onClick} contentEditable={false}>Narrate</Button>}
            <div className={BLOCKQUOTE_BLOCK+'-block-content'}>
                <EditorBlock {...this.props} />
            </div>
        </div>
      )
    }
  }

export const blockquoteBlockRenderMap = Immutable.Map({
  [BLOCKQUOTE_BLOCK]: {
    element: "div",
    wrapper: <blockquote className="comment-block"/>
  },
})
