import { uniq } from "lodash";
import { useContext } from "react";
import { Extension, ExtensionNoteTemplateType } from "../../../Extensions/ExtensionsFramework/ExtensionsList";
import { getNoteTypeWithSchemaFor } from "../../../Extensions/ExtensionsFramework/ExtensionsSchemas";
import { getJSONFormsObject } from "../../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject";
import { NotesContext, NotesContextType } from "../../../Notes/Data/NotesContext";
import { Note } from "../../../Notes/Data/NoteType";
import { htmlToMarkdown } from "../../../ServerConnection/LLMServer/MarkdownConverter";
import { LinkToNoteFunction, useLinkToNote_ForEditor } from "../../Utilities/NavigateTo";
import { getNoteDraftJS } from "../../../Notes/Data/FirestoreNoteClient";
import { getJustThisDraftContentHTML } from "../../../Notes/UIs/NoteInformationComponents/DocReferences";
import { useExtensions } from "../../../Extensions/ExtensionsFramework/GetExtension";

// Cache type definitions:
type NoteMarkdownCacheEntry = {
    version: number;
    markdownPrompt: string;
};

type NoteMarkdownCache = {
    [noteId: string]: NoteMarkdownCacheEntry;
};

// Global in-memory cache:
const NOTE_MARKDOWN_CACHE: NoteMarkdownCache = {};


export type NoteAsMarkdown = {
    // From note:
    doc_name: string;
    doc_id: string;
    type: string;
    emoji?: string;

    // Converted from note:
    markdownPrompt: string;
};


export function getUniqMarkdownSystemPrompt(systemPrompts: NoteAsMarkdown[]) {
    return uniq(systemPrompts.map(({ markdownPrompt }) => markdownPrompt)).join("\n\n").trim();
}


async function getJustThisNoteHTML(note:Note, notesContext:NotesContextType, linkToNote:LinkToNoteFunction, extensions:Extension[]):Promise<string> {
    const doc_data = getNoteDraftJS(note);
    if (!doc_data || !doc_data.blocks)
        return ""; // Not loaded yet, or no data.

    return await getJustThisDraftContentHTML(doc_data, notesContext, linkToNote, extensions);
}


async function convertNoteToMarkdown(note:Note, notesContext:NotesContextType, linkToNote:LinkToNoteFunction, extensions:Extension[]):Promise<string> {
    const noteHtml = await getJustThisNoteHTML(note, notesContext, linkToNote, extensions);
    let noteMarkdown = htmlToMarkdown(noteHtml);
    return noteMarkdown;
}


export function useLoadNoteAsMarkdown() {
    const notesContext = useContext(NotesContext);
    const linkToNote = useLinkToNote_ForEditor();
    const { extensions } = useExtensions();

    async function loadNoteAsMarkdown(note: Note, includeJSON: boolean, templatesWithSchemas: ExtensionNoteTemplateType<any>[], numObjsWithTemplateSchema: number[]): Promise<NoteAsMarkdown> {
        if (!note) {
            throw new Error("Invalid note provided to loadNoteAsMarkdown");
        }
        // Check cache using note.id and note.version (if available)
        if (note.version) {
            const cached = NOTE_MARKDOWN_CACHE[note.id];
            if (cached && cached.version === note.version) {
                return { doc_name: note.doc_name, markdownPrompt: cached.markdownPrompt, doc_id: note.id, emoji: note.emoji, type: note.type } as NoteAsMarkdown;
            } else if (cached && cached.version !== note.version) {
                // Remove outdated entry
                delete NOTE_MARKDOWN_CACHE[note.id];
            }
        }

        // Convert note to markdown (this can be slow)
        let markdownPrompt = await convertNoteToMarkdown(note, notesContext, linkToNote, extensions);

        if (includeJSON) {
            const noteTemplateType = getNoteTypeWithSchemaFor(extensions, note);
            if (noteTemplateType && noteTemplateType.schema) {
                templatesWithSchemas.push(noteTemplateType);
                numObjsWithTemplateSchema.push(0);
                const schema = noteTemplateType?.schema;
                const schemaString = "# Schema for " + note.doc_name + "\n\n```json\n" + JSON.stringify(schema) + "\n```";
                if (markdownPrompt.trim().length > 0)
                    markdownPrompt = markdownPrompt + "\n\n" + schemaString;

                else
                    markdownPrompt = schemaString;
            }
        } else if (note.template_doc_id) {
            // Check if this note has a template that's in our list of templates with schemas:
            const templateIndex = templatesWithSchemas.findIndex((template: ExtensionNoteTemplateType<any>) => template.template_doc_ids?.includes(note.template_doc_id!));
            const template = templatesWithSchemas[templateIndex];
            if (template) {
                // Include the JSON from this note in the markdown.
                const obj = await getJSONFormsObject(note.id);
                if (obj) {
                    numObjsWithTemplateSchema[templateIndex]++;
                    const schemaString = "\n\n```json\n" + JSON.stringify(obj) + "\n```";
                    if (markdownPrompt.trim().length > 0)
                        markdownPrompt = markdownPrompt + "\n\n" + schemaString;

                    else
                        markdownPrompt = schemaString;
                }
            }
        }

        // Update cache with the new markdown conversion
        if (note.version) {
            NOTE_MARKDOWN_CACHE[note.id] = { version: note.version, markdownPrompt };
        }

        return { doc_name: note.doc_name, markdownPrompt, doc_id: note.id, emoji: note.emoji, type: note.type };
    }

    return loadNoteAsMarkdown;
}

