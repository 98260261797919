import { Button, Progress, Segmented, Spin, Table, TableProps, Tooltip, Alert, Input, Modal, Collapse } from "antd";
import { useContext, useMemo, useState } from "react";
import { useLinkToNote, useNavigateToNote } from "../../../DecisionGraph/Utilities/NavigateTo";
import { NoteHierarchy, TopLevelNoteHierarchy } from "./NoteHierarchy";
import { NoteHierarchyContext } from "./NoteHierarchyProvider";
import { getNoteNameAndEmoji_multipurpose } from "../../NotesTree/TreeUtilities/CreateTagTreeNodes";
import { NotesContext, NotesContextType } from "../../Data/NotesContext";
import { useStateLSString } from "../../../DecisionGraph/Utilities/StateWithLocalCache";
import { ApartmentOutlined, AppstoreOutlined, CheckCircleOutlined, CopyOutlined, PushpinFilled, PushpinOutlined, SaveOutlined, SelectOutlined, UnorderedListOutlined, SearchOutlined } from "@ant-design/icons";
import { isATemplateOrType } from "../../../Extensions/ExtensionsFramework/IsATemplate";
import { SegmentedOptions } from "antd/es/segmented";
import { PinnedJSONFormsContext, SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { OPERATION_COMPLETE, OPERATION_SELECT, OperationRenderable, getSaveOperationsForNote, saveOperation } from "../../../Extensions/ExtensionsFramework/SaveOperationsBasics";
import { Note } from "../../Data/NoteType";
import { Extension } from "../../../Extensions/ExtensionsFramework/ExtensionsList";
import InlineDraftJSViewer from '../../UIs/InlineDraftJSViewer';
import { useRootParentNotes } from "../../Data/NoteDBHooks";
import { useExtensions } from "../../../Extensions/ExtensionsFramework/GetExtension";

const DEBUG_LOAD = false;
const DEBUG_PINNED_AND_SELECTED_SECTION = false;

type IncludedWhen = "Always" | "As needed" | "EditContext";

interface TableRelatedNotesDataType {
    key: string;
    title: string;
    inclusionType?: IncludedWhen; // optional because the flat list isn't able to check for this
    type: string;
    noteHierarchy: NoteHierarchy;
    children?: TableRelatedNotesDataType[];
}

function getNoteAndChildren(topLevelNoteHierarchy: TopLevelNoteHierarchy, noteHierarchy:NoteHierarchy, notesContext:NotesContextType, parentIDs:string[], inclusionType:IncludedWhen, extensions:Extension[], displayedNotes:Set<string>):TableRelatedNotesDataType | null {
    if (displayedNotes.has(noteHierarchy.note.id))
        return null;
    displayedNotes.add(noteHierarchy.note.id);
    function getChildrenList(noteIDs:string[], inclusionType:IncludedWhen):TableRelatedNotesDataType[] {
        return noteIDs.map((doc_id:string)=>{
            const childNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[doc_id];
            // There are infinite loops in the list. Don't repeat them.
            if (parentIDs.includes(doc_id) || displayedNotes.has(doc_id))
                return null;
            if (!childNoteHierarchy)
                return null;
            return getNoteAndChildren(topLevelNoteHierarchy, childNoteHierarchy, notesContext, [...parentIDs, doc_id], inclusionType, extensions, displayedNotes);
        }).filter((x)=>x!==null) as TableRelatedNotesDataType[];
    }

    let childrenList = getChildrenList(noteHierarchy.fullyIncludedNoteIDs, "Always");
    childrenList = childrenList.concat(getChildrenList(noteHierarchy.linkedNoteIDs, "As needed"));
    childrenList = childrenList.concat(getChildrenList(noteHierarchy.editContextNotes, "EditContext"));    

    const children = childrenList.length>0 ? childrenList : undefined;
    return {
        key: noteHierarchy.note.id,
        title: getNoteNameAndEmoji_multipurpose(noteHierarchy.note, notesContext, extensions).note_title,
        type: noteHierarchy.note.type,
        inclusionType,
        noteHierarchy,
        children,
    };
}

const SupportsTreeWhatToShow = ["Related Notes"];

export function PinSaveOperationsButtons({note}:{note:Note}) {
    const pinnedJSONFormsContext = useContext(PinnedJSONFormsContext);
    const navigateToNote = useNavigateToNote();
    const {extensions} = useExtensions();
    
    const operations = getSaveOperationsForNote(extensions, pinnedJSONFormsContext,note);
    // Order it so "Undo" or remove is last.
    operations.sort((a:OperationRenderable,b:OperationRenderable) => {
        if (a.add)
            return -1;
        if (b.add)
            return 1;
        return 0;
    });
    if (!operations || operations.length===0) return <></>;
    function saveToTypeAction(operationIndex:number) {
        saveOperation(extensions, pinnedJSONFormsContext,note,operations[operationIndex], navigateToNote);
    }
    return <>{operations.map((operation:OperationRenderable,operationIndex:number) => {
        let icon = <SaveOutlined key={operationIndex}/>;
        if (operation.operation===OPERATION_COMPLETE)
            icon = <CheckCircleOutlined key={operationIndex}/>;
        else if (operation.operation===OPERATION_SELECT)
            icon = <SaveOutlined key={operationIndex}/>;
        return <Button type="link" icon={icon} onClick={()=>saveToTypeAction(operationIndex)} key={operationIndex}>{operation.text}</Button>;
    })}</>;
}

export function PinnedAndSelectedSection({compressed=false}:{compressed?:boolean}) {
    const linkToNote = useLinkToNote();
    const pinnedJSONFormsContext = useContext(PinnedJSONFormsContext);
    const pinnedNote = pinnedJSONFormsContext.note;    
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const selectedNote = selectedJsonFormsContext.note;

    function pinNoteAction() {
        if (!pinnedJSONFormsContext.setNoteIdForContext)
            throw new Error("This error should be impossible to reach. It's here for TypeScript.");
        pinnedJSONFormsContext.setNoteIdForContext(selectedNote.id);
    }
    function unpinNoteAction() {
        if (!pinnedJSONFormsContext.setNoteIdForContext)
            throw new Error("This error should be impossible to reach. It's here for TypeScript.");
        pinnedJSONFormsContext.setNoteIdForContext("");
    }
    function UnpinButton() {
        return <Button type="link" icon={<PushpinFilled />} onClick={unpinNoteAction}>Unpin</Button>;
    }

    return <>
        {/* Pinned Note: */}
        {pinnedNote && <div style={compressed?{display:"inline-block"}:{}}>
                    {/* {compressed && pinnedNote?.id!==selectedNote?.id && linkToNote(pinnedJSONFormsContext.note,true,20)} */}
                    {!compressed && <>Pinned: &nbsp;&nbsp;
                        {pinnedNote?.id!==selectedNote?.id && linkToNote(pinnedJSONFormsContext.note)}
                        {pinnedNote?.id===selectedNote?.id && pinnedJSONFormsContext.note.doc_name}</>}
                    {(!compressed || pinnedNote?.id===selectedNote?.id && pinnedJSONFormsContext.note.doc_name) && <>
                        {UnpinButton()}</>}
                {!pinnedJSONFormsContext.note && <><Spin/>{DEBUG_PINNED_AND_SELECTED_SECTION && "loading relatedNotesInfo.note"}</>}
            </div>}
        {/* If the selected note is not pinned: */}
        {(!pinnedNote || pinnedNote?.id!==selectedNote?.id) && selectedNote && 
                <div style={compressed?{display:"inline-block"}:{}}>
                    {!compressed && <>Selected: &nbsp;&nbsp;
                    {linkToNote(selectedNote)}</>}
                    {/* Offer to pin it: */}
                    {!pinnedNote && /*(pinnedNote?.id!==selectedNote?.id) &&*/
                    <Tooltip title={"Pin '"+selectedNote?.doc_name+"' and its related info to AI chat. Then you can click into other notes while still chatting & focusing on this note."}>
                        <Button type="link" icon={<PushpinOutlined />} onClick={pinNoteAction}>Pin</Button>
                    </Tooltip>}
                    {/* Offer to save it: */}
                    <PinSaveOperationsButtons note={selectedNote} />
                </div>
        }
        {((!selectedJsonFormsContext.isJSONFormsLoaded && selectedJsonFormsContext.hasNote) || (!pinnedJSONFormsContext.isJSONFormsLoaded && pinnedJSONFormsContext.hasNote)) && <><Spin/>{DEBUG_PINNED_AND_SELECTED_SECTION && "loading JSON forms context"}</>}
    </>;
}

enum WhatToShow {
    RelatedNotes = "Related Notes",
    SavedNotes = "Saved Notes",
    CompletedNotes = "Completed Notes",
    Templates = "Templates",
    Warnings = "Warnings", // renamed from DeletedNotes
};
enum ListType {
    Flat = "Flat",
    Tree = "Tree by inclusion",
};

// New type for parent reference data.
type ParentReference = {
    parentId: string;
    parentDocName: string;
    linkType: string;
    // The raw draft state of the parent's note line fragment.
    lineDraft?: any; // use appropriate RawDraftContentState type
};

// New hook to fetch real parent references.
function useFetchParentReferences(noteId: string): ParentReference[] {
    const { topLevelNoteHierarchy } = useContext(NoteHierarchyContext);
    const references: ParentReference[] = [];
    if (topLevelNoteHierarchy)
        Object.values(topLevelNoteHierarchy.noteHierarchiesById).forEach(hierarchy => {
            // TODO this block is wrong. We have to check the note itself, similar to what is done in NoteHieararchy. It would be great if we could use the same check code they have there.

            let linkType;
            if (hierarchy.fullyIncludedNoteIDs?.includes(noteId)) {
                linkType = "fullyIncluded";
            } else if (hierarchy.linkedNoteIDs?.includes(noteId)) {
                linkType = "linked";
            } else if (hierarchy.editContextNotes?.includes(noteId)) {
                linkType = "editContext";
            } else if (hierarchy.note.children?.includes(noteId)) {
                linkType = "child";
            }
            if (linkType) {
                const parentId = hierarchy.note.id;
                const parentDocName = hierarchy.note.doc_name;
                // Look for a block in parent's doc_data that mentions noteId.
                // TODO this search strategy is wrong.
                // let lineDraft: RawDraftContentState | null = null;
                // if (hierarchy.note.doc_data?.blocks) {
                //     for (const block of hierarchy.note.doc_data.blocks) {
                //         if (block.text.includes(noteId)) {
                //             lineDraft = { blocks: [block], entityMap: {} };
                //             break;
                //         }
                //     }
                // }
                // if (!lineDraft && hierarchy.note.doc_data) {
                //     lineDraft = hierarchy.note.doc_data;
                // }
                references.push({ parentId, parentDocName, linkType } as ParentReference);
            }
        });
    useMemo(() => {
        console.log("Parent references for note", noteId, references);
    }, [references]);
    return references;
}

// Updated modal component to show parent references using the hook.
function DeletedNoteReferenceModal({ noteId, open, onClose }: { 
    noteId: string; 
    open: boolean; 
    onClose: () => void;
}) {
    const navigateToNote = useNavigateToNote();
    const references = useFetchParentReferences(noteId);
    return (
        <Modal
            title={`References for deleted note ${noteId}`}
            open={open}
            onCancel={onClose}
            footer={null}
            width={600}
        >
            {references.length===0 ? (
                <div>TODO link to parents coming soon.</div>
            ) : (
                <ul>
                    {references.map(ref => (
                        <li key={ref.parentId} style={{ marginBottom: "1em" }}>
                            <div>
                                <Button type="link" onClick={() => navigateToNote(ref.parentId)}>
                                    {ref.parentDocName}
                                </Button>
                                &nbsp;- Link type: {ref.linkType}
                            </div>
                            <div style={{ border: "1px solid #ddd", padding: "0.5em", marginTop: "0.5em" }}>
                                <InlineDraftJSViewer draftJSMessage={ref.lineDraft} />
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </Modal>
    );
}

// Updated NoteWarningsPanel component.
function NoteWarningsPanel() {
    const notesContext = useContext(NotesContext);
    const loadedNotes = notesContext.loadedNotes;
    const deletedIDs = notesContext.getDeletedNoteIDs();
    const [selectedDeletedNote, setSelectedDeletedNote] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const navigateToNote = useNavigateToNote();
    const [loadingAll, setLoadingAll] = useState(false);
    const rootParentNotes = useRootParentNotes();

    // Calculate orphan and inconsistent warnings only if all notes are loaded.
    const { orphans, inconsistent } = useMemo(() => {
        if (!notesContext.hasLoadedAllNotes) {
            return { orphans: [], inconsistent: [] };
        }
        const allChildIds = new Set<string>();
        function traverse(note: Note) {
            allChildIds.add(note.id);
            if (Array.isArray(note.children)) {
                for (const childID of note.children) {
                    allChildIds.add(childID);
                    const child = loadedNotes.find(n => n.id === childID);
                    if (child)
                        traverse(child);
                }
            }
        }
        rootParentNotes.forEach(root => traverse(root));
        const orphanNotes: Note[] = [];
        const inconsistentNotes: Note[] = [];
        for (const note of loadedNotes) {
            if (note.parent) {
                if (!allChildIds.has(note.id)) {
                    orphanNotes.push(note);
                }
            }
        }
        return { orphans: orphanNotes, inconsistent: inconsistentNotes };
    }, [notesContext.hasLoadedAllNotes, loadedNotes, rootParentNotes]);

    async function loadAllNotes() {
        setLoadingAll(true);
        await notesContext.loadAllNotesAll();
        setLoadingAll(false);
    }

    const totalWarnings = deletedIDs.length + orphans.length + inconsistent.length;


    return (
        <div>
            <h4>Warnings</h4>
            {loadingAll ? <Spin /> : null}
            {selectedDeletedNote && (
                <DeletedNoteReferenceModal 
                    noteId={selectedDeletedNote}
                    open={modalOpen}
                    onClose={() => { setModalOpen(false); setSelectedDeletedNote(null); }}
                />
            )}
            {!loadingAll && !notesContext.hasLoadedAllNotes && (
                <>
                    <Alert 
                        type="info" 
                        message="To analyze all notes—including unshared ones—click below." 
                        showIcon 
                    />
                    <Button type="primary" onClick={loadAllNotes}>
                        Load All Notes for Warning Analysis
                    </Button>
                </>
            )}
            {totalWarnings === 0 ? (
                <div>No warnings found.</div>
            ) : (
                <div style={{ maxHeight: "400px", overflowY: "auto", marginTop: 16 }}>
                    <Collapse>
                        {deletedIDs.length > 0 && (
                        <Collapse.Panel header={`Deleted notes still linked from parents (${deletedIDs.length})`} key="deleted">
                            <ul>
                                {deletedIDs.map(noteID => (
                                    <li key={noteID}>
                                        <Button type="link" onClick={() => { 
                                            setSelectedDeletedNote(noteID); 
                                            setModalOpen(true);
                                        }}>
                                            {noteID}
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        </Collapse.Panel>
                        )}
                        {orphans.length > 0 && (
                            <Collapse.Panel header={`Orphan Notes (${orphans.length})`} key="orphans">
                                {orphans.map(note => (
                                    <div key={note.id} style={{ marginBottom: 8 }}>
                                        <Button type="link" onClick={() => navigateToNote(note.id)}>
                                            {note.doc_name} ({note.id})
                                        </Button>
                                        <span> - Has a parent listed but isn’t referenced in the tree.</span>
                                    </div>
                                ))}
                            </Collapse.Panel>
                        )}
                        {inconsistent.length > 0 && (
                            <Collapse.Panel header={`Inconsistent Parent Links (${inconsistent.length})`} key="inconsistent">
                                {inconsistent.map(note => (
                                    <div key={note.id} style={{ marginBottom: 8 }}>
                                        <Button type="link" onClick={() => navigateToNote(note.id)}>
                                            {note.doc_name} ({note.id})
                                        </Button>
                                        <span> - Parent record does not list this note as its child.</span>
                                    </div>
                                ))}
                            </Collapse.Panel>
                        )}
                    </Collapse>
                </div>
            )}
        </div>
    );
}

export function RelatedNotesLargePanel() {
    const {topLevelNoteHierarchy, isLoaded, percentLoadingComplete} = useContext(NoteHierarchyContext);
    const {extensions} = useExtensions();
    
    const navigateToNote = useNavigateToNote();
    const notesContext = useContext(NotesContext);
    const [whatToShow, setWhatToShow] = useStateLSString<WhatToShow>("relatedNotesPanel_whatToShow",WhatToShow.RelatedNotes);
    const [listType_internal, setListType] = useStateLSString<ListType>("relatedNotesPanel_listType",ListType.Flat);

    const listType = SupportsTreeWhatToShow.includes(whatToShow) ? listType_internal : ListType.Flat;

    const {tableDataSource, distinctTypes} = useMemo(()=>{            
        if (!topLevelNoteHierarchy?.isLoaded) return {tableDataSource:[], distinctTypes: []};
        const tableDataSource = [] as TableRelatedNotesDataType[];
        const distinctTypes = [] as string[];
        const displayedNotes = new Set<string>();

        for (const noteHierarchy of Object.values(topLevelNoteHierarchy.noteHierarchiesById)) {
            if (!distinctTypes.includes(noteHierarchy.note.type))
                distinctTypes.push(noteHierarchy.note.type);
            if (listType===ListType.Flat) {
                if (displayedNotes.has(noteHierarchy.note.id)) continue;
                if (whatToShow===WhatToShow.SavedNotes) {
                    // Check the selections. Also include the top level notes and their selections
                    let foundASelected = false;
                    for (const topNoteHierarchy of topLevelNoteHierarchy.topNoteHierarchies) {
                        if (topNoteHierarchy.selectedNoteIDs.includes(noteHierarchy.note.id)) {
                            foundASelected=true;
                            break;
                        }
                    }
                    if (!foundASelected)
                        continue;
                } else if (whatToShow===WhatToShow.CompletedNotes) {
                    // Check the selections. Also include the top level notes and their selections
                    let foundACompleted = false;
                    for (const topNoteHierarchy of topLevelNoteHierarchy.topNoteHierarchies) {
                        if (topNoteHierarchy.completedNoteIDs.includes(noteHierarchy.note.id)) {
                            foundACompleted=true;
                            break;
                        }
                    }
                    if (!foundACompleted)
                        continue;
                } else if (whatToShow===WhatToShow.Templates) {
                    const matches = isATemplateOrType(noteHierarchy.note)
                    if (!matches)
                        continue;
                }
                tableDataSource.push({
                    key: noteHierarchy.note.id,
                    title: getNoteNameAndEmoji_multipurpose(noteHierarchy.note, notesContext, extensions).note_title,
                    type: noteHierarchy.note.type,
                    noteHierarchy: noteHierarchy,
                });
                displayedNotes.add(noteHierarchy.note.id);
            }
        }
        if (listType===ListType.Tree) {
            // Check how many are selected/pinned. If just one, we show only children, not the parent, in the list.
            if (topLevelNoteHierarchy.topNoteHierarchies.length===1) {
                // Don't push the original on. It's already listed at the top as the context.
                const tableRelatedNotes = getNoteAndChildren(topLevelNoteHierarchy, topLevelNoteHierarchy.topNoteHierarchies[0], notesContext, [], "Always", extensions, displayedNotes);
                if (tableRelatedNotes && tableRelatedNotes.children)
                    tableDataSource.push(...tableRelatedNotes.children);
            } else {
                // Include all of them
                // Hierarchical list:
                for (const noteHierarchy of topLevelNoteHierarchy.topNoteHierarchies) {
                    const noteAndChildren = getNoteAndChildren(topLevelNoteHierarchy, noteHierarchy, notesContext, [], "Always", extensions, displayedNotes);
                    if (noteAndChildren) {
                        tableDataSource.push(noteAndChildren);
                    }
                }
            }

        }
        // console.log("tableDataSource",dataSource);
        return {tableDataSource, distinctTypes};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[topLevelNoteHierarchy?.noteHierarchiesById, topLevelNoteHierarchy?.isLoaded,listType, whatToShow]);

    const tableColumns:TableProps<TableRelatedNotesDataType>['columns'] = useMemo(()=>{
        const tableColumnsInner = [{
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => <Button type="link" onClick={()=>navigateToNote(record.noteHierarchy.note.id)}>{text}</Button>,
                sorter: (a, b) => a.title.localeCompare(b.title),
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            autoFocus
                            placeholder="Search note names"
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => { confirm(); setListType(ListType.Flat); }}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Button type="primary" onClick={() => { confirm(); setListType(ListType.Flat); }} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
                            Search
                        </Button>
                        <Button onClick={() => { clearFilters && clearFilters(); confirm(); }} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </div>
                ),
                filterIcon: filtered => (
                    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
                ),
                onFilter: (value, record) => record.title.toLowerCase().includes(String(value).toLowerCase()),
                filterSearch: true,
            }, {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                width: 150,
                filters: distinctTypes.map((type)=>({text:type,value:type})),
                onFilter: (value, record) => record.type === value,
                sorter: (a, b) => a.type.localeCompare(b.type),
            }
        ] as TableProps<TableRelatedNotesDataType>['columns'];
        if (listType===ListType.Tree)
            //@ts-ignore tableColumnsInner is never undefined. What are you thinking, TypeScript?
            tableColumnsInner.push({
                title: 'Include',
                dataIndex: 'inclusionType',
                key: 'inclusionType',
                width: 150,
                filters: [
                    {text:"Always",value:"Always"},
                    {text:"As needed",value:"As needed"},
                    {text:"EditContext",value:"EditContext"},
                ],
                onFilter: (value, record) => record.inclusionType === value,
                //@ts-ignore inclusionType is never undefined when listType is Tree
                sorter: (a, b) => a.inclusionType.localeCompare(b.inclusionType),
            });
        return tableColumnsInner;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[distinctTypes, listType, navigateToNote, setListType]);

    const {segmentedOptions, relatedComponents} = useMemo(()=>{
        // TODO implement the other warnings, these are just the deleted notes.. We'll have to get them from above somehow after load time, likely using a passed react ref.
        const countWarnings = notesContext.getDeletedNoteIDs().length;
        const segmentedOptions = [
            {value:WhatToShow.RelatedNotes,label: WhatToShow.RelatedNotes, icon:<AppstoreOutlined />},
            {value:WhatToShow.SavedNotes, label: WhatToShow.SavedNotes, icon:<SelectOutlined />},
            {value:WhatToShow.CompletedNotes, label:WhatToShow.CompletedNotes, icon:<CheckCircleOutlined/>},
            {value:WhatToShow.Templates, label:WhatToShow.Templates, icon:<CopyOutlined />},
            { value: WhatToShow.Warnings, label: `Warnings (${countWarnings})` } // renamed option
        ] as SegmentedOptions<WhatToShow>;

        // Get additional components to show from the extensions:
        const relatedComponents = {} as {[key:string]:()=>JSX.Element};
        for (const extension of extensions) {
            for (const relatedListType of extension.relatedListTypes) {
                segmentedOptions.push({                    
                    value: relatedListType.name as WhatToShow,
                    label: relatedListType.name,
                    // icon: extension.icon,
                });
                relatedComponents[relatedListType.name] = relatedListType.listComponent;
            }
        }
        return {segmentedOptions, relatedComponents};
    },[extensions, notesContext.getDeletedNoteIDs()]); // include deletedNoteIDs in deps

    const extensionComponentInsteadOfTable = relatedComponents[whatToShow];

    // console.log("[RelatedNotesLargePanel] isLoaded=",isLoaded,"topLevelNoteHierarchy=",topLevelNoteHierarchy);

    return <div className="related-notes-large-panel">
        <h3>Context</h3>
        <PinnedAndSelectedSection/>
        <br/>

        {DEBUG_LOAD && <div>
            isLoaded = {isLoaded ? "true" : "false"}<br/>
            percentLoadingComplete = {percentLoadingComplete}<br/>
        </div>}

        {!isLoaded && topLevelNoteHierarchy && <Progress type="circle" percent={Math.round(percentLoadingComplete*100)}/>}

        {topLevelNoteHierarchy?.isLoaded && <>
            <Segmented value={whatToShow} onChange={setWhatToShow} options={segmentedOptions}/>
            {SupportsTreeWhatToShow.includes(whatToShow) && <Segmented value={listType} onChange={setListType} options={[
                {value:ListType.Flat, icon: <UnorderedListOutlined />},
                {value:ListType.Tree, icon: <ApartmentOutlined />}]}/>}

            {whatToShow === WhatToShow.Warnings ? (
                <NoteWarningsPanel/>
            ) : extensionComponentInsteadOfTable ? (
                <div className="extensionComponentInsteadOfTable">{extensionComponentInsteadOfTable()}</div>
            ) : (
                <Table
                    rowKey={(record) => record.key}  // Added for stable expansions
                    columns={tableColumns}
                    dataSource={tableDataSource}
                    scroll={{y:"calc( 100vh - 300px )"}}
                    size="small"
                    pagination={false}/>
            )}
        </>}

        {/* TODO link to the search for save games and a button to create a new save game */}
        {extensions.length===0 && <div>To use the TTRPG extension select a TTRPG save game Note.</div>}
    </div>;
}