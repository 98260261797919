import { useRef, useEffect, useContext } from "react";
import { recommendMusic, TagSelectionResponse } from "./MusicRecommenderAI";
import { GenericTestPage, GenericTestPageRef, Test } from "../../../TestPages/GenericTestPage";
import { LLMServerContextProvider, SelectableLLMServerDropdown, LLMServerContext } from "../../../DecisionGraph/ChatEditor/UI/SelectableLLM";
import { GENRE_FANTASY, GENRE_SCIFI, MOOD_CALM, MOOD_EMOTIONAL, MOOD_EPIC, MOOD_HOPEFUL, MOOD_HORROR, SOUNDTRACK, TAG_BEGINNING_OF_AN_ADVENTURE, TAG_CHASE } from "../../../DecisionGraph/Utilities/Sound/Soundtrack1";
import { LLMServer } from "../GenericChatServerConsts";

const MINIMUM_TAG_COUNT = 10;

const testCases = [
    {
        name: "Detect Tags - Basic Test",
        userInput: "I need an epic and hopeful soundtrack for the start of a fantasy adventure.",
        expectedTags: [MOOD_EPIC, MOOD_HOPEFUL, GENRE_FANTASY, TAG_BEGINNING_OF_AN_ADVENTURE],
        expectedSongCount: 1
    },
    {
        name: "Detect Tags - 1 Matching Songs",
        userInput: "I need a soundtrack for a sci-fi horror chase.",
        expectedTags: [GENRE_SCIFI, MOOD_HORROR, TAG_CHASE],
        expectedSongCount: 1
    },
    {
        name: "Detect Tags - Matching Songs",
        userInput: "I need an emotional soundtrack.",
        expectedTags: [MOOD_EMOTIONAL, ],
        expectedSongCount: 14
    }
];

async function runTest(test:{name:string, userInput: string, expectedTags: string[], expectedSongCount: number}, llmServer:LLMServer): Promise<{ success: boolean, message?: string }> {
    // Get llmServer from context
    const result: TagSelectionResponse = await recommendMusic(test.userInput, undefined, llmServer);
    const expectedTags = test.expectedTags;
    const expectedSongCount = SOUNDTRACK.filter(track => expectedTags.every(tag => track.tags.includes(tag))).length;

    const missingTags = expectedTags.filter(tag => !result.tags.includes(tag));
    const extraTags = result.tags.filter(tag => !expectedTags.includes(tag));

    if (missingTags.length === 0 && extraTags.length === 0 && result.songs.length === expectedSongCount) {
        return { success: true };
    } else {
        let message = "Mismatch found: ";
        if (missingTags.length > 0) {
            message += `Missing tags: ${missingTags.join(", ")}. `;
        }
        if (extraTags.length > 0) {
            message += `Extra tags: ${extraTags.join(", ")}. `;
        }
        if (result.songs.length !== expectedSongCount) {
            message += `Expected ${expectedSongCount} matching songs, but found ${result.songs.length}.`;
        }
        return { success: false, message };
    }
}

// Internal component (renamed to avoid exporting directly)
function MusicRecommenderAITestPageInternal() {
    const testPageRef = useRef<GenericTestPageRef | null>(null);
    const { serverType } = useContext(LLMServerContext);

    const tests: Test[] = [
        {
            name: "Check Tag Groups and Count",
            check: async function() {
                const tags = SOUNDTRACK.reduce((acc, track) => {
                    track.tags.forEach(tag => {
                        if (!acc.includes(tag)) {
                            acc.push(tag);
                        }
                    });
                    return acc;
                }, [] as string[]);
    
                const groupedTags = tags.reduce((acc, tag) => {
                    const [category] = tag.split(": ");
                    if (!acc[category]) {
                        acc[category] = [];
                    }
                    acc[category].push(tag);
                    return acc;
                }, {} as { [key: string]: string[] });
    
                const tagGroupCount = Object.keys(groupedTags).length;
                const totalTagCount = tags.length;
    
                if (tagGroupCount < 3) {
                    return { success: false, message: `Expected at least 3 tag groups, but found ${tagGroupCount}` };
                }
                if (totalTagCount < MINIMUM_TAG_COUNT) {
                    return { success: false, message: `Expected at least ${MINIMUM_TAG_COUNT} tags, but found ${totalTagCount}` };
                }
                return { success: true };
            }
        },
        ...testCases.map(test => ({
            name: test.name,
            check: () => runTest(test, serverType)
        }))
    ];

    useEffect(() => {
        if (!serverType) return; // wait until serverType is defined
        if (testPageRef.current) {
            testPageRef.current.runSelectedTest();
        }
    }, [serverType]);

    return (
        <GenericTestPage ref={testPageRef} tests={tests} title="Music Detection AI Test Page" children={<SelectableLLMServerDropdown />} />
    );
}

// Wrapper component that provides the LLMServerContextProvider
export default function MusicRecommenderAITestPageWrapper() {
    return (
        <LLMServerContextProvider>
            <MusicRecommenderAITestPageInternal />
        </LLMServerContextProvider>
    );
}
