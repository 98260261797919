import React, { useContext, useEffect, useRef, useState } from 'react';
import type { GetRef } from 'antd';
import { Button, Form, Input, Popconfirm, Select, Table } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { Note, NotePromptMentionExamples, PROMPT_MENTION_TYPES } from '../../../Data/NoteType';
import { useSaveNoteDebounced } from '../../../Data/NoteDBHooks';
import { saveRootDocAnswer } from '../../../Data/FirestoreJsonFormsClient';

type InputRef = GetRef<typeof Input>;
type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface DataType extends NotePromptMentionExamples {
    key: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DataType;
  record: DataType;
  handleSave: (record: DataType) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
        //@ts-ignore
        if (inputRef.current?.focus)
            //@ts-ignore
            inputRef.current.focus();
    }
  }, [editing]);

  function toggleEdit() {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  }

  async function save() {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  }

  let childNode = children;
  if (editable) {
    if (editing) {
        if (dataIndex==="promptExample" || dataIndex==="reasoning")
            childNode = <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[{
                    required: true,
                    message: `${title} is required.`,
                    }]}
                >
                    <Input ref={inputRef} style={{width:"100%"}} onPressEnter={save} onBlur={save} placeholder="Enter an example prompt" />
                </Form.Item>
        else if (dataIndex==="type") {
            childNode = <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[{
                    required: true,
                    message: `${title} is required.`,
                    }]}
                >
                    <Select style={{ width: "100%" }} onChange={save} options={
                        PROMPT_MENTION_TYPES.map((type:string) => {
                            return { value: type, label: type }
                        })}/>
                </Form.Item>;
        }
    } else 
        childNode = <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
              {children}
            </div>;
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const DEFAULT_PROMPT_VALUE = "Enter an example prompt";
const DEFAULT_TYPE_VALUE = "Enter a type";
const DEFAULT_REASONING_VALUE = "Enter reasoning";

const DEFAULT_DATA: DataType = {
    promptExample: DEFAULT_PROMPT_VALUE,
    //@ts-ignore
    type: DEFAULT_TYPE_VALUE,
    reasoning: DEFAULT_REASONING_VALUE
};

export default function NoteMentionPrompt({note}: {note:Note}) {
    const promptMentionExamples=note.promptMentionExamples || [] as NotePromptMentionExamples[];
    const [dataSource, setDataSource] = useState<DataType[]>(promptMentionExamples.map((example:NotePromptMentionExamples, index:number) => {
        return {
            key: index.toString(),
            promptExample: example.promptExample,
            type: example.type,
            reasoning: example.reasoning
        }
    }));
    const saveNoteCallback = useSaveNoteDebounced(note.id);

    function saveToNote(newData:DataType[]) {
        // Also save to the server.
        const newDataNoKey = newData.map((item:DataType)=>{
            return {
                promptExample: item.promptExample,
                type: item.type,
                reasoning: item.reasoning,
            }
        });
        saveRootDocAnswer("promptMentionExamples", newDataNoKey, note, saveNoteCallback);
    }

  function handleDelete(key: React.Key) {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    saveToNote(newData);
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Prompt Example',
      dataIndex: 'promptExample',
      width: '70%',
      editable: true,
    },
    {
      title: 'Mentions this',
      dataIndex: 'type',
      editable: true,
    },
    {
      title: 'Reasoning',
      dataIndex: 'reasoning',
      editable: true,
    },
    {
      title: '',
      dataIndex: 'operation',
      //@ts-ignore
      render: (_, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <Button type="link" style={{ padding: 0 }}>Delete</Button>
          </Popconfirm>
        ) : null,
    },
  ];

  function handleAdd() {
    const newData: DataType = {
        ...DEFAULT_DATA,
      key: uuidv4(),
    };
    setDataSource([...dataSource, newData]);
    // We don't saveToNote here because there's no data yet... We wait until the user has edited.
  }

  function handleSave(row: DataType) {
    console.log("handleSave: row=",row);
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    saveToNote(newData);
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
        Add a row
      </Button>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
      />
    </div>
  );
};
