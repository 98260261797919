import React from 'react';
import { useMusicRecommendation } from '../../Utilities/Sound/MusicRecommendationContext';

export default function AutoPlayRecommendedMusicCheckbox() {
  const { isMusicRecommendationEnabled, setIsMusicRecommendationEnabled } = useMusicRecommendation();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMusicRecommendationEnabled(event.target.checked);
  };

  return <label>
        <input
          type="checkbox"
          checked={isMusicRecommendationEnabled}
          onChange={handleCheckboxChange}
        />
        Auto play recommended music
      </label>
}

