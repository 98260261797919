import { useState, useEffect, useCallback, useImperativeHandle, forwardRef } from "react";
import { Select, Tooltip, Button, Table, Progress, message as antdMessage } from "antd";
import { CopyOutlined } from "@ant-design/icons";

export type TestResults = {
    success: boolean;
    message?: string; // error if failure, optional context if success
};

export type Test = {
    name: string;
    check: () => Promise<TestResults>;
};

export type GenericTestPageRef = {
    runSelectedTest: () => void;
};

export const GenericTestPage = forwardRef((
    { tests, title, children }: { tests: Test[]; title: string; children?: React.ReactNode },
    ref
) => {
    const [results, setResults] = useState<{ name: string; success: boolean; message: string | undefined }[]>([]);
    const [selectedTest, setSelectedTest] = useState<string>("all");
    const [output, setOutput] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);

    async function runTestDef(test: Test) {
        try {
            const result = await test.check();
            const success = result.success;
            setResults(prev => [...prev, { name: test.name, success, message: result.message }]);
            return success;
        } catch (e: any) {
            console.error("Error running test itself: " + test.name, e);
            setResults(prev => [...prev, { name: test.name, success: false, message: e.toString() }]);
            return false;
        }
    }

    const runAllTests = useCallback(async () => {
        setOutput("");
        setLoading(true);
        setResults([]);
        setProgress(0);
        let successCount = 0;
        for (let i = 0; i < tests.length; i++) {
            const success = await runTestDef(tests[i]);
            if (success) successCount++;
            setProgress(Math.round(((i + 1) / tests.length) * 100));
        }
        const endTime = new Date().toLocaleTimeString();
        if (successCount === tests.length) {
            // A better icon would be <CheckCircleTwoTone twoToneColor="#52c41a" />
            setOutput(`✔ All ${tests.length} succeeded. Completed at ${endTime}.`);
        } else {
            setOutput(`All tests finished: ${successCount}/${tests.length} succeeded. Completed at ${endTime}.`);
        }
        setLoading(false);
    }, [tests]);

    async function runOneTest(test: Test) {
        setOutput("");
        setLoading(true);
        setResults([]);
        const success = await runTestDef(test);
        const endTime = new Date().toLocaleTimeString();
        setOutput(`Test finished: ${success ? "✔" : "❌"} ${test.name}. Completed at ${endTime}.`);
        setLoading(false);
    }

    async function runOneTestByName(name: string) {
        const test = tests.find(t => t.name.toLowerCase() === name);
        if (!test) {
            setOutput("Test not found");
            setLoading(false);
            return;
        }
        await runOneTest(test);
    }

    async function runSelectedTest(selectedTest2:string = selectedTest) {
        if (selectedTest2 === "all") {
            await runAllTests();
        } else {
            await runOneTestByName(selectedTest2);
        }
    }

    function setSelectedTestAndRun(value: string) {
        setSelectedTest(value);
        runSelectedTest(value);
    }

    function copyErrorMessage(testName: string, errorMessage: string) {
        const textToCopy = `On page '${title}' Test '${testName}' is erroring with the output '${errorMessage}'. Find the source of the bug. If it's unclear, improve the output message (not console) to include additional dynamic runtime information (don't add any info that's visible in the code itself, e.g. exclude const variable data). If it's clear, fix the error.`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            antdMessage.success("Error message copied to clipboard");
        }).catch(err => {
            console.error("Failed to copy text: ", err);
        });
    }

    useImperativeHandle(ref, () => ({
        runSelectedTest
    }));

    return (
        <div>
            <h1>{title}</h1>
            {children}
            <div>
                <Select style={{ width: "450px" }} value={selectedTest} onChange={setSelectedTestAndRun}>
                    <Select.Option value="all">Run All Tests</Select.Option>
                    {tests.map(test => (
                        <Select.Option key={test.name} value={test.name.toLowerCase()}>
                            {test.name}
                        </Select.Option>
                    ))}
                </Select>
                <Tooltip title="Run the selected test or all tests">
                    <Button onClick={()=>runSelectedTest()} disabled={loading}>Run</Button>
                </Tooltip>
            </div>
            <div>{output}</div>
            {loading && <Progress percent={progress} />}
            {results.length > 0 && (
                <Table
                    dataSource={results}
                    columns={[
                        { title: 'Status', dataIndex: 'success', key: 'success', render: (success: boolean) => success ? "✔" : "❌" },
                        { title: 'Test Name', dataIndex: 'name', key: 'name' },
                        { title: 'Message', dataIndex: 'message', key: 'message', render: (message: string, record) => (
                            <div style={{maxWidth: '800px' }}>
                                <Tooltip title={message}>
                                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                        {message}
                                    </div>
                                </Tooltip>
                                {!record.success && (
                                    <Tooltip title="Copy fix prompt">
                                        <Button
                                            icon={<CopyOutlined />}
                                            onClick={() => copyErrorMessage(record.name, message)}
                                            style={{ marginLeft: 8 }}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        ) },
                    ]}
                    rowKey="name"
                />
            )}
        </div>
    );
});
